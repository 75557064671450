import React from 'react';
import { Modal, TextField } from '@shopify/polaris';
import { withTranslation } from 'react-i18next';

class NewFolderModal extends React.Component {
    constructor() {
        super();
        this.state = {
            folderNameDisplayText: undefined,
        };
        this.onFolderNameChanged = this.onFolderNameChanged.bind(this);
    }

    onFolderNameChanged(newVal) {
        this.setState({
            folderNameDisplayText: newVal,
        });
    }

    render() {
        const { onCancel, onSetNewFolderName, t } = this.props;
        return (<>
            <Modal
                open={true}
                onClose={() => onCancel()}
                title={t('workflows.projects.addNewFolder')}
                primaryAction={{
                    content: t('form.label.ok'),
                    onAction: () => { onSetNewFolderName(this.state.folderNameDisplayText) },
                }}>
                <Modal.Section>
                    <TextField
                        label={t('workflows.folders.newFolderName')}
                        onChange={this.onFolderNameChanged}
                        value={this.state.folderNameDisplayText}
                    />
                </Modal.Section>
            </Modal>
        </>);
    }
}

export default withTranslation()(NewFolderModal);
