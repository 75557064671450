import React from 'react';
import * as userActions from "../../../redux/actions/userActions";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { Link, TextStyle, Form, FormLayout, TextField, Button, DisplayText, Page } from '@shopify/polaris';
import inputValidators from '../../../utilities/validation/inputValidators';
import { hasValidationErrors, hasEmptyInputFields } from '../../../utilities/validation/formValidation';
import { SignUp } from 'aws-amplify-react/lib/Auth';
import PageLoader from "../../../components/PageLoader/PageLoader";
import { withTranslation } from 'react-i18next';
import ExternalNavigation from "../../Navigation/TopNavigation/ExternalNavigation";

const validators = {
    email: inputValidators.email,
    password: inputValidators.password,
    firstName: inputValidators.name,
    lastName: inputValidators.name
};

class CustomSignup extends SignUp {
    constructor(props) {
        super(props);
        this.state = {
            user: {
                email: undefined,
                password: undefined,
                firstName: undefined,
                lastName: undefined,
                organizationId: 21,
            },
            isValid: {
                email: true,
                password: true,
                firstName: true,
                lastName: true,
            },
            isLoading: false,
            errorMessage: undefined,
        }

        this.handleIsLoadingChange = this.handleIsLoadingChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    async handleSubmit(event) {
        event.preventDefault();
        await this.handleIsLoadingChange(true);
        try {
            await this.props.userActions.createSimpleUserAction(this.state.user);
            this.changeState('signedIn', this.props.signedInUser);
            this.props.history.push('/search');
        } catch (err) {
            console.log('signup error is: ', err);
            this.setState({ errorMessage: this.props.t('form.errors.signinFail', { err }) });
        } finally {
            this.handleIsLoadingChange(false);
        }
    };

    handleIsLoadingChange(isLoading) {
        return new Promise((resolve, reject) => {
            this.setState({ isLoading, }, resolve());
        });
    }

    handleChange = (value, id) => {
        const change = {
            user: {
                ...this.state.user,
                [id]: value,
            },
            isValid: {
                ...this.state.isValid,
                [id]: validators[id](value)
            },
        };
        this.setState(change)
    }

    showComponent() {
        const { t } = this.props;
        const shouldDisableSubmit = hasValidationErrors(this.state.isValid) || hasEmptyInputFields(this.state.user);

        return (
            <>
                <ExternalNavigation external />
                <PageLoader show={this.state.isLoading} displayText={t('common.processing')} offset={{ bottom: 150 }} />
                <div className='user-authentication-container'>
                    {this.state.errorMessage && <TextStyle variation="negative">{this.state.errorMessage}</TextStyle>}
                    <div>
                        <form className='user-authentication-form' onSubmit={this.handleSubmit} autoComplete={true}>
                            <h2 className='form-header'>{t('auth.forgotPassword.resetPassword')}</h2>
                            <div className='field-item-content'>
                                <TextField id='firstName' type="text" placeholder={t('form.label.firstName')} error={this.state.isValid.firstName ? false : t('form.errors.firstName')} value={this.state.user.firstName} onChange={this.handleChange} />
                            </div>
                            <div className='field-item-content'>
                                <TextField id='lastName' type="text" placeholder={t('form.label.lastName')} error={this.state.isValid.lastName ? false : t('form.errors.lastName')} value={this.state.user.lastName} onChange={this.handleChange} />
                            </div>
                            <div className='field-item-content'>
                                <TextField id='email' type="email" placeholder={t('form.label.email')} error={this.state.isValid.email ? false : t('form.errors.email')} value={this.state.user.email} onChange={this.handleChange} />
                            </div>
                            <div className='field-item-content'>
                                <TextField id='password' type="password" placeholder={t('form.label.password')} autoComplete={true} error={this.state.isValid.password ? false : t('form.errors.passwordRules')} value={this.state.user.password} onChange={this.handleChange} />
                            </div>
                            <div className='submit-container'>
                                <Button submit primary disabled={shouldDisableSubmit}>{t('form.label.submit')}</Button>
                            </div>
                            <div className='form-link-container'>
                                <TextStyle variation="subdued">{t('auth.signup.alreadyHaveAccount')} <Link onClick={() => this.changeState('signIn')}> {t('common.here')}</Link></TextStyle>
                            </div>
                        </form>
                    </div>
                    <div className='hide'>
                        <Form onSubmit={this.handleSubmit} autoComplete={true}>
                            <FormLayout>
                                <DisplayText element='h1' size='large'>{t('auth.signup.signupHere')}</DisplayText>
                                <TextField id='firstName' type="text" label={t('form.label.firstName')} error={this.state.isValid.firstName ? false : t('form.errors.firstName')} value={this.state.user.firstName} onChange={this.handleChange} />
                                <TextField id='lastName' type="text" label={t('form.label.lastName')} error={this.state.isValid.lastName ? false : t('form.errors.lastName')} value={this.state.user.lastName} onChange={this.handleChange} />
                                <TextField id='email' type="email" label={t('form.label.email')} error={this.state.isValid.email ? false : t('form.errors.email')} value={this.state.user.email} onChange={this.handleChange} />
                                <TextField id='password' type="password" label={t('form.label.password')} autoComplete={true} error={this.state.isValid.password ? false : t('form.errors.passwordRules')} value={this.state.user.password} onChange={this.handleChange} />
                                <Button submit primary disabled={shouldDisableSubmit}>{t('form.label.submit')}</Button>
                                <TextStyle variation="subdued">{t('auth.signup.alreadyHaveAccount')} <Link onClick={() => this.changeState('signIn')}> {t('common.here')}</Link></TextStyle>
                            </FormLayout>
                        </Form>
                    </div>
                </div>
            </>
        );
    }
}

const mapStateToProps = (state, ownProps) => {
    return {
        userInfoComposite: state.user.userInfoComposite,
        signedInUser: state.user.signedInUser
    };
}

const mapDispatchToProps = dispatch => {
    return {
        userActions: bindActionCreators(userActions, dispatch)
    }
}
export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(CustomSignup));
