import { searchProjectsByOrganizationId } from '../../api/vermillionApi/projectManagementApi';
import * as types from "../constants/action-types";

export const searchProjectsByOrganizationIdSuccess = (projects) => {
    return { type: types.GET_PROJECTS_SUCCESS, projects };
};

export const selectProjectSuccess = (selectedProject) => {
    return { type: types.SELECT_PROJECT_SUCCESS, selectedProject };
};

export const getAdminProjectsSuccess = (adminProjects) => {
    return { type: types.GET_ADMIN_PROJECTS_SUCCESS, adminProjects };
};

export const createProjectSuccess = () => {
    return { type: types.CREATE_PROJECT_SUCCESS };
};

export const getProjectsByOrganizationId = (organizationId) => {
    return async (dispatch) => {
        const projects = await searchProjectsByOrganizationId(organizationId);
        dispatch(searchProjectsByOrganizationIdSuccess(projects));
        return;
    };
};

export const selectProject = (project) => {
    return (dispatch) => {
        dispatch(selectProjectSuccess(project));
        return;
    };
}