import React from 'react';

export const TermsEn = () => {

	return (
		<div>
		  <meta httpEquiv="Content-Type" content="text/html; charset=utf-8" />
		  <meta name="Generator" content="Microsoft Word 15 (filtered)" />
		  <style dangerouslySetInnerHTML={{__html: "\n<!--\n /* Font Definitions */\n @font-face\n\t{font-family:Wingdings;\n\tpanose-1:5 0 0 0 0 0 0 0 0 0;}\n@font-face\n\t{font-family:\"Cambria Math\";\n\tpanose-1:2 4 5 3 5 4 6 3 2 4;}\n@font-face\n\t{font-family:\"Yu Mincho\";\n\tpanose-1:2 2 4 0 0 0 0 0 0 0;}\n@font-face\n\t{font-family:\"\\@Yu Mincho\";}\n@font-face\n\t{font-family:\"MS PGothic\";\n\tpanose-1:2 11 6 0 7 2 5 8 2 4;}\n@font-face\n\t{font-family:\"\\@MS PGothic\";}\n /* Style Definitions */\n p.MsoNormal, li.MsoNormal, div.MsoNormal\n\t{margin:0cm;\n\tmargin-bottom:.0001pt;\n\tfont-size:12.0pt;\n\tfont-family:\"MS PGothic\",sans-serif;}\np.MsoFooter, li.MsoFooter, div.MsoFooter\n\t{mso-style-link:\"Footer Char\";\n\tmargin:0cm;\n\tmargin-bottom:.0001pt;\n\tlayout-grid-mode:char;\n\tfont-size:12.0pt;\n\tfont-family:\"MS PGothic\",sans-serif;}\np.MsoListParagraph, li.MsoListParagraph, div.MsoListParagraph\n\t{margin-top:0cm;\n\tmargin-right:0cm;\n\tmargin-bottom:0cm;\n\tmargin-left:42.0pt;\n\tmargin-bottom:.0001pt;\n\tfont-size:12.0pt;\n\tfont-family:\"MS PGothic\",sans-serif;}\nspan.FooterChar\n\t{mso-style-name:\"Footer Char\";\n\tmso-style-link:Footer;\n\tfont-family:\"MS PGothic\",sans-serif;}\n.MsoChpDefault\n\t{font-family:\"Yu Mincho\",serif;}\n /* Page Definitions */\n @page WordSection1\n\t{size:595.3pt 841.9pt;\n\tmargin:99.25pt 3.0cm 3.0cm 3.0cm;\n\tlayout-grid:18.0pt;}\ndiv.WordSection1\n\t{page:WordSection1;}\n /* List Definitions */\n ol\n\t{margin-bottom:0cm;}\nul\n\t{margin-bottom:0cm;}\n-->\n" }} />
		  <div className="WordSection1" style={{layoutGrid: '18.0pt'}}>
			<p className="MsoNormal"><b><u><span lang="EN-US" style={{fontSize: '14.0pt', fontFamily: '"Times New Roman",serif', color: 'black'}}>Terms of Use</span></u></b><span lang="EN-US" style={{fontSize: '10.5pt', fontFamily: '"Times New Roman",serif', color: 'black'}}> </span></p>
			<p className="MsoNormal"><span lang="EN-US" style={{fontSize: '10.5pt', fontFamily: '"Times New Roman",serif', color: 'black'}}>&nbsp;</span></p>
			<p className="MsoNormal"><span lang="EN-US" style={{fontSize: '10.5pt', fontFamily: '"Times New Roman",serif', color: 'black'}}>In using DAM ‘Digital Asset Management’ Service (hereinafter this
				‘System’) operated and provided by the Japan National Tourism Agency
				(hereinafter ‘JNTO’), the user must agree to the following terms of use
				(hereinafter these ‘Terms of Use’). </span></p>
			<p className="MsoNormal"><b><u><span lang="EN-US" style={{fontSize: '10.5pt', fontFamily: '"Times New Roman",serif', color: 'black'}}><span style={{textDecoration: 'none'}}>&nbsp;</span></span></u></b></p>
			<p className="MsoNormal"><b><u><span lang="EN-US" style={{fontSize: '10.5pt', fontFamily: '"Times New Roman",serif', color: 'black'}}>Article 1 Purpose of this System</span></u></b></p>
			<p className="MsoNormal"><span lang="EN-US" style={{fontSize: '10.5pt', fontFamily: '"Times New Roman",serif', color: 'black'}}>&nbsp;</span></p>
			<p className="MsoNormal"><span lang="EN-US" style={{fontSize: '10.5pt', fontFamily: '"Times New Roman",serif', color: 'black'}}>This System is meant to encourage the use of digital assets
				(images, video, text etc.) for the purpose of raising awareness of the diverse
				sightseeing charm of Japan and to increase the number of overseas tourists
				visiting here.</span></p>
			<p className="MsoNormal"><b><u><span lang="EN-US" style={{fontSize: '10.5pt', fontFamily: '"Times New Roman",serif', color: 'black'}}><span style={{textDecoration: 'none'}}>&nbsp;</span></span></u></b></p>
			<p className="MsoNormal"><b><u><span lang="EN-US" style={{fontSize: '10.5pt', fontFamily: '"Times New Roman",serif', color: 'black'}}>Article 2 Registration etc. for
					Organizations</span></u></b></p>
			<p className="MsoNormal" style={{marginLeft: '21.0pt', textIndent: '-21.0pt'}}><span lang="EN-US" style={{fontSize: '10.5pt', fontFamily: '"Times New Roman",serif', color: 'black'}}>1. Government agencies, public organizations, municipalities, and
				other external organizations, by agreeing to these Terms of Use, the Digital
				Asset Upload Terms, and the Digital Asset Download Terms (hereinafter,
				collectively referred as ‘Terms of Use etc.’) may be registered to use this
				System by the JNTO; and, these organizations (hereinafter, ‘Primary Registered
				Organizations’) will be able to use this System. </span></p>
			<p className="MsoNormal" style={{marginLeft: '21.0pt', textIndent: '-21.0pt'}}><span lang="EN-US" style={{fontSize: '10.5pt', fontFamily: '"Times New Roman",serif', color: 'black'}}>&nbsp;</span></p>
			<p className="MsoNormal" style={{marginLeft: '21.0pt', textIndent: '-21.0pt'}}><span lang="EN-US" style={{fontSize: '10.5pt', fontFamily: '"Times New Roman",serif', color: 'black'}}>2. Primary Registered Organizations may register the use of this
				System to third parties charged with duties that contribute to the purpose of
				use of digital assets (See Purpose of Use in Digital Asset Download Terms) or
				that are charged with the management of digital assets. These third parties
				registered to use this System shall be referred to as Secondary Registered
				Organizations. Secondary Registered Organizations may use this System. </span></p>
			<p className="MsoNormal" style={{marginLeft: '21.0pt', textIndent: '-21.0pt'}}><span lang="EN-US" style={{fontSize: '10.5pt', fontFamily: '"Times New Roman",serif', color: 'black'}}>&nbsp;</span></p>
			<p className="MsoNormal" style={{marginLeft: '21.0pt', textIndent: '-21.0pt'}}><span lang="EN-US" style={{fontSize: '10.5pt', fontFamily: '"Times New Roman",serif', color: 'black'}}>3. The registered use of this System by Primary Registered
				Organizations and Secondary Registered Organizations (hereinafter, collectively
				referred to as ‘Registered Organizations’) must submit the JNTO-specified
				application for use and other documentation to the Secretariat. Additionally,
				the JNTO, may select a suitable operator as an external contractor for
				Secretariat duties, and may conclude contracts on confidentiality and safety
				management etc., and carry out the appropriate oversight. </span></p>
			<p className="MsoNormal" style={{marginLeft: '21.0pt', textIndent: '-21.0pt'}}><span lang="EN-US" style={{fontSize: '10.5pt', fontFamily: '"Times New Roman",serif', color: 'black'}}>&nbsp;</span></p>
			<p className="MsoNormal" style={{marginLeft: '21.0pt', textIndent: '-21.0pt'}}><span lang="EN-US" style={{fontSize: '10.5pt', fontFamily: '"Times New Roman",serif', color: 'black'}}>4. The JNTO reserves the right to revoke or refuse registration of
				Registered Organizations that apply or have completed an application for
				registration to use this System in any of the following circumstances; and, the
				JNTO accepts no liability to the Registered Organization regarding such.
				Further, the JNTO bears no responsibility to explain to the Registered
				Organization the reason for said revocation or refusal, and the Registered
				Organization does not have the right to protest such. </span></p>
			<p className="MsoNormal" style={{marginLeft: '21.0pt'}}><span lang="EN-US" style={{fontSize: '10.5pt', fontFamily: '"Times New Roman",serif', color: 'black'}}>I. Should
				the information, in part or in whole, provided to the JNTO be falsified, in
				error, or be omitted; </span></p>
			<p className="MsoNormal" style={{marginLeft: '21.0pt'}}><span lang="EN-US" style={{fontSize: '10.5pt', fontFamily: '"Times New Roman",serif', color: 'black'}}>II.
				Should the organization incur revocation or refusal of registered use in the
				past; </span></p>
			<p className="MsoNormal" style={{marginLeft: '21.0pt'}}><span lang="EN-US" style={{fontSize: '10.5pt', fontFamily: '"Times New Roman",serif', color: 'black'}}>III.
				Should there be a lack of users at the Registered Organization; </span></p>
			<p className="MsoNormal" style={{marginLeft: '21.0pt'}}><span lang="EN-US" style={{fontSize: '10.5pt', fontFamily: '"Times New Roman",serif', color: 'black'}}>IV.
				Should the JNTO otherwise deem the registered use to be inappropriate.</span></p>
			<p className="MsoNormal" style={{marginLeft: '21.0pt', textIndent: '-21.0pt'}}><span lang="EN-US" style={{fontSize: '10.5pt', fontFamily: '"Times New Roman",serif', color: 'black'}}>&nbsp;</span></p>
			<p className="MsoNormal" style={{marginLeft: '21.0pt', textIndent: '-21.0pt'}}><span lang="EN-US" style={{fontSize: '10.5pt', fontFamily: '"Times New Roman",serif', color: 'black'}}>5. The Registered Organization may not transfer to a third party
				nor allow the use of the registered e-mail address and password to this System
				by a third party.</span></p>
			<p className="MsoNormal" style={{marginLeft: '21.0pt', textIndent: '-21.0pt'}}><span lang="EN-US" style={{fontSize: '10.5pt', fontFamily: '"Times New Roman",serif', color: 'black'}}>&nbsp;</span></p>
			<p className="MsoNormal" style={{marginLeft: '21.0pt', textIndent: '-21.0pt'}}><span lang="EN-US" style={{fontSize: '10.5pt', fontFamily: '"Times New Roman",serif', color: 'black'}}>6. The Registered Organization shall keep strict control over the
				registered e-mail address and password to this System, and shall not allow the
				password to this System to be known by a third party. </span></p>
			<p className="MsoNormal" style={{marginLeft: '21.0pt', textIndent: '-21.0pt'}}><span lang="EN-US" style={{fontSize: '10.5pt', fontFamily: '"Times New Roman",serif', color: 'black'}}>&nbsp;</span></p>
			<p className="MsoNormal" style={{marginLeft: '21.0pt', textIndent: '-21.0pt'}}><span lang="EN-US" style={{fontSize: '10.5pt', fontFamily: '"Times New Roman",serif', color: 'black'}}>7. Should the Registered Organization incur damage through the use
				of the registered e-mail address or password to this System by a third party,
				the JNTO shall assume no liability whatsoever for such, regardless of the
				willful intent or negligence of the Registered Organization. </span></p>
			<p className="MsoNormal" style={{marginLeft: '21.0pt', textIndent: '-21.0pt'}}><span lang="EN-US" style={{fontSize: '10.5pt', fontFamily: '"Times New Roman",serif', color: 'black'}}>&nbsp;</span></p>
			<p className="MsoNormal" style={{marginLeft: '21.0pt', textIndent: '-21.0pt'}}><span lang="EN-US" style={{fontSize: '10.5pt', fontFamily: '"Times New Roman",serif', color: 'black'}}>8. Should changes occur to the details submitted to the JNTO
				regarding the Registered Organization, or should the Registered Organization
				determine that said details are inaccurate, the Registered Organization shall
				promptly notify the JNTO of such. The Registered Organization shall register
				contact details where they can be always be reached, and shall promptly reply
				to any inquiries received from the JNTO. </span></p>
			<p className="MsoNormal" style={{marginLeft: '21.0pt', textIndent: '-21.0pt'}}><span lang="EN-US" style={{fontSize: '10.5pt', fontFamily: '"Times New Roman",serif', color: 'black'}}>&nbsp;</span></p>
			<p className="MsoNormal" style={{marginLeft: '21.0pt', textIndent: '-21.0pt'}}><span lang="EN-US" style={{fontSize: '10.5pt', fontFamily: '"Times New Roman",serif', color: 'black'}}>9. The JNTO assumes no responsibility whatsoever should the
				Registered Organization incur any disadvantage by failing to notify the JNTO of
				any changes or revisions of the preceding paragraph. </span></p>
			<p className="MsoNormal" style={{marginLeft: '21.0pt', textIndent: '-21.0pt'}}><span lang="EN-US" style={{fontSize: '10.5pt', fontFamily: '"Times New Roman",serif', color: 'black'}}>&nbsp;</span></p>
			<p className="MsoNormal" style={{marginLeft: '21.0pt', textIndent: '-21.0pt'}}><span lang="EN-US" style={{fontSize: '10.5pt', fontFamily: '"Times New Roman",serif', color: 'black'}}>10. The JNTO may register Secondary Registered Organizations to
				this System. </span></p>
			<p className="MsoNormal" style={{marginLeft: '21.0pt', textIndent: '-21.0pt'}}><span lang="EN-US" style={{fontSize: '10.5pt', fontFamily: '"Times New Roman",serif', color: 'black'}}>&nbsp;</span></p>
			<p className="MsoNormal" style={{marginLeft: '21.0pt', textIndent: '-21.0pt'}}><span lang="EN-US" style={{fontSize: '10.5pt', fontFamily: '"Times New Roman",serif', color: 'black'}}>11. Primary Registered Organizations shall ensure that Secondary
				Registered Organizations observe the Terms of Use etc., and any breach of the
				Terms of Use etc. by Secondary Registered Organizations shall be deemed to be a
				breach of the Terms of Use etc. by the Primary Registered Organization. </span></p>
			<p className="MsoNormal" style={{marginLeft: '21.0pt', textIndent: '-21.0pt'}}><span lang="EN-US" style={{fontSize: '10.5pt', fontFamily: '"Times New Roman",serif', color: 'black'}}>&nbsp;</span></p>
			<p className="MsoNormal" style={{marginLeft: '21.0pt', textIndent: '-21.0pt'}}><span lang="EN-US" style={{fontSize: '10.5pt', fontFamily: '"Times New Roman",serif', color: 'black'}}>&nbsp;12. Media organizations and travel agencies outside of Japan
				registered with Japan Online Media Center (hereinafter, the ‘JOMC’) run by the
				JNTO, may download and use digital assets from this System by agreeing to the Terms
				of Use etc. through the JOMC screen (Media organizations and travel agencies
				outside of Japan that agree to the Terms of Use etc. shall be referred to
				hereinafter as ‘Overseas Media etc.’). Overseas Media etc. shall be deemed as
				Primary Registered Organizations in regards to the application of the Terms of
				Use etc., and the stipulations on uploading digital assets and Secondary
				Registered Organizations shall not be applied to Overseas Media etc.</span></p>
			<p className="MsoNormal"><b><u><span lang="EN-US" style={{fontSize: '10.5pt', fontFamily: '"Times New Roman",serif', color: 'black'}}><span style={{textDecoration: 'none'}}>&nbsp;</span></span></u></b></p>
			<p className="MsoNormal"><b><u><span lang="EN-US" style={{fontSize: '10.5pt', fontFamily: '"Times New Roman",serif', color: 'black'}}>Article 3 User Registration etc.</span></u></b></p>
			<p className="MsoNormal"><span lang="EN-US" style={{fontSize: '10.5pt', fontFamily: '"Times New Roman",serif', color: 'black'}}>1. Individuals, among the officers and employees of the JNTO and
				Registered Organizations, requiring use of this System to perform their duties
				may be registered as users of this System (hereinafter, ‘Users’). Moreover, the
				registered e-mail addresses of Users shall contain the organization’s domain
				name.<br />
				<br />
			  </span></p>
			<p className="MsoNormal"><span lang="EN-US" style={{fontSize: '10.5pt', fontFamily: '"Times New Roman",serif', color: 'black'}}>2. The JNTO reserves the right to revoke the registration of Users
				who have completed user registration should any of the following circumstances
				apply; and, the JNTO accepts no liability to the Registered Organization
				regarding such. Further, the JNTO bears no responsibility to explain to the
				Registered Organization the reason for said revocation, and the Registered
				Organization does not have the right to protest such. </span></p>
			<p className="MsoNormal" style={{marginLeft: '21.0pt'}}><span lang="EN-US" style={{fontSize: '10.5pt', fontFamily: '"Times New Roman",serif', color: 'black'}}>I. The
				period of validity of the user account has expired; </span></p>
			<p className="MsoNormal" style={{marginLeft: '21.0pt'}}><span lang="EN-US" style={{fontSize: '10.5pt', fontFamily: '"Times New Roman",serif', color: 'black'}}>II. Should
				the information, in part or in whole, provided to the JNTO be falsified, in
				error, or be omitted; </span></p>
			<p className="MsoNormal" style={{marginLeft: '21.0pt'}}><span lang="EN-US" style={{fontSize: '10.5pt', fontFamily: '"Times New Roman",serif', color: 'black'}}>III. Should
				the individual have their user registration revoked in the past;</span></p>
			<p className="MsoNormal" style={{marginLeft: '21.0pt'}}><span lang="EN-US" style={{fontSize: '10.5pt', fontFamily: '"Times New Roman",serif', color: 'black'}}>IV. Should
				the User resign from the Registered Organization, or should their duties no
				longer require them to use this System due to transfer or otherwise; </span></p>
			<p className="MsoNormal" style={{marginLeft: '21.0pt'}}><span lang="EN-US" style={{fontSize: '10.5pt', fontFamily: '"Times New Roman",serif', color: 'black'}}>V.
				Should the JNTO otherwise deem the registered use to be inappropriate.</span></p>
			<p className="MsoNormal"><span lang="EN-US" style={{fontSize: '10.5pt', fontFamily: '"Times New Roman",serif', color: 'black'}}>&nbsp;</span></p>
			<p className="MsoNormal"><span lang="EN-US" style={{fontSize: '10.5pt', fontFamily: '"Times New Roman",serif', color: 'black'}}>3. The User may not transfer to a third party nor allow the use of
				the registered e-mail address and password to this System by a third party
				(This includes individuals belonging to the same Registered Organization.). (Further,
				should the User no longer be tasked with duties requiring use of this System
				due to resigning from the Registered Organization or a transfer, the user
				account and registered e-mail address may remain the same when the successor
				user of this System applies to change the user’s registered information.)<br />
				<br />
			  </span></p>
			<p className="MsoNormal"><span lang="EN-US" style={{fontSize: '10.5pt', fontFamily: '"Times New Roman",serif', color: 'black'}}>4. The User shall keep strict control over the registered e-mail
				address and password to this System, and shall not allow the password to this
				System to be known by a third party (This includes individuals belonging to the
				same Registered Organization.). <br />
				<br />
			  </span></p>
			<p className="MsoNormal"><span lang="EN-US" style={{fontSize: '10.5pt', fontFamily: '"Times New Roman",serif', color: 'black'}}>5. Should the Registered Organization or User incur damage through
				the use of the registered e-mail address or password to this System by a third
				party (This includes individuals belonging to the same Registered
				Organization.), the JNTO shall assume no liability whatsoever for such,
				regardless of the willful intent or negligence of the User.<br />
				<br />
			  </span></p>
			<p className="MsoNormal"><span lang="EN-US" style={{fontSize: '10.5pt', fontFamily: '"Times New Roman",serif', color: 'black'}}>6. Should changes occur to the details submitted to the JNTO
				regarding the Registered Organization, department affiliation of the User,
				registered e-mail address etc., or should the User determine that said details
				are inaccurate, the User shall promptly notify the JNTO of such. The User shall
				register contact details where they can be always be reached, and shall
				promptly reply to any inquiries received from the JNTO.<br />
				<br />
			  </span></p>
			<p className="MsoNormal"><span lang="EN-US" style={{fontSize: '10.5pt', fontFamily: '"Times New Roman",serif', color: 'black'}}>7. The JNTO assumes no responsibility whatsoever should the
				Registered Organization or the User incur any disadvantage by failing to notify
				the JNTO of any changes or revisions of the preceding paragraph.<br />
				<br />
			  </span></p>
			<p className="MsoNormal"><span lang="EN-US" style={{fontSize: '10.5pt', fontFamily: '"Times New Roman",serif', color: 'black'}}>8. The period of validity of the user account shall be until the
				final day of March, 2021.<br />
				<br />
			  </span></p>
			<p className="MsoNormal"><span lang="EN-US" style={{fontSize: '10.5pt', fontFamily: '"Times New Roman",serif', color: 'black'}}>9. Registered Organizations shall ensure that Users observe the
				Terms of Use etc., and any breach of the Terms of Use etc. by a User shall be
				deemed to be a breach of the Terms of Use etc. by the Registered Organization.</span></p>
			<p className="MsoListParagraph" style={{marginLeft: '21.0pt', textIndent: '-21.0pt'}}><span lang="EN-US" style={{fontSize: '10.5pt', fontFamily: '"Times New Roman",serif', color: 'black'}}>&nbsp;</span></p>
			<p className="MsoListParagraph" style={{marginLeft: '21.0pt', textIndent: '-21.0pt'}}><span lang="EN-US" style={{fontSize: '10.5pt', fontFamily: '"Times New Roman",serif', color: 'black'}}>10. Should the User use this System or the digital assets in a
				manner that breaches the Terms of Use etc., the JNTO may suspend the User’s
				account.</span></p>
			<p className="MsoNormal"><b><u><span lang="EN-US" style={{fontSize: '10.5pt', fontFamily: '"Times New Roman",serif', color: 'black'}}><span style={{textDecoration: 'none'}}>&nbsp;</span></span></u></b></p>
			<p className="MsoNormal"><b><u><span lang="EN-US" style={{fontSize: '10.5pt', fontFamily: '"Times New Roman",serif', color: 'black'}}>Article 4 Use of this System</span></u></b></p>
			<p className="MsoNormal"><span lang="EN-US" style={{fontSize: '10.5pt', fontFamily: '"Times New Roman",serif', color: 'black'}}>&nbsp;</span></p>
			<p className="MsoNormal"><span lang="EN-US" style={{fontSize: '10.5pt', fontFamily: '"Times New Roman",serif', color: 'black'}}>1. The digital assets on this System, including images, video, and
				text, belong to the rights holder over that digital asset, and are protected by
				intellectual property rights law, including the Copyright Act. Unauthorized use
				of these digital assets without agreeing to the Terms of Use etc. is
				prohibited. </span></p>
			<p className="MsoNormal"><span lang="EN-US" style={{fontSize: '10.5pt', fontFamily: '"Times New Roman",serif', color: 'black'}}>&nbsp;</span></p>
			<p className="MsoNormal"><span lang="EN-US" style={{fontSize: '10.5pt', fontFamily: '"Times New Roman",serif', color: 'black'}}>2. Registered Organizations may not upload digital assets to this
				System without agreeing to the following Rules on Uploading Digital Assets.
				Primary Registered Organizations shall verify uploads of digital assets made by
				their Secondary Registered Organizations, and shall guarantee to other
				Registered Organizations that the upload of these digital assets does not
				breach the Terms of Use etc. <br />
				<br />
			  </span></p>
			<p className="MsoNormal"><span lang="EN-US" style={{fontSize: '10.5pt', fontFamily: '"Times New Roman",serif', color: 'black'}}>3. Registered Organizations may not download and use the digital
				assets of this System without agreeing to the following Rules on Downloading
				Digital Assets. Primary Registered Organizations shall guarantee to other
				Registered Organizations that the purpose of use of these digital assets by
				their Secondary Registered Organization does not breach the Terms of Use etc.</span></p>
			<p className="MsoNormal"><span lang="EN-US" style={{fontSize: '10.5pt', fontFamily: '"Times New Roman",serif', color: 'black'}}>&nbsp;</span></p>
			<p className="MsoNormal"><span lang="EN-US" style={{fontSize: '10.5pt', fontFamily: '"Times New Roman",serif', color: 'black'}}>4. In regards to the use of digital assets, the JNTO or Primary
				Registered Organization uploading (Both parties shall be referred to as the ‘Uploading
				Organization’ and shall include when their Secondary Registered Organization
				uploads.) and the JNTO or Primary Registered Organization downloading (Both
				parties shall be referred to as the ‘Downloading Organization’ and shall
				include when their Secondary Registered Organization downloads.) shall agree to
				the use of the digital assets, and this agreement shall be deemed to have been
				established at the time the digital assets are downloaded by the Downloading
				Organization. The Uploading Organization shall, according to the Term of Use
				etc., license the non-exclusive copyrights and all other rights of use of the digital
				assets to the Downloading Organization (However, this shall yield to any
				restrictions on use selected by the Uploading Organization and stipulated in
				the Terms of Use etc.). The JNTO assumes no liability whatsoever for any damage
				incurred through the use of this System or digital assets by the Primary
				Registered Organization. </span></p>
			<p className="MsoNormal"><span lang="EN-US" style={{fontSize: '10.5pt', fontFamily: '"Times New Roman",serif', color: 'black'}}>&nbsp;</span></p>
			<p className="MsoNormal"><span lang="EN-US" style={{fontSize: '10.5pt', fontFamily: '"Times New Roman",serif', color: 'black'}}>5. The digital asset library within this System is classified by
				project folder. Lower-ranked organizations may browse and use project folders
				within the scope that higher-ranked organizations may browse, and higher-ranked
				organizations can determine the project folders that lower-ranked organizations
				may browse and use. The following ranking lists the higher-ranked organizations
				to the lower-ranked organizations. </span></p>
			<p className="MsoNormal" style={{marginLeft: '21.0pt'}}><span lang="EN-US" style={{fontSize: '10.5pt', fontFamily: '"Times New Roman",serif', color: 'black'}}>I.
				JNTO (System administrator)</span></p>
			<p className="MsoNormal" style={{marginLeft: '21.0pt'}}><span lang="EN-US" style={{fontSize: '10.5pt', fontFamily: '"Times New Roman",serif', color: 'black'}}>II.
				JNTO (Head office and branch offices)</span></p>
			<p className="MsoNormal" style={{marginLeft: '21.0pt'}}><span lang="EN-US" style={{fontSize: '10.5pt', fontFamily: '"Times New Roman",serif', color: 'black'}}>III.
				Primary Registered Organizations</span></p>
			<p className="MsoNormal" style={{marginLeft: '21.0pt'}}><span lang="EN-US" style={{fontSize: '10.5pt', fontFamily: '"Times New Roman",serif', color: 'black'}}>IV.
				Secondary Registered Organizations</span></p>
			<p className="MsoNormal"><span lang="EN-US" style={{fontSize: '10.5pt', fontFamily: '"Times New Roman",serif', color: 'black'}}>6. Should a Registered Organization’s registration be revoked, or
				should there be a lack of Users in the Registered Organization, the digital
				assets uploaded to this System by the Registered Organization will be deleted,
				and the Downloading Organization that downloaded those digital assets will be
				notified of such. After this notification, the Downloading Organization may not
				use any new digital assets. However, should the Secondary Registered
				Organization be an Uploading Organization, the registration of that Primary
				Registered Organization shall continue, and should there be Users, this
				paragraph shall not apply.</span></p>
			<p className="MsoNormal"><b><u><span lang="EN-US" style={{fontSize: '10.5pt', fontFamily: '"Times New Roman",serif', color: 'black'}}><span style={{textDecoration: 'none'}}>&nbsp;</span></span></u></b></p>
			<p className="MsoNormal"><b><u><span lang="EN-US" style={{fontSize: '10.5pt', fontFamily: '"Times New Roman",serif', color: 'black'}}>Article 5 The Content of this System</span></u></b></p>
			<p className="MsoNormal"><span lang="EN-US" style={{fontSize: '10.5pt', fontFamily: '"Times New Roman",serif', color: 'black'}}>1. The JNTO may, should it deem it reasonably necessary, change,
				suspend, or abolish the contents of this System without bearing any
				responsibility whatsoever to the Registered Organization. <br />
				<br />
			  </span></p>
			<p className="MsoNormal"><span lang="EN-US" style={{fontSize: '10.5pt', fontFamily: '"Times New Roman",serif', color: 'black'}}>2. The JNTO may, should it deem it reasonably necessary, delete or
				alter the digital assets and other accompanying information uploaded to this
				System by the Registered Organization without prior notification to, nor
				requiring the consent of the Registered Organization, and without bearing any
				responsibility whatsoever to the Registered Organization. <br />
				<br />
			  </span></p>
			<p className="MsoNormal"><span lang="EN-US" style={{fontSize: '10.5pt', fontFamily: '"Times New Roman",serif', color: 'black'}}>3. The JNTO may, should it deem it reasonably necessary, alter the
				content of the Terms of Use etc. The Registered Organization’s consent to the
				newest version of the Terms of Use etc. shall be deemed to have been gained
				when the Registered Organization uses this System. <br />
				<br />
			  </span></p>
			<p className="MsoNormal"><span lang="EN-US" style={{fontSize: '10.5pt', fontFamily: '"Times New Roman",serif', color: 'black'}}>4. The JNTO does not guarantee the accuracy nor completeness of
				the digital assets, accompanying tags, titles or other information (This
				includes the display of copyrights, portrait rights, publicity rights and other
				rights.) (Hereinafter, collectively known as ‘accompanying information’.) on
				this System. When digital assets and accompanying information are uploaded to this
				System, and digital assets and accompanying information on this System are downloaded,
				the Registered Organization shall observe the Terms of Use etc., and shall bear
				responsibility for such. </span></p>
			<p className="MsoNormal"><b><u><span lang="EN-US" style={{fontSize: '10.5pt', fontFamily: '"Times New Roman",serif', color: 'black'}}><span style={{textDecoration: 'none'}}>&nbsp;</span></span></u></b></p>
			<p className="MsoNormal"><b><u><span lang="EN-US" style={{fontSize: '10.5pt', fontFamily: '"Times New Roman",serif', color: 'black'}}>Article 4 Prohibited Matters when Using
					this System</span></u></b></p>
			<p className="MsoNormal"><span lang="EN-US" style={{fontSize: '10.5pt', fontFamily: '"Times New Roman",serif', color: 'black'}}>Registered Organizations shall not engage in any of the following
				acts in the use of this System: </span></p>
			<p className="MsoNormal"><span lang="EN-US" style={{fontSize: '10.5pt', fontFamily: '"Times New Roman",serif', color: 'black'}}>1. Acts that infringe upon or have the potential to infringe upon
				the copyrights, and other intellectual property rights, portrait rights, the
				right to privacy, honor, or any other rights or interests of the JNTO,
				Registered Organizations, and all other third parties (This includes the
				unauthorized use of digital assets and accompanying information on this System
				as well as the uploading of digital assets copyrighted by third parties without
				the consent of that third party.);<br />
				<br />
			  </span></p>
			<p className="MsoNormal"><span lang="EN-US" style={{fontSize: '10.5pt', fontFamily: '"Times New Roman",serif', color: 'black'}}>2. Entering in false or mistaken information into this System;<br />
				<br />
			  </span></p>
			<p className="MsoNormal"><span lang="EN-US" style={{fontSize: '10.5pt', fontFamily: '"Times New Roman",serif', color: 'black'}}>3. Impeding the operation of this System through illegal or
				troublesome behavior through the Internet;<br />
				<br />
			  </span></p>
			<p className="MsoNormal"><span lang="EN-US" style={{fontSize: '10.5pt', fontFamily: '"Times New Roman",serif', color: 'black'}}>4. Accessing this System from a computer or other electronic
				device that does not have sufficient security measures in place;<br />
				<br />
			  </span></p>
			<p className="MsoNormal"><span lang="EN-US" style={{fontSize: '10.5pt', fontFamily: '"Times New Roman",serif', color: 'black'}}>5. Behavior that violates public order and decency, criminal
				behavior, and illicit behavior;<br />
				<br />
			  </span></p>
			<p className="MsoNormal"><span lang="EN-US" style={{fontSize: '10.5pt', fontFamily: '"Times New Roman",serif', color: 'black'}}>6. All other behavior deemed to be inappropriate by the JNTO.</span></p>
			<p className="MsoNormal"><span lang="EN-US" style={{fontSize: '10.5pt', fontFamily: '"Times New Roman",serif', color: 'black'}}>&nbsp;</span></p>
			<p className="MsoNormal"><span lang="EN-US" style={{fontSize: '10.5pt', fontFamily: '"Times New Roman",serif', color: 'black'}}>&nbsp;</span></p>
			<p className="MsoNormal"><b><u><span lang="EN-US" style={{fontSize: '10.5pt', fontFamily: '"Times New Roman",serif', color: 'black'}}>Article 6 Personal Information</span></u></b></p>
			<p className="MsoNormal"><span lang="EN-US" style={{fontSize: '10.5pt', fontFamily: '"Times New Roman",serif', color: 'black'}}>The JNTO shall appropriately handle personal information provided
				from Registered Organizations and Users regarding the use of this System based
				on the JNTO Privacy Policy, and Registered Organizations shall agree to the
				handling of said personal information based on the JNTO Privacy Policy and
				shall obtain consent from Users. The handling of specific personal information
				shall be pursuant to the JNTO basic policy regarding the appropriate handling
				of specific personal information, and Registered Organizations shall agree to
				this and shall obtain consent from Users. </span></p>
			<p className="MsoNormal"><span lang="EN-US" style={{fontSize: '10.5pt', fontFamily: '"Times New Roman",serif', color: 'black'}}>&nbsp;</span></p>
			<p className="MsoNormal"><b><u><span lang="EN-US" style={{fontSize: '10.5pt', fontFamily: '"Times New Roman",serif', color: 'black'}}>Article 7 Disclaimer Warning</span></u></b></p>
			<p className="MsoNormal"><span lang="EN-US" style={{fontSize: '10.5pt', fontFamily: '"Times New Roman",serif', color: 'black'}}>1. The JNTO does not guarantee to have received guarantees from
				providers of digital assets and accompanying information that said digital
				assets and accompanying information in this System does not infringe on the
				copyrights, portrait rights, or other rights of a third party. <br />
				<br />
			  </span></p>
			<p className="MsoNormal"><span lang="EN-US" style={{fontSize: '10.5pt', fontFamily: '"Times New Roman",serif', color: 'black'}}>2. The JNTO makes no guarantees whatsoever on the accuracy,
				completeness, or updated nature of the accompanying information. <br />
				<br />
			  </span></p>
			<p className="MsoNormal"><span lang="EN-US" style={{fontSize: '10.5pt', fontFamily: '"Times New Roman",serif', color: 'black'}}>3. This System and all digital assets and accompanying information
				contained therein are presented as is, and the JNTO makes absolutely no
				guarantee on the marketability or suitability for the specific purposes of
				such, nor whether said digital assets and accompanying information fulfill the
				demand of the Registered Organization, nor any defects contained therein. All
				Registered Organizations shall assume responsibility to verify and use this
				System and the digital assets and accompanying information therein.<br />
				<br />
			  </span></p>
			<p className="MsoNormal"><span lang="EN-US" style={{fontSize: '10.5pt', fontFamily: '"Times New Roman",serif', color: 'black'}}>4. The JNTO assumes no liability whatsoever to the Registered
				Organizations regarding computer system or network failure, accompanying
				display errors, nor other failures based on the accompanying environment of
				computers etc. <br />
				<br />
			  </span></p>
			<p className="MsoNormal"><span lang="EN-US" style={{fontSize: '10.5pt', fontFamily: '"Times New Roman",serif', color: 'black'}}>5. The JNTO assumes no liability whatsoever regarding the loss or
				damage of any data, such as the digital assets or accompanying information
				provided to the JNTO, including images, video, music data, or text. The
				Registered Organization shall make and maintain their own back-ups of such
				data. <br />
				<br />
			  </span></p>
			<p className="MsoNormal"><span lang="EN-US" style={{fontSize: '10.5pt', fontFamily: '"Times New Roman",serif', color: 'black'}}>6. The JNTO does not guarantee that this System, its servers,
				digital assets, accompanying information, or e-mails sent from this System are
				not infected with a computer virus or other malicious code. <br />
				<br />
			  </span></p>
			<p className="MsoNormal"><span lang="EN-US" style={{fontSize: '10.5pt', fontFamily: '"Times New Roman",serif', color: 'black'}}>7. The JNTO assumes no liability whatsoever in any circumstances
				for disputes with third parties or any other kind of related damage arising due
				to the Terms of Use etc., this System, the contents of this System, rights,
				reliance, or access granted based on the Terms of Use etc., nor the use,
				non-use, or delays etc. in such. <br />
				<br />
			  </span></p>
			<p className="MsoNormal"><b><u><span lang="EN-US" style={{fontSize: '10.5pt', fontFamily: '"Times New Roman",serif', color: 'black'}}>Article 8 Suspension/Discontinuance of
					this System</span></u></b></p>
			<p className="MsoNormal"><span lang="EN-US" style={{fontSize: '10.5pt', fontFamily: '"Times New Roman",serif', color: 'black'}}>The JNTO may suspend or discontinue the administration of this
				System without prior warning or notification to the Registered Organization.</span></p>
			<p className="MsoNormal"><b><u><span lang="EN-US" style={{fontSize: '10.5pt', fontFamily: '"Times New Roman",serif', color: 'black'}}><span style={{textDecoration: 'none'}}>&nbsp;</span></span></u></b></p>
			<p className="MsoNormal"><b><u><span lang="EN-US" style={{fontSize: '10.5pt', fontFamily: '"Times New Roman",serif', color: 'black'}}>Article 9 Governing Law/Dispute Resolution</span></u></b></p>
			<p className="MsoNormal"><span lang="EN-US" style={{fontSize: '10.5pt', fontFamily: '"Times New Roman",serif', color: 'black'}}>These Terms of Use etc. are governed by the laws of Japan. Should
				a dispute arise between the JNTO and a Registered Organization regarding the
				use of this System, the registration of an organization, the registration of a
				User, the Terms of Use etc., the JNTO and Registered Organization agree that
				the Tokyo District Court has primary, exclusive jurisdiction over the
				resolution of such. </span></p>
			<p className="MsoNormal"><span lang="EN-US" style={{fontSize: '10.5pt', fontFamily: '"Times New Roman",serif', color: 'black'}}>&nbsp;</span></p>
			<p className="MsoNormal"><b><u><span lang="EN-US" style={{fontSize: '14.0pt', fontFamily: '"Times New Roman",serif', color: 'black'}}>Rules on Uploading Digital Assets</span></u></b></p>
			<p className="MsoNormal"><span lang="EN-US" style={{fontSize: '10.5pt', fontFamily: '"Times New Roman",serif', color: 'black'}}>&nbsp;</span></p>
			<p className="MsoNormal"><span lang="EN-US" style={{fontSize: '10.5pt', fontFamily: '"Times New Roman",serif', color: 'black'}}>These Rules on Uploading Digital Assets (hereinafter, the
				‘Uploading Rules’) stipulate the conditions for uploading digital assets by
				Users to this System, and shall be applied to use of this System in conjunction
				with the Terms of Use and the Downloading Rules. Should either the Terms of Use
				or the Downloading Rules, or both, contradict or conflict with the Uploading
				Rules, the stipulations of the Uploading Rules shall take precedence in the
				uploading of digital assets. The terms defined in the Terms of Use or the
				Downloading Rules shall have the same meaning in the Uploading Rules unless
				stipulated separately by the Uploading Rules. </span></p>
			<p className="MsoNormal"><span lang="EN-US" style={{fontSize: '10.5pt', fontFamily: '"Times New Roman",serif', color: 'black'}}>The Uploading Organization shall hold the copyrights over the
				relevant digital assets. Additionally, the Downloading Organization, by
				downloading digital assets from this System, reaches an agreement with the
				Uploading Organization regarding the use of the digital assets, and the
				subsequent use of such shall be handled outside of this System, with both the
				Uploading Organization and the Downloading Organization agreeing to directly respond
				to such. </span></p>
			<p className="MsoNormal"><u><span lang="EN-US" style={{fontSize: '10.5pt', fontFamily: '"Times New Roman",serif', color: 'black'}}><span style={{textDecoration: 'none'}}>&nbsp;</span></span></u></p>
			<p className="MsoNormal"><u><span lang="EN-US" style={{fontSize: '10.5pt', fontFamily: '"Times New Roman",serif', color: 'black'}}>Uploadable Digital Assets</span></u></p>
			<p className="MsoNormal"><span lang="EN-US" style={{fontSize: '10.5pt', fontFamily: '"Times New Roman",serif', color: 'black'}}>Digital assets that can be uploaded to this System are as follows.
				Further, the JNTO may add/remove digital asset types that can be uploaded. </span></p>
			<p className="MsoNormal"><span lang="EN-US" style={{fontSize: '10.5pt', fontFamily: '"Times New Roman",serif', color: 'black'}}>&nbsp;</span></p>
			<p className="MsoNormal"><span lang="EN-US" style={{fontSize: '10.5pt', fontFamily: '"Times New Roman",serif', color: 'black'}}>1. Compatible filename extensions<br />
				.jpg .jpeg .png .gif .tiff .mv .mov .mp4 </span></p>
			<p className="MsoNormal"><span lang="EN-US" style={{fontSize: '10.5pt', fontFamily: '"Times New Roman",serif', color: 'black'}}>2. Metadata<br />
				File name, location taken, Prefecture, description, taken by, date/time taken,
				credit name, copyright holder, contact info, and other items indicated on the
				Appendix accompanying information input form</span></p>
			<p className="MsoNormal"><span lang="EN-US" style={{fontSize: '10.5pt', fontFamily: '"Times New Roman",serif', color: 'black'}}>&nbsp;</span></p>
			<p className="MsoNormal"><u><span lang="EN-US" style={{fontSize: '10.5pt', fontFamily: '"Times New Roman",serif', color: 'black'}}>Recommended size/volume</span></u></p>
			<p className="MsoNormal"><span lang="EN-US" style={{fontSize: '10.5pt', fontFamily: '"Times New Roman",serif', color: 'black'}}>The recommended size and volume of digital assets to be uploaded
				to this System is as follows. Video files that exceed the recommended volume
				should be compressed down to the recommended size before being uploaded. </span></p>
			<p className="MsoNormal"><span lang="EN-US" style={{fontSize: '10.5pt', fontFamily: '"Times New Roman",serif', color: 'black'}}>1. Recommended image size (Over 3,000 pixels and less than 5,000
				pixels; less than 10Mb) </span></p>
			<p className="MsoNormal"><span lang="EN-US" style={{fontSize: '10.5pt', fontFamily: '"Times New Roman",serif', color: 'black'}}>2. Recommended video size (less than 300Mb) </span></p>
			<p className="MsoNormal"><span lang="EN-US" style={{fontSize: '10.5pt', fontFamily: '"Times New Roman",serif', color: 'black'}}>The maximum total upload volume is 4GB for one Registered
				Organization (including Secondary Registered Organizations). <br />
				<br />
			  </span></p>
			<p className="MsoNormal"><u><span lang="EN-US" style={{fontSize: '10.5pt', fontFamily: '"Times New Roman",serif', color: 'black'}}>Restrictions on Use</span></u></p>
			<p className="MsoNormal"><span lang="EN-US" style={{fontSize: '10.5pt', fontFamily: '"Times New Roman",serif', color: 'black'}}>The Uploading Organization can impose the following restrictions
				on the uploaded digital asset. Additionally, restrictions cannot be added or
				changed after the digital asset has been uploaded. </span></p>
			<p className="MsoNormal"><span lang="EN-US" style={{fontSize: '10.5pt', fontFamily: '"Times New Roman",serif', color: 'black'}}>1. Restricting use by certain parties </span></p>
			<p className="MsoNormal" style={{marginLeft: '24.0pt'}}><span lang="EN-US" style={{fontSize: '10.5pt', fontFamily: '"Times New Roman",serif', color: 'black'}}>The
				following parties may be selected (multiple parties may be selected). Additionally,
				Secondary Registered Organizations tasked with uploading or downloading are
				also included among the following parties.</span></p>
			<p className="MsoNormal"><span lang="EN-US" style={{fontSize: '10.5pt', fontFamily: '"Times New Roman",serif', color: 'black'}}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; I. JNTO </span></p>
			<p className="MsoNormal" style={{textIndent: '48.0pt'}}><span lang="EN-US" style={{fontSize: '10.5pt', fontFamily: '"Times New Roman",serif', color: 'black'}}>II. Government
				agencies and public organizations, and other external organizations that are
				Primary Registered Organizations</span></p>
			<p className="MsoNormal" style={{textIndent: '48.0pt'}}><span lang="EN-US" style={{fontSize: '10.5pt', fontFamily: '"Times New Roman",serif', color: 'black'}}>III.
				Municipalities and other external organizations that are Primary Registered
				Organizations </span></p>
			<p className="MsoNormal"><span lang="EN-US" style={{fontSize: '10.5pt', fontFamily: '"Times New Roman",serif', color: 'black'}}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; IV. Media organizations and travel agencies
				outside of Japan<br />
				<br />
			  </span></p>
			<p className="MsoNormal" style={{marginLeft: '21.0pt', textIndent: '-21.0pt'}}><span lang="EN-US" style={{fontSize: '10.5pt', fontFamily: '"Times New Roman",serif', color: 'black'}}>2. Restrictions of period of use</span></p>
			<p className="MsoNormal" style={{marginLeft: '21.0pt', textIndent: '-21.0pt'}}><span lang="EN-US" style={{fontSize: '10.5pt', fontFamily: '"Times New Roman",serif', color: 'black'}}>&nbsp;</span></p>
			<p className="MsoNormal" style={{marginLeft: '21.0pt', textIndent: '-21.0pt'}}><span lang="EN-US" style={{fontSize: '10.5pt', fontFamily: '"Times New Roman",serif', color: 'black'}}>3. Restrictions on processing of digital assets </span></p>
			<p className="MsoNormal" style={{marginLeft: '21.0pt', textIndent: '-21.0pt'}}><span lang="EN-US" style={{fontSize: '10.5pt', fontFamily: '"Times New Roman",serif', color: 'black'}}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Enabling/disabling trimming, reducing size, altering aspect
				ratio</span></p>
			<p className="MsoNormal" style={{marginLeft: '24.0pt'}}><span lang="EN-US" style={{fontSize: '10.5pt', fontFamily: '"Times New Roman",serif', color: 'black'}}>Enabling/disabling
				adjustment of brightness, contrast ratio, tone, hue etc. </span></p>
			<p className="MsoNormal" style={{marginLeft: '21.0pt', textIndent: '-21.0pt'}}><span lang="EN-US" style={{fontSize: '10.5pt', fontFamily: '"Times New Roman",serif', color: 'black'}}>4. Restrictions on combining text </span></p>
			<p className="MsoNormal" style={{marginLeft: '21.0pt', textIndent: '-21.0pt'}}><span lang="EN-US" style={{fontSize: '10.5pt', fontFamily: '"Times New Roman",serif', color: 'black'}}>5. Compulsory credits </span></p>
			<p className="MsoNormal"><span lang="EN-US" style={{fontSize: '10.5pt', fontFamily: '"Times New Roman",serif', color: 'black'}}>6. Requiring advanced notice when downloading digital assets</span></p>
			<p className="MsoNormal" style={{marginLeft: '24.0pt'}}><span lang="EN-US" style={{fontSize: '10.5pt', fontFamily: '"Times New Roman",serif', color: 'black'}}>When the
				Uploading Organization adjusts the settings to require advanced notice when
				downloading digital assets (hereinafter, ‘advanced notice setting’), the JNTO
				or Registered Organization will enter the details of use when wishing to
				download a digital asset, the details of use shall be relayed to the Uploading
				Organization through the specified method. Should the Uploading Organization
				consent to the download, the JNTO or Registered Organization wishing to
				download will be able to download the digital asset.</span></p>
			<p className="MsoNormal"><span lang="EN-US" style={{fontSize: '10.5pt', fontFamily: '"Times New Roman",serif', color: 'black'}}>&nbsp;</span></p>
			<p className="MsoNormal"><u><span lang="EN-US" style={{fontSize: '10.5pt', fontFamily: '"Times New Roman",serif', color: 'black'}}>Guarantee on Digital Assets to be Uploaded</span></u></p>
			<p className="MsoNormal"><span lang="EN-US" style={{fontSize: '10.5pt', fontFamily: '"Times New Roman",serif', color: 'black'}}>1. The Uploading Organization either makes its own guarantee over
				all rights should they exist on the digital assets or copies thereof to be
				uploaded to this System, including portrait rights, publicity rights, trademark
				rights, copyrights (including neighboring rights), and any other third party
				right, or guarantees that consent has been obtained from the rights holder or
				their legitimate representative (including consent for non-execution of
				author’s moral rights) for the use of such on this System and the use of such
				by the JNTO and Registered Organizations in accordance with the Terms of Use
				etc. </span></p>
			<p className="MsoNormal"><span lang="EN-US" style={{fontSize: '10.5pt', fontFamily: '"Times New Roman",serif', color: 'black'}}>&nbsp;</span></p>
			<p className="MsoNormal"><span lang="EN-US" style={{fontSize: '10.5pt', fontFamily: '"Times New Roman",serif', color: 'black'}}>2. Should digital assets that include pictures of people or
				portraits that allow people to be identified be uploaded to this System, the Uploading
				Organization expresses and guarantees that consent has been obtained from the people
				in the pictures or all of the people identifiable in the portrait (hereinafter,
				the ‘subjects etc.’) for the use of their portrait rights regarding use on this
				System and use by the JNTO or Registered Organization in accordance with the
				Terms of Use etc. (model release), and that such consent is based on the real
				intention of the subjects etc.</span></p>
			<p className="MsoNormal"><span lang="EN-US" style={{fontSize: '10.5pt', fontFamily: '"Times New Roman",serif', color: 'black'}}>&nbsp;</span></p>
			<p className="MsoNormal"><span lang="EN-US" style={{fontSize: '10.5pt', fontFamily: '"Times New Roman",serif', color: 'black'}}>3. The Uploading Organization guarantees that the use of uploaded
				digital assets on this System and their use by the JNTO and Registered
				Organizations in accordance with the Terms of Use etc. does not infringe on the
				rights of a third party.</span></p>
			<p className="MsoNormal"><span lang="EN-US" style={{fontSize: '10.5pt', fontFamily: '"Times New Roman",serif', color: 'black'}}>&nbsp;</span></p>
			<p className="MsoNormal"><span lang="EN-US" style={{fontSize: '10.5pt', fontFamily: '"Times New Roman",serif', color: 'black'}}>4. When the advanced notice setting has been activated, the
				details of use (purpose, medium, period etc.) input by the Registered
				Organization wishing to download a digital asset shall be relayed to the Uploading
				Organization. The Uploading Organization shall, without delay, determine if use
				is to be authorized, and shall input such into this System. Should the Uploading
				Organization not input into this System if use is to be authorized within 14
				days of receiving notice, use shall not be deemed to be authorized.<br />
				<br />
			  </span></p>
			<p className="MsoNormal"><span lang="EN-US" style={{fontSize: '10.5pt', fontFamily: '"Times New Roman",serif', color: 'black'}}>5. The Registered Organization guarantees that, at the point that
				a digital asset is uploaded to this System, there are no problems with its
				quality, licensing for use, nor rights infringement etc., and that all tags,
				titles and other attached information are correct.</span></p>
			<p className="MsoNormal"><span lang="EN-US" style={{fontSize: '10.5pt', fontFamily: '"Times New Roman",serif', color: 'black'}}>&nbsp;</span></p>
			<p className="MsoNormal"><span lang="EN-US" style={{fontSize: '10.5pt', fontFamily: '"Times New Roman",serif', color: 'black'}}>6. Should problems, such as claims or litigation, result through
				the digital asset content or errors in the accompanying information (This
				includes accompanying information appended by the JNTO. The same shall apply
				below.), the Uploading Organization shall assume responsibility for such. <br />
				<br />
			  </span></p>
			<p className="MsoNormal"><span lang="EN-US" style={{fontSize: '10.5pt', fontFamily: '"Times New Roman",serif', color: 'black'}}>7. Should any claims, objections, complaints, or demands for
				compensation of rights infringement be directed to the JNTO from a third party
				regarding digital assets or accompanying information etc., and the JNTO suffers
				any damages because of this, the Uploading Organization of the digital asset in
				question shall compensate the JNTO for all expenses required, including damage
				incurred by the JNTO as well as reasonable lawyer’s fees. Additionally, should
				the aforementioned claims, objections, complaints, or demands for compensation
				be directed to the JNTO, the JNTO shall disclose to the third party the contact
				information of the Uploading Organization and the User that uploaded the
				digital asset in question to this System, as well as any other information held
				by the JNTO regarding the Uploading Organization and User in question, and the Uploading
				Organization shall consent to this disclosure and obtain consent from the User.
				<br />
				<br />
			  </span></p>
			<p className="MsoNormal"><u><span lang="EN-US" style={{fontSize: '10.5pt', fontFamily: '"Times New Roman",serif', color: 'black'}}>Approval/Refusal to Post Uploaded Digital
				  Assets</span></u></p>
			<p className="MsoNormal"><span lang="EN-US" style={{fontSize: '10.5pt', fontFamily: '"Times New Roman",serif', color: 'black'}}>The JNTO may review digital assets uploaded to this System by the Uploading
				Organization, and may approve or refuse the posting of such, or edit etc. the
				accompanying information. The Uploading Organization shall promptly reply to
				any inquiries received from the JNTO regarding a digital asset it has uploaded.
			  </span></p>
			<p className="MsoNormal"><u><span lang="EN-US" style={{fontSize: '10.5pt', fontFamily: '"Times New Roman",serif', color: 'black'}}><span style={{textDecoration: 'none'}}>&nbsp;</span></span></u></p>
			<p className="MsoNormal"><u><span lang="EN-US" style={{fontSize: '10.5pt', fontFamily: '"Times New Roman",serif', color: 'black'}}>Deletion of Uploaded Digital Assets</span></u></p>
			<p className="MsoNormal"><span lang="EN-US" style={{fontSize: '10.5pt', fontFamily: '"Times New Roman",serif', color: 'black'}}>The JNTO may, without any warning to the Uploading Organization,
				delete a digital asset that has been uploaded to this System by the Uploading
				Organization. The JNTO will not accept any inquiries regarding the reasons for
				this deletion. Further, the JNTO may revoke the registration of the Uploading
				Organization that uploaded a digital asset deemed inappropriate by the JNTO,
				and may suspend the account of the User that uploaded such. </span></p>
			<p className="MsoNormal" style={{textIndent: '12.0pt'}}><span lang="EN-US" style={{fontSize: '10.5pt', fontFamily: '"Times New Roman",serif', color: 'black'}}>Additionally,
				the JNTO may process the digital asset or edit the accompanying information in
				the following circumstances, but may also delete the digital asset without
				carrying out any such processing or editing: </span></p>
			<p className="MsoNormal"><span lang="EN-US" style={{fontSize: '10.5pt', fontFamily: '"Times New Roman",serif', color: 'black'}}>&nbsp;</span></p>
			<p className="MsoNormal" style={{marginLeft: '12.0pt'}}><span lang="EN-US" style={{fontSize: '10.5pt', fontFamily: '"Times New Roman",serif', color: 'black'}}>I.
				Should the digital asset display information clearly in error;</span></p>
			<p className="MsoNormal" style={{marginLeft: '12.0pt'}}><span lang="EN-US" style={{fontSize: '10.5pt', fontFamily: '"Times New Roman",serif', color: 'black'}}>II.
				Should it be discovered that the digital asset contravenes the prohibited
				matters stipulated in the Terms of Use; </span></p>
			<p className="MsoNormal" style={{marginLeft: '12.0pt'}}><span lang="EN-US" style={{fontSize: '10.5pt', fontFamily: '"Times New Roman",serif', color: 'black'}}>III.
				Should the JNTO determine that a deletion would contribute to enhancing the
				convenience of the System.</span></p>
			<p className="MsoNormal"><span lang="EN-US" style={{fontSize: '10.5pt', fontFamily: '"Times New Roman",serif', color: 'black'}}>&nbsp;</span></p>
			<p className="MsoNormal"><span lang="EN-US" style={{fontSize: '10.5pt', fontFamily: '"Times New Roman",serif', color: 'black'}}>&nbsp;</span></p>
			<p className="MsoNormal"><b><u><span lang="EN-US" style={{fontSize: '14.0pt', fontFamily: '"Times New Roman",serif', color: 'black'}}>Rules on Downloading Digital Assets</span></u></b></p>
			<p className="MsoNormal"><span lang="EN-US" style={{fontSize: '10.5pt', fontFamily: '"Times New Roman",serif', color: 'black'}}>These Rules on Downloading Digital Assets (hereinafter, the
				‘Downloading Rules’) stipulate the conditions for downloading digital assets from
				this System by the Downloading Organizations, and shall be applied to the use
				of this System in conjunction with the Terms of Use and the Uploading Rules.
				Should either the Terms of Use or the Uploading Rules, or both, contradict or
				conflict with the Downloading Rules, the stipulations of the Downloading Rules
				shall take precedence in the downloading of digital assets and use of
				downloaded digital assets. The terms defined in the Terms of Use or the Uploading
				Rules shall have the same meaning in the Downloading Rules unless stipulated
				separately by the Downloading Rules. </span></p>
			<p className="MsoNormal"><span lang="EN-US" style={{fontSize: '10.5pt', fontFamily: '"Times New Roman",serif', color: 'black'}}>&nbsp;</span></p>
			<p className="MsoNormal"><span lang="EN-US" style={{fontSize: '10.5pt', fontFamily: '"Times New Roman",serif', color: 'black'}}>The Downloading Organization, in regards to any issues of rights
				over the digital asset to be downloaded, shall use as reference the digital
				assets and accompanying information (The Uploading Organization guarantees the
				accuracy of such, but the JNTO, being the administrator of this System, makes
				no such guarantee.) to carefully verify the status of rights for these digital
				assets and accompanying information and use such at their own responsibility
				and discretion. </span></p>
			<p className="MsoNormal"><span lang="EN-US" style={{fontSize: '10.5pt', fontFamily: '"Times New Roman",serif', color: 'black'}}>&nbsp;</span></p>
			<p className="MsoNormal"><span lang="EN-US" style={{fontSize: '10.5pt', fontFamily: '"Times New Roman",serif', color: 'black'}}>Additionally, the Downloading Organization, by downloading digital
				assets from this System, reaches an agreement with the Uploading Organization
				regarding the use of the digital assets, and the subsequent use of such shall
				be handled outside of this System, with both the Uploading Organization and the
				Downloading Organization agreeing to directly respond to such.</span></p>
			<p className="MsoNormal"><span lang="EN-US" style={{fontSize: '10.5pt', fontFamily: '"Times New Roman",serif', color: 'black'}}>&nbsp;</span></p>
			<p className="MsoNormal"><u><span lang="EN-US" style={{fontSize: '10.5pt', fontFamily: '"Times New Roman",serif', color: 'black'}}>Restrictions on Volume</span></u></p>
			<p className="MsoNormal"><span lang="EN-US" style={{fontSize: '10.5pt', fontFamily: '"Times New Roman",serif', color: 'black'}}>The maximum total download capacity for a single Registered
				Organization (including Secondary Registered Organizations) is 4GB. </span></p>
			<p className="MsoNormal"><span lang="EN-US" style={{fontSize: '10.5pt', fontFamily: '"Times New Roman",serif', color: 'black'}}>&nbsp;</span></p>
			<p className="MsoNormal"><u><span lang="EN-US" style={{fontSize: '10.5pt', fontFamily: '"Times New Roman",serif', color: 'black'}}>Purpose of Use</span></u></p>
			<p className="MsoNormal"><span lang="EN-US" style={{fontSize: '10.5pt', fontFamily: '"Times New Roman",serif', color: 'black'}}>The Downloading Organization may download and use digital assets
				from this System only for the purpose below. However, the digital assets may
				not be used for commercial purposes. Media organizations and travel agencies
				outside of Japan may use the digital assets for promotional purposes related to
				the following purposes that promote tourism to Japan. </span></p>
			<p className="MsoNormal"><span lang="EN-US" style={{fontSize: '10.5pt', fontFamily: '"Times New Roman",serif', color: 'black'}}>&nbsp;(Purpose) Use for the purpose of raising awareness of the diverse
				sightseeing charm of Japan and to increase the number of overseas tourists
				visiting here.</span></p>
			<p className="MsoNormal"><span lang="EN-US" style={{fontSize: '10.5pt', fontFamily: '"Times New Roman",serif', color: 'black'}}>&nbsp;</span></p>
			<p className="MsoNormal"><span lang="EN-US" style={{fontSize: '10.5pt', fontFamily: '"Times New Roman",serif', color: 'black'}}>Additionally, when downloading (in advance when advanced notice
				setting is activated), the Downloading Organization shall register the details
				of use of the digital assets to be downloaded (purpose, medium, period etc.). Further,
				the details of use will be relayed to the Uploading Organization. Should the
				advanced notice setting be activated, the digital asset will be first made
				available for download when approval for use is granted by the Uploading
				Organization after notice has been made. </span></p>
			<p className="MsoNormal"><span lang="EN-US" style={{fontSize: '10.5pt', fontFamily: '"Times New Roman",serif', color: 'black'}}>&nbsp;</span></p>
			<p className="MsoNormal"><u><span lang="EN-US" style={{fontSize: '10.5pt', fontFamily: '"Times New Roman",serif', color: 'black'}}>Processing/Use</span></u></p>
			<p className="MsoNormal"><span lang="EN-US" style={{fontSize: '10.5pt', fontFamily: '"Times New Roman",serif', color: 'black'}}>Downloaded digital assets may be processed/used within the follow
				range only when permission has been granted for such. The presence/absence of
				this permission can be verified with the digital asset’s accompanying
				information. </span></p>
			<p className="MsoNormal"><span lang="EN-US" style={{fontSize: '10.5pt', fontFamily: '"Times New Roman",serif', color: 'black'}}>&nbsp;</span></p>
			<p className="MsoNormal" style={{marginLeft: '12.0pt'}}><span lang="EN-US" style={{fontSize: '10.5pt', fontFamily: '"Times New Roman",serif', color: 'black'}}>1. Trimming,
				reducing size, cropping</span></p>
			<p className="MsoNormal" style={{marginLeft: '12.0pt'}}><span lang="EN-US" style={{fontSize: '10.5pt', fontFamily: '"Times New Roman",serif', color: 'black'}}>2. Combining
				of text</span></p>
			<p className="MsoNormal" style={{marginLeft: '12.0pt'}}><span lang="EN-US" style={{fontSize: '10.5pt', fontFamily: '"Times New Roman",serif', color: 'black'}}>3. adjustment
				of brightness, contrast ratio, tone, hue etc.</span></p>
			<p className="MsoNormal"><span lang="EN-US" style={{fontSize: '10.5pt', fontFamily: '"Times New Roman",serif', color: 'black'}}>&nbsp;</span></p>
			<p className="MsoNormal"><u><span lang="EN-US" style={{fontSize: '10.5pt', fontFamily: '"Times New Roman",serif', color: 'black'}}>Prohibited Matters</span></u></p>
			<p className="MsoNormal"><span lang="EN-US" style={{fontSize: '10.5pt', fontFamily: '"Times New Roman",serif', color: 'black'}}>The Downloading Organization prohibits the use of downloaded
				digital assets in the following manners:</span></p>
			<p className="MsoNormal"><span lang="EN-US" style={{fontSize: '10.5pt', fontFamily: '"Times New Roman",serif', color: 'black'}}>&nbsp;</span></p>
			<p className="MsoNormal" style={{marginLeft: '22.5pt', textIndent: '-10.5pt'}}><span lang="EN-US" style={{fontSize: '10.5pt', fontFamily: '"Times New Roman",serif', color: 'black'}}>1. Use under false pretenses, or use under conditions that may
				give rise to a false impression;</span></p>
			<p className="MsoNormal" style={{marginLeft: '22.5pt', textIndent: '-10.5pt'}}><span lang="EN-US" style={{fontSize: '10.5pt', fontFamily: '"Times New Roman",serif', color: 'black'}}>2. Use in a context that is slanderous or critical of the author
				or copyrights holder, picture subject or tourist location depicted in the
				digital asset;</span></p>
			<p className="MsoNormal" style={{marginLeft: '22.5pt', textIndent: '-10.5pt'}}><span lang="EN-US" style={{fontSize: '10.5pt', fontFamily: '"Times New Roman",serif', color: 'black'}}>3. Sale of the digital asset as a product or service, either as is
				or in duplicated or processed form, or provision of the digital asset using
				public means of communication etc. regardless of it being for profit or not for
				profit;</span></p>
			<p className="MsoNormal" style={{marginLeft: '22.5pt', textIndent: '-10.5pt'}}><span lang="EN-US" style={{fontSize: '10.5pt', fontFamily: '"Times New Roman",serif', color: 'black'}}>4. Use of the digital asset in a method or form that allows the
				use or downloading of such by a third party;</span></p>
			<p className="MsoNormal" style={{marginLeft: '22.5pt', textIndent: '-10.5pt'}}><span lang="EN-US" style={{fontSize: '10.5pt', fontFamily: '"Times New Roman",serif', color: 'black'}}>5. Registering or applying for trademark rights, design rights,
				patent rights, copyrights or other intellectual property rights over the
				digital asset;</span></p>
			<p className="MsoNormal" style={{marginLeft: '22.5pt', textIndent: '-10.5pt'}}><span lang="EN-US" style={{fontSize: '10.5pt', fontFamily: '"Times New Roman",serif', color: 'black'}}>6. Transferring to a third party, or allowing a third party use of
				the digital asset;</span></p>
			<p className="MsoNormal" style={{marginLeft: '22.5pt', textIndent: '-10.5pt'}}><span lang="EN-US" style={{fontSize: '10.5pt', fontFamily: '"Times New Roman",serif', color: 'black'}}>7. Use for any other purposes other than the aforementioned
				purpose of use.</span></p>
			<p className="MsoNormal"><span lang="EN-US" style={{fontSize: '10.5pt', fontFamily: '"Times New Roman",serif', color: 'black'}}>&nbsp;</span></p>
			<p className="MsoNormal"><u><span lang="EN-US" style={{fontSize: '10.5pt', fontFamily: '"Times New Roman",serif', color: 'black'}}>Oath regarding Downloaded Digital Assets</span></u></p>
			<p className="MsoNormal"><span lang="EN-US" style={{fontSize: '10.5pt', fontFamily: '"Times New Roman",serif', color: 'black'}}>The Downloading Organization promises to not use the downloaded
				digital assets for any use other than that registered within the scope of the
				Terms of Use etc. </span></p>
			<p className="MsoNormal"><span lang="EN-US" style={{fontFamily: '"Times New Roman",serif', color: 'black'}}>&nbsp;</span></p>
			<p className="MsoNormal"><span lang="EN-US" style={{fontFamily: '"Times New Roman",serif', color: 'black'}}>&nbsp;(Appendix)</span></p>
			<p className="MsoNormal"><span lang="EN-US" style={{fontFamily: '"Times New Roman",serif', color: 'black'}}>&nbsp;</span></p>
			<table className="MsoNormalTable" border={0} cellSpacing={0} cellPadding={0} width={567} style={{width: '425.0pt', borderCollapse: 'collapse'}}>
			  <tbody><tr style={{height: '29.0pt'}}>
				  <td width={567} nowrap style={{width: '425.0pt', border: 'solid black 1.0pt', background: '#D9D9D9', padding: '0cm 4.95pt 0cm 4.95pt', height: '29.0pt'}}>
					<p className="MsoNormal" align="center" style={{textAlign: 'center'}}><span lang="EN-US" style={{fontSize: '10.5pt', fontFamily: '"Times New Roman",serif', color: 'black'}}>Attributable
						Metadata upon Uploading of Digital Assets</span></p>
				  </td>
				</tr>
				<tr style={{height: '29.0pt'}}>
				  <td width={567} nowrap style={{width: '425.0pt', border: 'solid black 1.0pt', borderTop: 'none', padding: '0cm 4.95pt 0cm 4.95pt', height: '29.0pt'}}>
					<p className="MsoNormal"><span lang="JA" style={{fontSize: '10.5pt', fontFamily: '"Yu Mincho",serif', color: 'black'}}>・</span><span lang="EN-US" style={{fontSize: '10.5pt', fontFamily: '"Times New Roman",serif', color: 'black'}}>File name</span></p>
				  </td>
				</tr>
				<tr style={{height: '29.0pt'}}>
				  <td width={567} nowrap style={{width: '425.0pt', border: 'solid black 1.0pt', borderTop: 'none', padding: '0cm 4.95pt 0cm 4.95pt', height: '29.0pt'}}>
					<p className="MsoNormal"><span lang="JA" style={{fontSize: '10.5pt', fontFamily: '"Yu Mincho",serif', color: 'black'}}>・</span><span lang="EN-US" style={{fontSize: '10.5pt', fontFamily: '"Times New Roman",serif', color: 'black'}}>Location taken</span></p>
				  </td>
				</tr>
				<tr style={{height: '29.0pt'}}>
				  <td width={567} nowrap style={{width: '425.0pt', border: 'solid black 1.0pt', borderTop: 'none', padding: '0cm 4.95pt 0cm 4.95pt', height: '29.0pt'}}>
					<p className="MsoNormal"><span lang="JA" style={{fontSize: '10.5pt', fontFamily: '"Yu Mincho",serif', color: 'black'}}>・</span><span lang="EN-US" style={{fontSize: '10.5pt', fontFamily: '"Times New Roman",serif', color: 'black'}}>Prefecture</span></p>
				  </td>
				</tr>
				<tr style={{height: '29.0pt'}}>
				  <td width={567} nowrap style={{width: '425.0pt', border: 'solid black 1.0pt', borderTop: 'none', padding: '0cm 4.95pt 0cm 4.95pt', height: '29.0pt'}}>
					<p className="MsoNormal"><span lang="JA" style={{fontSize: '10.5pt', fontFamily: '"Yu Mincho",serif', color: 'black'}}>・</span><span lang="EN-US" style={{fontSize: '10.5pt', fontFamily: '"Times New Roman",serif', color: 'black'}}>Description</span></p>
				  </td>
				</tr>
				<tr style={{height: '29.0pt'}}>
				  <td width={567} nowrap style={{width: '425.0pt', border: 'solid black 1.0pt', borderTop: 'none', padding: '0cm 4.95pt 0cm 4.95pt', height: '29.0pt'}}>
					<p className="MsoNormal"><span lang="JA" style={{fontSize: '10.5pt', fontFamily: '"Yu Mincho",serif', color: 'black'}}>・</span><span lang="EN-US" style={{fontSize: '10.5pt', fontFamily: '"Times New Roman",serif', color: 'black'}}>Date taken</span></p>
				  </td>
				</tr>
				<tr style={{height: '29.0pt'}}>
				  <td width={567} nowrap style={{width: '425.0pt', border: 'solid black 1.0pt', borderTop: 'none', padding: '0cm 4.95pt 0cm 4.95pt', height: '29.0pt'}}>
					<p className="MsoNormal"><span lang="JA" style={{fontSize: '10.5pt', fontFamily: '"Yu Mincho",serif', color: 'black'}}>・</span><span lang="EN-US" style={{fontSize: '10.5pt', fontFamily: '"Times New Roman",serif', color: 'black'}}>Original source/acquisition source</span></p>
				  </td>
				</tr>
				<tr style={{height: '29.0pt'}}>
				  <td width={567} nowrap style={{width: '425.0pt', border: 'solid black 1.0pt', borderTop: 'none', padding: '0cm 4.95pt 0cm 4.95pt', height: '29.0pt'}}>
					<p className="MsoNormal"><span lang="JA" style={{fontSize: '10.5pt', fontFamily: '"Yu Mincho",serif', color: 'black'}}>・</span><span lang="EN-US" style={{fontSize: '10.5pt', fontFamily: '"Times New Roman",serif', color: 'black'}}>Material ID</span></p>
				  </td>
				</tr>
				<tr style={{height: '29.0pt'}}>
				  <td width={567} nowrap style={{width: '425.0pt', border: 'solid black 1.0pt', borderTop: 'none', padding: '0cm 4.95pt 0cm 4.95pt', height: '29.0pt'}}>
					<p className="MsoNormal"><span lang="JA" style={{fontSize: '10.5pt', fontFamily: '"Yu Mincho",serif', color: 'black'}}>・</span><span lang="EN-US" style={{fontSize: '10.5pt', fontFamily: '"Times New Roman",serif', color: 'black'}}>Taken/created by</span></p>
				  </td>
				</tr>
				<tr style={{height: '29.0pt'}}>
				  <td width={567} nowrap style={{width: '425.0pt', border: 'solid black 1.0pt', borderTop: 'none', padding: '0cm 4.95pt 0cm 4.95pt', height: '29.0pt'}}>
					<p className="MsoNormal"><span lang="JA" style={{fontSize: '10.5pt', fontFamily: '"Yu Mincho",serif', color: 'black'}}>・</span><span lang="EN-US" style={{fontSize: '10.5pt', fontFamily: '"Times New Roman",serif', color: 'black'}}>Copyright holder</span></p>
				  </td>
				</tr>
				<tr style={{height: '29.0pt'}}>
				  <td width={567} nowrap style={{width: '425.0pt', border: 'solid black 1.0pt', borderTop: 'none', padding: '0cm 4.95pt 0cm 4.95pt', height: '29.0pt'}}>
					<p className="MsoNormal"><span lang="JA" style={{fontSize: '10.5pt', fontFamily: '"Yu Mincho",serif', color: 'black'}}>・</span><span lang="EN-US" style={{fontSize: '10.5pt', fontFamily: '"Times New Roman",serif', color: 'black'}}>Credits</span></p>
				  </td>
				</tr>
				<tr style={{height: '29.0pt'}}>
				  <td width={567} nowrap style={{width: '425.0pt', border: 'solid black 1.0pt', borderTop: 'none', padding: '0cm 4.95pt 0cm 4.95pt', height: '29.0pt'}}>
					<p className="MsoNormal"><span lang="JA" style={{fontSize: '10.5pt', fontFamily: '"Yu Mincho",serif', color: 'black'}}>・</span><span lang="EN-US" style={{fontSize: '10.5pt', fontFamily: '"Times New Roman",serif', color: 'black'}}>Use period</span></p>
				  </td>
				</tr>
				<tr style={{height: '29.0pt'}}>
				  <td width={567} nowrap style={{width: '425.0pt', border: 'solid black 1.0pt', borderTop: 'none', padding: '0cm 4.95pt 0cm 4.95pt', height: '29.0pt'}}>
					<p className="MsoNormal"><span lang="JA" style={{fontSize: '10.5pt', fontFamily: '"Yu Mincho",serif', color: 'black'}}>・</span><span lang="EN-US" style={{fontSize: '10.5pt', fontFamily: '"Times New Roman",serif', color: 'black'}}>Trimming restrictions</span></p>
				  </td>
				</tr>
				<tr style={{height: '29.0pt'}}>
				  <td width={567} nowrap style={{width: '425.0pt', border: 'solid black 1.0pt', borderTop: 'none', padding: '0cm 4.95pt 0cm 4.95pt', height: '29.0pt'}}>
					<p className="MsoNormal"><span lang="JA" style={{fontSize: '10.5pt', fontFamily: '"Yu Mincho",serif', color: 'black'}}>・</span><span lang="EN-US" style={{fontSize: '10.5pt', fontFamily: '"Times New Roman",serif', color: 'black'}}>Model release acquisition status</span></p>
				  </td>
				</tr>
				<tr style={{height: '29.0pt'}}>
				  <td width={567} nowrap style={{width: '425.0pt', border: 'solid black 1.0pt', borderTop: 'none', padding: '0cm 4.95pt 0cm 4.95pt', height: '29.0pt'}}>
					<p className="MsoNormal"><span lang="JA" style={{fontSize: '10.5pt', fontFamily: '"Yu Mincho",serif', color: 'black'}}>・</span><span lang="EN-US" style={{fontSize: '10.5pt', fontFamily: '"Times New Roman",serif', color: 'black'}}>Property release acquisition status</span></p>
				  </td>
				</tr>
				<tr style={{height: '29.0pt'}}>
				  <td width={567} nowrap style={{width: '425.0pt', border: 'solid black 1.0pt', borderTop: 'none', padding: '0cm 4.95pt 0cm 4.95pt', height: '29.0pt'}}>
					<p className="MsoNormal"><span lang="JA" style={{fontSize: '10.5pt', fontFamily: '"Yu Mincho",serif', color: 'black'}}>・</span><span lang="EN-US" style={{fontSize: '10.5pt', fontFamily: '"Times New Roman",serif', color: 'black'}}>Advance permission required/not required
						for use</span></p>
				  </td>
				</tr>
				<tr style={{height: '29.0pt'}}>
				  <td width={567} nowrap style={{width: '425.0pt', border: 'solid black 1.0pt', borderTop: 'none', padding: '0cm 4.95pt 0cm 4.95pt', height: '29.0pt'}}>
					<p className="MsoNormal"><span lang="JA" style={{fontSize: '10.5pt', fontFamily: '"Yu Mincho",serif', color: 'black'}}>・</span><span lang="EN-US" style={{fontSize: '10.5pt', fontFamily: '"Times New Roman",serif', color: 'black'}}>User restrictions </span></p>
				  </td>
				</tr>
				<tr style={{height: '29.0pt'}}>
				  <td width={567} nowrap style={{width: '425.0pt', borderTop: 'none', borderLeft: 'solid black 1.0pt', borderBottom: 'none', borderRight: 'solid black 1.0pt', padding: '0cm 4.95pt 0cm 4.95pt', height: '29.0pt'}}>
					<p className="MsoNormal"><span lang="JA" style={{fontSize: '10.5pt', fontFamily: '"Yu Mincho",serif', color: 'black'}}>・</span><span lang="EN-US" style={{fontSize: '10.5pt', fontFamily: '"Times New Roman",serif', color: 'black'}}>Contact info</span></p>
				  </td>
				</tr>
				<tr style={{height: '29.0pt'}}>
				  <td width={567} nowrap style={{width: '425.0pt', border: 'solid black 1.0pt', borderTop: 'none', padding: '0cm 4.95pt 0cm 4.95pt', height: '29.0pt'}}>
					<p className="MsoNormal"><span lang="EN-US" style={{fontSize: '10.5pt', fontFamily: '"Times New Roman",serif', color: 'black'}}>&nbsp;</span></p>
				  </td>
				</tr>
			  </tbody></table>
			<p className="MsoNormal"><span lang="EN-US" style={{fontFamily: '"Times New Roman",serif', color: 'black'}}>&nbsp;</span></p>
		  </div>
		</div>
	  );
  
}
