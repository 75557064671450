import AssetState from "./AssetState";
class AssetStateBuilder {
    init(state, increment) {
        this.state = new AssetState(state);
        this.increment = increment;
        return this;
    }

    forSearchText(searchText = "") {
        this.state.searchText = searchText;
        this.state.setPageNumber(0);
        return this;
    }

    forSearchResults(assets, searchText = "") {
        this.state.setAssets(assets);
        return this;
    }

    forClearAssets() {
        this.state.setAssets([]);
        return this;
    }

    forNextPageSearchResults(assets, numberOfHits, searchText, newPageNum, imageCount, videoCount ) {
        // if (newPageNum === 0) {
        this.state.setAssets(assets)
        // } else {
        //     this.state.addAssets(assets);
        // }
        this.state.setNumberOfHits(numberOfHits);
        this.state.setPageNumber(newPageNum);
        this.state.setNumberImages(imageCount);
        this.state.setNumberVideos(videoCount);

        return this;
    }

    forDownloadLink(downloadLink) {
        this.state.setDownloadLink(downloadLink.url, downloadLink.bytes);
        return this;
    }
    
    forTotalNumberImagesJOMC(numberOfHits) {
        this.state.setTotalImagesJOMC(numberOfHits);
        return this;
    }
    forTotalNumberImagesJNTO(numberOfHits) {
        this.state.setTotalImagesJNTO(numberOfHits);
        return this;
    }
    forTotalNumberVideosJOMC(numberOfHits) {
        this.state.setTotalVideosJOMC(numberOfHits);
        return this;
    }
    forTotalNumberVideosJNTO(numberOfHits) {
        this.state.setTotalVideosJNTO(numberOfHits);
        return this;
    }
    forSelectedAsset(asset) {
        this.state.selectAsset(asset);
        return this;
    }

    forAutoTags(autoTags) {
        this.state.setAutoTags(autoTags);
        return this;
    }

    build() {
        return this.state;
    }
}

export default AssetStateBuilder;