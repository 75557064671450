import algoliaSearch from 'algoliasearch';
import { addErrorMessage } from '../../redux/actions/errorHandlerActions';
import config from '../../config';
import * as textUtils from '../../utilities/textUtils';
import AlgoliaSearchResponse from "../../models/core/AlgoliaSearchResponse";
class AlgoliaSearchClient {
    constructor(isJOMC) {
        if (isJOMC) {
            const client = algoliaSearch(config.algolia.APP_ID_JOMC, config.algolia.API_KEY_JOMC);
            this.index = client.initIndex(config.algolia.INDEX_NAME_JOMC);
            this.autoTagsIndex = client.initIndex(config.algolia.AUTO_TAGS_INDEX_NAME);
        }
        else {
            const client = algoliaSearch(config.algolia.APP_ID, config.algolia.API_KEY);
            this.index = client.initIndex(config.algolia.INDEX_NAME);
            this.indexSizeSorted = client.initIndex(config.algolia.INDEX_NAME_SIZE)
            this.indexFilenameSorted = client.initIndex(config.algolia.INDEX_NAME_FILENAME)
            this.indexDownloadsSorted = client.initIndex(config.algolia.INDEX_NAME_DOWLOADS)
            this.autoTagsIndex = client.initIndex(config.algolia.AUTO_TAGS_INDEX_NAME);
        }
    }
    
}

const searchClient = new AlgoliaSearchClient(false);

export const queryAutoTags = async (publicId, dispatch) => {
    const facetFilterArray = [`objectID:${publicId}`];
    const response = await searchClient.autoTagsIndex.search("",{ facetFilters: [facetFilterArray] });
    const hasAutoTags = response && response.hits && response.hits.length > 0;
    return hasAutoTags ? response.hits[0].autoTags : [];
}

export const searchAssetsByPublicIdsPaginated = async (searchText, publicIds, pageNumber, userGroup) => {
    const facetFilterArray = publicIds.reduce((filtersArr, publicId) => {
        return [...filtersArr, `public_id:${publicId}`];
    }, []);

    let searchResponse;
    // var filterStr = ''
    var filterStr = textUtils.buildExpiredFilter();
    searchResponse = await searchClient.index.search(searchText,{facetFilters: [facetFilterArray], filters: filterStr, page: pageNumber, hitsPerPage: 250 });
    return AlgoliaSearchResponse.fromResponse(searchResponse);
};

export const searchAssetsByTextAndProjectIdsPaginated = async (searchText, pageNumber, projectIds, userGroup, orderBy, sortBy, filterAspect,filterMediaType, filterPrefecture, filterSeason, isFuzzy, filterTags, filterDamId, filterDownloadPermission, filterPrePostApproval, filterValidDate, filterApprovedExternal) => {
    if (!searchText) {
        searchText = '';
    }
    var filterStr = textUtils.buildJNTOJOMCFilterString(sortBy, filterAspect, filterMediaType, filterPrefecture, filterSeason, filterTags, filterDamId, filterDownloadPermission, filterPrePostApproval, filterValidDate, filterApprovedExternal);
    let index = searchClient.index;
    if (orderBy === 'Size') {
        index = searchClient.indexSizeSorted
    }
    if (orderBy === 'Filename') {
        index = searchClient.indexFilenameSorted
    }
    if (orderBy === 'Download Count') {
        index = searchClient.indexDownloadsSorted
    }
    const searchResponse = await index.search(searchText, {facets:['format'], filters: filterStr, page: pageNumber, hitsPerPage: 50, typoTolerance: isFuzzy });
    return AlgoliaSearchResponse.fromResponse(searchResponse);
};

export const searchAssetsByPublicIds = async (publicIds, userGroup) => {
    const facetFilterArray = publicIds.reduce((filtersArr, publicId) => {
        return [...filtersArr, `public_id:${publicId}`];
    }, []);

    let searchResponse;
    searchResponse = await searchClient.index.search('',{facetFilters: [facetFilterArray] });
    return AlgoliaSearchResponse.fromResponse(searchResponse);
};

export const assetExistsByPublicId = async (publicId) => {
    try {
        const searchClientJOMC = new AlgoliaSearchClient(true);
        await searchClientJOMC.index.getObject(publicId);
    }
    catch (err) {
        return false;
    }
    return true;
};

export const getAllImagesJNTO = async () => {
        const searchResponse = await searchClient.index.search('',{ attributesToRetrieve: null, filters: `NOT format:"mp4" AND (NOT context.secondaryUseJOMC:'true' AND NOT context.jomcAsset:'true' AND NOT context.secondaryUseJomcJnto:'true')`});
        return AlgoliaSearchResponse.fromResponse(searchResponse);
};

export const getAllImagesJOMC = async () => {
    const searchResponse = await searchClient.index.search('',{ attributesToRetrieve: null, filters: `NOT format:"mp4" AND (context.secondaryUseJOMC:'true' OR context.jomcAsset:'true' OR context.secondaryUseJomcJnto:'true')`});
    return AlgoliaSearchResponse.fromResponse(searchResponse);
};

export const getAllVideosJNTO = async () => {
    const searchResponse = await searchClient.index.search('',{ attributesToRetrieve: null, filters: `format:"mp4" AND (NOT context.secondaryUseJOMC:'true' AND NOT context.jomcAsset:'true' AND NOT context.secondaryUseJomcJnto:'true')`});
    return AlgoliaSearchResponse.fromResponse(searchResponse);
};

export const getAllVideosJOMC = async () => {
    const searchResponse = await searchClient.index.search('', { attributesToRetrieve: null, filters: `format:"mp4" AND (context.secondaryUseJOMC:'true' OR context.jomcAsset:'true' OR context.secondaryUseJomcJnto:'true')`});
    return AlgoliaSearchResponse.fromResponse(searchResponse);
};

