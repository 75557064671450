import React from 'react';
import { Button, ButtonGroup, DisplayText, Form, FormLayout } from "@shopify/polaris";
import { useTranslation } from 'react-i18next';
import i18n from 'i18next';

const LocaleSelector = ({ handleLanguageUpdate, languageOptions, onUserLogout, onPasswordReset }) => {
    const { t } = useTranslation();



    // const currentSelectedLanguageVariations = (localeVariations) => {
    //     return localeVariations.includes(i18n.language);
    // }

    const currentSelectedLanguage = (languageItem) => {
        if ((i18n.language !== 'jp' && languageItem.locale === 'jp') || (i18n.language === 'jp' && languageItem.locale !== 'jp')) {
            return false;
        }
        return true;
    }

    const languageButtons = languageOptions ? languageOptions.map((languageItem, index) => {
        return <Button primary={currentSelectedLanguage(languageItem)} key={index} onClick={() => handleLanguageUpdate(languageItem.locale)}>{t(languageItem.labelKey)}</Button>
    }) : [];

    return (
        <div className='user-profile-localization-container'>
            <Form>
                <FormLayout>
                    <label className='dam-ui-label'>{t('workflows.userProfile.selectLanguage')}</label>
                    <div className='d-flex'>
                        <ButtonGroup segmented>
                            {languageButtons}
                        </ButtonGroup>
                    </div>
                </FormLayout>
            </Form>
            <div>
                <hr className='simple-divider' />
            </div>
            <div>
                <p className='dam-ui-par' style={{ marginBottom: '20px' }}>{t('common.logout')} </p>
                <p className='dam-ui-par'><a className='user-page' onClick={onUserLogout}>{t('common.logout')}</a></p><br></br>
                <p className='dam-ui-par' style={{ marginBottom: '20px' }}>{t('auth.forgotPassword.resetPassword')} </p>
                <p className='dam-ui-par'><a className='user-page' onClick={onPasswordReset}>{t('auth.forgotPassword.resetPassword')}</a></p>
            </div>
        </div>);
};

export default LocaleSelector;
