import * as types from '../constants/action-types';

export function addErrorMessage(errorMessage) {
    return {
        type: types.ERROR_MESSAGE_ADDED,
        errorMessage
    }
}

export const dismissErrorMessage = () => {
    return { type: types.ERROR_MESSAGE_DISMISSED };
}