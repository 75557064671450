const initialState = {
    navigation: {
        currentAuthPage: 'signin', //[signin, signup, forgot-password]. default is signin,
        currentPage: 'user-authentication'
    },
    admin: {
        userGroupNames: [],
        usersForAdmin: [],
        adminProjects: [],
        allOrganizations: [],
        selectedOrganization: undefined,
        organizationUserIds: {},
    },
    user: {
        signedInUser: {},
        userInfoComposite: {},
        userGroupNames: [],
        usersForAdmin: []
    },
    project: {
        selectedProject: {},
        adminProjects: [],
        projects: []
    },
    organization: {
        organization: {},
        allOrganizations: []
    },
    asset: {
        assets: [],
        numberOfHits: 0,
        autoTags: [],
        searchText: '',
        pageNumber: 0,
        downloadLink: undefined,
    },
    folder: {
        toAdd: {
            assets: [],
            folder: undefined
        },
        new: {
            folderName: undefined
        },
        folders: [],
    },
    myRequests: {
        approvedUploadRequests: [],
        rejectedUploadRequests: [],
        pendingUploadRequests: [],
        pendingUserUploadRequests: [],
        downloadRequests: [],
        isUploadUser: false

    },
    myPermissionRequests: {
        outgoingPermissionRequests: [],
        incomingPermissionRequests: [],
    },
    fileUploadGrid: {
        errorMessages: [],
        fileUploadInProgress: undefined
    },
    errorHandler: {
        errorMessage: undefined
    },
    genericMessage: {
        message: undefined,
    },
};

export default initialState;