import * as types from "../constants/action-types";
import initialState from './initialState';

export default function genericMessageReducer(state = initialState.genericMessage, action) {
    let newState;
    switch (action.type) {
        case types.GENERIC_MESSAGE_DISPLAYED:
            newState = {
                ...state,
                message: action.message,
            }
            break;
        case types.GENERIC_MESSAGE_DISMISSED:
            newState = {
                ...state,
                message: undefined,
            }
            break;
        default:
            break;
    }

    return newState || state;
}