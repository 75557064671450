import axios from 'axios';
import config from '../../config';
import * as errorHandlerActions from '../../redux/actions/errorHandlerActions';
import { requestToFormData } from '../../utilities/fileUploadUtils';

class BaseSimpleApi {
    constructor() {
        this.host = config.apiGateway.ELB;
    }

    postMulti(path, requests, dispatch) {
        const fullPath = `${this.host}${path}`;
        const axiosRequests = requestToFormData(requests);
    
        const outgoingRequests = axiosRequests.map(req => axios({
            method: "post",
            url: fullPath,
            data: req,
        }));

        return Promise.all(outgoingRequests)
            .catch(err => {
                const { message } = err;
                dispatch(errorHandlerActions.addErrorMessage(message));
                throw (err.message);
        });
    
    }
}

export default new BaseSimpleApi();
