import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { Layout } from '@shopify/polaris';
// redux
import * as adminActions from '../../../../redux/actions/adminActions';
import * as assetActions from '../../../../redux/actions/assetActions'
import * as folderActions from '../../../../redux/actions/folderActions'
import * as requestActions from '../../../../redux/actions/requestActions'
import * as genericMessageActions from '../../../../redux/actions/genericMessageActions';
import FloatingGenericModal from "../../../FloatingGenericModal/FloatingGenericModal";
// custom components
import AssetGallery from '../../../shared/galleries/AssetGallery/AssetGallery';
import AssetDetails from '../../../AssetDetails/AssetDetails';
import SimpleModal from '../../../shared/modals/SimpleModal/SimpleModal';
import FilterBar from "../../../Navigation/FilterBar/FilterBar";
import { withRouter } from 'react-router-dom';
import {Pagination} from '@shopify/polaris';

class AssetsPanel extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            downloadOverFourBytes: false,
            selectedAsset: null,
            showDeleteAssetFromFolderModal: false,
            showUpdateAssetSuccessModal: false,
            assetToRemoveFromFolder: null,
            editMode: false,
            uploadUser: undefined
        };

        // method bindings
        this.onShareUrl = this.onShareUrl.bind(this);
        this.onGetDownloadLink = this.onGetDownloadLink.bind(this);
        this.downloadLinkifUnderFourGB = this.downloadLinkifUnderFourGB.bind(this);
        this.downloadLink = this.downloadLink.bind(this);
        this.getUploadUser = this.getUploadUser.bind(this);
        this.onDeleteAsset = this.onDeleteAsset.bind(this);
        this.onShowDeleteAssetFromFolder = this.onShowDeleteAssetFromFolder.bind(this);
        this.deleteAssetFromFolder = this.deleteAssetFromFolder.bind(this);
        this.PaginationBar = this.PaginationBar.bind(this)
        this.toggleEditMode = this.toggleEditMode.bind(this);
        this.onAssetUpdate = this.onAssetUpdate.bind(this);

    }

    PaginationBar(numberOfHits, onLoadNextPage, onLoadPreviousPage, pageNumber) {
        var pageStart = pageNumber * 50 +1
        var pageEnd = (pageStart+50 >numberOfHits) ? numberOfHits : (pageStart + 49) ;
        const showNext = (pageEnd!==numberOfHits)
        return (
          <div
            style={{
                display:"flex",
              maxWidth: '500px',
              margin: 'auto',
              border: "1px solid darkGray", 
              lineHeight: "40px",
              textAlign: "center",
              backgroundColor: "white",
              marginTop:"30px",
              borderRadius: "5px",
              paddingLeft: "7px",
              paddingRight: "7px",
              paddingBottom: "7px",
              paddingTop: "7px",

                        }}
          >
            <div style={{ "margin-left": "-55px", width:"300px"}}>{`${pageStart}-${pageEnd} of ${numberOfHits} Assets`}</div>
            <Pagination
              onPrevious={() => {
                onLoadPreviousPage()
              }}
              onNext={() => {
                onLoadNextPage()
              }}
              hasNext={showNext}
              hasPrevious={pageNumber>0}
              type="table"
              label=""
            />
          </div>
        );
      }

    /* Lifecycle Methods */
    componentDidUpdate(prevProps) {
        const downloadLinkChanged = this.props.downloadLink && prevProps.downloadLink !== this.props.downloadLink;
        //download link if over
        if (downloadLinkChanged) {
            this.downloadLinkifUnderFourGB();
        }
    }
    componentDidMount(){
        this.getUploadUser();
    }

    /* Custom Methods */
    onShareUrl() {
        const { genericMessageActions, t } = this.props;
        genericMessageActions.displayMessage(t('workflows.projects.copiedAssetUrl'));
    }

    downloadLinkifUnderFourGB(){
        if((this.props.downloadSize / Math.pow(1024, 3)) > 4){
            this.setState({downloadOverFourBytes: true});
        }
        else{
            let updatedImageLink = this.props.downloadLink;
            if(updatedImageLink.startsWith('http') && !updatedImageLink.startsWith('https')){
                updatedImageLink = updatedImageLink.replace('http', 'https');
            }
            window.location = updatedImageLink;        }
    }

    downloadLink(){
        this.setState({downloadOverFourBytes: false});
        let updatedImageLink = this.props.downloadLink;
        if(updatedImageLink.startsWith('http')){
            updatedImageLink = updatedImageLink.replace('http', 'https');
        }
        window.location = updatedImageLink;
    }

    onGetDownloadLink(publicId, isVideo, agreedToTerms, folder = false) {
        const { assetActions, userInfoComposite } = this.props;
        const userId = userInfoComposite.user.id;
        assetActions.getDownloadLink([publicId], userId, agreedToTerms, folder, isVideo);
    }

    onShowDeleteAssetFromFolder() {
        if (!(this.props.folderPage  && this.props.folderPage.title)){
            this.setState({selectedAsset: null, uploadUser:null})
            this.deleteAssetFromFolder(this.state.selectedAsset.publicId)
            return;
        }
       this.setState({showDeleteAssetFromFolderModal: true})
       this.setState({assetToRemoveFromFolder: this.state.selectedAsset.publicId})
       this.setState({selectedAsset: null, uploadUser:null})
    }

    deleteAssetFromFolder(asset) {
        const { folderActions, userInfoComposite, onToggleIsLoading, match} = this.props;
        const userId = userInfoComposite.user.id;
        const folderId = match.params.folderId;
        var assetId;
        if(asset){
            assetId = asset
        }
        else{
            assetId = this.state.assetToRemoveFromFolder
        }
        this.setState({showDeleteAssetFromFolderModal: false})
        onToggleIsLoading(true);
        folderActions.deleteAssetFromFolder(assetId, userId, folderId).then(()=>{
            onToggleIsLoading(false);
            this.setState({selectedAsset: null, uploadUser:null})
        }
        );
        this.setState({selectedAsset: null, uploadUser:null})
        this.setState({assetToRemoveFromFolder: null})

     }

    async onDeleteAsset(publicId) {
        const { assetActions, userInfoComposite, onToggleIsLoading} = this.props;
        const userId = userInfoComposite.user.id;
        onToggleIsLoading(true);
        assetActions.deleteAssetAction(publicId, userId).then(()=>{
            onToggleIsLoading(false);
            this.setState({selectedAsset: null, uploadUser:null})
        }
    );
    }

    async onAssetUpdate(updates) {
        const { assetActions, userInfoComposite, onToggleIsLoading} = this.props;
        const userId = userInfoComposite.user.id;
        this.setState({editMode:false, selectedAsset: null})
        onToggleIsLoading(true);
        if(updates.length > 0){
            var updatesFormatted= {}
            for(var update of updates){
                updatesFormatted[update.field] = update.newValue
            }
            assetActions.editAssetAction(this.state.selectedAsset.publicId, updatesFormatted, userId).then(()=>{
                onToggleIsLoading(false);
                this.setState({
                    selectedAsset: null,
                    uploadUser:null,
                    showUpdateAssetSuccessModal: true
                })
            })
        }
        else{
            onToggleIsLoading(false);
            this.setState({
                selectedAsset: null,
                uploadUser:null,
                showUpdateAssetSuccessModal: true
            })
        }
    }

    getUploadUser() {
        const {userInfoComposite, requestActions } = this.props;
        const userId = userInfoComposite.user.id;
        const userOrg = userInfoComposite.user.organizationId;
        requestActions.getUploadedByUser(userId);
        requestActions.getUploadedByOrg(userOrg);
    }

    async getAssetUserInfo(asset, isAdmin){
        const {adminActions } = this.props;
        if(!isAdmin){
            return;
        }
        else{
            const uploadUser = await adminActions.getUploadUserForAssetId(asset.publicId)
            this.setState({uploadUser: uploadUser})
        }
    }

    toggleEditMode() {
        this.setState({editMode: !this.state.editMode})
    }


    render() {
        const {t, signedInUser, searchText, assets, onAddAssetToFolder, onRemoveAssetFromFolder, onLoadNextPage, assetsForFolder = [], folderPage = false, outgoingPermissionRequests, onSelectOrderBy, onSelectSortBy, match, isUploadUser, onReset, numberOfHits, totalNumberImagesJNTO, totalNumberImagesJOMC, imageCount, videoCount, totalNumberVideosJNTO, userInfoComposite, totalNumberVideosJOMC, isJOMC, onLoadPreviousPage, pageNumber} = this.props;
        const { selectedAsset, downloadOverFourBytes,showDeleteAssetFromFolderModal, editMode, showUpdateAssetSuccessModal } = this.state;
        const userGroups = signedInUser.signInUserSession.idToken.payload["cognito:groups"];
        const isJNTOAdmin = userGroups.some(userGroup => ((userGroup.indexOf('admin') > -1) || userGroup.indexOf('jnto-hq-admin') > -1));
        const isFolderPage = (match.path.indexOf('/folders') !== -1);
        const folderId = match.params.folderId;
        return (
                <Layout>
                    <Layout.Section>
                    {!isFolderPage &&
                        <FilterBar onSelectOrderBy ={onSelectOrderBy} onSelectSortBy ={onSelectSortBy}  onReset={onReset} searchText={searchText}/>
                    }
                    <AssetGallery
                        numberOfHits = {numberOfHits}
                        totalNumberImagesJNTO= {totalNumberImagesJNTO}
                        totalNumberImagesJOMC= {totalNumberImagesJOMC}
                        videoCount ={videoCount}
                        imageCount={imageCount}
                        isJOMC={isJOMC}
                        totalNumberVideosJNTO={totalNumberVideosJNTO}
                        totalNumberVideosJOMC={totalNumberVideosJOMC}
                        uploadedByUser={isUploadUser}
                        enableAssetCardBanner
                        onLoadNextPage={onLoadNextPage}
                        onSelectAsset={(asset, editMode = false) => {
                            this.getAssetUserInfo(asset, isJNTOAdmin)
                            this.setState({ selectedAsset: asset, editMode })
                        }}
                        assets={assets}
                        signedInUser={this.props.signedInUser}
                        uploadedByOrg={this.props.isUploadOrg}
                        canShareUrl={isJNTOAdmin}
                        onShareUrl={this.onShareUrl}
                        onExit={assetActions.clearAssets}
                        assetIdsForFolder={assetsForFolder.map(asset => asset.publicId)}
                        onAddAssetToFolder={onAddAssetToFolder}
                        onRemoveAssetFromFolder={onRemoveAssetFromFolder}
                        folderPage = {isFolderPage}
                        outgoingPermissionRequests = {outgoingPermissionRequests} />
                { !folderPage && this.PaginationBar(numberOfHits, onLoadNextPage, onLoadPreviousPage, pageNumber)}

                </Layout.Section>
                {downloadOverFourBytes &&
                    <SimpleModal
                        title={t('common.attention')}
                        message = {t('workflows.downloadHistory.downloadSizeWarning')}
                        onConfirm={() => {this.downloadLink()}}
                        onCancel={() => {this.setState({downloadOverFourBytes: false});}}
                    /> }
                <FloatingGenericModal show={showDeleteAssetFromFolderModal}>
                    <div className='generic-modal'>
                    <h2 >{t('workflows.folders.areYouSure')}</h2>
                    <div>{t('workflows.myPermissionRequests.deleteAssetFromFolder')}</div>
                    <div className="actions">
                    <button className="text-center dui-btn dui-btn-primary" onClick={(e) => this.setState({ showDeleteAssetFromFolderModal: false })}>
                        {t('form.label.cancel')}
                        </button>
                        <button className="text-center dui-btn dui-btn-primary" onClick={(e)=>this.deleteAssetFromFolder()}>
                        {t('form.label.ok')}
                        </button>
                    </div>
                    </div>
                </FloatingGenericModal>
                    <FloatingGenericModal show={showUpdateAssetSuccessModal}>
                        <div className='generic-modal'>
                            <h2 >{t('common.updateSuccess')}</h2>
                            <div>{t('common.updateSuccessSub')}</div>
                            <div className="actions">
                                <button className="text-center dui-btn dui-btn-primary" onClick={(e)=>this.setState({showUpdateAssetSuccessModal: false})}>
                                    {t('form.label.ok')}
                                </button>
                            </div>
                        </div>
                    </FloatingGenericModal>
                {selectedAsset &&
                    // <div className='asset-details-wrapper'>

                        <div className="jnto-modal-wrapper large" id="jnto-modal">
                            <div className='jnto-modal large'>
                                <div className="close-modal" onClick={() => this.setState({ selectedAsset: null, uploadUser:null })}>
                                    X
                                </div>
                                <div className="content">
                        <AssetDetails
                            assetUploadedByUser= {this.state.uploadUser}
                            onShowDeleteAssetFromFolder = {this.onShowDeleteAssetFromFolder}
                            onDeleteAsset={this.onDeleteAsset}
                            onAssetUpdate={this.onAssetUpdate}
                            signedInUser={signedInUser}
                            uploadedByUser={this.props.isUploadUser}
                            uploadedByOrg={this.props.isUploadOrg}
                            assetIdsForFolder={assetsForFolder}
                            onGetDownloadLink={this.onGetDownloadLink}
                            onUnselectAsset={() => this.setState({ selectedAsset: null, uploadUser:null })}
                            asset={selectedAsset}
                            canUpdateTags={true}
                            folderPage = {isFolderPage}
                            folderId = {folderId}
                            onToggleIsLoading = {this.props.onToggleIsLoading}
                            outgoingPermissionRequests = {outgoingPermissionRequests}
                            editMode = {editMode}
                            toggleEditMode={this.toggleEditMode}
                            onEdit={this.onEdit}
                        />

                                </div>
                            </div>
                        </div>

                }
            </Layout>
        );
    }
}

const mapStateToProps = ({ asset, user, myRequests }) => ({
    downloadLink: asset.downloadLink,
    downloadSize: asset.downloadBytes,
    userInfoComposite: user.userInfoComposite,
    signedInUser: user.signedInUser,
    isUploadUser: myRequests.isUploadUser,
    isUploadOrg: myRequests.isUploadOrg,

});

const mapDispatchToProps = (dispatch) => ({
    folderActions: bindActionCreators(folderActions, dispatch),
    assetActions: bindActionCreators(assetActions, dispatch),
    requestActions: bindActionCreators(requestActions, dispatch),
    adminActions: bindActionCreators(adminActions, dispatch),
    genericMessageActions: bindActionCreators(genericMessageActions, dispatch),
});

export default withTranslation()(withRouter((connect(mapStateToProps, mapDispatchToProps)(AssetsPanel))));
