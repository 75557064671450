import React from "react";

import userProfileIcon from '../../../assets/icons/icon_account_off.svg';
import userProfileIconRed from '../../../assets/icons/icon_account_on.svg';
import systemPreferencesIconRed from '../../../assets/icons/icon_setting_on.svg';
import systemPreferencesIcon from '../../../assets/icons/icon_setting_off.svg';
import myBagIcon from '../../../assets/icons/icon_my-bag_off.svg';
import myBagIconRed from '../../../assets/icons/icon_my-bag_on.svg';
import jntoLogo from '../../../assets/icons/icon_jnto_logo.svg';
import searchIcon from '../../../assets/icons/icon_search_off.svg';
import searchIconRed from '../../../assets/icons/icon_search_on.svg';
import { withTranslation } from 'react-i18next';
import { withRouter } from 'react-router-dom';

class TopNavigation extends React.Component {
  constructor() {
    super();
    this.onSubmitSearch = this.onSubmitSearch.bind(this);
    this.onUpdateSearchTerm = this.onUpdateSearchTerm.bind(this);
    this.state = {
      searchTerm: ''
    }
  }

  onNavigate = (link) => {
    this.props.history && this.props.history.push(link);
  }

  onUpdateSearchTerm(e) {
    this.setState({ searchTerm: e.target.value })
  }

  onSubmitSearch(e) {
    e.preventDefault();
    const { history } = this.props;
    var searchText = this.state.searchTerm;
    const queryStr = searchText ? `?inputText=${searchText}` : '';
    history.push(`/search${queryStr}`);
  }

  render() {
    const { t, permissionRequests, locationPath, isAdmin } = this.props;
    const dashBoardActive = locationPath.pathname.includes('dashboard') ? 'active' : '';
    const uploadActive = locationPath.pathname.includes('file-upload') ? 'active' : '';
    const manualActive = locationPath.pathname.includes('user-guide') ? 'active' : '';
    const permissionActive = locationPath.pathname.includes('permission-requests') ? 'active' : '';

    return (
      <div className='main-nav flex'>
        <div className='logo-container flex'>
          <div className='nav-logo'>
            <a onClick={() => this.onNavigate('/dashboard')}>
              <img src={jntoLogo} />
            </a>
          </div>
          <div className='nav-title'>
            JNTO Photo &amp; Video Library
          </div>
        </div>
        {!this.props.external &&
          <div className='nav-items flex'>
            <ul>
              <li><a className={dashBoardActive} onClick={() => this.onNavigate('/dashboard')}>{t('header.dashboard')}</a></li>
              <li><a className={uploadActive} onClick={() => this.onNavigate('/file-upload/select-project')}>{t('header.upload')}</a></li>
              <li className={permissionRequests ? 'active-notification ' : ''}><a className={permissionActive} onClick={() => this.onNavigate('/permission-requests')}>{t('header.downloadRequest')}</a></li>
              <li><a className={manualActive} onClick={() => this.onNavigate('/user-guide')}>{t('header.userGuide')}</a></li>
              <li className='nav-icon-wrapper'>
                <a className='no-hover' onClick={() => this.onNavigate('/folders')}>
                  <img className='nav-icon nav-icon-black' src={myBagIcon} />
                  <img className='nav-icon nav-icon-red' src={myBagIconRed} />
                </a>
              </li>
              {/* <li className='nav-icon-wrapper'>
                <a className='no-hover'>
                  <img className='nav-icon nav-icon-black' src={searchIcon} onClick={() => this.onNavigate('/search')} />
                  <img className='nav-icon nav-icon-red' src={searchIconRed} onClick={() => this.onNavigate('/search')} />
                  <div className='dropdown'>
                    <div className='nav-search-container'>
                      <input type='text' placeholder={t('common.search')} onChange={this.onUpdateSearchTerm} />
                      <button className='dui-btn small dui-btn-primary' onClick={this.onSubmitSearch}>Ok</button>
                    </div>
                  </div>
                </a>
              </li> */}
              {isAdmin &&
                <li className='nav-icon-wrapper'>
                  <a className='no-hover' onClick={() => this.onNavigate('/user-admin')}>
                    <img className='nav-icon nav-icon-black' src={systemPreferencesIcon} />
                    <img className='nav-icon nav-icon-red' src={systemPreferencesIconRed} />
                  </a>
                </li>
              }
              <li className='nav-icon-wrapper'>
                <a className='no-hover' onClick={() => this.onNavigate('/user-profile')}>
                  <img className='nav-icon nav-icon-black' src={userProfileIcon} />
                  <img className='nav-icon nav-icon-red' src={userProfileIconRed} />
                </a>
              </li>
            </ul>
          </div>
        }
      </div>
    )
  }
}

export default withTranslation()(withRouter(TopNavigation));
