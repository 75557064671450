import React from "react";
import PropTypes from "prop-types";

export default class FloatingModal extends React.Component {

  onClose = e => {
    this.props.onClose && this.props.onClose(e);
  }

  render() {
    const {closeText} = this.props;
    if (!this.props.show) {
      return null;
    }
    return (
      <div>
        <div className="jnto-modal-wrapper upload-modal-wrapper" id="jnto-modal">
          <div className='jnto-modal upload-modal'>
            <div className="content">{this.props.children}</div>
            <div className="actions">
              <button className="text-center dui-btn dui-btn-secondary-alt" onClick={this.onClose}>
              {closeText}
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
FloatingModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  show: PropTypes.bool.isRequired
};
