import React from "react";
import ManualFloatingBar from "../../components/Navigation/ManualFloatingBar/ManualFloatingBar";
import { Frame, Page } from "@shopify/polaris";
import { withTranslation } from 'react-i18next';
import { withRouter } from "react-router-dom";

class UserGuideUpload01 extends React.Component {
    constructor() {
        super();
        this.state = {}
    }
    render() {
        const { t, history, match } = this.props;
        return (
            <>
                <Frame>
                    <Page fullWidth>
                        <div className='jnto-generic-page-wrapper'>
                            <section className='generic-block'>
                                <h3>{t('manual.myBag.subTitle')}</h3>
                                <h2>{t('manual.myBag.create.title')}</h2>
                                <hr className='simple-divider' />
                            </section>
                            <section>
                                <p>{t('manual.myBag.create.detail01')}</p>
                                <br></br><br></br>
                                <p>{t('manual.myBag.create.detail02Title')}</p>
                                <section className='bullet-list'>
                                    <ul>
                                        <li>{t('manual.myBag.create.detail02')}</li>
                                        <li>{t('manual.myBag.create.detail03')}</li>
                                    </ul>
                                </section>
                                <p className='text-red'>{t('manual.myBag.note')}</p>
                                <p>{t('manual.myBag.create.note01Detail')}</p>
                            </section>
                            <section>
                                <p>{t('manual.myBag.create.detail04')}</p>
                                <p>{t('manual.myBag.create.detail05')}</p>
                                <img src='https://asset.japan.travel/image/upload/w_600,f_auto,fl_lossy,e_improve,e_sharpen/v1615457596/dam-admin/manual/updated/Screen_Shot_2021-03-11_at_11.00.11.png' />
                                <p>{t('manual.myBag.create.detail06')}</p>
                            </section>
                            <section>
                                <p className='text-red'>{t('manual.myBag.note')}</p>
                                <p>{t('manual.myBag.create.detail07')}</p>
                                <img src='https://asset.japan.travel/image/upload/w_600,f_auto,fl_lossy,e_improve,e_sharpen/v1615457596/dam-admin/manual/updated/Screen_Shot_2021-03-11_at_11.00.11.png' />
                            </section>
                            <section>
                                <p>{t('manual.myBag.create.detail08')}</p>
                                <img src='https://asset.japan.travel/image/upload/w_600,f_auto,fl_lossy,e_improve,e_sharpen/v1615457598/dam-admin/manual/updated/Screen_Shot_2021-03-11_at_11.01.20.png' />
                            </section>
                            <section>
                                <p>{t('manual.myBag.create.detail09')}</p>
                                <img src='https://asset.japan.travel/image/upload/w_600,f_auto,fl_lossy,e_improve,e_sharpen/v1611661984/dam-admin/manual/temp/Screen_Shot_2021-01-26_at_20.52.37.png' />
                            </section>


                            <ManualFloatingBar buttons={[
                                {
                                    position: 'left',
                                    text: t('manual.myBag.title'),
                                    callback: () => history.push(`my-bag`),
                                },
                                {
                                    position: 'right',
                                    text: t('manual.myBag.edit.title'),
                                    callback: () => history.push(`my-bag-edit`),
                                }
                            ]} />
                        </div>
                    </Page>
                </Frame>
            </>)
    }
}

export default withTranslation()(withRouter((UserGuideUpload01)));