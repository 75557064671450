import React from 'react';
import { Icon, TextField } from "@shopify/polaris";
import { searchAssetsByPublicIds } from '../../api/algoliaSearchApi/algoliaSearchApi';
import WithConfirmationModal from '../shared/modals/WithConfirmationModal/WithConfirmationModal';
import WithTextInputModal from '../shared/modals/WithTextInputModal/WithTextInputModal';
import editIcon from '../../assets/icons/icon_edit.svg';
import deleteIcon from '../../assets/icons/icon_delete.svg';
import { withTranslation } from 'react-i18next';
import FloatingGenericModal from "../FloatingGenericModal/FloatingGenericModal";
import TextInputModal from "../shared/modals/TextInputModal/TextInputModal";

class FolderCard extends React.Component {
    constructor() {
        super();
        this.state = {
            thumbnailUrl: '',
            updatedFolderName: null,
            permissionImages: false,
            showDeleteConfirmModal: false,
            showRenameFolderModal: false
        };

        this.onNameChange = this.onNameChange.bind(this);
        this.onFolderRenameSubmit = this.onFolderRenameSubmit.bind(this);
        this.setThumbnailUrlAndPermssions = this.setThumbnailUrlAndPermssions.bind(this);
        this.deleteConfirmApprove = this.deleteConfirmApprove.bind(this);
    }

    componentDidMount() {
        this.setThumbnailUrlAndPermssions();
    }

    componentDidUpdate(prevProps){
        const {folderImageComposite} = this.props;
        if (prevProps.folderImageComposite.id !== folderImageComposite.id){
            this.setThumbnailUrlAndPermssions();
        };
    }

    setThumbnailUrlAndPermssions(){
        const {folderImageComposite, userGroups} = this.props;
        searchAssetsByPublicIds([folderImageComposite.imageIds[0]], userGroups).then(searchResponse => {
            const assets = searchResponse.items;
            if (assets.length > 0) {
                var permissionImages = false;
                for(var asset of assets){
                    if(asset.context && asset.context.requestApproval)
                        if(asset.context.requestApproval === 'true'){
                            permissionImages = true;
                            break;
                        }
                }
                this.setState({
                    permissionImages: permissionImages,
                    thumbnailUrl: assets[0].thumbnailUrl,
                });
            }
        });
    }

    onNameChange(newVal) {
        this.setState({
            updatedFolderName: newVal,
        });
    }

    onFolderRenameSubmit() {
        const { folderImageComposite, onRenameFolderApprove, t } = this.props;
        const { updatedFolderName } = this.state;
        onRenameFolderApprove(folderImageComposite.id, updatedFolderName);
        this.setState({showRenameFolderModal: false})
    }

    deleteConfirmApprove(folderImageComposite) {
        const { onDeleteFolderApprove } = this.props;
        onDeleteFolderApprove(folderImageComposite)
        this.setState({
            showDeleteConfirmModal: false
        })
    }

    render() {
        const { updatedFolderName, permissionImages } = this.state;
        const { folderImageComposite, onSelectFolder, t } = this.props;
        return (<div className='generic-card-container-v2'>

            <div className='generic-card-container-image' onClick={() => onSelectFolder(folderImageComposite)}>
                {this.state.thumbnailUrl && <img alt={'Loading...'} className='generic-card-image' src={this.state.thumbnailUrl} />}
            </div>
            <div className='generic-card-container-content'>
                <div className='generic-card-container-content-name' onClick={() => onSelectFolder(folderImageComposite)}>{folderImageComposite.folderName}</div>
                <div className='generic-card-container-content-bottom'>
                    <div>{permissionImages && t('workflows.folders.folderHasPermissionImage')}</div>
                    <div className='generic-card-container-content-bottom-actions'>

                        <img onClick={() => this.setState({showRenameFolderModal : true})} src={editIcon} />
                        <FloatingGenericModal show={this.state.showRenameFolderModal}>
                            <div className='generic-modal'>
                                <h2> {t('workflows.folders.renameFolder')}</h2>
                                <div className='generic-form-container set-bg'>
                                    <div className='field-item-content'>
                                        <TextField
                                          value={updatedFolderName}
                                          onChange={this.onNameChange}
                                        />
                                    </div>
                                </div>
                                <div className="actions">
                                    <button className="text-center dui-btn dui-btn-secondary-alt" onClick={(e)=> this.setState({showRenameFolderModal: false})}>
                                        {t('form.label.cancel')}
                                    </button>
                                    <button className="text-center dui-btn dui-btn-primary" onClick={(e)=> this.onFolderRenameSubmit()}>
                                        {t('form.label.ok')}
                                    </button>
                                </div>
                            </div>
                        </FloatingGenericModal>
                        <img onClick={() => this.setState({showDeleteConfirmModal: true})} src={deleteIcon}/>
                        <FloatingGenericModal show={this.state.showDeleteConfirmModal}>
                            <div className='generic-modal'>
                                <h2> {t('workflows.folders.deleteFolderPrompt')}</h2>
                                <div>
                                    {t('common.confirmChanges')}
                                </div>
                                <div className="actions">
                                    <button className="text-center dui-btn dui-btn-secondary-alt" onClick={(e)=> this.setState({showDeleteConfirmModal: false})}>
                                        {t('form.label.cancel')}
                                    </button>
                                    <button className="text-center dui-btn dui-btn-primary" onClick={(e)=> this.deleteConfirmApprove(folderImageComposite)}>
                                        {t('form.label.ok')}
                                    </button>
                                </div>
                            </div>
                        </FloatingGenericModal>


                    </div>
                </div>
            </div>
        </div>);
    }
};

export default withTranslation()(FolderCard);
