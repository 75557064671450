export default {
    apiGateway: {
        REGION: process.env.REACT_APP_AWS_REGION,
        ELB: process.env.REACT_APP_ELB,
        URL: process.env.REACT_APP_API_GATEWAY_URL,
    },
    cognito: {
        REGION: process.env.REACT_APP_AWS_REGION,
        USER_POOL_ID: process.env.REACT_APP_USER_POOL_ID,
        APP_CLIENT_ID: process.env.REACT_APP_COGNITO_CLIENT_ID
    },
    algolia: {
        API_KEY: process.env.REACT_APP_ALGOLIA_API_KEY,
        APP_ID: process.env.REACT_APP_ALGOLIA_APP_ID,
        INDEX_NAME: process.env.REACT_APP_ALGOLIA_INDEX_NAME,
        INDEX_NAME_SIZE: process.env.REACT_APP_ALGOLIA_INDEX_NAME_SIZE,
        INDEX_NAME_FILENAME: process.env.REACT_APP_ALGOLIA_INDEX_NAME_FILENAME,
        API_KEY_JOMC: process.env.REACT_APP_ALGOLIA_API_KEY_JOMC,
        APP_ID_JOMC: process.env.REACT_APP_ALGOLIA_APP_ID_JOMC,
        INDEX_NAME_JOMC: process.env.REACT_APP_ALGOLIA_INDEX_NAME_JOMC,
        AUTO_TAGS_INDEX_NAME: process.env.REACT_APP_ALGOLIA_AUTO_TAGS_INDEX_NAME,
        INDEX_NAME_SUGGESTIONS: process.env.REACT_APP_ALGOLIA_INDEX_NAME_SUGGESTIONS,
        INDEX_NAME_DOWLOADS: process.env.REACT_APP_ALGOLIA_INDEX_NAME_DOWNLOADS
    },
    projectConfig: {
        projectId: {
            jomc: process.env.REACT_APP_JOMC_PROJECT_ID
        }
    },
    fileUpload: {
        templateUrlJNTO: process.env.REACT_APP_TEMPLATE_URL_JNTO,
        templateUrlJOMC: process.env.REACT_APP_TEMPLATE_URL_JOMC
    },
    cloudinary: {
        API_KEY: process.env.REACT_APP_CLOUDINARY_API_KEY,
        NOTIFICATION_URL: process.env.CLOUDINARY_NOTIFY_URL
    }
};
