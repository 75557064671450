import {
    searchAssetsByPublicIdsPaginated,
    searchAssetsByTextAndProjectIdsPaginated, queryAutoTags, getAllImagesJNTO, getAllImagesJOMC,getAllVideosJOMC, getAllVideosJNTO
} from "../../api/algoliaSearchApi/algoliaSearchApi";
import { uploadAssetsDirect, queryDownloadLink, deleteAsset, editAsset } from '../../api/vermillionApi/assetManagementApi';
import * as types from "../constants/action-types";

export const searchNextPageSuccess = (assets, numberOfHits, searchText, newPageNumber, imageCount, videoCount) => {
    return { type: types.GET_NEXT_PAGE_SUCCESS, searchText, assets, numberOfHits, newPageNumber, imageCount, videoCount };
};

export const selectAssetSuccess = asset => {
    return { type: types.SELECT_ASSET_SUCCESS, asset };
};

export const searchTextUpdated = (searchText) => {
    return { type: types.SEARCH_TEXT_UPDATED, searchText };
};

export const uploadAssetSuccess = () => {
    return { type: types.UPLOAD_ASSET_SUCCESS };
};

export const getDownloadLinkSuccess = (downloadLink) => {
    return {
        type: types.GET_DOWNLOAD_LINK_SUCCESS,
        downloadLink,
    }
}

export const deleteAssetSuccess = () => {
    return { type: types.DELETE_ASSET_SUCCESS }
}

export const getAutoTagsSuccess = (autoTags) => {
    return {
        type: types.GET_AUTO_TAGS_SUCCESS,
        autoTags,
    };
}

export const clearAssetsSuccess = () => ({
    type: types.CLEAR_ASSETS_SUCCESS,
});

export const clearAutoTagsSuccess = () => {
    return {
        type: types.CLEAR_AUTO_TAGS_SUCCESS,
    };
};

export const getTotalNumberImagesJOMCSuccess = (numberOfHits) => ({
    type: types.GET_TOTAL_NUMBER_IMAGES_JOMC_SUCCESS,
    numberOfHits
});

export const getTotalNumberImagesJNTOSuccess = (numberOfHits) => ({
    type: types.GET_TOTAL_NUMBER_IMAGES_JNTO_SUCCESS,
    numberOfHits
});

export const getTotalNumberVideosJNTOSuccess = (numberOfHits) => ({
    type: types.GET_TOTAL_NUMBER_VIDEOS_JNTO_SUCCESS,
    numberOfHits
});

export const getTotalNumberVideosJOMCSuccess = (numberOfHits) => ({
    type: types.GET_TOTAL_NUMBER_VIDEOS_JOMC_SUCCESS,
    numberOfHits
});

export const getAssetsByPublicIdsPaginated = (searchText, publicIds, pageNumber, userGroups) => {
    return async (dispatch) => {
        if(!publicIds || publicIds.length === 0){
            dispatch(searchNextPageSuccess([], 0, '', 0));
        }
        else{
            const searchResponse = await searchAssetsByPublicIdsPaginated(searchText, publicIds, pageNumber, userGroups);
            dispatch(searchNextPageSuccess(searchResponse.items, searchResponse.numberOfHits, '', pageNumber));
        }
        return;
    };
};

export const getAssetsByTextAndProjectIdsPaginated = (searchText, pageNumber, projectIds, userGroups, orderBy, sorbBy, filterAspect, filterMediaType, filterPrefecture, filterSeason, isFuzzy, filterTags, filterDamId, filterPrePostApproval, filterDownloadPermission, filterValidDate, filterApprovedExternal) => {
    return async (dispatch) => {
        const searchResponse = await searchAssetsByTextAndProjectIdsPaginated(searchText, pageNumber, projectIds, userGroups, orderBy, sorbBy, filterAspect, filterMediaType, filterPrefecture, filterSeason, isFuzzy, filterTags, filterDamId, filterPrePostApproval, filterDownloadPermission, filterValidDate, filterApprovedExternal);
        dispatch(searchNextPageSuccess(searchResponse.items, searchResponse.numberOfHits, searchText, pageNumber, searchResponse.imageCount, searchResponse.videoCount));
        return;
    };
};

// export const sendUploadAssetRequests = (assetUploadRequests) => {
//     return async (dispatch) => {
//         await uploadAssetsDirect(assetUploadRequests, dispatch);
//         dispatch(uploadAssetSuccess());
//         return;
//     };
// };

export const updateSearchText = searchText => {
    return dispatch => {
        dispatch(searchTextUpdated(searchText));
    };
};

export const selectAsset = asset => {
    return dispatch => {
        dispatch(selectAssetSuccess(asset));
    };
};

export const getDownloadLink = (publicIds, userId, agreedToTerms, folder, isVideo) => {
    return async dispatch => {
        const downloadLink = await queryDownloadLink(publicIds, userId, agreedToTerms, folder, isVideo, dispatch);
        if (downloadLink) {
            dispatch(getDownloadLinkSuccess(downloadLink));
        }
        return;
    };
}

export const deleteAssetAction = (publicIds, userId) => {
    return async dispatch => {
        const deletedAsset = await deleteAsset(publicIds, userId, dispatch);
        if (deletedAsset) {
            dispatch(getDownloadLinkSuccess(deletedAsset));
        }
        return;
    };
}

export const editAssetAction = (publicId, edits, userId) => {
    if('validDate' in edits){
        edits['validDate'] = edits['validDate'].replaceAll('-','');
        edits['validDate'] = parseInt(edits['validDate'])
    }
    return async dispatch => {
        const editedAsset = await editAsset(publicId, edits, userId, dispatch);
        return;
    };
}

export const getAutoTags = (publicId) => {
    return async dispatch => {
        const autoTags = await queryAutoTags(publicId, dispatch);
        dispatch(getAutoTagsSuccess(autoTags));
        return;
    }
}

export const clearAutoTags = () => {
    return dispatch => {
        dispatch(clearAutoTagsSuccess());
        return
    };
}

export const clearAssets = () => {
    return dispatch => {
        dispatch(clearAssetsSuccess());
        return;
    }
}

export const getTotalNumberImagesJNTO = () => {
    return async dispatch => {
        const searchResponse = await getAllImagesJNTO();
        dispatch(getTotalNumberImagesJNTOSuccess(searchResponse.numberOfHits));
        return;
    }
}

export const getTotalNumberImagesJOMC = () => {
    return async dispatch => {
        const searchResponse = await getAllImagesJOMC();
        dispatch(getTotalNumberImagesJOMCSuccess(searchResponse.numberOfHits));
        return;
    }
}

export const getTotalNumberVideosJNTO = () => {
    return async dispatch => {
        const searchResponse = await getAllVideosJNTO();
        dispatch(getTotalNumberVideosJNTOSuccess(searchResponse.numberOfHits));
        return;
    }
}

export const getTotalNumberVideosJOMC = () => {
    return async dispatch => {
        const searchResponse = await getAllVideosJOMC();
        dispatch(getTotalNumberVideosJOMCSuccess(searchResponse.numberOfHits));
        return;
    }
}

