import i18n from 'i18next';
export const typeUse= function(){
    return [
        {
            id: 'SNS',
            content: i18n.t('workflows.myPermissionRequests.uses.sns'),
        },
        {
            id: 'WEBSITE',
            content: i18n.t('workflows.myPermissionRequests.uses.website'),
        },
        {
            id: 'NEWSLETTER',
            content: i18n.t('workflows.myPermissionRequests.uses.newsletter'),
        },
        {
            id: 'EVENTS',
            content: i18n.t('workflows.myPermissionRequests.uses.events'),
        },
        {
            id: 'INVITATION',
            content: i18n.t('workflows.myPermissionRequests.uses.invitation'),
        },
        {
            id: 'ADVERTISTING',
            content: i18n.t('workflows.myPermissionRequests.uses.advertising'),
        },
        {
            id: 'COMMERCIAL',
            content: i18n.t('workflows.myPermissionRequests.uses.commercial'),
        },
        {
            id: 'NON-COMMERCIAL',
            content: i18n.t('workflows.myPermissionRequests.uses.noncommercial'),
        },
        {
            id: 'OTHER',
            content: i18n.t('workflows.myPermissionRequests.uses.other'),
        },
    ];
}

export const getContentForTypeID = (id) =>{
    const types = typeUse();
    return types.reduce((acc, curr) => {
        return curr.id===id ? curr.content : acc;
    }, undefined)
}
