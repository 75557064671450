import * as XLSX from 'xlsx';
import axios from "axios";
import {
    searchMyUploadRequests, searchMyDownloadRequests, saveMyUploadRequests, imageUploadByOrg,
    adminSearchUserUploadRequests, adminApproveUploads, adminRejectUploads, imageUploadByUser, downloadHistoryExcel
} from '../../api/vermillionApi/historyManagementApi';
import * as types from "../constants/action-types";

export const getMyUploadRequestsSuccess = (uploadRequests, isComplete) => {
    return { type: types.GET_MY_UPLOAD_REQUESTS, uploadRequests, isComplete };
};

export const getMyDownloadRequestsSuccess = (downloadRequests) => {
    return { type: types.GET_MY_DOWNLOAD_REQUESTS, downloadRequests };
};

export const updateMyUploadRequestsSuccess = (updatedUploadRequests) => {
    return { type: types.UPDATE_MY_UPLOAD_REQUESTS_SUCCESS, updatedUploadRequests };
};

export const adminGetUserUploadRequestsSuccess = (uploadRequests) => {
    return { type: types.GET_USER_UPLOAD_REQUESTS_SUCCESS, uploadRequests };
};

export const adminUpdateUploadRequestsSuccess = (uploadRequests) => {
    return { type: types.ADMIN_UPDATE_UPLOAD_REQUESTS_SUCCESS, uploadRequests };
};

export const adminApproveUploadRequestsSuccess = (uploadRequests) => {
    return { type: types.ADMIN_APPROVE_UPLOAD_REQUESTS_SUCCESS, uploadRequests };
};

export const adminRejectUploadRequestsSuccess = (uploadRequests) => {
    return { type: types.ADMIN_REJECT_UPLOAD_REQUESTS_SUCCESS, uploadRequests };
};

export const sortMyUploadRequestsSuccess = (uploadRequests, currentTab) => {
    return { type: types.SORT_MY_UPLOAD_REQUESTS_SUCCESS, currentTab, uploadRequests };
};

export const sortDownloadRequestsSuccess = (uploadRequests) => {
    return { type: types.SORT_MY_DOWNLOAD_REQUESTS_SUCCESS, uploadRequests };
};


export const getDownloadHistoryExcelSuccess = (historyExcel) => {
    return { type: types.GET_DOWNLOAD_HISTORY_EXCEL_SUCCESS, historyExcel };
};

export const getUploadedByUserSuccess = (uploadUser) => {
    return {
        type: types.GET_UPLOAD_USER_SUCCESS,
        uploadUser,
    };
};

export const getUploadedByOrgSuccess = (uploadsOrg) => {
    return {
        type: types.GET_UPLOAD_ORG_SUCCESS,
        uploadsOrg,
    };
};

export const getUploadedByUser = (user) => {
    return async dispatch => {
        const uploadUser = await imageUploadByUser(user);
        dispatch(getUploadedByUserSuccess(uploadUser));
        return;
    };
}


export const getUploadedByOrg = (org) => {
    return async dispatch => {
        const uploadsOrg = await imageUploadByOrg(org);
        dispatch(getUploadedByOrgSuccess(uploadsOrg));
        return;
    };
}

export const getDownloadHistoryExcel = (userId,isAdmin, startDate, endDate) => {
    return async dispatch => {

        const downloadHistories = await downloadHistoryExcel(userId, isAdmin, startDate, endDate);
        const xlData = await axios({ url: downloadHistories, method: 'GET', responseType: 'blob' })
        const url = window.URL.createObjectURL(new Blob([xlData.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', `JPhoto_Downloads.xlsx`);
        document.body.appendChild(link);
        link.click();
        // XLSX.writeFile(xlData.data, 'JPhoto_Download_Usage.xlsx');
        dispatch(getUploadedByUserSuccess(downloadHistories));
        return;
    };
}

export const getMyUploadRequests = (userId, isComplete) => {
    return async (dispatch) => {
        const uploadRequests = await searchMyUploadRequests(userId, isComplete);
        dispatch(getMyUploadRequestsSuccess(uploadRequests, isComplete));
        return;
    };
};

export const getMyDownloadRequests = (userId, isAdmin) => {
    return async (dispatch) => {
        var downloadRequests = await searchMyDownloadRequests(userId, isAdmin);
        if(!downloadRequests){
            downloadRequests = []
        }
        dispatch(getMyDownloadRequestsSuccess(downloadRequests.reverse()));
        return;
    };
};

export const updateMyUploadRequests = (updateRequests) => {
    return async (dispatch) => {
        const updatedUploadRequests = await saveMyUploadRequests(updateRequests);
        dispatch(updateMyUploadRequestsSuccess(updatedUploadRequests));
        return;
    };
};

export const editMyUploadRequests = (updateRequests) => {
    return async (dispatch) => {
        dispatch(updateMyUploadRequestsSuccess(updateRequests));
        return;
    };
};

export const adminGetUserUploadRequests = (adminUserId) => {
    return async (dispatch) => {
        const allUserUploadRequests = await adminSearchUserUploadRequests(adminUserId, false);
        dispatch(adminGetUserUploadRequestsSuccess(allUserUploadRequests));
        return;
    };
};

export const adminUpdateUploadRequests = (uploadRequests) => {
    return (dispatch) => {
        dispatch(adminUpdateUploadRequestsSuccess(uploadRequests));
        return;
    };
};

export const adminApproveUploadRequests = (adminUserId, uploadRequestIds) => {
    return async (dispatch) => {
        const approvedUploadRequests = await adminApproveUploads(adminUserId, uploadRequestIds, dispatch);
        dispatch(adminApproveUploadRequestsSuccess(approvedUploadRequests));
        return;
    };
};

export const adminRejectUploadRequests = (adminUserId, uploadRequests) => {
    return async (dispatch) => {
        const rejectedUploadRequests = await adminRejectUploads(adminUserId, uploadRequests, dispatch);
        dispatch(adminRejectUploadRequestsSuccess(rejectedUploadRequests));
        return;
    };
};

export const sortUploadRequests = (sortedRequests, currentTab) => {
    return (dispatch) => {
        dispatch(sortMyUploadRequestsSuccess(sortedRequests, currentTab));
        return;
    };
};

export const sortDownloadRequests = (sortedRequests) => {
    return (dispatch) => {
        dispatch(sortDownloadRequestsSuccess(sortedRequests));
        return;
    };
};
