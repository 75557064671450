import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Toast } from '@shopify/polaris';
import { withTranslation } from 'react-i18next';
import * as errorHandlerActions from '../../../../redux/actions/errorHandlerActions';
import FloatingGenericModal from "../../../../components/FloatingGenericModal/FloatingGenericModal";
import FloatingContactModal from "../../../../components/FloatingContactModal/FloatingContactModal";

class ErrorIndicator extends React.Component {
    constructor() {
        super();
        this.dismissContact = this.dismissContact.bind(this);
        this.contactSupport = this.contactSupport.bind(this);
        this.dismissError = this.dismissError.bind(this);
        this.state = {
            contactVisible: false,
          }
    }

    dismissError() {
        this.props.errorHandlerActions.dismissErrorMessage();
    }

    dismissContact(){
        this.setState({
            contactVisible: false,
          });
    }
    contactSupport(){
        this.props.errorHandlerActions.dismissErrorMessage();
        this.setState({
            contactVisible: true,
          });
    }

    render() {
        const {t} = this.props;
        const {contactVisible} = this.state;

        const errorMessage = this.props.t('form.errors.requestFailed', { message: this.props.errorMessage });
        return (<div>
                <FloatingGenericModal show={this.props.errorMessage}>
                       <div className='generic-modal'>
                       <div className='generic-form'>
                       <h2>{t('form.errors.errorTitle')}</h2>
                           <div className='field-group'>
                           <label>{this.props.errorMessage}</label>
                           <label>{t('form.errors.errorPopupMessage')}</label>
                           </div>
               
                       </div>
                       <div className="actions">
                           <button className="text-center dui-btn dui-btn-secondary-alt" onClick={this.dismissError}>
                           {t('form.label.close')}
                           </button>
                           <button className="text-center dui-btn dui-btn-primary"onClick={this.contactSupport}>
                            {t('form.errors.contactSupport')}
                           </button>
                       </div>
                       </div>
                </FloatingGenericModal>

                <FloatingContactModal onHide={this.dismissContact} show={contactVisible}/>

        </div>);
    }
}

const mapStateToProps = (state) => ({
    errorMessage: state.errorHandler.errorMessage,
});

const mapDispatchToProps = dispatch => ({
    errorHandlerActions: bindActionCreators(errorHandlerActions, dispatch),
});

export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(ErrorIndicator));
