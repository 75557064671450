import React from "react";
import PropTypes from "prop-types";
import FloatingGenericModal from "../../components/FloatingGenericModal/FloatingGenericModal";
import * as userActions from '../../redux/actions/userActions';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withTranslation } from 'react-i18next';

class FloatingContactModal extends React.Component {
  constructor() {
    super();
    this.state = {
      errorType: undefined,
      errorTitle: undefined,
      errorMessage: undefined,
      viewType: 'list',
      showSuccess: false
    }
    this.showBugForm = this.showBugForm.bind(this);
    this.sendEmail = this.sendEmail.bind(this);
    this.onSetErrorType = this.onSetErrorType.bind(this);
    this.onSetErrorTitle = this.onSetErrorTitle.bind(this);
    this.onSetErrorMessage = this.onSetErrorMessage.bind(this);
  }

  showBugForm() {
    this.setState({
      errorType: undefined,
      errorTitle: undefined,
      errorMessage: undefined,
    });
  };

  onSetErrorType(e) {
    this.setState({ errorType: e.target.value })
  }

  onSetErrorTitle(e) {
    this.setState({ errorTitle: e.target.value })
  }

  onSetErrorMessage(e) {
    this.setState({ errorMessage: e.target.value })
  }

  async sendEmail(e) {
    const { signedInUser, onHide } = this.props;
    if (!signedInUser.username || !this.state.errorType || !this.state.errorTitle || !this.state.errorMessage) {
      return;
    }
    this.showBugForm();
    onHide();
    await this.props.userActions.sendContactEmailAction(signedInUser && signedInUser.username, this.state.errorType, this.state.errorTitle, this.state.errorMessage);
    this.setState({showSuccess:true})
  };


  render() {
    const { show, signedInUser, onHide, t } = this.props;
    const {showSuccess} = this.state;
    const contactSubmitEnabled = (this.state.errorType && this.state.errorTitle && this.state.errorMessage)

    return (
      <>
      <FloatingGenericModal show={show}>
        <div className='generic-modal'>
          <div className='generic-form'>
            <h2>{t('contactForm.title')}</h2>

            <div className='field-group'>
              <label>{t('contactForm.subject')}</label>
              <div className='jnto-selector-container'>
                <select name='select-input' onChange={this.onSetErrorType}>
                  <option>{t('contactForm.subject')}</option>
                  <option>{t('contactForm.option1')}</option>
                  <option>{t('contactForm.option2')}</option>
                  <option>{t('contactForm.option3')}</option>
                </select>
              </div>
            </div>

            <div className='field-group'>
              <label>{t('contactForm.inputfield')}</label>
              <input type='text' name='field1' onChange={this.onSetErrorTitle} />
            </div>

            <div className='field-group'>
              <label>{t('contactForm.message')}</label>
              <textarea id="w3review" name="textarea" rows="6" cols="50" placeholder={t('contactForm.placeholder')} onChange={this.onSetErrorMessage}>
              </textarea>
            </div>

          </div>
          <div className="actions">
            <button className="text-center dui-btn dui-btn-secondary-alt" onClick={() => { onHide(); this.showBugForm(); }}>
              {t('contactForm.cancel')}
            </button>

            <button className="text-center dui-btn dui-btn-primary" onClick={this.sendEmail} disabled={!contactSubmitEnabled}>
              {t('contactForm.send')}
            </button>

          </div>
        </div>
      </FloatingGenericModal>

      <FloatingGenericModal show={showSuccess}>
      <div className='generic-modal'>
        <div className='generic-form'>
          <p>{t('contactForm.success')}</p>

        </div>
        <div className="actions">
          <button className="text-center dui-btn dui-btn-secondary-alt" onClick={() => {this.setState({showSuccess:false});}}>
            {t('form.label.close')}
          </button>

        </div>
      </div>
      </FloatingGenericModal>

      </>
    )
  }
}
const mapDispatchToProps = dispatch => ({
  userActions: bindActionCreators(userActions, dispatch)

});

const mapStateToProps = (state, myPermissionRequests) => ({
  signedInUser: state.user.signedInUser,
});

export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(FloatingContactModal));
