import i18n from 'i18next';
export const tabs = function(){
    return [
        {
            id: 'complete-uploads',
            content: i18n.t('workflows.myRequests.tabLabels.pending'),
            panelID: 'pending-content',
        },
        {
            id: 'rejected-uploads',
            content: i18n.t('workflows.myRequests.tabLabels.rejected'),
            panelID: 'rejected-content',
        },
        {
            id: 'approved-uploads',
            content: i18n.t('workflows.myRequests.tabLabels.approved'),
            panelID: 'approved-content',
        },
    ];
}

export const TAB_ENUMS = {
    PENDING_UPLOADS: 0,
    REJECTED_UPLOADS: 1,
    APPROVED_UPLOADS: 2,
}