import * as types from "../constants/action-types";
import * as folderManagementApi from '../../api/vermillionApi/folderManagementApi';

export const selectFolderSuccess = (folderImageComposite) => {
    return { type: types.SELECT_FOLDER_SUCCESS, folderImageComposite };
};

export const addAssetToFolderSuccess = (asset) => {
    return { type: types.ADD_ASSET_FOR_FOLDER_SUCCESS, asset };
};

export const removeAssetFromFolderSuccess = (asset) => {
    return { type: types.REMOVE_ASSET_FOR_FOLDER_SUCCESS, asset };
};

export const clearAssetsFromFolderSuccess = () => {
    return { type: types.CLEAR_ASSETS_FROM_FOLDER_SUCCESS };
};

export const selectFolderToAddAssetsSuccess = (folder) => {
    return { type: types.SELECT_FOLDER_TO_ADD_ASSETS_SUCCESS, folder };
};

export const saveAssetIdsToFolderSuccess = (folderImageComposite, ) => {
    return { type: types.SAVE_ASSET_IDS_TO_FOLDER_SUCCESS, folderImageComposite};
}

export const getFoldersByUserIdSuccess = (folderImageComposites) => {
    return { type: types.GET_FOLDERS_BY_USER_ID_SUCCESS, folderImageComposites };
}

export const addNewFolderWithAssetsSuccess = (folderImageComposite) => {
    return { type: types.SAVE_ASSET_IDS_TO_FOLDER_SUCCESS, folderImageComposite};
};

export const deleteFolderSuccess = (folder) => {
    return { type: types.DELETE_FOLDER_SUCCESS, folder };
};

export const deleteImageFromFolderSuccess = () => {
    return { type: types.DELETE_IMAGE_FOLDER_SUCCESS };
};

export const updateFolderNameSuccess = (folder) => {
    return { type: types.UPDATE_FOLDER_NAME_SUCCESS, folder };
};


export const selectFolder = (folderImageComposite) => {
    return (dispatch) => {
        dispatch(selectFolderSuccess(folderImageComposite));
        return;
    };
};

export const addAssetToFolder = (assets) => {
    return (dispatch) => {
        dispatch(addAssetToFolderSuccess(assets));
        return;
    };
};

export const removeAssetFromFolder = (asset) => {
    return (dispatch) => {
        dispatch(removeAssetFromFolderSuccess(asset));
        return;
    };
}

export const selectFolderToAddAssets = (folder) => {
    return (dispatch) => {
        dispatch(selectFolderToAddAssetsSuccess(folder));
        return;
    };
}

export const clearAssetsFromFolder = () => {
    return (dispatch) => {
        dispatch(clearAssetsFromFolderSuccess());
        return;
    };
}

export const addFolderWithAssets = (folderName, assets, userId) => {
    return async (dispatch) => {
        // combine folder's original assetIds with the new assetIds to be added, and de-dupe
        const assetIds = assets.map(asset => asset.publicId);
        const newFolderImageComposite = await folderManagementApi
            .addFolderWithAssetIds(folderName, assetIds, userId);
        dispatch(addNewFolderWithAssetsSuccess(newFolderImageComposite));
        return newFolderImageComposite;
    };
};

export const saveFolderWithAssets = (folderImageComposite, assets, userId) => {
    return async (dispatch) => {
        // combine folder's original assetIds with the new assetIds to be added, and de-dupe
        const uniqueAssetIds = [...new Set([...folderImageComposite.imageIds, ...assets.map(asset => asset.publicId)])]
       const updatedFolderImageComposite = 
        await folderManagementApi
            .saveAssetIdsToFolder(folderImageComposite.id, uniqueAssetIds, userId);
        dispatch(saveAssetIdsToFolderSuccess(updatedFolderImageComposite));
        return;
    };
};

export const getFoldersByUserId = (userId) => {
    return async (dispatch) => {
        const folderImageComposites = await folderManagementApi
            .getFoldersByUserId(userId);
        dispatch(getFoldersByUserIdSuccess(folderImageComposites));
        return;
    };
};

export const deleteFolder = ({ userId, folderId }) => {
    return async (dispatch) => {
        const deleteFolderResponse = await folderManagementApi
            .deleteFolder(userId, folderId);
        dispatch(deleteFolderSuccess(deleteFolderResponse));
        return;
    };
};

export const deleteAssetFromFolder = (imageId, userId, folderId ) => {
    return async (dispatch) => {
        const deleteFolderResponse = await folderManagementApi
            .deleteAssetFromFolder(imageId, userId, folderId);
        dispatch(deleteImageFromFolderSuccess());
        return;
    };
};

export const updateFolderName = ({ userId, folderId, folderName }) => {
    return async (dispatch) => {
        const updateFolderResponse = await folderManagementApi
            .updateFolderName(userId, folderId, folderName);
        dispatch(updateFolderNameSuccess(updateFolderResponse));
        return;
    };
};
