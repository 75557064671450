export const PREFECTURE_FILTERS = ['Hokkaido',
  'Akita',
  'Aomori',
  'Fukushima',
  'Iwate',
  'Miyagi',
  'Yamagata',
  'Chiba',
  'Gunma',
  'Ibaraki',
  'Kanagawa',
  'Saitama',
  'Tochigi',
  'Tokyo',
  'Fukui',
  'Ishikawa',
  'Nagano',
  'Niigata',
  'Toyama',
  'Aichi',
  'Gifu',
  'Mie',
  'Shizuoka',
  'Yamanashi',
  'Hyogo',
  'Kyoto',
  'Nara',
  'Osaka',
  'Shiga',
  'Wakayama',
  'Hiroshima',
  'Okayama',
  'Shimane',
  'Tottori',
  'Yamaguchi',
  'Ehime',
  'Kagawa',
  'Kochi',
  'Tokushima',
  'Fukuoka',
  'Kagoshima',
  'Kumamoto',
  'Miyazaki',
  'Nagasaki',
  'Oita',
  'Saga',
  'Okinawa'];



export const DEPARTMENT_IN_CHARGE = [
  // "担当部",
  "MICEプロモーション部市場戦略G",
  "MICEプロモーション部誘致推進G",
  "海外プロモーション部欧米豪・中東Ｇ",
  "海外プロモーション部東アジアG",
  "海外プロモーション部東南アジアG",
  "海外プロモーション部統括G",
  "企画総室デジタルマーケティングセンター",
  "企画総室広報G",
  "企画総室事業・プロモーション統括Ｇ",
  "企画総室調査・マーケティング統括G",
  "市場横断プロモーション部高付加価値旅行推進室",
  "市場横断プロモーション部市場横断G",
  "総務部海外事務所管理G",
  "総務部財務G",
  "総務部情報システムG",
  "総務部総務G",
  "総務部秘書室",
  "地域連携部会員サービスG",
  "地域連携部受入対策G",
  "地域連携部地域プロモーション連携室"
]

export const SEASONS= [
  'Spring',
  'Summer',
  'Autumn',
  'Winter',
  'All Season'
]

export const validThemeOne = [
  'Attraction',
  'Culture',
  'Relaxation',
  'Shopping',
  'Action & Adventure',
  'Art & Design',
  'History',
  'Nature',
  'Festival & Events',
  'Eat & Drink',
  'Landmark',
]

export const validLocationTwo = [
  'Hokkaido',
  'Tohoku',
  'Hokuriku-Shinetsu',
  'Kanto',
  'Tokai',
  'Kansai',
  'Chugoku',
  'Shikoku',
  'Kyusyu',
  'Okinawa'
]

export const EXTERNAL_USAGE =[
    "Approved for External Usage",
    "External Usage Forbidden",
    "Contact the Department in Charge in JNTO"
]