import { searchOrganizationById, updateOrganizationProjects } from '../../api/vermillionApi/organizationManagementApi';
import * as types from "../constants/action-types";

export const searchOrganizationByIdSuccess = (organizationComposite) => {
    return { type: types.GET_ORGANIZATION_BY_ID_SUCCESS, organizationComposite };
}

export const searchOrganizationsSuccess = (organizationComposites) => {
    return { type: types.GET_ORGANIZATIONS_SUCCESS, organizationComposites };
}

export const updateOrganizationProjectsSuccess = (updatedOrganization) => {
    return { type: types.UPDATE_ORGANIZATION_PROJECTS_SUCCESS, updatedOrganization };
}

export const createOrganizationSuccess = () => {
    return { type: types.CREATE_ORGANIZATION_SUCCESS };
}

export const getOrganizationById = (organizationId) => {
    return async (dispatch) => {
        const organizationComposite = await searchOrganizationById(organizationId, dispatch);
        dispatch(searchOrganizationByIdSuccess(organizationComposite));
        return;
    };
}

export const updateOrganizationProjectsAction = (organizationId, projectIds) => {
    return async (dispatch) => {
        const updatedOrganization = await updateOrganizationProjects(organizationId, projectIds, dispatch);
        dispatch(updateOrganizationProjectsSuccess(updatedOrganization));
    }
}
