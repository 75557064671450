import Project from './Project';

class OrganizationComposite {
    constructor(properties) {
        this.id = properties.id;
        this.organizationName = properties.organizationName;
        this.supervisorOrganizationId = properties.supervisorOrganizationId;
        this.projects = Project.fromDataObjects(properties.projects);
    }

    canAccessProject(projectId) {
        return this.projects.map(proj => proj.id).includes(projectId);
    }

    static fromDataObjects(dataObjects) {
        return dataObjects.map(dataObj => new OrganizationComposite(dataObj));
    }
}

export default OrganizationComposite;
