import * as types from "../constants/action-types";
import initialState from './initialState';

export default function folderReducer(state = initialState.folder, action) {
    let newState;
    switch (action.type) {
        case types.SELECT_FOLDER_SUCCESS:
            newState = {
                ...state,
                selectedFolder: action.folderImageComposite,
            }
            break;
        case types.ADD_ASSET_FOR_FOLDER_SUCCESS:
            newState = {
                ...state,
                toAdd: {
                    ...state.toAdd,
                    assets: [...state.toAdd.assets, action.asset]
                }
            }
            break;
        case types.REMOVE_ASSET_FOR_FOLDER_SUCCESS:
            newState = {
                ...state,
                toAdd: {
                    ...state.toAdd,
                    assets: state.toAdd.assets.filter(asset => asset.publicId !== action.asset.publicId)
                }
            }
            break;
        case types.SELECT_FOLDER_TO_ADD_ASSETS_SUCCESS:
            newState = {
                ...state,
                toAdd: {
                    ...state.toAdd,
                    folder: action.folder,
                }
            }
            break;
        case types.CLEAR_ASSETS_FROM_FOLDER_SUCCESS:
            newState = {
                ...state,
                toAdd: {
                    ...state.toAdd,
                    assets: [],
                }
            };
            break;
        case types.SAVE_ASSET_IDS_TO_FOLDER_SUCCESS:
            const {folderImageComposite} = action;
            newState = {
                ...state,
                folders: state.folders.reduce((newFolders, currentFolder)=>
                    currentFolder.id === folderImageComposite.id ? newFolders : newFolders.concat(currentFolder)
                    ,[folderImageComposite]),
            };
            break;
        case types.GET_FOLDERS_BY_USER_ID_SUCCESS:
            newState = {
                ...state,
                folders: action.folderImageComposites,
            };
            break;
        case types.DELETE_FOLDER_SUCCESS:
            newState = {
                ...state,
                folders: state.folders.filter(folder => parseInt(action.folder.folderIdRemoved) !== folder.id)
            };
            break;
        case types.UPDATE_FOLDER_NAME_SUCCESS:
            newState = {
                ...state,
                folders: state.folders.map(
                    (folder) => action.folder.id === folder.id ? {...folder, folderName: action.folder.folderName} : folder
                )
            };
            break;
        default:
            break;
    }

    return newState || state;
}