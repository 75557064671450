import React from "react";
import ManualFloatingBar from "../../components/Navigation/ManualFloatingBar/ManualFloatingBar";
import { Frame, Page } from "@shopify/polaris";
import { withTranslation } from 'react-i18next';
import { withRouter } from "react-router-dom";

class UserGuideUpload01 extends React.Component {
    constructor() {
        super();
        this.state = {}
    }
    render() {
        const { t, history, match } = this.props;
        return (
            <>
                <Frame>
                    <Page fullWidth>
                        <div className='jnto-generic-page-wrapper'>
                            <section className='generic-block'>
                                <h3>{t('manual.myBag.subTitle')}</h3>
                                <h2>{t('manual.myBag.delete.title')}</h2>
                                <hr className='simple-divider' />
                            </section>

                            <section>
                                <p className='text-red'>{t('manual.myBag.note')}</p>
                                <p>{t('manual.myBag.delete.note01Detail')}</p>
                            </section>
                            <section>
                                <p>{t('manual.myBag.delete.detail01')}</p>
                                <section className='bullet-list'>
                                    <ul>
                                        <li>{t('manual.myBag.delete.detail02')}</li>
                                        <li>{t('manual.myBag.delete.detail05')}</li>
                                    </ul>
                                </section>
                            </section>
                            <section>
                                <p style={{ fontWeight: 'bolder' }}>{t('manual.myBag.delete.detail02')}</p>
                                <p>{t('manual.myBag.delete.detail03')}</p>
                                <img src='https://asset.japan.travel/image/upload/w_600,f_auto,fl_lossy,e_improve,e_sharpen/v1611706267/dam-admin/manual/temp/Screen_Shot_2021-01-27_at_9.10.25.png' />
                                <p>{t('manual.myBag.delete.detail04')}</p>
                            </section>
                            <section>
                                <p style={{ fontWeight: 'bolder' }}>{t('manual.myBag.delete.detail05')}</p>
                                <p>{t('manual.myBag.delete.detail06')}</p>
                                <img src='https://asset.japan.travel/image/upload/w_600,f_auto,fl_lossy,e_improve,e_sharpen/v1615457600/dam-admin/manual/updated/Screen_Shot_2021-03-11_at_11.02.34.png' />
                                <br></br><br></br>
                                <p>{t('manual.myBag.delete.detail04')}</p>
                                <img src='https://asset.japan.travel/image/upload/w_600,f_auto,fl_lossy,e_improve,e_sharpen/v1615457600/dam-admin/manual/updated/Screen_Shot_2021-03-11_at_11.02.39.png' />
                            </section>


                            <ManualFloatingBar buttons={[
                                {
                                    position: 'left',
                                    text: t('manual.myBag.edit.title'),
                                    callback: () => history.push(`my-bag-edit`),
                                },
                                {
                                    position: 'right',
                                    text: t('manual.download.title'),
                                    callback: () => history.push(`download`),
                                }
                            ]} />
                        </div>
                    </Page>
                </Frame>
            </>)
    }
}

export default withTranslation()(withRouter((UserGuideUpload01)));