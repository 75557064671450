import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Toast } from '@shopify/polaris';
import * as genericMessage from '../../../../redux/actions/genericMessageActions';

const GenericMessagePopup = ({ message, genericMessageActions }) => (
    <>
        {message && <Toast content={message} onDismiss={() => genericMessageActions.dismissMessage()} duration={15000} />}
    </>
);

const mapStateToProps = (state) => ({
    message: state.genericMessage.message
});

const mapDispatchToProps = (dispatch) => ({
    genericMessageActions: bindActionCreators(genericMessage, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(GenericMessagePopup);
