import React from 'react';
import { Popover, Button, ActionList, TextField, Stack, TextContainer, Heading } from '@shopify/polaris';
import WithConfirmation from '../shared/modals/WithConfirmation/WithConfirmation';
import SimpleModal from '../shared/modals/SimpleModal/SimpleModal';
import { withTranslation } from 'react-i18next';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { typeUse, getContentForTypeID } from './PermissionRequestProjectTypes';
import { withRouter} from 'react-router-dom';
import * as permissionActions from '../../redux/actions/permissionActions';

/** Redux */
import * as folderActions from '../../redux/actions/folderActions';

class DownloadRequestModal extends React.Component {
    constructor() {
        super();
        this.state = {
                isDropdownActive: false,
                selectedType: undefined,
                typeDetailDisplayText: undefined,
        };
        this.togglePopover = this.togglePopover.bind(this);
        this.selectType = this.selectType.bind(this);
        this.onTypeDetailsChanged = this.onTypeDetailsChanged.bind(this);
        this.onSave = this.onSave.bind(this);
        this.onModalClose = this.onModalClose.bind(this);
    }

    componentDidMount() {
        const { folderActions, userInfoComposite } = this.props;
        folderActions.getFoldersByUserId(userInfoComposite.user.id);
    }

    togglePopover() {
            this.setState(({ isDropdownActive }) => ({ isDropdownActive: !isDropdownActive }));
    }

    onTypeDetailsChanged(newVal) {
        this.setState({
            typeDetailDisplayText: newVal,
        });
    }

    onSave(saveCallback) {
        if(this.props.folder){
            const { onToggleIsLoading, userInfoComposite, folder, folderActions, permissionActions} = this.props;
            onToggleIsLoading(true);

            const permissionAction = permissionActions.requestFolderPermission({
                userId: this.props.userInfoComposite.user.id,
                folderId: this.props.folder.id,
                useDescription: folder.requestReason || this.state.typeDetailDisplayText,
                use: folder.title || this.state.selectedType.id,
            });

            permissionAction.then(async () => {
                folderActions.getFoldersByUserId(userInfoComposite.user.id);
                permissionActions.getMySimpleOutgoingPermissionRequests(userInfoComposite.user.id, folder.id);
                onToggleIsLoading(false);
                saveCallback();
                this.onModalClose()
            });
        }
    }

    selectType(type) {
        this.setState({
            selectedType: type,
        });
    }

    onModalClose(){
        this.setState({
            isDropdownActive: false,
            selectedType: undefined,
            typeDetailDisplayText: undefined,
        });
    }

    render(){
        const { isDropdownActive, selectedType, typeDetailDisplayText} = this.state;
        const { title, message, t, children, folder} = this.props;
        let folderDownloadTitle;
        if(folder.title){
            folderDownloadTitle = getContentForTypeID(folder.title) || folder.title;
        }

        const folderUsageExisting = (folderDownloadTitle && folder.requestReason) || false;

        const activator = (
            <Button onClick={this.togglePopover}>
                {selectedType ? selectedType.content : t('workflows.myPermissionRequests.useType')}
            </Button>
        );
        
        const dropdownItems = typeUse().filter((a)=> (a.id!=='COMMERCIAL' && a.id !== 'NON-COMMERCIAL')).map((type) => {
            return {
            content: type.content,
            onAction: () => {
                this.selectType(type);
                this.togglePopover();
            }
        }})

        return(
            <WithConfirmation>
                {(show, confirm, hide, open) => (
                    <>
                        {(open && <SimpleModal
                            title={title}
                            message = {message}
                            onConfirm={() => {this.onSave(hide);}}
                            onCancel={() => {this.onModalClose(); hide()}}
                            disabled = {() => (!folderUsageExisting && (!selectedType || (!typeDetailDisplayText || !typeDetailDisplayText.trim())))}>
                        {(!folderDownloadTitle && !folder.requestReason) && <div style = {{'padding-bottom':'1rem'}}>{message}</div>}
                        <div>
                            {!folderDownloadTitle && <Popover
                                active={isDropdownActive}
                                activator={activator}
                                fixed = {true}
                                onClose={this.togglePopover}>
                                <ActionList
                                    items={dropdownItems} />
                            </Popover>}
                            {folderDownloadTitle && <Stack vertical={true}>
                                <Heading>{t('workflows.myPermissionRequests.modalTitleExisting')} </Heading>
                                <TextContainer>{folderDownloadTitle}</TextContainer>
                            </Stack> }
                            {!folder.requestReason && <div style = {{'padding-top':'1rem'}}>
                                <TextField
                                    label={t('workflows.myPermissionRequests.usageDescription')}
                                    onChange={this.onTypeDetailsChanged}
                                    value={this.state.typeDetailDisplayText}
                                />
                             </div>}
                             {folder.requestReason && <div style = {{'padding-top':'1rem'}}>
                                 <Stack vertical={true}>
                                    <Heading>{t('workflows.myPermissionRequests.modalReasonExisting')} </Heading>
                                    <TextContainer>{folder.requestReason}</TextContainer>
                                </Stack>
                             </div>}
                        </div>
                        </SimpleModal>
                        )}
                        {children(show)}
                    </>
                )}
            </WithConfirmation>
            );
     }
}

const mapStateToProps = (state) => ({
    userInfoComposite: state.user.userInfoComposite,
});

const mapDispatchToProps = (dispatch) => ({
    folderActions: bindActionCreators(folderActions, dispatch),
    permissionActions: bindActionCreators(permissionActions, dispatch),

});

// export default PermissionFolderModal;
export default withTranslation()(withRouter(connect(mapStateToProps, mapDispatchToProps)(DownloadRequestModal)));
