import React from 'react';
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Auth } from 'aws-amplify';
import { ForgotPassword } from 'aws-amplify-react';
import { Link, TextStyle, Form, FormLayout, TextField, Button, DisplayText, Page } from '@shopify/polaris';
import { withTranslation } from 'react-i18next';
import inputValidators from '../../../utilities/validation/inputValidators';
import { setStateToPromise } from '../../../utilities/componentUtils';
import { hasValidationErrors, hasEmptyInputFields } from '../../../utilities/validation/formValidation';
import * as userActions from "../../../redux/actions/userActions";
import PageLoader from "../../../components/PageLoader/PageLoader";
import ExternalNavigation from "../../Navigation/TopNavigation/ExternalNavigation";

const validators = {
    email: inputValidators.email,
    password: inputValidators.password,
    verificationCode: inputValidators.verificationCode,
};

class CustmForgotPassword extends ForgotPassword {
    constructor() {
        super();
        this.state = {
            user: {
                email: undefined,
                password: undefined,
                verificationCode: undefined,
            },
            isValid: {
                email: true,
                password: true,
                verificationCode: true,
            },
            errorMessage: undefined,
            codeSent: false,
            isLoading: false,
        };

        this.handleChange = this.handleChange.bind(this);
        this.handleSubmitEmail = this.handleSubmitEmail.bind(this);
        this.handleSubmitConfirmation = this.handleSubmitConfirmation.bind(this);
    }

    handleChange = (value, id) => {
        const { user, isValid } = this.state;
        const change = {
            user: {
                ...user,
                [id]: value,
            },
            isValid: {
                ...isValid,
                [id]: validators[id](value)
            },
        };
        this.setState(change)
    }

    async handleSubmitEmail(event) {
        event.preventDefault();
        await setStateToPromise(this, 'isLoading', true)
        try {
            await Auth.forgotPassword(this.state.user.email);
            // this.props.history.push('/password');
            this.setState({
                codeSent: true,
            });
        } catch (ex) {
            this.setState({
                errorMessage: this.props.t('form.errors.passwordCouldNotBeReset'),
            });
        } finally {
            await setStateToPromise(this, 'isLoading', false)
        }
    }

    async handleSubmitConfirmation(event) {
        event.preventDefault();
        await setStateToPromise(this, 'isLoading', true)
        try {
            await Auth.forgotPasswordSubmit(this.state.user.email, this.state.user.verificationCode, this.state.user.password);
            await this.props.userActions.userSignin(this.state.user.email, this.state.user.password);
            this.changeState('signedIn', this.props.signedInUser);
            this.props.history.push('/search');
        } catch (ex) {
            this.setState({
                errorMessage: this.props.t('form.errors.passwordCouldNotBeReset'),
            });
        } finally {
            await setStateToPromise(this, 'isLoading', false)
        }
    }

    showComponent() {
        const { t } = this.props;
        const { isValid, user, isLoading, codeSent, errorMessage } = this.state;
        const shouldDisableEmailSubmit = !isValid.email || !user.email;
        const shouldDisableConfirmationSubmit = hasValidationErrors(isValid) || hasEmptyInputFields(user);

        // email entry component
        const emailEntryComponent = (<>
            <div className='user-authentication-container'>
                <form className='user-authentication-form' onSubmit={this.handleSubmitEmail} autoComplete={true}>
                    <h2 className='form-header'>{t('common.forgotPassword')}</h2>
                    <div className='field-item-content'>
                        <TextField placeholder={t('form.label.email')} id='email' type="email" error={isValid.email ? false : t('form.errors.email')} value={user.email} onChange={this.handleChange} />
                    </div>
                    <div className='submit-container'>
                        <Button submit primary disabled={shouldDisableEmailSubmit}>Submit</Button>
                    </div>
                    <div className='form-link-container'>
                        <Link onClick={() => this.changeState('signIn')}>{t('auth.forgotPassword.backToSignin')}</Link>
                    </div>
                </form>
            </div>
            <div className='hide'>
                <Form onSubmit={this.handleSubmitEmail} autoComplete={true}>
                    <FormLayout>
                        <DisplayText element='h1' size='large'>{t('common.forgotPassword')}</DisplayText>
                        <TextField id='email' type="email" label={t('form.label.email')} error={isValid.email ? false : t('form.errors.email')} value={user.email} onChange={this.handleChange} />
                        <Button submit primary disabled={shouldDisableEmailSubmit}>Submit</Button>
                        <Link onClick={() => this.changeState('signIn')}>{t('auth.forgotPassword.backToSignin')}</Link>
                    </FormLayout>
                </Form>
            </div>
        </>);


        // new password entry component
        const newPasswordEntryComponent = (<>
            <div>
                <form className='user-authentication-form' onSubmit={this.handleSubmitConfirmation} autoComplete={true}>
                    <h2 className='form-header'>{t('auth.forgotPassword.resetPassword')}</h2>
                    <div className='field-item-content'>
                        <TextField id='email' type="email" placeholder={t('form.label.email')} error={isValid.email ? false : t('form.errors.email')} value={user.email} onChange={this.handleChange} />
                    </div>
                    <div className='field-item-content'>
                        <TextField id='verificationCode' type="password" placeholder={t('form.label.verificationCode')} error={isValid.verificationCode ? false : t('form.errors.verificationCode')} value={user.verificationCode} onChange={this.handleChange} />
                    </div>
                    <div className='field-item-content'>
                        <TextField id='password' type="password" placeholder={t('form.label.newPassword')} error={isValid.password ? false : t('form.label.passwordRules')} value={user.password} onChange={this.handleChange} />
                    </div>
                    <div className='submit-container'>
                        <Button submit primary disabled={shouldDisableConfirmationSubmit}>{t('form.label.submit')}</Button>
                    </div>
                    <div className='form-link-container'>
                        <Link onClick={() => this.changeState('signIn')}>{t('auth.forgotPassword.backToSignin')}</Link>
                    </div>
                </form>
            </div>
            <div className='hide'>
                <Form onSubmit={this.handleSubmitConfirmation} autoComplete={true}>
                    <FormLayout>
                        <DisplayText element='h1' size='large'>{t('auth.forgotPassword.resetPassword')}</DisplayText>
                        <TextField id='email' type="email" label={t('form.label.email')} error={isValid.email ? false : t('form.errors.email')} value={user.email} onChange={this.handleChange} />
                        <TextField id='verificationCode' type="password" label={t('form.label.verificationCode')} error={isValid.verificationCode ? false : t('form.errors.verificationCode')} value={user.verificationCode} onChange={this.handleChange} />
                        <TextField id='password' type="password" label={t('form.label.newPassword')} error={isValid.password ? false : t('form.errors.password')} value={user.password} onChange={this.handleChange} />
                        <Button submit primary disabled={shouldDisableConfirmationSubmit}>{t('form.label.submit')}</Button>
                        <Link onClick={() => this.changeState('signIn')}>{t('auth.forgotPassword.backToSignin')}</Link>
                    </FormLayout>
                </Form >
            </div>
        </>);

        return (
            <>
                <ExternalNavigation external />
                <PageLoader show={isLoading} displayText={t('common.processing')} offset={{ bottom: 150 }} />
                <div className='user-authentication-container'>
                    {!codeSent && emailEntryComponent}
                    {codeSent && newPasswordEntryComponent}
                    <br />
                    {errorMessage && <TextStyle variation="negative">{errorMessage}</TextStyle>}
                </div>
            </>)
    }

}

const mapStateToProps = (state) => ({
    signedInUser: state.user.signedInUser,
});

const mapDispatchToProps = dispatch => ({
    userActions: bindActionCreators(userActions, dispatch),
});

export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(CustmForgotPassword));
