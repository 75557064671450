export const LOAD_FIVE_DAY_FORECAST_SUCCESS = 'LOAD_FIVE_DAY_FORECAST_SUCCESS';
export const LOAD_HOURLY_FORECASTS_SUCCESS = 'LOAD_HOURLY_FORECASTS_SUCCESS';
export const GET_LOCATIONS_SUCCESS = 'GET_LOCATIONS_SUCCESS';
export const GET_CURRENT_LOCATION_SUCCESS = 'GET_CURRENT_LOCATION_SUCCESS';
export const CURRENT_LOCATION_UPDATED = 'CURRENT_LOCATION_UPDATED';
export const BEGIN_AJAX_CALL = 'BEGIN_AJAX_CALL';
export const AJAX_CALL_ERROR = 'AJAX_CALL_ERROR';
export const AJAX_CALL_SUCCESS = 'AJAX_CALL_SUCCESS';
export const GET_BLOG_POSTS_SUCCESS = 'GET_BLOG_POSTS_SUCCESS';
export const START_INDEX_UPDATED = 'START_INDEX_UPDATED';
export const SEARCH_TEXT_UPDATED = 'SEARCH_TEXT_UPDATED';
export const GET_NEXT_PAGE_SUCCESS = 'GET_NEXT_PAGE_SUCCESS';
export const USER_SIGN_IN_SUCCESS = 'USER_SIGN_IN_SUCCESS';
export const USER_SIGN_UP_SUCCESS = 'USER_SIGN_UP_SUCCESS';
export const GET_USER_INFO_COMPOSITE_SUCCESS = 'GET_USER_INFO_COMPOSITE_SUCCESS';
export const SWITCH_AUTH_PAGE = 'SWITCH_AUTH_PAGE';
export const SWITCH_PAGE_SUCCESS = 'SWITCH_PAGE_SUCCESS';
export const GET_PROJECTS_SUCCESS = 'GET_PROJECTS_SUCCESS';
export const SELECT_PROJECT_SUCCESS = 'SELECT_PROJECT_SUCCESS';
export const SELECT_ASSET_SUCCESS = 'SELECT_ASSET_SUCCESS';
export const SELECT_FOLDER_SUCCESS = 'SELECT_FOLDER_SUCCESS';
export const DELETE_FOLDER_SUCCESS = 'DELETE_FOLDER_SUCCESS';
export const DELETE_IMAGE_FOLDER_SUCCESS = 'DELETE_IMAGE_FOLDER_SUCCESS';
export const UPDATE_FOLDER_NAME_SUCCESS = 'UPDATE_FOLDER_NAME_SUCCESS';
export const ADD_ASSET_FOR_FOLDER_SUCCESS = 'ADD_ASSET_FOR_FOLDER_SUCCESS';
export const REMOVE_ASSET_FOR_FOLDER_SUCCESS = 'REMOVE_ASSET_FOR_FOLDER_SUCCESS';
export const SELECT_FOLDER_TO_ADD_ASSETS_SUCCESS = 'SELECT_FOLDER_TO_ADD_ASSETS_SUCCESS';
export const CLEAR_ASSETS_FROM_FOLDER_SUCCESS = 'CLEAR_ASSETS_FROM_FOLDER_SUCCESS';
export const SAVE_ASSET_IDS_TO_FOLDER_SUCCESS = 'SAVE_ASSET_IDS_TO_FOLDER_SUCCESS';
export const GET_FOLDERS_BY_USER_ID_SUCCESS = 'GET_FOLDERS_BY_USER_ID_SUCCESS';
export const GET_ORGANIZATION_BY_ID_SUCCESS = 'GET_ORGANIZATION_BY_ID_SUCCESS';
export const UPLOAD_ASSET_SUCCESS = 'UPLOAD_ASSET_SUCCESS';
export const GET_MY_UPLOAD_REQUESTS = 'GET_MY_UPLOAD_REQUESTS';
export const GET_MY_DOWNLOAD_REQUESTS = 'GET_MY_DOWNLOAD_REQUESTS';
export const UPDATE_MY_UPLOAD_REQUESTS_SUCCESS = 'UPDATE_MY_UPLOAD_REQUESTS_SUCCESS';
export const USER_SIGN_OUT_SUCCESS = 'USER_SIGN_OUT_SUCCESS';
export const EDIT_MY_UPLOAD_REQUESTS_SUCCESS = 'EDIT_MY_UPLOAD_REQUESTS_SUCCESS';
export const GET_USER_UPLOAD_REQUESTS_SUCCESS = 'GET_USER_UPLOAD_REQUESTS_SUCCESS';
export const APPROVE_UPLOAD_REQUESTS_SUCCESS = 'APPROVE_UPLOAD_REQUESTS_SUCCESS';
export const REJECT_USER_UPLOAD_REQUESTS_SUCCESS = 'REJECT_USER_UPLOAD_REQUESTS_SUCCESS';
export const ADD_FILE_UPLOAD_GRID_ERROR_MESSAGE_SUCCESS = 'ADD_FILE_UPLOAD_GRID_ERROR_MESSAGE_SUCCESS';
export const ADD_STATIC_FILE_UPLOAD_GRID_ERROR_MESSAGE_SUCCESS = 'ADD_STATIC_FILE_UPLOAD_GRID_ERROR_MESSAGE_SUCCESS';
export const REMOVE_STATIC_FILE_UPLOAD_GRID_ERROR_MESSAGE_SUCCESS = 'REMOVE_FILE_UPLOAD_GRID_ERROR_MESSAGE_SUCCESS';
export const REMOVE_FILE_UPLOAD_GRID_ERROR_MESSAGE_SUCCESS = 'REMOVE_STATIC_FILE_UPLOAD_GRID_ERROR_MESSAGE_SUCCESS';
export const BEGIN_FILE_UPLOAD_GRID_AJAX_CALL = 'BEGIN_FILE_UPLOAD_GRID_AJAX_CALL';
export const FILE_UPLOAD_GRID_AJAX_CALL_ERROR = 'FILE_UPLOAD_GRID_AJAX_CALL_ERROR';
export const FILE_UPLOAD_GRID_AJAX_CALL_SUCCESS = 'FILE_UPLOAD_GRID_AJAX_CALL_SUCCESS';
export const CLEAR_UPLOAD_GRID_STATUS_SUCCESS = 'CLEAR_UPLOAD_GRID_STATUS_SUCCESS';
export const CLEAR_UPLOAD_GRID_ERROR_MESSAGES_SUCCESS = 'CLEAR_UPLOAD_GRID_ERROR_MESSAGES_SUCCESS';
export const ADMIN_UPDATE_UPLOAD_REQUESTS_SUCCESS = 'ADMIN_UPDATE_UPLOAD_REQUESTS_SUCCESS';
export const ADMIN_APPROVE_UPLOAD_REQUESTS_SUCCESS = 'ADMIN_APPROVE_UPLOAD_REQUESTS_SUCCESS';
export const ADMIN_REJECT_UPLOAD_REQUESTS_SUCCESS = 'ADMIN_REJECT_UPLOAD_REQUESTS_SUCCESS';
export const SORT_MY_UPLOAD_REQUESTS_SUCCESS = 'SORT_MY_UPLOAD_REQUESTS_SUCCESS';
export const SORT_MY_DOWNLOAD_REQUESTS_SUCCESS = 'SORT_MY_DOWNLOAD_REQUESTS_SUCCESS';
export const REQUEST_PERMISSION_SUCCESS = 'REQUEST_PERMISSION_SUCCESS';
export const SORT_PERMISSION_SUCCESS = 'SORT_PERMISSION_SUCCESS';
export const GET_INCOMING_PERMISSION_REQUESTS = 'GET_INCOMING_PERMISSION_REQUESTS';
export const GET_OUTGOING_PERMISSION_REQUESTS = 'GET_OUTGOING_PERMISSION_REQUESTS';
export const REJECT_PERMISSION_REQUESTS = 'REJECT_PERMISSION_REQUESTS';
export const APPROVE_PERMISSION_REQUESTS = 'APPROVE_PERMISSION_REQUESTS';
export const CLEAR_COULDNT_DOWNLOAD_MESSAGE_SUCCESS = 'CLEAR_COULDNT_DOWNLOAD_MESSAGE_SUCCESS';
export const ERROR_MESSAGE_ADDED = 'ERROR_MESSAGE_ADDED';
export const ERROR_MESSAGE_DISMISSED = 'ERROR_MESSAGE_DISMISSED';
export const GET_DOWNLOAD_LINK_SUCCESS = 'GET_DOWNLOAD_LINK_SUCCESS';
export const GET_AUTO_TAGS_SUCCESS = 'GET_AUTO_TAGS_SUCCESS';
export const CLEAR_AUTO_TAGS_SUCCESS = 'CLEAR_AUTO_TAGS_SUCCESS';
export const GET_ORGANIZATIONS_SUCCESS = 'GET_ORGANIZATIONS_SUCCESS';
export const GET_ALL_USERS_SUCCESS = 'GET_ALL_USERS_SUCCESS';
export const CREATE_ADVANCED_USER_SUCCESS = 'CREATE_ADVANCED_USER_SUCCESS';
export const CREATE_PROJECT_SUCCESS = 'CREATE_PROJECT_SUCCESS';
export const GET_ADMIN_PROJECTS_SUCCESS = 'GET_ADMIN_PROJECTS_SUCCESS';
export const GET_USER_GROUP_NAMES_SUCCESS = 'GET_USER_GROUP_NAMES_SUCCESS';
export const CREATE_ORGANIZATION_SUCCESS = 'CREATE_ORGANIZATION_SUCCESS';
export const GET_USERS_BY_EMAIL_SUCCESS = 'GET_USERS_BY_EMAIL_SUCCESS';
export const REMOVE_USERS_SUCCESS = 'REMOVE_USERS_SUCCESS';
export const GET_USERS_BY_ORGANIZATION_IDS_SUCCESS = 'GET_USERS_BY_ORGANIZATION_IDS_SUCCESS';
export const GENERIC_MESSAGE_DISMISSED = 'GENERIC_MESSAGE_DISMISSED';
export const GENERIC_MESSAGE_DISPLAYED = 'GENERIC_MESSAGE_DISPLAYED';
export const UPDATE_ORGANIZATION_PROJECTS_SUCCESS = 'UPDATE_ORGANIZATION_PROJECTS_SUCCESS';
export const GET_ORGANIZATION_USER_IDS_SUCCESS = 'GET_ORGANIZATION_USER_IDS_SUCCESS';
export const REMOVE_ORGANIZATIONS_SUCCESS = 'REMOVE_ORGANIZATIONS_SUCCESS';
export const CLEAR_ASSETS_SUCCESS = 'CLEAR_ASSETS_SUCCESS';
export const　SEND_CONTACT_EMAIL_SUCCESS  = 'SEND_CONTACT_EMAIL_SUCCESS ';
export const GET_UPLOAD_USER_SUCCESS = 'GET_UPLOAD_USER_SUCCESS';
export const DELETE_ASSET_SUCCESS = 'DELETE_ASSET_SUCCESS';
export const GET_TOTAL_NUMBER_IMAGES_JNTO_SUCCESS = 'GET_TOTAL_NUMBER_IMAGES_JNTO_SUCCESS';
export const GET_TOTAL_NUMBER_IMAGES_JOMC_SUCCESS = 'GET_TOTAL_NUMBER_IMAGES_JOMC_SUCCESS';
export const GET_TOTAL_NUMBER_VIDEOS_JNTO_SUCCESS = 'GET_TOTAL_NUMBER_VIDEOS_JNTO_SUCCESS';
export const GET_TOTAL_NUMBER_VIDEOS_JOMC_SUCCESS = 'GET_TOTAL_NUMBER_VIDEOS_JOMC_SUCCESS';
export const GET_DOWNLOAD_HISTORY_EXCEL_SUCCESS = 'GET_DOWNLOAD_HISTORY_EXCEL_SUCCESS';
export const DELETE_FOLDER_REQUESTS_SUCCESS = 'DELETE_FOLDER_REQUESTS_SUCCESS';
export const GET_UPLOAD_ORG_SUCCESS = 'GET_UPLOAD_ORG_SUCCESS';
export const EDIT_USER_SUCCESS = 'EDIT_USER_SUCCESS';
export const GET_ASSET_UPLOAD_USER_SUCCESS = 'GET_ASSET_UPLOAD_USER_SUCCESS';
