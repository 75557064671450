import React from 'react';
import { Button, TextStyle, Collapsible } from '@shopify/polaris';
import VideoDisplay from '../shared/mediaDisplay/VideoDisplay/VideoDisplay';
import ImageDisplay from '../shared/mediaDisplay/ImageDisplay/ImageDisplay';
import AssetContextSection from '../AssetContextSection/AssetContextSection';
import AssetContextSectionBrief from '../AssetContextSection/AssetContextSectionBrief';
import AssetContextSectionUserData from '../AssetContextSection/AssetContextSectionUserData';
import AssetTagsSection from '../AssetTagsSection/AssetTagsSection';
import { stringToBooleanDefaultFalse } from '../../utilities/textUtils';
import FloatingGenericModal from "../../components/FloatingGenericModal/FloatingGenericModal";
import { withTranslation } from 'react-i18next';

class AssetDetails extends React.Component {

    constructor() {
        super();
        this.state = {
            showDeleteButton: false,
            viewMoreDetailsSection: false,
            assetEdits: []
        };
        this.onEdit = this.onEdit.bind(this);
        this.onAssetUpdateDetail = this.onAssetUpdateDetail.bind(this);
      }

    componentDidMount() {
        if(this.props.editMode) {
            this.setState({
                viewMoreDetailsSection: true
            })
        }
    }

    componentDidUpdate(prevProps, prevState) {
        if(this.props.editMode !== prevProps.editMode) {
            if(this.props.editMode) {
                this.setState({viewMoreDetailsSection: true})
            }
        }
        if(this.state.viewMoreDetailsSection !== prevState.viewMoreDetailsSection) {
            if(!this.state.viewMoreDetailsSection && this.props.editMode) {
                this.props.toggleEditMode()
            }
        }
    }

    onEdit(type, value){
        var edits = this.state.assetEdits
        var edit ={field:type, newValue: value}
        var duplicate = -1;
        for(var i = 0; i< edits.length; i++){
            if(edits[i].field === type){
                duplicate = i;
            }
        }
        if(duplicate >= 0){
            edits.splice(duplicate, 1)
        }
        edits.push(edit)
        this.setState({assetEdits:edits})        
    }

    onAssetUpdateDetail(){

        this.props.onAssetUpdate(this.state.assetEdits)
    }


    render(){

    const { canUpdateTags, asset, onUnselectAsset, onShowDeleteAssetFromFolder, onGetDownloadLink, onToggleIsLoading, folderId, folderPage, outgoingPermissionRequests,userId, assetIdsForFolder, signedInUser, uploadedByUser, uploadedByOrg, onDeleteAsset, t, editMode = false, toggleEditMode, onAssetUpdate, assetUploadedByUser} = this.props;


    // const { t } = useTranslation();
    const { secureUrl, thumbnailUrl, publicId, format, fileSizeInMB, tags, orientation, height, width, context, dam_id, duration } = asset;
    const isVideo = format.toUpperCase().indexOf('MP4') > -1;
    const credit = context && context.credit;
    const caption = context && context.caption;
    const jomcAsset =  context && context.jomcAsset && context.jomcAsset.toLowerCase() !== 'false';
    const approvalRequired = context && stringToBooleanDefaultFalse(context.requestApproval);
    const approvalGranted = () => {
        return outgoingPermissionRequests && outgoingPermissionRequests[asset.publicId] === "approved"
    }
    const canDownload = approvalGranted() || !approvalRequired;

    // Check if user is admin user
    const userList = ['admin', 'jnto-hq-admin'];
    const userGroups = signedInUser.signInUserSession.idToken.payload["cognito:groups"];
    const isAdmin = userGroups.some(r=> userList.indexOf(r) >= 0);

    var contextEdited= {};
    if(this.state.assetEdits.length >0){
        var contextEdited = JSON.parse(JSON.stringify(asset.context))
        for(var edit of this.state.assetEdits){
            contextEdited[edit.field] = edit.newValue
        }
    }
    const contextToShow = ()=>{
        if(this.state.assetEdits.length >0){
            return contextEdited
        }
        else{
            return context
        }
    }
    return (
        <>
            <div className="asset-detail-row">
                <div className="asset-detail-col left">
                    {!canDownload &&
                      <TextStyle variation='strong'>{t('common.approvalRequired')}</TextStyle>}

                    <div className='media-wrapper'>
                        {isVideo ?
                          <VideoDisplay
                            thumbnailUrl={thumbnailUrl}
                            secureUrl={secureUrl}
                            height={height}
                            width={width}/>
                          : <ImageDisplay
                            secureUrl={secureUrl}
                            height={height}
                            width={width}
                            size={fileSizeInMB}/>}
                    </div>
                    { (!editMode && ((uploadedByOrg && uploadedByOrg.indexOf(asset.publicId) > -1)|| isAdmin)) &&
                        <div>
                            <button className='dui-btn dui-btn-secondary-alt red--text skinny mx-auto slim-radius uppercase'
                                    onClick={() => toggleEditMode()}
                                    primary>
                                {t('common.edit')}
                            </button>
                        </div>
                    }
                </div>
                <div className="asset-detail-col right">
                    {/* Asset Id and Action buttons*/}
                    <div className="panel-section">
                        <div className="panel-two-col">

                        <div>Asset Id: {dam_id}</div>
                            <div>
                                <div className='asset-detail-buttons'>
                                {folderPage &&
                                      <button className='dui-btn dui-btn-secondary-alt red--text skinny'
                                              onClick={() => onShowDeleteAssetFromFolder()}
                                              primary>
                                          {t('workflows.folders.deleteFromBag')}
                                      </button>}
                                    {folderPage &&
                                      <button className='dui-btn dui-btn-secondary-alt red--text skinny'
                                              onClick={() => onGetDownloadLink(publicId, isVideo, false, (folderPage && folderId) && folderId)}
                                              disabled={!canDownload}
                                              primary>
                                          {t('form.label.download')}
                                      </button>}
                                    {!folderPage && ((isAdmin || (uploadedByOrg && uploadedByOrg.indexOf(asset.publicId) > -1))) &&
                                      <div className='delete-button-container'>
                                          <Button onClick={() => {
                                              this.setState({showDeleteButton: true})
                                          }}
                                                  primary>
                                              {t('workflows.deletionSystem.delete')}
                                          </Button>
                                      </div>}
                                      {(!folderPage && !approvalRequired) &&
                                      <div className='delete-button-container'>
                                          <Button onClick={() => {
                                                onGetDownloadLink(publicId, isVideo, false, (folderPage && folderId) && folderId)
                                          }}
                                                  primary>
                                              {t('form.label.download')}
                                          </Button>
                                      </div>}
                                      {(!folderPage && approvalRequired) &&
                                      <div className='delete-button-container'>
                                          <Button onClick={() => {
                                                onGetDownloadLink(publicId, isVideo, false, (folderPage && folderId) && folderId)
                                          }}
                                                  primary disabled>
                                              {t('form.label.download')}
                                          </Button>
                                      </div>}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="panel-divider"></div>

                    {/* Asset Context Data */}
                    <div className="panel-section">
                        {context &&
                          <AssetContextSectionBrief
                            assetContext={contextToShow()}
                            publicId={publicId}
                            orientation={orientation}
                            fileSizeInMB={fileSizeInMB}
                            editMode={editMode}
                            onEdit={this.onEdit}
                            duration={duration}
                          />}
                    </div>

                    {!this.state.viewMoreDetailsSection &&
                      <>
                          <div className="panel-divider"></div>
                          <div>
                              <button onClick={(() => {this.setState({viewMoreDetailsSection: true})})} className="chev-down-btn red block"><span>{t('common.viewMoreDetails')}</span></button>
                          </div>
                      </>
                    }

                    <Collapsible
                      open={this.state.viewMoreDetailsSection}
                      id="basic-collapsible"
                      transition={{ duration: "500ms", timingFunction: "ease-in-out" }}
                      expandOnPrint
                    >
                        <>
                            {/* Rest Of Asset Context Section*/}
                            <div className="panel-section">
                                {context &&
                                  <AssetContextSection
                                    assetContext={contextToShow()}
                                    publicId={publicId}
                                    orientation={orientation}
                                    fileSizeInMB={fileSizeInMB}
                                    duration={duration}
                                    editMode={editMode}
                                    onEdit={this.onEdit}
                                  />}
                            </div>
                            { editMode &&
                              <div className='mx-auto mt-3'>
                                  <button className='dui-btn dui-btn-secondary-alt skinny w-full white--text bg-red no-mrgn slim-radius uppercase'
                                          onClick={() => this.onAssetUpdateDetail(asset)}
                                          primary>
                                      {t('common.update')}
                                  </button>
                              </div>
                            }

                            <div className="panel-divider"></div>
                            {isAdmin && 
                            <>
                             <div className="panel-section">

                                <AssetContextSectionUserData
                                    assetContext={{
                                        uploadUser: assetUploadedByUser && assetUploadedByUser.uploaderUserEmail,
                                        uploadUserOrg: assetUploadedByUser && assetUploadedByUser.uploaderOrgName,
                                    }}
                                />
                                </div>
                                <div className="panel-divider"></div>

                            </>
                            }
                            
                            {/* Asset Tags */}
                            <div className="panel-section">
                                <AssetTagsSection
                                  publicId={publicId}
                                  selectedAsset={asset}
                                  canUpdateTags={canUpdateTags}
                                  tags={tags} />
                            </div>
                            <div>
                                <button onClick={(() => {this.setState({viewMoreDetailsSection: false})})} className="chev-down-btn red reverse block"><span>{t('form.label.close')}</span></button>
                            </div>
                        </>
                    </Collapsible>
                </div>
            </div>

            {/*  Modals Section Start */}
            <FloatingGenericModal show={this.state.showDeleteButton}>
                <div className='generic-modal'>
                    <h2> {t('workflows.deletionSystem.confirmTitle')}</h2>
                    <div>
                        { jomcAsset ? t('workflows.deletionSystem.confirmTextJOMC') : t('workflows.deletionSystem.confirmText') }
                    </div>
                    <br></br>
                    <div style={{fontSize: '12px'}}>
                        { t('workflows.deletionSystem.infoText') }
                    </div>
                    <div className="actions">
                        <button className="text-center dui-btn dui-btn-secondary-alt" onClick={(() => {this.setState({showDeleteButton: false})})}>
                            {t('form.label.cancel')}
                        </button>
                        <button className="text-center dui-btn dui-btn-primary" onClick={(e)=> onDeleteAsset(asset.publicId)}>
                            {t('workflows.deletionSystem.delete')}
                        </button>
                    </div>
                </div>
            </FloatingGenericModal>
            </>
    );
};
}

export default withTranslation()(AssetDetails);
