import React from "react";
import ManualFloatingBar from "../../components/Navigation/ManualFloatingBar/ManualFloatingBar";
import { Frame, Page } from "@shopify/polaris";
import { withTranslation } from 'react-i18next';
import { withRouter } from "react-router-dom";

class UserGuideUpload01 extends React.Component {
    constructor() {
        super();
        this.state = {}
    }
    render() {
        const { t, history, match } = this.props;
        return (
            <>
                <Frame>
                    <Page fullWidth>
                        <div className='jnto-generic-page-wrapper'>
                            <section className='generic-block'>
                                <h3>{t('manual.assets.assetsDetails.subTitle')}</h3>
                                <h2>{t('manual.assets.assetsDetails.title')}</h2>
                                <hr className='simple-divider' />
                            </section>

                            <section>
                                <p>{t('manual.assets.assetsDetails.detail01')}</p>
                                <br></br>
                                <p>{t('manual.assets.assetsDetails.detail02')}</p>
                                <section className='bullet-list'>
                                    <ul>
                                        <li>{t('manual.assets.assetsDetails.detail04')}</li>
                                        <li>{t('manual.assets.assetsDetails.detail05')}</li>
                                        <li>{t('manual.assets.assetsDetails.detail06')}</li>
                                    </ul>
                                </section>
                                <p>{t('manual.assets.assetsDetails.detail07')}</p>
                                <br></br>
                                <p className='text-red'>{t('manual.assets.assetsDetails.notes')}</p>
                                <section className='bullet-list'>
                                    <ul>
                                        <li>{t('manual.assets.assetsDetails.notes1')}</li>
                                        <li>{t('manual.assets.assetsDetails.notes2')}</li>
                                        <li>{t('manual.assets.assetsDetails.notes3')}</li>
                                    </ul>
                                </section>
                            </section>
                            <section>
                            <img src='https://asset.japan.travel/image/upload/w_600,f_auto,fl_lossy,e_improve,e_sharpen/v1711708190/dam-admin/manual/updated/P15_7-1.png' />
                            </section>
                            

                            <ManualFloatingBar buttons={[
                                {
                                    position: 'left',
                                    text: t('manual.assets.title'),
                                    callback: () => history.push(`assets-about`),
                                },
                                {
                                    position: 'right',
                                    text: t('manual.assets.assetsGallery.title'),
                                    callback: () => history.push(`assets-gallery`),
                                }
                            ]} />
                        </div>
                    </Page>
                </Frame>
            </>)
    }
}

export default withTranslation()(withRouter((UserGuideUpload01)));