import axios from 'axios';
import * as errorHandlerActions from '../../redux/actions/errorHandlerActions';
import { requestToFormData } from '../../utilities/fileUploadUtils';
import config from '../../config';
const retry = require('retry');

class CloudinaryApi {

    constructor() {
        this.cloudinary_api_key = '913975795745652';
        this.cloudinary_notification_url = "http://jnto-prd-elb31-1677611009.ap-northeast-1.elb.amazonaws.com/image-management/upload/cloudinary-notify";

    }

    postMulti(requests, dispatch) {
            // const enndpoint = config.cloudinary.upload;
        const host = "https://api.cloudinary.com/v1_1/jnto/"


        // const enndpoint = config.cloudinary.upload;

        
        const outgoingRequests = requests.map((req) => {

            const operation = retry.operation({
                retries: 7,
                minTimeout: 1 * 1000,
                maxTimeout: 20 * 1000,
                // randomize: true,
            });

        if(req.file.size < 80000000){
            return send(req, this.cloudinary_api_key, this.cloudinary_notification_url)
        }

        return processFile(req.file, this.cloudinary_api_key, this.cloudinary_notification_url)

        function processFile(file, cloudinary_api_key, cloudinary_notification_url) {
            var size = file.size;
            var sliceSize = 20000000;
            var start = 0;
            var XUniqueUploadId = +new Date();
        
            return new Promise((resolve, reject) =>{
                setTimeout(loop(resolve, reject, cloudinary_api_key, cloudinary_notification_url), 3);
            })
        
            async function loop(resolve, reject, cloudinary_api_key, cloudinary_notification_url) {
                var end = start + sliceSize;

                try{
                    if (end > size) {
                    end = size;
                    }
                    var s = slice(file, start, end);
                    await sendLarge(s, file, start, end - 1, size, XUniqueUploadId, cloudinary_api_key, cloudinary_notification_url);
                    if (end < size) {
                    start += sliceSize;
                    setTimeout(loop(resolve, reject, cloudinary_api_key, cloudinary_notification_url), 3);
                    }
                    else{
                        resolve("Upload Complete")
                    }
                }
                catch(e){
                    reject(e)
                }
            }
            }

            function slice(file, start, end) {
                var slice = file.mozSlice
                  ? file.mozSlice
                  : file.webkitSlice
                  ? file.webkitSlice
                  : file.slice
                  ? file.slice
                  : noop;
          
                return slice.bind(file)(start, end);
              }
          
              function noop() {}


     function send(req, cloudinary_api_key, cloudinary_notification_url) {

        return new Promise( (resolve, reject) =>{

            const host = "https://api.cloudinary.com/v1_1/jnto/";
            
            operation.attempt(async (currentAttempt) => {
                var response;
                try {
                //    await new Promise((resolve, reject) =>{
                        const axiosRequest = requestToFormData([req]);
                        var timestamp = req.timestamp;
                        var folder = req.options.folder;
                        var context = req.options.context;
                        var isVideo = req.file.name.indexOf('mp4') > -1;
                        var type = isVideo ? 'video' : 'image';
                        var use_filename = req.options.use_filename;
                        var auto_tagging = req.options.auto_tagging;
                        const params = `${type}/upload?api_key=${cloudinary_api_key}&context=${context}&auto_tagging=${auto_tagging}&use_filename=${use_filename}&folder=${folder}&timestamp=${timestamp}&notification_url=${cloudinary_notification_url}`
                        const fullPath = host + params;
                        response = await axios.post(fullPath, axiosRequest[0])
                        if(!response || !response.status || response.status !== 200){
                            throw new Error('Error on Image Upload')
                        }
                        // });              
                } catch (e) {
                    if (operation.retry(e)) { return; }
                }

                if(!response || !response.status || response.status !== 200){
                    reject('Error on Image Upload')
                }
                else{
                    resolve(response)
                }

            })
        })
    }


     function sendLarge(piece, file, start, end, size, XUniqueUploadId, cloudinary_api_key, cloudinary_notification_url) {

        const host = "https://api.cloudinary.com/v1_1/jnto/";

           return new Promise( (resolve, reject) =>{

                operation.attempt(async (currentAttempt) => {
                    var response;
                    try {
                    //    await new Promise((resolve, reject) =>{
                            var timestamp = req.timestamp;
                            var folder = req.options.folder;
                            var context = req.options.context;
                            var isVideo = file.name.indexOf('mp4') > -1;
                            piece.name = file.name;
                            req.file =piece;
                            const axiosRequest = requestToFormData([req]);
                            var type = isVideo ? 'video' : 'image';
                            var use_filename = req.options.use_filename;
                            var auto_tagging = req.options.auto_tagging;
                            const params = `${type}/upload?api_key=${cloudinary_api_key}&context=${context}&auto_tagging=${auto_tagging}&use_filename=${use_filename}&folder=${folder}&timestamp=${timestamp}&notification_url=${cloudinary_notification_url}`
                            const fullPath = host + params;
                            const headers = {
                                "headers":{
                                "Content-Range": ("bytes " + start + "-" + end + "/" + size),
                                "X-Unique-Upload-Id": XUniqueUploadId
                                }

                            }
                            response = await axios.post(fullPath, axiosRequest[0], headers)
                            if(!response || !response.status || response.status !== 200){
                                throw new Error('Error on Image Upload')
                            }
                            // });              
                    } catch (e) {
                        if (operation.retry(e)) { return; }
                    }

                    if(!response || !response.status || response.status !== 200){
                        reject('Error on Image Upload')
                    }
                    else{
                        resolve(response)
                    }

                // })
             })
            })
            }
        })

        return Promise.all(outgoingRequests)
            .catch(err => {
                const { message } = err;
                dispatch(errorHandlerActions.addErrorMessage(message));
                throw (err.message);
        });
    
    }

    // postMulti(requests, dispatch) {


    //     // const enndpoint = config.cloudinary.upload;
    //     const host = "https://api.cloudinary.com/v1_1/funkycorp-dam-staging/"
        
    //     const outgoingRequests = requests.map((req) => {

    //         const operation = retry.operation({
    //             retries: 7,
    //             minTimeout: 1 * 1000,
    //             maxTimeout: 20 * 1000,
    //             // randomize: true,
    //         });

    //        return new Promise((resolve, reject) =>{

    //             operation.attempt(async (currentAttempt) => {
    //                 var response;
    //                 try {
    //                 //    await new Promise((resolve, reject) =>{
    //                         const axiosRequest = requestToFormData([req]);
    //                         var timestamp = req.timestamp;
    //                         var folder = req.options.folder;
    //                         var context = req.options.context;
    //                         var isVideo = req.file.name.indexOf('mp4') > -1;
    //                         var type = isVideo ? 'video' : 'image';
    //                         var use_filename = req.options.use_filename;
    //                         var auto_tagging = req.options.auto_tagging;
    //                         const params = `${type}/upload?api_key=${this.cloudinary_api_key}&context=${context}&auto_tagging=${auto_tagging}&use_filename=${use_filename}&folder=${folder}&timestamp=${timestamp}&notification_url=http://vermillion-dev-application-1762901889.ap-northeast-1.elb.amazonaws.com/image-management/upload/cloudinary-notify`
    //                         const fullPath = host + params;
    //                         response = await axios.post(fullPath, axiosRequest[0])
    //                         if(!response || !response.status || response.status !== 200){
    //                             throw new Error('Error on Image Upload')
    //                         }
    //                         // });              
    //                 } catch (e) {
    //                     if (operation.retry(e)) { return; }
    //                 }

    //                 if(!response || !response.status || response.status !== 200){
    //                     reject('Error on Image Upload')
    //                 }
    //                 else{
    //                     resolve(response)
    //                 }

    //             })
    //          })
    //     })

    //     return Promise.all(outgoingRequests)
    //         .catch(err => {
    //             const { message } = err;
    //             dispatch(errorHandlerActions.addErrorMessage(message));
    //             throw (err.message);
    //     });
    
    // }
}

export default new CloudinaryApi();
