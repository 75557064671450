import React, { useState } from "react";

const ToggleBtn = ({
    fieldLabel = "Fuzzy search",
    onLabel = "on",
    offLabel = "off",
    checked = false,
    ...props
}) => {

    const defaultChecked = checked ? checked : false;
    const [isChecked, setIsChecked] = useState(defaultChecked);
    const { onSelect } = props;

    return (
        <div className="toggle-btn-container">
            <span className={'field-label'}>{fieldLabel}</span>
            <label className="switch btn-color-mode-switch">
                <input type="checkbox" name="color_mode" id="color_mode" checked={isChecked} onChange={() => { setIsChecked((prev) => !prev); onSelect('select', 'fuzzy') }} {...props} />
                <label htmlFor="color_mode" data-on={onLabel} data-off={offLabel} className="btn-color-mode-switch-inner"></label>
            </label>
        </div>
    )
}

export default ToggleBtn
