import {
    searchMyIncomingPermissionRequests, searchMyOutgoingPermissionRequests, acceptPermissionRequests, rejectPermissionRequests, postRequestFolderPermission, deleteFolderRequests,searchMySimpleOutgoingPermissionRequests
} from '../../api/vermillionApi/permissionManagementApi';
import * as types from "../constants/action-types";

export const getMyIncomingPermissionRequestsSuccess = (permissionRequests, isComplete) => {
    return { type: types.GET_INCOMING_PERMISSION_REQUESTS, permissionRequests, isComplete };
};

export const getMyOutgoingPermissionRequestsSuccess = (permissionRequests, isComplete) => {
    return { type: types.GET_OUTGOING_PERMISSION_REQUESTS, permissionRequests, isComplete };
};

export const getMySimpleOutgoingPermissionRequestsSuccess = (permissionRequests) => {
    return { type: types.GET_OUTGOING_PERMISSION_REQUESTS, permissionRequests };
};

export const postApprovePermissionRequestsSuccess = (permissionRequests) => {
    return { type: types.REJECT_PERMISSION_REQUESTS, permissionRequests };
};

export const postRejectPermissionRequestsSuccess = (permissionRequests) => {
    return { type: types.APPROVE_PERMISSION_REQUESTS, permissionRequests };
};

export const requestPermissionRequestSuccess = (permissionRequests) => {
    return { type: types.REQUEST_PERMISSION_SUCCESS, permissionRequests };
};

export const sortPermissionRequestsSuccess = (sortedRequests, currentTab) => {
    return { type: types.SORT_PERMISSION_SUCCESS, sortedRequests, currentTab };
};

export const clearCouldntDownloadWarningSuccess = () => {
    return { type: types.CLEAR_COULDNT_DOWNLOAD_MESSAGE_SUCCESS };
};

export const deleteFolderRequestsSuccess = () => {
    return { type: types.DELETE_FOLDER_REQUESTS_SUCCESS };
};


export const getMyIncomingPermissionRequests = (userId, isComplete) => {
    return async (dispatch) => {
        const permissionRequests = await searchMyIncomingPermissionRequests(userId, isComplete);
        dispatch(getMyIncomingPermissionRequestsSuccess(permissionRequests));
        return;
    };
};

export const getMyOutgoingPermissionRequests = (userId, isComplete) => {
    return async (dispatch) => {
        const permissionRequests = await searchMyOutgoingPermissionRequests(userId, isComplete);
        dispatch(getMyOutgoingPermissionRequestsSuccess(permissionRequests));
        return;
    };
};

export const getMySimpleOutgoingPermissionRequests = (userId, folderId) => {
    return async (dispatch) => {
        const permissionRequests = await searchMySimpleOutgoingPermissionRequests(userId, folderId);
        dispatch(getMySimpleOutgoingPermissionRequestsSuccess(permissionRequests));
        return;
    };
};

export const postApprovePermissionRequests = (userId, permissionIdsToApprove) => {
    return async (dispatch) => {
        const permissionRequests = await acceptPermissionRequests(userId, permissionIdsToApprove);
        dispatch(postApprovePermissionRequestsSuccess(permissionRequests));
        return;
    };
};

export const postRejectPermissionRequests = (userId, permissionIdsToReject) => {
    return async (dispatch) => {
        const permissionRequests = await rejectPermissionRequests(userId, permissionIdsToReject);
        dispatch(postRejectPermissionRequestsSuccess(permissionRequests));
        return;
    };
};

export const requestFolderPermission = ({ userId, folderId, useDescription, use }) => {
    return async (dispatch) => {
        const requestFolderPermissionResponse = await postRequestFolderPermission(userId, folderId, useDescription, use, dispatch);
        dispatch(requestPermissionRequestSuccess(requestFolderPermissionResponse));
        return;
    };
};

export const deletePermissionRequests = ( userId, folderId ) => {
    return async (dispatch) => {
        const deleteFolderRequestsResponse = await deleteFolderRequests(userId, folderId, dispatch);
        dispatch(deleteFolderRequestsSuccess(deleteFolderRequestsResponse));
        return;
    };
};

export const clearCouldntDownloadWarning = () => {
    return async (dispatch) => {
        dispatch(clearCouldntDownloadWarningSuccess());
        return;
    };
};

export const sortPermissionRequests = (sortedRequests, currentTab) => {
    return (dispatch) => {
        dispatch(sortPermissionRequestsSuccess(sortedRequests, currentTab));
        return;
    };
};
