import React from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from "react-redux";

/**
 * AutomaticRedirect
 * This component watches the browser push state and 
 * redirects to the Projects page ONLY IF the root path is accessed
 * and the user is currently signed in
 */

class AutomaticRedirect extends React.Component {
    render() {

        const userSignedIn = this.props.userInfoComposite.user && this.props.userInfoComposite.user.id && this.props.signedInUser.username;
        const locationPath = this.props.history.location.pathname;
        if ((locationPath=== '/'  || locationPath === '/projects' || locationPath === '/projects/' )&& userSignedIn) {
            this.props.history.push('/dashboard');
        }
        return null;
    }
}

const mapStateToProps = (state, ownProps) => {
    return {
        userInfoComposite: state.user.userInfoComposite,
        signedInUser: state.user.signedInUser,
    };
}

export default withRouter(connect(mapStateToProps, null)(AutomaticRedirect));