import React from 'react';
import { useTranslation } from 'react-i18next';
import { Card, Caption, Icon, Tooltip } from '@shopify/polaris';
import { ExternalMinor } from '@shopify/polaris-icons';
import { truncateAssetCardTitle, stringToBooleanDefaultFalse } from '../../../../utilities/textUtils';
import { CircleInformationMajorMonotone, CircleDisabledMajorMonotone, CircleDotsMajorMonotone, CircleTickMajorMonotone } from '@shopify/polaris-icons';
import contactIcon from '../../../../assets/icons/icon_contact.svg';
import movieIcon from '../../../../assets/icons/icon_movie.svg';
import lockIcon from '../../../../assets/icons/icon_lock.svg';
import unlockIcon from '../../../../assets/icons/icon_un-lock.svg';
import infoIcon from '../../../../assets/icons/icon_user-profile.svg';
import urlIcon from '../../../../assets/icons/icon_url-copy.svg';
import editIcon from '../../../../assets/icons/icon_edit_v2.svg';

const AssetCard = ({ enableBanner, selectAsset, asset, onAddAssetToFolder, onRemoveAssetFromFolder, isSelectedForFolder, canShareUrl, onShareUrl, folderPage, outgoingPermissionRequests, uploadedByUser, canEdit = false, uploadedByOrg, signedInUser  }) => {
    const { t } = useTranslation();
    const { thumbnailUrl, context, format, fileSizeInMB, date } = asset;
    const location = context && context.location;
    const prefecture = context && context.prefecture;
    const locationDisplayText = location ? truncateAssetCardTitle(location) : t('common.unavailable');
    const approvalRequired = context && stringToBooleanDefaultFalse(context.requestApproval);
    const THREE_DAYS = 72 * 60 * 60 * 1000;
    const recentUpload = ((new Date()) - date) < THREE_DAYS;
    const userList = ['admin', 'jnto-hq-admin'];
    const userGroups = signedInUser.signInUserSession.idToken.payload["cognito:groups"];
    const isAdmin = userGroups.some(r=> userList.indexOf(r) >= 0);

    const onAddIconClicked = (event) => {
        event.stopPropagation();
        if (isSelectedForFolder) {
            onRemoveAssetFromFolder(asset);
        } else {
            onAddAssetToFolder(asset);
        }
    };

    const onShareIconClicked = (event) => {
        event.stopPropagation();
        if (window.clipboardData) { // this is for IE11
            window.clipboardData.setData("Text", asset.secureUrl);
        } else { // this is for Chrome
            const textField = document.createElement('textarea');
            textField.innerText = asset.secureUrl;
            document.body.appendChild(textField);
            textField.select();
            document.execCommand('copy');
            textField.remove();
        }

        onShareUrl();
    }

    const permisisonIcon = () => {
        switch (outgoingPermissionRequests[asset.publicId]) {
            case "pending":
                return <img className='icon small' src={'https://asset.japan.travel/image/upload/v1611286890/dam-admin/icon_pending.svg'} />
            case "rejected":
                return <img className='icon small' src={'https://asset.japan.travel/image/upload/v1611286886/dam-admin/icon_reject.svg'} />
            case "approved":
                return <img className='icon small' src={unlockIcon} />
            default:
                return <img className='icon small' src={lockIcon} />
        }
    }

    const topBannerClassName = isSelectedForFolder ? 'asset-card-top-banner-selected' : 'asset-card-top-banner';
    const movieFormat = format === 'mp4';
    const userUploadedAsset = (uploadedByUser && uploadedByUser.indexOf(asset.publicId) > -1);

    return (
        <div className='asset-card-container' onClick={() => selectAsset(asset)}>
            <Card>
                <div className='asset-card-image-container'>
                    <div className='card-overlay-action-menu-top-right'>
                        <div className='card-overlay-action-menu-top-content'>
                            {movieFormat &&
                                <div className='content-item'>
                                    <img className='icon' src={movieIcon} />
                                </div>
                            }
                        </div>
                    </div>
                    <div className='card-overlay-action-menu-top-left'>
                        <div className='card-overlay-action-menu-top-content'>
                            {recentUpload &&
                                <div className='content-item'>
                                    <img className='icon' src={'https://asset.japan.travel/image/upload/v1611295729/dam-admin/icon_new.svg'} />
                                </div>
                            }
                        </div>
                    </div>
                    <div className='card-overlay-action-menu-bottom'>
                        <div className='card-overlay-action-menu-bottom-content'>
                            {(enableBanner && onAddAssetToFolder) &&
                                <div className='content-item checkbox' onClick={onAddIconClicked}>
                                    <div className="checkbox-field-group">
                                        <input type="checkbox" checked={isSelectedForFolder ? 'checked' : ''} />
                                        <span className="checkmark"></span>
                                    </div>
                                </div>
                            }
                            {userUploadedAsset &&
                                <div className='content-item pointer'>
                                    <img className='icon' src={infoIcon} />
                                </div>
                            }
                            {(canShareUrl ||  (!approvalRequired)) &&
                                <div className='content-item pointer'>
                                    <img className='icon' src={urlIcon} onClick={onShareIconClicked} />
                                </div>
                            }
                            {(canEdit &&  (uploadedByOrg && uploadedByOrg.indexOf(asset.publicId) > -1)|| isAdmin) &&
                              <div className='content-item pointer'>
                                  <img className='icon' src={editIcon} onClick={(e) => {e.stopPropagation(); selectAsset(asset, true)}} />
                              </div>
                            }
                            {!folderPage && approvalRequired &&
                                <div className='content-item'>
                                    <img className='icon small' src={lockIcon} />
                                </div>}
                            {folderPage && approvalRequired &&
                                <div className='content-item'>
                                    {permisisonIcon()}
                                </div>
                            }
                        </div>
                    </div>
                    <img alt={'Loading...'} className='asset-card-image' src={thumbnailUrl} />
                </div>
                <Card.Section title={
                    <div>
                        <h3 style={{ display: 'inline-block' }}>
                            {locationDisplayText}
                        </h3>
                    </div>
                }>
                    <div className='asset-card-grid-container'>
                        <div className='asset-card-grid-item-left'>
                            <Caption>{prefecture}</Caption>
                        </div>
                    </div>
                    <div className='asset-card-grid-container'>
                        <div className='asset-card-grid-container'>
                            <div className='asset-card-grid-item-left'><Caption>{t('filters.assetId')}: {asset.dam_id}</Caption></div>
                        </div>
                    </div>
                    {movieFormat &&
                        <div className='asset-card-grid-container'>
                            <div className='asset-card-grid-container'>
                                <div className='asset-card-grid-item-left'><Caption>{t('common.duration')}: {asset.duration} {t('common.durationSeconds')}</Caption></div>
                            </div>
                        </div>
                    }
                </Card.Section>
            </Card>
        </div>);
};

export default AssetCard;
