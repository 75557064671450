class AlgoliaAssetItem {
    constructor(properties) {
        this.thumbnailUrl = properties.thumbnailUrl;
        this.publicId = properties.public_id;
        this.description = properties.description;
        this.resourceType = properties.resource_type;
        this.format = properties.format;
        this.duration = properties.duration && Math.floor(properties.duration)
        this.fileSizeInMB = properties.bytes ? Math.round(properties.bytes / 1000000 * 100) / 100 : Math.round(properties.size / 1000000 * 100) / 100 ;
        this.tags = properties.tags && AlgoliaAssetItem.formatTags(properties.tags,  this.publicId );
        this.width = properties.width;
        this.height = properties.height;
        this.orientation = properties.orientation;
        this.secureUrl = properties.secureUrl;
        this.context = properties.context;
        this.date = properties.date;
        this.dam_id = properties.dam_id;
        this.autoTags = properties.autoTags;
    }

    static formatTags(tags, id) {
        var tagArray = [];
        if(tags){
            for(var tag of tags){
                    var spliTag= tag.split('，');
                    tagArray = tagArray.concat(spliTag);
                }
        }
        else{
            tagArray = []
        }
        return tagArray;
    }
}

export default AlgoliaAssetItem;