import React from 'react';
import { TextField } from '@shopify/polaris';
import SimpleModal from '../SimpleModal/SimpleModal';

const TextInputModal = ({ label, value, onChange, ...simpleModalProps }) => (
    <SimpleModal {...simpleModalProps}>
        <TextField
            label={label}
            onChange={onChange}
            value={value}
        />
    </SimpleModal>)

export default TextInputModal;
