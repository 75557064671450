import * as types from "../constants/action-types";
import initialState from './initialState';
import { TAB_ENUMS } from '../../workflows/PermissionRequestsPage/PermissionRequestsPageTabs';


export default function myPermissionRequestsReducer(state = initialState.myPermissionRequests, action) {
    let newState;
    let clonedPermissionRequests = [];
    switch (action.type) {
        case types.GET_INCOMING_PERMISSION_REQUESTS:
            newState = {
                ...state,
                incomingPermissionRequests: action.permissionRequests,
            }
            break;
        case types.DELETE_FOLDER_REQUESTS_SUCCESS:
            break;
        case types.GET_OUTGOING_PERMISSION_REQUESTS:
            newState = {
                ...state,
                outgoingPermissionRequests: action.permissionRequests,
            }
            break;
        case types.REJECT_PERMISSION_REQUESTS:
            clonedPermissionRequests = state.incomingPermissionRequests.slice();
            action.permissionRequests.forEach(rejectedPermissionRequest => {
                const permissionToRemove = clonedPermissionRequests.find(req => req.id === rejectedPermissionRequest.id);
                const indexToRemove = clonedPermissionRequests.indexOf(permissionToRemove);
                clonedPermissionRequests.splice(indexToRemove, 1);
            });
            newState = {
                ...state,
                incomingPermissionRequests: clonedPermissionRequests
            };
            break;
        case types.APPROVE_PERMISSION_REQUESTS:
            clonedPermissionRequests = state.incomingPermissionRequests.slice();
            action.permissionRequests.forEach(approvedPermissionRequest => {
                const permissionToRemove = clonedPermissionRequests.find(req => req.id === approvedPermissionRequest.id);
                const indexToRemove = clonedPermissionRequests.indexOf(permissionToRemove);
                clonedPermissionRequests.splice(indexToRemove, 1);
            });
            newState = {
                ...state,
                incomingPermissionRequests: clonedPermissionRequests
            };
            break;
        case types.REQUEST_PERMISSION_SUCCESS:
            newState = {
                ...state,
                requestsFailNouser: action.permissionRequests.noUsers,
            }
        break;
        case types.SORT_PERMISSION_SUCCESS:
            if (action.currentTab === TAB_ENUMS.INCOMING_REQUESTS) {
                newState = {
                    ...state,
                    incomingPermissionRequests: action.sortedRequests,
                }
            } else if (action.currentTab === TAB_ENUMS.OUTGOING_REQUESTS) {
                newState = {
                    ...state,
                    outgoingPermissionRequests: action.sortedRequests,
                }
            }
        break;
        case types.CLEAR_COULDNT_DOWNLOAD_MESSAGE_SUCCESS:
            newState = {
                ...state,
                requestsFailNouser: undefined,
            }
        break;
        default:
            break;
    }

    return newState || state;
}