// if length is greater than 21, sub the last 3 chars with '...'
export const truncateAssetCardTitle = originalTitle => {
    if (!originalTitle || originalTitle.length < 40) {
        return originalTitle;
    }

    return originalTitle.substring(0, 37) + '...';
};

export const truncateProjectCardTitle = originalTitle => {
    if (!originalTitle || originalTitle.length < 15) {
        return originalTitle;
    }

    return originalTitle.substring(0, 12) + '...';
};

export const buildProjectIdFilterString = projectIds => {
    const filterStr = projectIds.reduce((str, projectId, index) => {
        const shouldAddOr = index < projectIds.length - 1;
        const orStr = shouldAddOr ? `projectId:${projectId} OR ` : `projectId:${projectId}`;
        return str.concat(orStr);
    }, '');
    return filterStr;
};
export const buildExpiredFilter = () => {
    var validString = '';
    var todayDate = new Date()
    const offset = todayDate.getTimezoneOffset(); 
    todayDate = new Date(todayDate.getTime() + (offset*60*1000));
    var todayDateFormatted = todayDate.toISOString().split('T')[0]
    todayDateFormatted= todayDateFormatted.replaceAll('-', '');
    validString += `context.validDate >= ${todayDateFormatted} OR context.validDate <0`
    return validString;
}


export const buildJNTOJOMCFilterString = (jomcFilter, filterAspect, filterMediaType, filterPrefecture, filterSeason, filterTags, filterDamId, filterPrePostApproval, filterDownloadPermission, filterValidDate, filterApprovedExternal) => {
    var filterString = ''
    if (jomcFilter === 'JOMC') {
        filterString = `(context.secondaryUseJOMC:'true' OR context.jomcAsset:'true' OR context.secondaryUseJomcJnto:'true')`
    }
    else if (jomcFilter === 'JNTO') {
        filterString = `(NOT context.secondaryUseJOMC:'true' AND NOT context.jomcAsset:'true' AND NOT context.secondaryUseJomcJnto:'true')`
    }
    if (filterMediaType && filterMediaType.length > 0) {
        var mediaTypeString = ' AND (';
        for (var i = 0; i < filterMediaType.length; i++) {
            if (i == 0) {
                mediaTypeString += `format:'${filterMediaType[i]}'`
            }
            else {
                mediaTypeString += ` OR format:'${filterMediaType[i]}'`
            }
        }
        mediaTypeString += `)`;
        filterString += mediaTypeString;
    }
    if (filterPrefecture && filterPrefecture.length>0) {
        var prefString = ' AND (';
        for (var i = 0; i < filterPrefecture.length; i++) {
            if (i == 0) {
                prefString += `context.prefecture:'${filterPrefecture[i]}'`
            }
            else {
                prefString += ` OR context.prefecture:'${filterPrefecture[i]}'`
            }
        }
        prefString += `)`;
        filterString += prefString;
    }
    if (filterDamId) {
        if (filterString == `dam_id:${filterDamId}`) {

        }
        else {
            filterString += ` AND dam_id:${filterDamId}`
        }
    }
    if (filterAspect && filterAspect.length > 0) {
        var aspectString = ' AND (';
        for (var i = 0; i < filterAspect.length; i++) {
            if (i == 0) {
                aspectString += `orientation:'${filterAspect[i]}'`
            }
            else {
                aspectString += ` OR orientation:'${filterAspect[i]}'`
            }
        }
        aspectString += `)`;
        filterString += aspectString;
    }
    if (filterApprovedExternal && filterApprovedExternal.length > 0) {
        var approvalString = ' AND (';
        for (var i = 0; i < filterApprovedExternal.length; i++) {
            if (i == 0) {
                approvalString += `context.approvalForExternalUsage:'${filterApprovedExternal[i]}'`
            }
            else {
                approvalString += ` OR context.approvalForExternalUsage:'${filterApprovedExternal[i]}'`
            }
        }
        approvalString += `)`;
        filterString += approvalString;
    }
    if (filterSeason && filterSeason.length > 0) {
        var seasonString = ' AND (';
        for (var i = 0; i < filterSeason.length; i++) {
            if (i == 0) {
                seasonString += `context.season:'${filterSeason[i]}'`
            }
            else {
                seasonString += ` OR context.season:'${filterSeason[i]}'`
            }
        }
        seasonString += `)`;
        filterString += seasonString;
    }
    if (filterTags && filterTags.length > 0) {
        var tagString = ' AND (';
        for (var i = 0; i < filterTags.length; i++) {
            if (i == 0) {
                tagString += `tags:'${filterTags[i]}'`
            }
            else {
                tagString += ` OR tags:'${filterTags[i]}'`
            }
        }
        tagString += `)`;
        filterString += tagString;
    }
    if (filterDownloadPermission && filterDownloadPermission.length > 0) {
        var permissionString = ' AND (';
        for (var i = 0; i < filterDownloadPermission.length; i++) {
            if (i == 0) {
                permissionString += `context.requestApproval:'${filterDownloadPermission[i]}'`
            }
            else {
                permissionString += ` OR context.requestApproval:'${filterDownloadPermission[i]}'`
            }
        }
        permissionString += `)`;
        filterString += permissionString;
    }
    //filter by todays date
    if (true) {
        var validString = ' AND (';
        var todayDate = new Date()
        const offset = todayDate.getTimezoneOffset(); 
        todayDate = new Date(todayDate.getTime() + (offset*60*1000));
        var todayDateFormatted = todayDate.toISOString().split('T')[0]
        todayDateFormatted= todayDateFormatted.replaceAll('-', '');
        validString += `context.validDate >= ${todayDateFormatted} OR context.validDate <0`
        validString += `)`;
        filterString += validString;
    }
    if (filterPrePostApproval && filterPrePostApproval.length > 0) {
        var approvalString = ' AND (';
        for (var i = 0; i < filterPrePostApproval.length; i++) {
            if (i == 0) {
                approvalString += `context.prePostApproval:'${filterPrePostApproval[i]}'`
            }
            else {
                approvalString += ` OR context.prePostApproval:'${filterPrePostApproval[i]}'`
            }
        }
        approvalString += `)`;
        filterString += approvalString;
    }
    return filterString
};


export const stringToBooleanDefaultTrue = str => {
    if (typeof str === 'boolean') {
        return str;
    }
    // default to true if value not populated
    if (str === null || str === undefined || str === "") {
        return true;
    }
    return (str && (str.toLowerCase() === 'true' || str.toLowerCase() === 'yes'));
};

export const booleanToYesNo = str => {
    if (typeof str === 'boolean') {
        return str ? 'Yes' : 'No'
    }
    // default to true if value not populated
    if (str === null || str === undefined || str === "") {
        return false;
    }
    if (str === 'True' || str === 'true' || str === 'Yes' || str === 'yes') {
        return 'Yes';
    };
    if (str === 'False' || str === 'false' || str === 'No' || str === 'no') {
        return 'No';
    };
    return false;
};

export const booleanToYesNoDefaultTrue = str => {
    if (typeof str === 'boolean') {
        return str ? 'Yes' : 'No'
    }
    // default to true if value not populated
    if (str === null || str === undefined || str === "") {
        return 'Yes';
    }
    if (str === 'True' || str === 'true' || str === 'Yes' || str === 'yes') {
        return 'Yes'
    };
    return false;
};

export const stringtoDate = str => {
    return str.replace(/T/, ' ').replace(/\..+/, '')
}

export const formatYYYMMDD = str => {
    if (str && str.length === 8 && (str.indexOf('-') <0)) {
        return str.substring(0, 4) + '-' + str.substring(4, 6) + '-' + str.substring(6, 8);
    }
    return str
}

export const stringToBooleanDefaultFalse = str => {
    if (typeof str === 'boolean') {
        return str;
    }
    // default to true if value not populated
    if (str === null || str === undefined || str === "") {
        return false;
    }
    return (str && (str.toLowerCase() === 'true' || str.toLowerCase() === 'yes'));
};

const approvalForExternalUsageOptions = {
    "Approved for External Usage": "Approved External",
    "External Usage Forbidden": "Forbidden External",
    "Contact the Department in Charge in JNTO": "Contact"
}

export const approvalForExternalUsageUI = {
    "Approved External": "Approved for External Usage",
    "Forbidden External": "External Usage Forbidden",
    "Contact": "Contact the Department in Charge in JNTO"
}

//Convert current "Approval for External Usage" text to simplified backend in case of text change
export const getApprovalForExternalUsageOptions = () => {
    return Object.keys(approvalForExternalUsageOptions);
};


//Convert current "Approval for External Usage" text to simplified backend in case of text change
export const approvalForExternalUsageToUI = str => {

    for (var option of Object.keys(approvalForExternalUsageOptions)) {
        if (approvalForExternalUsageOptions.option === str) {
            return option
        }
    }
    return str
};


//Convert current "Approval for External Usage" text to simplified backend in case of text change
export const approvalForExternalUsageToBackend = str => {

    if (approvalForExternalUsageOptions.hasOwnProperty(str)) {
        return approvalForExternalUsageOptions[str]
    }
    return str
};
