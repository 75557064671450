import React from "react";
import ManualFloatingBar from "../../components/Navigation/ManualFloatingBar/ManualFloatingBar";
import { Frame, Page } from "@shopify/polaris";
import { withTranslation } from 'react-i18next';
import { withRouter } from "react-router-dom";

class UserGuideUpload01 extends React.Component {
    constructor() {
        super();
        this.state = {}
    }
    render() {
        const { t, history, match } = this.props;
        return (
            <>
                <Frame>
                    <Page fullWidth>
                        <div className='jnto-generic-page-wrapper'>
                            <section className='generic-block'>
                                <h2>{t('manual.navigation.title')}</h2>
                                <hr className='simple-divider' />
                            </section>

                            <section>
                                <p>{t('manual.navigation.navigationDetail01')}</p>
                                <img src='https://asset.japan.travel/image/upload/w_600,f_auto,fl_lossy,e_improve,e_sharpen/v1615457590/dam-admin/manual/updated/Screen_Shot_2021-03-11_at_10.54.29.png' />
                                <br></br> <br></br>
                                <p>{t('manual.navigation.navigationDetail02')}</p>
                                <br></br> <br></br>
                                <p>{t('manual.navigation.menuHeader')}</p>
                                <table>
                                    <tr>
                                        <th>#</th>
                                        <th>Name</th>
                                        <th>Description</th>
                                    </tr>
                                    <tr>
                                        <td>1</td>
                                        <td>{t('header.dashboard')}</td>
                                        <td>{t('manual.navigation.dashboardDescription')}</td>
                                    </tr>
                                    <tr>
                                        <td>2</td>
                                        <td>{t('header.upload')}</td>
                                        <td>{t('manual.navigation.uploaddDescription')}</td>
                                    </tr>
                                    <tr>
                                        <td>3</td>
                                        <td>{t('header.downloadRequest')}</td>
                                        <td>{t('manual.navigation.downloadDescription')}</td>
                                    </tr>
                                    <tr>
                                        <td>4</td>
                                        <td>{t('header.userGuide')}</td>
                                        <td>{t('manual.navigation.userGuidedDescription')}</td>
                                    </tr>
                                    <tr>
                                        <td>5</td>
                                        <td>{t('manual.navigation.myBag')}</td>
                                        <td>{t('manual.navigation.myBagDescription')}</td>
                                    </tr>
                                    <tr>
                                        <td>6</td>
                                        <td>{t('manual.navigation.adminPage')}</td>
                                        <td>{t('manual.navigation.adminDescription')}</td>
                                    </tr>
                                    <tr>
                                        <td>7</td>
                                        <td>{t('manual.navigation.profilePage')}</td>
                                        <td>{t('manual.navigation.profileDescription')}</td>
                                    </tr>
                                </table>

                            </section>

                            <ManualFloatingBar buttons={[
                                {
                                    position: 'left',
                                    text: t('manual.logout.title'),
                                    callback: () => history.push(`logout`),
                                },
                                {
                                    position: 'right',
                                    text: t('manual.upload.step1.title'),
                                    callback: () => history.push(`upload-step1`),
                                }
                            ]} />
                        </div>
                    </Page>
                </Frame>
            </>)
    }
}

export default withTranslation()(withRouter((UserGuideUpload01)));