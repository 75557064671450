import React from "react";
import ManualFloatingBar from "../../components/Navigation/ManualFloatingBar/ManualFloatingBar";
import { Frame, Page } from "@shopify/polaris";
import { withTranslation } from 'react-i18next';
import { withRouter } from "react-router-dom";

class UserGuideUpload01 extends React.Component {
    constructor() {
        super();
        this.state = {}
    }
    render() {
        const { t, history, match } = this.props;
        return (
            <>
                <Frame>
                    <Page fullWidth>
                        <div className='jnto-generic-page-wrapper'>
                            <section className='generic-block'>
                                <h3>{t('manual.assets.assetsDelete.subTitle')}</h3>
                                <h2>{t('manual.assets.assetsDelete.title')}</h2>
                                <hr className='simple-divider' />
                            </section>

                            <section>
                                <p>{t('manual.assets.assetsGallery.detail30')}</p>
                                <br/>
                                <img src='https://asset.japan.travel/image/upload/w_300,f_auto,fl_lossy,e_improve,e_sharpen/v1711718203/dam-admin/manual/updated/P20-3.png' />
                            </section>
                            <ManualFloatingBar buttons={[
                                {
                                    position: 'left',
                                    text: t('manual.assets.assetsEdit.title'),
                                    callback: () => history.push(`assets-edit`),
                                },
                                {
                                    position: 'right',
                                    text: t('manual.myBag.title'),
                                    callback: () => history.push(`my-bag`),
                                }
                            ]} />
                        </div>
                    </Page>
                </Frame>
            </>)
    }
}

export default withTranslation()(withRouter((UserGuideUpload01)));