import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import * as assetActions from '../../redux/actions/assetActions';

class PanelWithData extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            pageNumber: 0,
            onLoadNextPage: null,
            assets: [],
        };

        this.onLoadNextPage = this.onLoadNextPage.bind(this);
        this.onLoadPreviousPage = this.onLoadPreviousPage.bind(this);
        this.loadData = this.loadData.bind(this);
    }

    componentDidMount() {
        this.loadData();
    }

    componentDidUpdate(prevProps, prevState) {
        const { location, dataLoader, orderBy, sortBy, filterMediaType, filterAspect, filterPrefecture, filterSeason, filterTags, filterDamId, filterDownloadPermission, filterPrePostApproval, filterValidDate, filterApprovedExternal} = this.props;
        const { pageNumber } = this.state;
        if (prevProps.location !== location || (prevProps.dataLoader === null && dataLoader || prevProps.orderBy !== orderBy || prevProps.sortBy !== sortBy || prevProps.orderBy !== orderBy || prevProps.filterMediaType !== filterMediaType || prevProps.filterAspect !== filterAspect || prevProps.filterPrefecture !== filterPrefecture || prevProps.filterSeason !== filterSeason || prevProps.filterTags !== filterTags || prevProps.filterDamId !== filterDamId || prevProps.filterValidDate != filterValidDate ||prevProps.filterPrePostApproval !== filterPrePostApproval || prevProps.filterDownloadPermission !== filterDownloadPermission || prevProps.filterApprovedExternal !== filterApprovedExternal)) {
            this.setState({ pageNumber: 0 }, () => this.loadData());
        } else if (pageNumber !== prevState.pageNumber) {
            this.loadData();
        }
    }

    loadData() {
        const { pageNumber } = this.state;
        const { match, history, dataLoader } = this.props;
        dataLoader && dataLoader(match, history, pageNumber);
    }

    componentWillUnmount() {
        const { assetActions } = this.props;
        assetActions.clearAssets();
        assetActions.updateSearchText();
    }

    onLoadNextPage() {
        this.setState(({ pageNumber }) => ({ pageNumber: pageNumber + 1 }));
    }

    onLoadPreviousPage() {
        this.setState(({ pageNumber }) => ({ pageNumber: pageNumber - 1 }));
    }

    render() {
        const { children, assets } = this.props;
        return (
            <>{children(this.onLoadNextPage, assets, this.onLoadPreviousPage, this.state.pageNumber)}</>);
    }
}

const mapStateToProps = ({ asset }) => ({
    assets: asset.assets,
});

export const mapDispatchToProps = (dispatch) => ({
    assetActions: bindActionCreators(assetActions, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(PanelWithData);
