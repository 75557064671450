import React from "react";
import { withTranslation } from 'react-i18next';

class MainGalleryBanner extends React.Component {
  constructor() {
    super();
    this.state = {};
  }

  render() {
    const {assetCount, items, submitDisabled, selectFolder, folderId, onSave, optionDisabled, t} = this.props;
  var folderoptions = [<option disabled={optionDisabled}>{t('workflows.projects.addBagDropDown')}</option>];
    for(var item of items){
      folderoptions.push(<option folderId={folderId} >{item.content}</option>);
    }
    return (
      <div className='main-folder-banner'>
        <p className='bold'>{t('workflows.projects.addToBag1')}</p>
        <p className='bold'>{t('workflows.projects.addToBag2')}</p>
        <p style = {{paddingTop: '10px'}}className='bold'>{t('workflows.projects.numberOfAssets', { count: assetCount ||  '0' })}</p>

        <section className='form-section'>
          <div className='field-group'>
            <div className='jnto-selector-container'>
              <select name='select-input' onChange= {selectFolder} >
                {folderoptions}
              </select>
            </div>
          </div>
          <div>
            <button className='dui-btn dui-btn-primary skinny' disabled = {submitDisabled} onClick={onSave}>{t('workflows.projects.addBagSubmit')}</button>
          </div>
        </section>

      </div>
    )
  }
}

export default withTranslation()(MainGalleryBanner);
