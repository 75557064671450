import React, { useState } from "react";
import { TextStyle, Popover } from '@shopify/polaris';
import copyIcon from '../../../../assets/icons/icon_copy-to-clipboard.svg';
import { withTranslation } from 'react-i18next';
import ModuleRowInput from './ModuleRowInput';

const ModuleRow = ({ label, value, isBold, isRed, t, showCopyOption = false, showPopover = false,  editMode = false, fieldType, fieldValues, onEdit }) => {
    const [popoverOpen, setPopoverOpen] = useState(false);
    var updatedValue = value;
    if (value && (typeof value == 'string') && value.toLowerCase() === 'true') {
        updatedValue = t('common.true')
    }
    else if (value && (typeof value == 'string') && value.toLowerCase() === 'false') {
        updatedValue = t('common.false')
    }

    const handleOpenPopover = () => {
        showPopover && setPopoverOpen(true);

    };

    const copyToClipboard = () => {
        navigator.clipboard.writeText(updatedValue).then(() => {
            alert("Copied to clipboard");
        });
    }

    const activator = (
        <div className={'module-row-item'}>
            { !editMode ?
              <>
                  <div onClick={handleOpenPopover} onMouseOver={handleOpenPopover} className={showPopover ? 'jnto-clamp' : ''}>
                      <TextStyle variation={isBold ? 'strong' : 'subdued'} >{updatedValue}</TextStyle>
                  </div>
                  {showCopyOption &&
                  <div onClick={copyToClipboard}>
                      <img className='icon' src={copyIcon} />
                  </div>
                  }
              </> :
              <ModuleRowInput fieldType={fieldType} value={value} fieldValues={fieldValues} t={t} handleChange={onEdit}/>
            }

        </div>
    );

    return (<div className='asset-details-container'>
        <div className={isRed ? 'asset-details-item-left asset-details-item-left-red' : 'asset-details-item-left'}>
            <TextStyle variation={isBold ? 'strong' : 'subdued'} >{label}</TextStyle>
        </div>
        <div className={isRed ? 'asset-details-item-right asset-details-item-right-red' : 'asset-details-item-right'}>
            <Popover
                active={popoverOpen}
                activator={activator}
                onClose={() => setPopoverOpen(false)}
                preferredPosition={'above'}
                preferredAlignment={'left'}
            >
                <Popover.Pane fluidContent>
                    <div style={{
                        alignItems: 'stretch',
                        borderTop: '1px solid #DFE3E8',
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'stretch',
                        position: 'relative',
                        width: '100%',
                        height: '100%',
                        padding: '10px',
                        overflow: 'scroll',
                    }}>
                        {updatedValue}
                    </div>
                </Popover.Pane>
            </Popover>
        </div>
    </div>);
};

export default withTranslation()(ModuleRow);
