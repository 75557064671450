import React from 'react';
import { withTranslation } from 'react-i18next';
import { TextStyle } from '@shopify/polaris';

const GenericGallery = ({ items, displayText }) => {
    const displayItems = [...items];

    // add dummy placeholder items to make flexbox items align
    for (let ii = 0; ii < 2; ii++) {
        displayItems.push(<div key={`placeholder-card-${ii}`} className='generic-card-placeholder'></div>);
    }

    return (
        <>
            <div className='folders-text'>
                <TextStyle variation="subdued">{displayText}</TextStyle>
            </div>
            <div className='folders-container'>{displayItems}</div>
        </>);
};

export default withTranslation()(GenericGallery);
