import React from 'react';
import {connect} from 'react-redux';
import i18n from "i18next";
import {Page, Frame} from '@shopify/polaris';
import {languageOptions} from "./UserProfilePageLanguageOptions";
import UserProfileForm from '../../components/UserProfileForm/UserProfileForm';
import LocaleSelector from "../../components/shared/selectors/LocaleSelector/LocaleSelector";
import FloatingBar from "../../components/Navigation/FloatingBar/FloatingBar";
import Sidebar from '../../components/Navigation/SideBar/SideBar'
import { bindActionCreators } from 'redux';
import * as userActions from '../../redux/actions/userActions';
import * as navigationActions from '../../redux/actions/navigationActions';
import ToggleContainer from "../../components/ToggleContainer/ToggleContainer";
import { withTranslation } from 'react-i18next';


class UserProfilePage extends React.Component {
    constructor() {
        super();
        this.onUserLogout = this.onUserLogout.bind(this);
        this.onPasswordReset = this.onPasswordReset.bind(this);
      }

 async onUserLogout(){
    const { navigationActions, userActions } = this.props;
    await userActions.userSignOut();
    await navigationActions.switchPage('user-authentication');
    this.props.history.push('');
}

componentDidMount(){
    this.props.userActions.getSignedInUser();
}

async onPasswordReset(){
    const { navigationActions, userActions } = this.props;
    await userActions.userSignOut();
    await navigationActions.switchPage('user-authentication');
    this.props.history.push('');
}

render(){
    const { userInfoComposite, signedInUser, organization, t} = this.props;

    const tabs = [
        {
        id: 'info',
        name: t('workflows.userProfile.information'),
        component: UserProfileForm,
        data: {
            userInfoComposite,
            signedInUser,
            organization
        }
        },
        {
        id: 'preferences',
        name: t('workflows.userProfile.preferences'),
        component: LocaleSelector,
        data: {
            languageOptions,
            handleLanguageUpdate: (locale) => i18n.changeLanguage(locale),
            onUserLogout: this.onUserLogout,
            onPasswordReset: this.onPasswordReset
        }
        }];

    return(
    <Sidebar>
        {(onUpdateSideBarView) => {
            onUpdateSideBarView('default');
            return <React.Fragment>
                <div className="app">
                <Frame>
                    <Page fullWidth>
                        <div className='jnto-generic-page-wrapper'>
                        <ToggleContainer tabs={tabs} defaultTabIndex={0} />
                        </div>
                    </Page>
                    </Frame>
                </div>
            </React.Fragment>}}
        </Sidebar>
        
        
        );
    }
};

const mapStateToProps = (state) => ({
  userInfoComposite: state.user.userInfoComposite,
  signedInUser: state.user.signedInUser,
  organization: state.organization

});

const mapDispatchToProps = dispatch => ({
    userActions: bindActionCreators(userActions, dispatch),
    navigationActions: bindActionCreators(navigationActions, dispatch),
});

export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(UserProfilePage));
