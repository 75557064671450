import React from 'react';
import { Scrollable, Checkbox  } from '@shopify/polaris';
import WithConfirmation from '../shared/modals/WithConfirmation/WithConfirmation';
import SimpleModal from '../shared/modals/SimpleModal/SimpleModal';
import { withTranslation } from 'react-i18next';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { withRouter} from 'react-router-dom';
import {TermsEn} from './TermsEn'; 
import {TermsJp} from './TermsJp'; 


/** Redux */
import * as folderActions from '../../redux/actions/folderActions';
class TermsModal extends React.Component {
    constructor() {
        super();
        this.state = {
            checkboxDisabled: true,
            checkBoxSelected: false,
        };
        this.onSubmit = this.onSubmit.bind(this);
        this.checkBoxSelected = this.checkBoxClicked.bind(this);
        this.activateCheckBox = this.activateCheckBox.bind(this);
        this.onModalClose = this.onModalClose.bind(this);
    }

    async onSubmit(close) {
        const { onAcceptTerms } = this.props;
        const { checkBoxSelected } = this.state;
        onAcceptTerms(checkBoxSelected);
        this.onModalClose();
        close();
    }

    isBottom(el) {
        return el && (el.scrollTop >= ((el.scrollHeight - el.offsetHeight) - 10));
      }

      modalScroll = () => {
        const wrappedElement = document.getElementsByClassName('Polaris-Scrollable--hasTopShadow Polaris-Scrollable--hasBottomShadow')[0];
        if (this.isBottom(wrappedElement)) {
          this.activateCheckBox()
        }
      };

    checkBoxClicked(){
        this.setState(({ checkBoxSelected }) => ({ checkBoxSelected: !checkBoxSelected }));
    }

    activateCheckBox(){
        if (this.state.checkboxDisabled === true){
            this.setState({checkboxDisabled: false});
        }
    }

    onModalClose(){
        this.setState({
            checkboxDisabled: true,
            checkBoxSelected: false,
        });
    }

    render(){
        const { checkboxDisabled, checkBoxSelected } = this.state;
        const {t, children, i18n} = this.props;
        // console.log('language: ' + i18n.language);
        var langJp = false;
        langJp = (i18n && i18n.language && (i18n.language === 'jp' || i18n.language === 'ja-JP'));
        // console.log('isJapanese: ' + langJp);

        return(
            <WithConfirmation>
                {(show, confirm, hide, open) => (
                    <>
                        {(open && <SimpleModal 
                            title={t('termsAndConditions.termsAndConditions')} 
                            onConfirm={() => this.onSubmit(confirm)} 
                            onCancel={() => {this.onModalClose(); hide()}} 
                            disabled = {() => !checkBoxSelected}> 
                        <div>
                            <Scrollable shadow style={{height: '175px'}} 
                                onScroll = {() => this.modalScroll()}>
                                {!langJp && TermsEn()}
                                {langJp && TermsJp()}
                            </Scrollable>
                            <div style = {{'padding-top':'2rem'}}>
                                <Checkbox
                                    label= {t('termsAndConditions.download.downloadAgree')}
                                    checked={checkBoxSelected}
                                    disabled = {checkboxDisabled}
                                    onChange={()=> this.checkBoxClicked()}
                                    />           
                            </div>                 
                        </div>
                        </SimpleModal>
                        )}
                        {children(show)}
                    </>
                )}
            </WithConfirmation>
            );
     }
}

const mapStateToProps = (state) => ({
    folders: state.folder.folders,
});

const mapDispatchToProps = (dispatch) => ({
    folderActions: bindActionCreators(folderActions, dispatch),
});

// export default PermissionFolderModal;
export default withTranslation()(withRouter(connect(mapStateToProps, mapDispatchToProps)(TermsModal)));
