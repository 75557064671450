import React from "react";
import { withRouter} from 'react-router-dom';
import { Switch, Route } from 'react-router-dom';
import { withTranslation } from 'react-i18next';

class SideBarAdminView extends React.Component {
  constructor() {
    super();
  }

  onNavigate = (link) => {
    this.props.history && this.props.history.push(link);
  }
  
  render() {
    const {sideBarView, t } = this.props;
    const userAdminActive = sideBarView === 'user-admin' ? 'active' : '';
    const orgAdminActive = sideBarView ==='org-admin' ? 'active' : '';

    return (
      <>
      <div className='sidebar-main-title sidebar-wrapper'>{t('common.adminMenu')}</div>
      <div className='list-view'>
        <ol className="ordered-list">
          <li className={userAdminActive}><div><a onClick={() => this.onNavigate('/user-admin')}>{t('workflows.admin.userAdmin')}</a></div></li>
          <li className={orgAdminActive}><div><a onClick={() => this.onNavigate('/organization-admin')}>{t('workflows.admin.organizationAdmin')}</a></div></li>
        </ol>
      </div>
      </>
    )
  }
}

export default withTranslation()(withRouter(SideBarAdminView));
