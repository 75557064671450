import React from "react";
import jntoLogo from '../../../assets/icons/icon_jnto_logo.svg';
import { withTranslation } from 'react-i18next';

class ExternalNavigation extends React.Component {

  render() {

    return (
      <div className='main-nav main-nav-external flex'>
        <div className='logo-container flex'>
          <div className='nav-logo' style={{cursor:'default'}}>
              <img src={jntoLogo}/>
          </div>
          <div className='nav-title'>
            JNTO Photo &amp; Video Library
          </div>
        </div>
      </div>
    )
  }
}

export default withTranslation()(ExternalNavigation);
