import React from 'react';
import MediaDisplayFooter from '../../footers/MediaDisplayFooter/MediaDisplayFooter';

class ImageDisplay extends React.Component {
    constructor(props) {
        super(props);
        this.state = { loaded: false };
        this.handleOnLoad = this.handleOnLoad.bind(this);
    }

    handleOnLoad() { this.setState({ loaded: true }); }

    render() {
        const { height, width, secureUrl, size } = this.props;
        const { loaded } = this.state;
        const indexToInsert = secureUrl.lastIndexOf('/image/upload') + '/image/upload'.length;
        const fittedImageSource = secureUrl.slice(0, indexToInsert) + '/c_fill' + secureUrl.slice(indexToInsert, secureUrl.length);
        return (
            <>
                {!loaded && <div className='asset-details-image blur'></div>}
                <img alt='test' className='asset-details-image' src={fittedImageSource} style={loaded ? {} : { display: 'none' }}
                    onLoad={this.handleOnLoad} />
            </>);
    }
}

export default ImageDisplay;
