const setStateToPromise = (context, targetField, value) => {
    return new Promise((resolve, reject) => {
        if (!context.setState) {
            reject();
        }

        context.setState({
            [targetField]: value,
        }, () => {
            resolve();
        });
    });
};

export {
    setStateToPromise,
};