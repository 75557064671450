import baseVermillionApi from './baseVermillionApi';
import cloudinaryApi from '../cloudinaryApi/cloudinaryApi';
import baseSimpleApi from './baseSimpleApi';

const apiRoot = '/image-management';

export const queryDownloadLink = (publicIds, userId, agreedToTerms, folder = undefined, isVideo, dispatch = () => {} ) => {
    const path = `${apiRoot}/download-link`;
    return baseVermillionApi.postWithErrorHandling(path, {
        imageIds: publicIds,
        userId,
        isVideo,
        agreedToTerms,
        isJOMC: false,
        folder,
    }, dispatch);
};

// export const uploadAssets = (assetUploadRequests, dispatch) => {
//     const path = `${apiRoot}/upload/image`;
//     return baseSimpleApi.postMulti(path, assetUploadRequests, dispatch);
// }

export const cloudinarySignature = (assetUploadRequest, dispatch) => {
    var request = {
        agreedToTerms: assetUploadRequest.agreedToTerms,
        userId: assetUploadRequest.userId,
        context: assetUploadRequest.context,
        options: assetUploadRequest.options,
        filename: assetUploadRequest.filename,
        dataFileName: assetUploadRequest.dataFileName,
    }
    const path = `${apiRoot}/upload/signed-request`;
    return baseVermillionApi.post(path, request, dispatch);
}

export const uploadAssetsDirect = (assetUploadRequests, dispatch) => {
    return cloudinaryApi.postMulti(assetUploadRequests, dispatch);
}

export const deleteAsset = (publicIds, userId, dispatch) => {
    const path = `${apiRoot}/delete-asset`;
    return baseVermillionApi.delete(path, {
        imageId: publicIds,
        userId,
        agreedToTerms: true,
    }, dispatch);
}

export const editAsset = (publicId, edits, userId, dispatch) => {
    const path = `${apiRoot}/edit-asset`;
    return baseVermillionApi.post(path, {
        imageId: publicId,
        updates: edits,
        userId,
    }, dispatch);
}


export const updateTags = (tags, imageId, isVideo, autoTagsArray) => {
    const path = `${apiRoot}/update-tags`;
    return baseVermillionApi.post(path, {
        tags,
        imageId,
        isVideo,
        autoTagsArray
    });
}