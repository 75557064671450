import * as types from "../constants/action-types";
import initialState from './initialState';

export default function userReducer(state = initialState.user, action) {
    let newState;
    switch (action.type) {
        case types.USER_SIGN_UP_SUCCESS:
            newState = {
                ...state,
                signedInUser: action.signedInUserResponse,
                userInfoComposite: action.userInfoComposite,
            }
            break;
        case types.USER_SIGN_IN_SUCCESS:
            newState = {
                ...state,
                signedInUser: action.signedInUserResponse,
                userInfoComposite: action.userInfoComposite,
            };
            break;
        case types.USER_SIGN_OUT_SUCCESS:
            newState = {
                ...state,
                signedInUser: undefined,
                userInfoComposite: undefined,
            };
            break;
        case types.GET_USER_INFO_COMPOSITE_SUCCESS:
            newState = {
                ...state,
                userInfoComposite: action.userInfoComposite,
            };
            break;
        case types.GET_USER_GROUP_NAMES_SUCCESS:
            newState = {
                ...state,
                userGroupNames: action.userGroupNames,
            };
            break;
        case types.GET_USERS_BY_ORGANIZATION_IDS_SUCCESS:
            newState = {
                ...state,
                usersForAdmin: action.users,
            };
            break;
        case types.REMOVE_USERS_SUCCESS:
            const usersForAdminOriginal = [...state.usersForAdmin];
            const updatedUsersForAdmin = usersForAdminOriginal.filter(user => action.userIds.indexOf(user.id) < 0);
            newState = {
                ...state,
                usersForAdmin: updatedUsersForAdmin,
            };
            break;
        default:
            break;
    }

    return newState || state;
}