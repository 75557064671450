import React from "react";
import PropTypes from "prop-types";
import {bindActionCreators} from "redux";
import * as folderActions from "../../redux/actions/folderActions";
import * as permissionActions from "../../redux/actions/permissionActions";
import {withTranslation} from "react-i18next";
import {withRouter} from "react-router-dom";
import {connect} from "react-redux";
import {
  ActionList,
  Button,
  Checkbox,
  Heading,
  Popover,
  Scrollable,
  Stack,
  TextContainer,
  TextField
} from "@shopify/polaris";
import {getContentForTypeID, typeUse} from "../DownloadRequestModal/PermissionRequestProjectTypes";
import {TermsEn} from "../TermsModal/TermsEn";
import {TermsJp} from "../TermsModal/TermsJp";
import SimpleModal from "../shared/modals/SimpleModal/SimpleModal";

class FloatingTermsModal extends React.Component {

  constructor() {
    super();
    this.state = {
      checkboxDisabled: true,
      checkBoxSelected: false,
    };
    this.onSubmit = this.onSubmit.bind(this);
    this.checkBoxSelected = this.checkBoxClicked.bind(this);
    this.activateCheckBox = this.activateCheckBox.bind(this);
    this.onModalClose = this.onModalClose.bind(this);
    this.onCancelBtnPressed = this.onCancelBtnPressed.bind(this);
  }

  async onSubmit(close) {
    const { onAcceptTerms } = this.props;
    const { checkBoxSelected } = this.state;
    onAcceptTerms(checkBoxSelected);
    this.onModalClose();
    close();
  }

  onCancelBtnPressed () {
    this.onModalClose()
    this.props.cancel()
  }

  isBottom(el) {
    return el && (el.scrollTop >= ((el.scrollHeight - el.offsetHeight) - 10));
  }

  modalScroll = () => {
    const wrappedElement = document.getElementsByClassName('Polaris-Scrollable--hasTopShadow Polaris-Scrollable--hasBottomShadow')[0];
    if (this.isBottom(wrappedElement)) {
      this.activateCheckBox()
    }
  };

  checkBoxClicked(){
    this.setState(({ checkBoxSelected }) => ({ checkBoxSelected: !checkBoxSelected }));
  }

  activateCheckBox(){
    if (this.state.checkboxDisabled === true){
      this.setState({checkboxDisabled: false});
    }
  }

  onModalClose(){
    this.setState({
      checkboxDisabled: true,
      checkBoxSelected: false,
    });
  }


  render() {
    const { checkboxDisabled, checkBoxSelected } = this.state;
    const {t, children, i18n} = this.props;
    if (!this.props.show) {
      return null;
    }

    var langJp = false;
    langJp = (i18n && i18n.language && (i18n.language === 'jp' || i18n.language === 'ja-JP'));

    const isDisabled = !checkBoxSelected

    return (
      <div>
        <div className="jnto-modal-wrapper" id="jnto-modal">
          <div className='jnto-modal'>
            <div className="content">
              <h2>{t('termsAndConditions.termsAndConditions')} </h2>
              <div>

                <Scrollable shadow style={{height: '175px'}}
                            onScroll = {() => this.modalScroll()}>
                  {!langJp && TermsEn()}
                  {langJp && TermsJp()}
                </Scrollable>
                <div style = {{'paddingTop':'2rem'}}>
                  <Checkbox
                    label= {t('termsAndConditions.download.downloadAgree')}
                    checked={checkBoxSelected}
                    disabled = {checkboxDisabled}
                    onChange={()=> this.checkBoxClicked()}
                  />
                </div>

              </div>
              <div className="actions">
                <button className="text-center dui-btn dui-btn-secondary-alt" onClick={this.onCancelBtnPressed}>
                  {t('form.label.cancel')}
                </button>
                <button disabled={isDisabled} className="text-center dui-btn dui-btn-primary" onClick={() => this.onSubmit(this.props.confirm)}>
                  {t('form.label.ok')}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
FloatingTermsModal.propTypes = {
  terms: PropTypes.string.isRequired,
  show: PropTypes.bool.isRequired,
  cancel: PropTypes.func.isRequired,
  confirm: PropTypes.func.isRequired,
  onAcceptTerms: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  folders: state.folder.folders,
});

const mapDispatchToProps = (dispatch) => ({
  folderActions: bindActionCreators(folderActions, dispatch),
});

export default withTranslation()(withRouter(connect(mapStateToProps, mapDispatchToProps)(FloatingTermsModal)));
