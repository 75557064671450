import React from "react";
import { withRouter} from 'react-router-dom';
import { Switch, Route } from 'react-router-dom';
import { withTranslation } from 'react-i18next';

class SideBarListView extends React.Component {
  constructor() {
    super();
  }

  render() {
    const {sideBarView, t } = this.props;
    const optionsStepActive = sideBarView === 'select-project' ? 'active' : '';
    const assetUploadStepActive = sideBarView ==='add-files' ? 'active' : '';
    const attachExcelStepActive = sideBarView === 'add-csv' ? 'active' : '';
    const gridStepActive = sideBarView === 'edit-grid' ? 'active' : '';

    return (
      <>
      <div className='sidebar-main-title sidebar-wrapper'>{t('sideBar.upload.title')}</div>
      <div className='list-view'>
        <ol className="ordered-list ordered-list-no-click">
          <li className={optionsStepActive}><div><a>{t('sideBar.upload.option')}</a></div></li>
          <li className={assetUploadStepActive}><div><a >{t('sideBar.upload.attachAsset')}</a></div></li>
          <li className={attachExcelStepActive}><div><a>{t('sideBar.upload.attachExcel')}</a></div></li>
          <li className={gridStepActive}><div><a >{t('sideBar.upload.upload')}</a></div></li>
        </ol>
      </div>
      </>
    )
  }
}

export default withTranslation()(withRouter(SideBarListView));
