import React from 'react';
import TextInputModal from '../TextInputModal/TextInputModal';
import WithConfirmation from '../WithConfirmation/WithConfirmation';

class WithTextInputModal extends React.Component {
    render() {
        const { children, value, onChange, title } = this.props;

        return (<WithConfirmation>
            {(show, confirm, hide, open) => (
                <>
                    {open && <TextInputModal
                        title = {title}
                        value={value}
                        onChange={onChange}
                        onConfirm={confirm}
                        onCancel={hide} />}
                    {children(show)}
                </>)}
        </WithConfirmation>);
    }
}

export default WithTextInputModal;
