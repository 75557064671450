import * as types from "../constants/action-types";
import initialState from './initialState';
import { TAB_ENUMS } from '../../workflows/MyRequestsPage/MyRequestsPageTabs';

export default function myRequestsReducer(state = initialState.myRequests, action) {
    let newState;
    let clonedUploadRequests = [];
    switch (action.type) {
        case types.GET_MY_UPLOAD_REQUESTS:
            if (action.isComplete) {
                newState = {
                    ...state,
                    approvedUploadRequests: action.uploadRequests ? action.uploadRequests.filter(req => req.status === 'approved') : [],
                }
            } else {
                newState = {
                    ...state,
                    pendingUploadRequests: action.uploadRequests ? action.uploadRequests.filter(req => req.status === 'pending') : [],
                    rejectedUploadRequests: action.uploadRequests ? action.uploadRequests.filter(req => req.status === 'rejected') : [],
                }
            }
            break;
        case types.GET_MY_DOWNLOAD_REQUESTS:
            newState = {
                ...state,
                downloadRequests: action.downloadRequests,
            }
            break;
        case types.UPDATE_MY_UPLOAD_REQUESTS_SUCCESS:
            const clonedIncompleteUploadRequests = [...state.incompleteUploadRequests];
            action.updatedUploadRequests.forEach(updatedReq => {
                let reqToReplace = clonedIncompleteUploadRequests.find(req => req.id === updatedReq.id); // eslint-disable-line no-unused-vars
                reqToReplace.context = updatedReq.context;
            });
            newState = {
                ...state,
                incompleteUploadRequests: clonedIncompleteUploadRequests,
            };
            break;
        case types.GET_USER_UPLOAD_REQUESTS_SUCCESS:
            newState = {
                ...state,
                pendingUserUploadRequests: action.uploadRequests ? action.uploadRequests.filter(req => req.status === 'pending') : [],
            };
            break;
        case types.ADMIN_UPDATE_UPLOAD_REQUESTS_SUCCESS:
            newState = {
                ...state,
                pendingUserUploadRequests: action.uploadRequests,
            };
            break;
        case types.ADMIN_APPROVE_UPLOAD_REQUESTS_SUCCESS:
            clonedUploadRequests = state.pendingUserUploadRequests.slice();
            action.uploadRequests.forEach(approvedUploadRequest => {
                const reqToRemove = clonedUploadRequests.find(req => req.id === approvedUploadRequest.id);
                const indexToRemove = clonedUploadRequests.indexOf(reqToRemove);
                clonedUploadRequests.splice(indexToRemove, 1);
            });
            newState = {
                ...state,
                pendingUserUploadRequests: clonedUploadRequests
            };
            break;
        case types.ADMIN_REJECT_UPLOAD_REQUESTS_SUCCESS:
            clonedUploadRequests = state.pendingUserUploadRequests.slice();
            action.uploadRequests.forEach(rejectedUploadRequest => {
                const reqToRemove = clonedUploadRequests.find(req => req.id === rejectedUploadRequest.id);
                const indexToRemove = clonedUploadRequests.indexOf(reqToRemove);
                clonedUploadRequests.splice(indexToRemove, 1);
            });
            newState = {
                ...state,
                pendingUserUploadRequests: clonedUploadRequests,
            };
            break;
        case types.SORT_MY_UPLOAD_REQUESTS_SUCCESS:
            if (action.currentTab === TAB_ENUMS.PENDING_UPLOADS) {
                newState = {
                    ...state,
                    pendingUploadRequests: action.uploadRequests,
                }
            } else if (action.currentTab === TAB_ENUMS.REJECTED_UPLOADS) {
                newState = {
                    ...state,
                    rejectedUploadRequests: action.uploadRequests,
                }
            } else {
                newState = {
                    ...state,
                    approvedUploadRequests: action.uploadRequests,
                }
            }
            break;
        case types.SORT_MY_DOWNLOAD_REQUESTS_SUCCESS:
            newState = {
                ...state,
                downloadRequests: action.uploadRequests,
            };
            break;
        
        case types.GET_UPLOAD_ORG_SUCCESS:
            newState = {
                ...state,
                isUploadOrg: action.uploadsOrg,
            };
            break;
        case types.GET_UPLOAD_USER_SUCCESS:
            newState = {
                ...state,
                isUploadUser: action.uploadUser,
            };
            break;
        default:
            break;
    }

    return newState || state;
}