import React from "react";
import ManualFloatingBar from "../../components/Navigation/ManualFloatingBar/ManualFloatingBar";
import { Frame, Page } from "@shopify/polaris";
import { withTranslation } from 'react-i18next';
import { withRouter } from "react-router-dom";

class UserGuideUpload01 extends React.Component {
    constructor() {
        super();
        this.state = {}
    }
    render() {
        const { t, history, match } = this.props;
        return (
            <>
                <Frame>
                    <Page fullWidth>
                        <div className='jnto-generic-page-wrapper'>
                            <section className='generic-block'>
                                <h3>{t('manual.resetPassword.subTitle')}</h3>
                                <h2>{t('manual.resetPassword.title')}</h2>
                                <hr className='simple-divider' />
                            </section>

                            <section>
                                <p>{t('manual.resetPassword.reset')}</p>
                                <p>{t('manual.resetPassword.resetDetail01')}</p>
                                <br></br>
                                <section className='bullet-list'>
                                    <ul>
                                        <li>{t('manual.resetPassword.resetDetail02')}</li>
                                    </ul>
                                    <p>{t('manual.resetPassword.resetDetail03')}</p>
                                    <img src='https://asset.japan.travel/image/upload/w_600,f_auto,fl_lossy,e_improve,e_sharpen/v1615457590/dam-admin/manual/updated/Screen_Shot_2021-03-11_at_10.54.29.png' />
                                    <br></br><br></br>
                                    <p>{t('manual.resetPassword.resetDetail032')}</p>
                                    <img src='https://asset.japan.travel/image/upload/w_600,f_auto,fl_lossy,e_improve,e_sharpen/v1615457588/dam-admin/manual/updated/Screen_Shot_2021-03-11_at_10.52.56.png' />
                                    <br></br><br></br>
                                    <p>{t('manual.resetPassword.resetDetail04')}</p>
                                    <p>{t('manual.resetPassword.resetDetail05')}</p>
                                    <img src='https://asset.japan.travel/image/upload/w_600,f_auto,fl_lossy,e_improve,e_sharpen/v1615457588/dam-admin/manual/updated/Screen_Shot_2021-03-11_at_10.52.17.png' />
                                    <p>{t('manual.resetPassword.resetDetail06')}</p>
                                    <br></br><br></br>
                                    <p>{t('manual.resetPassword.resetDetail07')}</p>
                                    <p>{t('manual.resetPassword.resetDetail08')}</p>
                                    <img src='https://asset.japan.travel/image/upload/w_600,f_auto,fl_lossy,e_improve,e_sharpen/v1615457588/dam-admin/manual/updated/Screen_Shot_2021-03-11_at_10.53.24.png' />
                                    <br></br><br></br>
                                    <p>{t('manual.resetPassword.resetDetail09')}</p>
                                </section>
                            </section>

                            <section>
                                <section className='bullet-list'>
                                    <ul>
                                        <li>{t('manual.resetPassword.resetDetail10')}<p>{t('manual.resetPassword.resetDetail11')}</p></li>
                                    </ul>
                                    <img src='https://asset.japan.travel/image/upload/w_600,f_auto,fl_lossy,e_improve,e_sharpen/v1615457588/dam-admin/manual/updated/Screen_Shot_2021-03-11_at_10.52.17.png' />
                                    <br></br><br></br>
                                    <p>{t('manual.resetPassword.resetDetail12')}</p>
                                    <p>{t('manual.resetPassword.resetDetail13')}</p>
                                    <p>{t('manual.resetPassword.resetDetail14')}</p>
                                    <img src='https://asset.japan.travel/image/upload/w_600,f_auto,fl_lossy,e_improve,e_sharpen/v1615457588/dam-admin/manual/updated/Screen_Shot_2021-03-11_at_10.53.11.png' />
                                    <br></br><br></br>
                                    <p>{t('manual.resetPassword.resetDetail15')}</p>
                                    <p>{t('manual.resetPassword.resetDetail16')}</p>
                                    <img src='https://asset.japan.travel/image/upload/w_600,f_auto,fl_lossy,e_improve,e_sharpen/v1615457588/dam-admin/manual/updated/Screen_Shot_2021-03-11_at_10.53.24.png' />
                                    <br></br><br></br>
                                    <p>{t('manual.resetPassword.resetDetail17')}</p>
                                    <p>{t('manual.resetPassword.resetDetail18')}</p>
                                </section>
                            </section>

                            <ManualFloatingBar buttons={[
                                {
                                    position: 'left',
                                    text: t('manual.login.title'),
                                    callback: () => history.push(`login`),
                                },
                                {
                                    position: 'right',
                                    text: t('manual.logout.title'),
                                    callback: () => history.push(`logout`),
                                }
                            ]} />
                        </div>
                    </Page>
                </Frame>
            </>)
    }
}

export default withTranslation()(withRouter((UserGuideUpload01)));