import React from "react";
import pdfIcon from '../../../assets/icons/icon_pdf.svg';
import contactIcon from '../../../assets/icons/icon_contact.svg';
import SideBarDefaultView from "../SideBarDefaultView/SideBarDefaultView";
import SideBarManualView from "../SideBarManualView/SideBarManualView";
import SideBarListView from "../SideBarListView/SideBarListView";
import SideBarAdminView from "../SideBarAdminView/SideBarAdminView";
import FloatingContactModal from "../../../components/FloatingContactModal/FloatingContactModal";
import { withRouter } from 'react-router-dom';
import * as userActions from '../../../redux/actions/userActions';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import TopNavigation from '../../../components/Navigation/TopNavigation/TopNavigation'
import { withTranslation } from 'react-i18next';

class SideBar extends React.Component {
  constructor() {
    super();
    this.state = {
      isModalVisible: false,
      viewType: 'list'
    }
    this.onUpdateSideBarView = this.onUpdateSideBarView.bind(this);
  }

  onUpdateSideBarView(routeSideBar) {
    if (this.state.viewType !== routeSideBar) {
      this.setState({ viewType: routeSideBar })
    }
  }

  showBugForm = e => {
    this.setState({
      isModalVisible: !this.state.isModalVisible,
    });
  };

  render() {
    const { signedInUser, children, incomingPermissionRequests, location, t, viewSubType, i18n } = this.props;
    const fileUpload = this.state.viewType !== 'default' && this.state.viewType !== 'manual'  && this.state.viewType !== 'user-admin' && this.state.viewType !== 'org-admin';
    const manual = this.state.viewType === 'manual';
    const admin = this.state.viewType === 'user-admin' || this.state.viewType === 'org-admin';
    const userList = ['admin', 'jnto-hq-admin', ];
    const userGroups = signedInUser.signInUserSession.idToken.payload["cognito:groups"];
    const isAdmin = userGroups.some(r => userList.indexOf(r) >= 0);

    return (
      <>
        <TopNavigation isAdmin={isAdmin} locationPath={location} permissionRequests={(Array.isArray(incomingPermissionRequests) && incomingPermissionRequests.length)} />
        {(fileUpload || manual || admin) &&
          <div className='main-sidebar'>
            <div className='top-content'>
              {/* // Default View  */}
              

              {/* // User Guide View */}
              {manual && <SideBarManualView showBugForm={this.showBugForm}/>}
          

              {/* // User Guide View * */}
              {admin && <SideBarAdminView sideBarView={this.state.viewType} />}

              {fileUpload && <SideBarListView sideBarView={this.state.viewType} />}
            </div>
        

          <FloatingContactModal onHide={this.showBugForm} signedInUser={signedInUser} show={this.state.isModalVisible} />
        </div>
        }
        {children(this.onUpdateSideBarView)}
      </>
    )
  }
}

const mapDispatchToProps = dispatch => ({
  userActions: bindActionCreators(userActions, dispatch)

});

const mapStateToProps = (state) => ({
  signedInUser: state.user.signedInUser,
  incomingPermissionRequests: state.myPermissionRequests.incomingPermissionRequests,

});

export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(withRouter(SideBar)));
