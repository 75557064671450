import React from "react";
import TermsModal from '../../TermsModal/TermsModal';
import { withTranslation } from 'react-i18next';

import hintIcon from '../../../assets/icons/icon_hint.svg';
// We can remove when implementation is finished
// const demoButtons = [
//   {
//     text: 'Next',
//     link: '/file-upload-mock/add-files',
//     tooltip: "Some random random tooltip text Some random random tooltip text Some random random tooltip text Some random random tooltip text Some random random tooltip text Some random random tooltip text"
//   },
//   {
//     text: 'Previous',
//     link: '/file-upload-mock/add-files',
//     type: 'secondary'
//   },
//   {
//     text: 'Cancel Longer Text Sample',
//     type: 'secondary',
//     tooltip: "Some random random tooltip text Some random random tooltip text",
//     callback: () => alert('test callback')
//   }
// ]

class FloatingBar extends React.Component {
  constructor() {
    super();
  }

  componentDidMount() {
  }

  generateButtons() {
    const buttonOptions = this.props.buttons;
    const{t } = this.props;
    return buttonOptions.map((button) => {
      if(!button){
        return '';
      }
      const buttonType = button.type && button.type === 'secondary' ? 'secondary' : 'primary';
      if(typeof button.callback === 'function' && !button.terms) {
        if(button.tooltip){
          return (
            <a key={button.text} >
              <button onClick={ () => button.callback()} className={`dui-btn tooltip dui-btn-${buttonType}`} disabled = { button.disabled}>
                  <span className='dam-ui-hint'>
                    <img src={hintIcon} />
                    <span className="dam-ui-tooltip-content">{button.tooltip}</span>
                  </span>{button.text}
              </button>
            </a>
          )
      }
      else{
        return (
          <a key={button.text} >
            <button onClick={ () => button.callback()} className={`dui-btn tooltip dui-btn-${buttonType}`} disabled = { button.disabled}>{button.text}</button>
            </a>
        )
      }
    } 
      else if( typeof button.callback === 'function' && button.terms) {
        if(button.tooltip){

        return (
          <TermsModal
          onAcceptTerms = {(agreedToTerms) => {button.callback(agreedToTerms)}}
          terms = {t('termsAndConditions.download.downloadTerms')}>
          {(show) => (
            <a key={button.text} href={button.link}>
              <button onClick={() => show(() => "")} className={`dui-btn tooltip dui-btn-${buttonType}`}  disabled = { button.disabled}>
                <span className='dam-ui-hint'>
                      <img src={hintIcon} />
                      <span className="dam-ui-tooltip-content">{button.tooltip}</span>
                    </span>{button.text}
                </button>
            </a>
           )}                     
          </TermsModal>           
        )
          }
          else{
            return (
              <TermsModal
              onAcceptTerms = {(agreedToTerms) => {button.callback(agreedToTerms)}}
              terms = {t('termsAndConditions.download.downloadTerms')}>
              {(show) => (
                <a key={button.text} href={button.link}>
                  <button onClick={() => show(() => "")} className={`dui-btn dui-btn-${buttonType}`}  disabled = { button.disabled}>
                    <span className='dam-ui-hint'>
                      <img src={hintIcon} />
                      <span className="dam-ui-tooltip-content">{button.tooltip}</span>
                    </span>{button.text}
                  </button>
                </a>
               )}                     
              </TermsModal>           
            )

          }
      } 
      else {
        if(button.tooltip){
      return (

          <a key={button.text} href={button.link}>
            <button className={`dui-btn dui-btn-${buttonType}`} buttonDisabled>{ button.disabbled}</button>
          </a>        
        )
        }
        else{
          return (

            <a key={button.text} href={button.link}>
              <button className={`dui-btn dui-btn-${buttonType}`} buttonDisabled>{ button.disabbled}</button>
            </a>        
          )
        }
      }
    })
  }

  render() {

    return (
      <div className='floating-nav-bar' style = {this.props.styles} >
        <div className='floating-nav-bar-content' >
          {this.generateButtons()}
        </div>
      </div>
    )
  }
}

export default withTranslation()(FloatingBar);
