import baseVermillionApi from './baseVermillionApi';
import OrganizationComposite from '../../models/core/OrganizationComposite'

const apiRoot = '/organization-management';

export const searchOrganizationById = (organizationId, dispatch) => {
    const path = `${apiRoot}/organization-composite/organizations/${organizationId}`;
    return baseVermillionApi.getWithErrorHandling(path, dispatch)
        .then(organization => OrganizationComposite.fromDataObjects([organization])[0]);
};

export const searchOrganizationByIdDirect = (organizationId, dispatch) => {
    const path = `${apiRoot}/organization-composite/organizations/${organizationId}`;
    return baseVermillionApi.get(path, dispatch)
        .then(organization => OrganizationComposite.fromDataObjects([organization])[0]);
};

export const searchAllOrganizations = (dispatch) => {
    const path = `${apiRoot}/organization-composite/organizations`;
    return baseVermillionApi.getWithErrorHandling(path, dispatch)
        .then(organizations => OrganizationComposite.fromDataObjects(organizations));
};

export const createOrganization = (organizationObj, dispatch) => {
    const path = `${apiRoot}/organizations`;
    return baseVermillionApi.postWithErrorHandling(path, organizationObj, dispatch)
        .then(organization => OrganizationComposite.fromDataObjects([organization])[0]);
};

export const updateOrganizationProjects = (organizationId, projectIds, dispatch) => {
    const path = `${apiRoot}/organizations/${organizationId}`;
    return baseVermillionApi.putWithErrorHandling(path, { projectIds }, dispatch)
        .then(organization => OrganizationComposite.fromDataObjects([organization])[0]);
}

export const removeOrganizations = (organizationIds, dispatch) => {
    const path = `${apiRoot}/organizations?organizationIds=${organizationIds}`;
    return baseVermillionApi.deleteWithErrorHandling(path, dispatch);
}