import React from "react";
import ManualFloatingBar from "../../components/Navigation/ManualFloatingBar/ManualFloatingBar";
import { Frame, Page } from "@shopify/polaris";
import { withTranslation } from 'react-i18next';
import { withRouter } from "react-router-dom";

class UserGuideUpload01 extends React.Component {
    constructor() {
        super();
        this.state = {}
    }
    render() {
        const { t, history, match } = this.props;
        return (
            <>
                <Frame>
                    <Page fullWidth>
                        <div className='jnto-generic-page-wrapper'>
                            <section className='generic-block'>
                                <h3>{t('manual.login.subTitle')}</h3>
                                <h2>{t('manual.login.title')}</h2>
                                <hr className='simple-divider' />
                            </section>

                            <section>
                                <p>{t('manual.login.loginDetailnew1')}</p>
                                <br></br>
                                <p>{t('manual.login.loginDetail1a')}</p>
                                <br></br>
                                <section className='bullet-list'>
                                    <ul>
                                        <li><a href="https://asset.jnto.go.jp/">JNTO Photo &amp; Video Library</a> </li>
                                    </ul><br></br>
                                    <p>{t('manual.login.loginDetail1b')}</p>
                                    <p>{t('manual.login.loginDetail1c')}</p>
                                    <p>{t('manual.login.loginDetail1d')}</p><br></br>
                                    <img src='https://asset.japan.travel/image/upload/w_600,f_auto,fl_lossy,e_improve,e_sharpen/v1615457588/dam-admin/manual/updated/Screen_Shot_2021-03-11_at_10.52.17.png' />
                                </section>

                            </section>
                            <section className='generic-block'>
                                <h2>{t('manual.login.title2')}</h2>
                                <hr className='simple-divider' />
                            </section>
                            <section>
                                <p>{t('manual.login.loginDetail2a')}</p>
                                <br></br>
                                <img src='https://asset.japan.travel/image/upload/w_600,f_auto,fl_lossy,e_improve,e_sharpen/v1615457590/dam-admin/manual/updated/Screen_Shot_2021-03-11_at_10.54.10.png' />
                                <p>{t('manual.login.loginDetail2b')}</p>
                                <br></br>
                                <img src='https://asset.japan.travel/image/upload/w_600,f_auto,fl_lossy,e_improve,e_sharpen/v1615457590/dam-admin/manual/updated/Screen_Shot_2021-03-11_at_10.54.13.png' />
                            </section>

                            <ManualFloatingBar buttons={[
                                {
                                    position: 'left',
                                    text: t('manual.userAuth.title'),
                                    callback: () => history.push(`auth`),
                                },
                                {
                                    position: 'right',
                                    text: t('manual.resetPassword.title'),
                                    callback: () => history.push(`reset-password`),
                                }
                            ]} />
                        </div>
                    </Page>
                </Frame>
            </>)
    }
}

export default withTranslation()(withRouter((UserGuideUpload01)));