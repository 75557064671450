import React from 'react';
import { withTranslation } from 'react-i18next';
import { Stack, DisplayText, Scrollable, Heading, TextContainer } from '@shopify/polaris';
import AssetCard from '../../cards/AssetCard/AssetCard';

class AssetGallery extends React.Component {
    componentWillUnmount() {
        const { onExit } = this.props;
        if (onExit) { onExit(); }
    }

    render() {
        const { enableAssetCardBanner, numberOfHits, totalNumberImagesJNTO, totalNumberImagesJOMC, imageCount, videoCount, totalNumberVideosJNTO,totalNumberVideosJOMC, assets, onSelectAsset,
            onLoadNextPage, assetIdsForFolder, onAddAssetToFolder, onRemoveAssetFromFolder,
            canShareUrl, onShareUrl, t, folderPage, outgoingPermissionRequests, uploadedByUser, isJOMC, uploadedByOrg, signedInUser } = this.props;

        const assetCards = assets.map(asset =>
            <AssetCard enableBanner={enableAssetCardBanner}
                uploadedByUser={uploadedByUser}
                key={asset.publicId}
                signedInUser={signedInUser}
                uploadedByOrg={uploadedByOrg}
                asset={asset}
                canEdit={true}
                canShareUrl={canShareUrl}
                selectAsset={onSelectAsset}
                isSelectedForFolder={assetIdsForFolder && assetIdsForFolder.indexOf(asset.publicId) > -1}
                onAddAssetToFolder={onAddAssetToFolder}
                onShareUrl={onShareUrl}
                onRemoveAssetFromFolder={onRemoveAssetFromFolder}
                folderPage={folderPage}
                outgoingPermissionRequests={outgoingPermissionRequests} />);

        return (
            <div className='asset-search-results-container'>
                {(numberOfHits > 0) &&
                <div style={{display:'flex', marginTop: '10px'}}>
                        <Heading style={{display: 'inline-block', 'margin-left':'10px'}} variation="subdued">{t('workflows.projects.numberOfImagesReturned', { imageCount: imageCount, totalImages: isJOMC? totalNumberImagesJOMC :totalNumberImagesJNTO })}</Heading>
                        <div style={{width:'40px'}}></div>
                        <Heading style={{display: 'inline-block'}} variation="subdued">{t('workflows.projects.numberOfVideosReturned', { videoCount: videoCount,totalVideos: isJOMC ? totalNumberVideosJOMC : totalNumberVideosJNTO })}</Heading>
                    </div>
                }
                <br></br>
                <Scrollable shadow style={{ height: '1070px', overflow: 'auto' }}>
                    {assets && assets.length > 0 ? <Stack>{assetCards}</Stack>
                        : <div className='search-results-placeholder'>
                            <DisplayText size='small'>{t('form.errors.noAssetsFound')}</DisplayText>
                        </div>}
                </Scrollable>
            </div>);
    }
}

export default withTranslation()(AssetGallery);
