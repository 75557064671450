import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withTranslation } from 'react-i18next';
import { Stack, Button, Badge, Heading, TextField, Icon, SkeletonBodyText, Collapsible } from '@shopify/polaris';
import { CircleCancelMinor } from '@shopify/polaris-icons';
import FloatingGenericModal from "../../components/FloatingGenericModal/FloatingGenericModal";
// redux actions
import * as assetActions from '../../redux/actions/assetActions';
import * as errorHandlerActions from '../../redux/actions/errorHandlerActions';
import * as genericMessage from '../../redux/actions/genericMessageActions';
import { updateTags } from "../../api/vermillionApi/assetManagementApi";

const AssetTag = ({ canUpdateTags, onTagDelete, publicId, isAutoTag, tag }) => (
    <Badge status={isAutoTag ? 'warning' : 'info'} >
        <div className="asset-detail-badge-container">
            <span className="asset-detail-badge-text">{tag}</span>
            {(canUpdateTags) && <span onClick={() => onTagDelete(tag, publicId)} className="delete-tag-icon floating">
                <span className="asset-icon-bg"></span><Icon source={CircleCancelMinor} accessibilityLabel="delete tag" color="black" />
            </span>}
        </div>
    </Badge>
);

class AssetTagsSection extends React.Component {
    constructor() {
        super();
        this.state = {
            isProcessingTags: false,
            showAddTag: false,
            newTag: '',
            tagToDelete: '',
            badgeExpand: false,
            showTagModal: false

        };

        // function bindings
        this.onTagAdd = this.onTagAdd.bind(this);
        this.onTagDelete = this.onTagDelete.bind(this);
        this.onTagDeleteConfirm = this.onTagDeleteConfirm.bind(this);
        this.toggleAddTag = this.toggleAddTag.bind(this);
        this.handleTagChange = this.handleTagChange.bind(this);
        // this.loadTagData = this.loadTagData.bind(this);
    }

    // componentDidMount() {
    //     this.loadTagData();
    // }

    // componentDidUpdate(prevProps) {
    //     if (prevProps.publicId !== this.props.publicId) {
    //         this.loadTagData();
    //     }
    // }

    // componentWillUnmount() {
    //     const { assetActions } = this.props;
    //     assetActions.clearAutoTags();
    //     this.setState({ showAddTag: false });
    // }

    // loadTagData() {
    //     const { publicId, assetActions } = this.props;
    //     assetActions.getAutoTags(publicId);
    //     this.setState({ showAddTag: false });
    // }

    async onTagAdd(event) {
        const { selectedAsset, assets, assetActions, errorHandlerActions } = this.props;
        const { newTag } = this.state;

        event.preventDefault();
        if (newTag !== "") {
            let { tags } = selectedAsset;
            let publicId = event.target.publicId.value;
            let isVideo = selectedAsset.format === "mp4";
            let tagsArray = [];
            if(tags){
                tagsArray = tagsArray.concat(tags);
            }
            tagsArray.push(newTag);

            // make the api call wait for success
            this.setState({ isProcessingTags: true });
            try {
                await updateTags(tagsArray, publicId, isVideo);
                this.setState({ isProcessingTags: false });
                // update the main list
                assets.map((asset) => asset.publicId === publicId ? asset.tags = tagsArray : null);
                // update the selected asset
                let updatedAsset = Object.assign({}, selectedAsset);
                updatedAsset.tags = tagsArray;
                assetActions.selectAsset(updatedAsset);

                this.setState({
                    showAddTag: false,
                    newTag: "", // clear form field out
                });
            } catch (err) {
                this.setState({ isProcessingTags: false });
                errorHandlerActions.addErrorMessage(err.message);
            }
        }
    }

    async onTagDelete() {
        let { tagToDelete } = this.state;
        let { publicId } = this.props;
        let { tags } = this.props.selectedAsset;
        let autoTags = this.props.selectedAsset.autoTags;
        let isVideo = this.props.selectedAsset.format === "mp4";
        let autoTagsArray = [].concat(autoTags);
        let tagsArray = [].concat(tags);
        let tagIndex = tagsArray.indexOf(tagToDelete);
        if (tagIndex > -1) {
            tagsArray.splice(tagIndex, 1);
        }
        let tagIndexAutoTag = autoTagsArray.indexOf(tagToDelete);
        if (tagIndexAutoTag > -1) {
            autoTagsArray.splice(tagIndexAutoTag, 1);
        }

        // make the api call wait for success
        this.setState({ isProcessingTags: true, showTagModal:false });
        try {
            await updateTags(tagsArray, publicId, isVideo, autoTagsArray);
            this.setState({ isProcessingTags: false });

            // update the main list
            this.props.assets.map((asset) => asset.publicId === publicId ? asset.autoTags = autoTagsArray : null);
            this.props.assets.map((asset) => asset.publicId === publicId ? asset.tags = tagsArray : null);
            // update the selected asset
            let updatedAsset = Object.assign({}, this.props.selectedAsset);
            updatedAsset.autoTags = autoTagsArray;
            updatedAsset.tags = tagsArray;
            this.props.assetActions.selectAsset(updatedAsset);
        } catch (err) {
            this.setState({ isProcessingTags: false });
            errorHandlerActions.addErrorMessage(err.message);
        }
    }

    async onTagDeleteConfirm(tag) {
        this.setState({tagToDelete: tag})
        this.setState({showTagModal: true})
    }

    handleTagChange(value) {
        this.setState({ newTag: value });
    }

    toggleAddTag() {
        this.setState(({ showAddTag }) => ({ showAddTag: !showAddTag }));
    }

    render() {
        const { publicId, canUpdateTags, t } = this.props;
        const { isProcessingTags, showAddTag, newTag } = this.state;
        let tags = [];
        if(this.props.selectedAsset.tags && this.props.selectedAsset.autoTags && this.props.selectedAsset.autoTags.length > 0 && this.props.selectedAsset.autoTags[0]){
            tags= this.props.selectedAsset.tags.concat(this.props.selectedAsset.autoTags)
        }
        else if (this.props.selectedAsset.autoTags && this.props.selectedAsset.autoTags.length && this.props.selectedAsset.autoTags[0] ){
            tags= this.props.selectedAsset.autoTags;
        }
        else{
            tags = this.props.selectedAsset.tags
        }

        const badges = tags ? tags.map(tag => {
            const isAutoTag = this.props.selectedAsset.autoTags && this.props.selectedAsset.autoTags.indexOf(tag) > -1;
            return (
                <div key={tag}>
                    <AssetTag
                        tag={tag}
                        canUpdateTags={canUpdateTags}
                        onTagDelete={this.onTagDeleteConfirm}
                        publicId={publicId}
                        isAutoTag={isAutoTag} />
                </div>)
        }) : [];

        const initialBadgesCnt = 10

        const initialBadges = badges.slice(0, initialBadgesCnt)
        const remainingBadges = badges.slice(initialBadgesCnt)

        let submitButtonRef;
        return (
            <>
                {isProcessingTags ? <SkeletonBodyText /> :
                    <>
                        {canUpdateTags && <div className="asset-details-tag-form-container">
                            <div className='asset-tags-wrapper'>
                                <div className="title">{t('common.tags')}</div>
                                <div>
                                    {showAddTag && <Button onClick={() => { submitButtonRef.click() }} className="asset-details-add-tag" primary size="slim">{t('form.label.save')}</Button>}
                                    {showAddTag && <Button onClick={this.toggleAddTag} className="asset-details-add-tag" secondary size="slim">{t('form.label.close')}</Button>}
                                    {!showAddTag && <Button onClick={this.toggleAddTag} className="asset-details-add-tag" primary size="slim"><span className='plus-icon'></span>{t('form.label.addTag')}</Button>}
                                </div>
                            </div>
                            {showAddTag &&
                                <form onSubmit={this.onTagAdd}>
                                    <TextField name="publicId" labelHidden type='hidden' value={publicId} />
                                    <TextField placeholder={t('form.placeholder.enterTag')} type='text' value={newTag} onChange={this.handleTagChange} />
                                    <button className={'d-none'} submit ref={node => { submitButtonRef = node; }} style={{ display: 'none;' }} />
                                </form>
                            }
                        </div>}
                        <div className="badge-section">
                            {initialBadges}
                            {this.state.badgeExpand &&
                              <>{remainingBadges}</>
                            }
                        </div>
                    </>}
                <br />
                <Stack className="legend-container" distribution={"fill"} spacing='extraTight'>
                    <div className='asset-panel-legend'>
                        <div className='left-tag-legend'>
                            <div className='legend-item blue'>{t('common.manualTags')}</div>
                            <div className='legend-item red'>{t('common.autoTags')}</div>
                        </div>
                       <div>
                           { !this.state.badgeExpand &&
                             <button className="text-center generic-btn" onClick={(() => {this.setState({badgeExpand: true})})}><span className='chev-down-icon'></span>{t('common.viewMoreTags')}</button>
                           }
                       </div>
                    </div>
                </Stack>
            {/*  Modals Section Start */}
            <FloatingGenericModal show={this.state.showTagModal}>
                <div className='generic-modal'>
                    <div style={{fontSize: '14px'}}>
                        { t('workflows.projects.tagEdit', {tagName: this.state.tagToDelete}) }
                    </div>
                    <div className="actions">
                        <button className="text-center dui-btn dui-btn-secondary-alt" onClick={(() => {this.setState({showTagModal: false})})}>
                            {t('form.label.cancel')}
                        </button>
                        <button className="text-center dui-btn dui-btn-primary" onClick={(e)=>{this.onTagDelete()}}>
                            {t('form.label.ok')}
                        </button>
                    </div>
                </div>
            </FloatingGenericModal>
            </>
        );
    }
};

const mapDispatchToProps = dispatch => ({
    assetActions: bindActionCreators(assetActions, dispatch),
    errorHandlerActions: bindActionCreators(errorHandlerActions, dispatch),
    genericMessageActions: bindActionCreators(genericMessage, dispatch),
});

const mapStateToProps = (state) => {
    const userState = state.user;
    const assetState = state.asset;

    return {
        assetSelection: assetState.selectedAsset,
        signedInUser: userState.signedInUser,
        userInfoComposite: userState.userInfoComposite,
        assets: assetState.assets,
        };
};

export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(AssetTagsSection));
