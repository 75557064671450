import baseVermillionApi from './baseVermillionApi';

const apiRoot = '/permission-management';

export const searchMyIncomingPermissionRequests = (userId, isComplete) => {
    const path = `${apiRoot}/${userId}/incoming`;
    return baseVermillionApi.get(path);
};

export const searchMyOutgoingPermissionRequests = (userId, isComplete) => {
    const path = `${apiRoot}/${userId}/outgoing`;
    return baseVermillionApi.get(path);
};

export const searchMySimpleOutgoingPermissionRequests = (userId, folderId) => {
    const path = `${apiRoot}/${userId}/outgoing/folders/${folderId}/simple`;
    return baseVermillionApi.get(path);
};

export const deleteFolderRequests= (userId, folderId) => {
    const path = `${apiRoot}/delete/${userId}/folders/${folderId}`;
    return baseVermillionApi.delete(path);
};


export const acceptPermissionRequests = (userId, permissionRequestIds, dispatch) => {
    const path = `${apiRoot}/users/${userId}/approve`;
    return baseVermillionApi.postWithErrorHandling(path, {
        permissionRequestIds,
    }, dispatch);};

export const rejectPermissionRequests = (userId, permissionRequestIds, dispatch) => {
    const path = `${apiRoot}/users/${userId}/reject`;
    return baseVermillionApi.postWithErrorHandling(path, {
        permissionRequestIds,
    }, dispatch);};

export const postRequestFolderPermission = (userId,folderId, useDescription,use, dispatch) => {
    const path = `${apiRoot}/${userId}/folders/${folderId}`;
    return baseVermillionApi.postWithErrorHandling(path,
        {
        requestReason: useDescription,
        title: use,
        }, dispatch);
};
