import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { SignIn } from 'aws-amplify-react';
import { Link, TextStyle, Form, FormLayout, TextField, Button, DisplayText, Page } from '@shopify/polaris';
import { withTranslation } from 'react-i18next';
import * as userActions from '../../../redux/actions/userActions';
import inputValidators from '../../../utilities/validation/inputValidators';
import { hasValidationErrors, hasEmptyInputFields } from '../../../utilities/validation/formValidation';
import PageLoader from "../../../components/PageLoader/PageLoader";
import { setStateToPromise } from '../../../utilities/componentUtils';
import ExternalNavigation from "../../Navigation/TopNavigation/ExternalNavigation";

const validators = {
    email: inputValidators.email,
    password: inputValidators.password
};

class CustomSignin extends SignIn {
    constructor(props) {
        super(props);
        this.state = {
            user: {
                email: undefined,
                password: undefined,
            },
            isValid: {
                email: true,
                password: true,
            },
            isLoading: false,
            errorMessage: undefined,
        }
        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.asyncSignin = this.asyncSignin.bind(this);
    }

    async asyncSignin() {
        await setStateToPromise(this, 'isLoading', true);
        await this.props.userActions.userSignin(this.state.user.email, this.state.user.password);
    }

    async handleSubmit(event) {
        event.preventDefault();
        try {
            await this.asyncSignin();
            this.changeState('signedIn', this.props.signedInUser);
            // this.props.history.push('/projects');
        } catch (err) {
            this.setState({ errorMessage: this.props.t('form.errors.signinFail', { err }) });
        } finally {
            await setStateToPromise(this, 'isLoading', false);
        }
    };

    handleChange = (value, id) => {
        const change = {
            user: {
                ...this.state.user,
                [id]: value,
            },
            isValid: {
                ...this.state.isValid,
                [id]: validators[id](value)
            },
        };
        this.setState(change)
    }

    showComponent() {
        const { t } = this.props;
        const shouldDisableSubmit = hasValidationErrors(this.state.isValid) || hasEmptyInputFields(this.state.user);

        return (
            <>
                <ExternalNavigation external />
                <PageLoader show={this.state.isLoading} displayText={t('common.processing')} offset={{ bottom: 150 }} />
                <div className='user-authentication-container'>
                    <form className='user-authentication-form' onSubmit={this.handleSubmit} autoComplete={true}>
                        <h2 className='form-header'>{t('auth.signin.welcomeBack')}</h2>
                        <div className='field-item-content'>
                            <TextField placeholder='Email' id='email' type="email" error={this.state.isValid.email ? false : t('form.errors.email')} value={this.state.user.email} onChange={this.handleChange} />
                        </div>
                        <div className='field-item-content'>
                            <TextField placeholder='Password' id='password' type="password" autoComplete={true} error={this.state.isValid.password ? false : t('form.errors.password')} value={this.state.user.password} onChange={this.handleChange} />
                        </div>
                        <div className='submit-container'>
                            <Button submit primary disabled={shouldDisableSubmit}>{t('form.label.submit')}</Button>
                        </div>
                        <div className='form-link-container'>
                            <Link onClick={() => this.changeState('forgotPassword')}>{t('common.forgotPassword')}</Link>
                        </div>
                    </form>

                    <div className='hide'>
                        <Form class='hide' onSubmit={this.handleSubmit} autoComplete={true}>
                            <FormLayout>
                                <DisplayText element='h1' size='large'>{t('auth.signin.welcomeBack')}</DisplayText>
                                <TextField id='email' type="email" label={t('form.label.email')} error={this.state.isValid.email ? false : t('form.errors.email')} value={this.state.user.email} onChange={this.handleChange} />
                                <TextField id='password' type="password" label={t('form.label.password')} autoComplete={true} error={this.state.isValid.password ? false : t('form.errors.password')} value={this.state.user.password} onChange={this.handleChange} />
                                <Button submit primary disabled={shouldDisableSubmit}>{t('form.label.submit')}</Button>
                                {/* <TextStyle variation="subadued">{t('auth.signin.noAccount')} <Link onClick={() => this.changeState('signUp')}>{t('common.here')}</Link></TextStyle> */}
                                <Link onClick={() => this.changeState('forgotPassword')}>{t('common.forgotPassword')}</Link>
                            </FormLayout>
                        </Form >
                    </div>
                    <br />
                    {this.state.errorMessage && <TextStyle variation="negative">{this.state.errorMessage}</TextStyle>}
                </div>
            </>)
    }
}

const mapStateToProps = (state, ownProps) => {
    return {
        userInfoComposite: state.user.userInfoComposite,
        signedInUser: state.user.signedInUser,
    };
}

const mapDispatchToProps = dispatch => {
    return {
        userActions: bindActionCreators(userActions, dispatch)
    }
}
export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(CustomSignin));
