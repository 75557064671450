import * as types from "../constants/action-types";

export const switchToAuthPage = (pageName) => {
    return { type: types.SWITCH_AUTH_PAGE, pageName };
};

export const switchPageSuccess = pageName => {
    return { type: types.SWITCH_PAGE_SUCCESS, pageName };
};

export const switchAuthPage = (pageName) => {
    return (dispatch) => {
        dispatch(switchToAuthPage(pageName));
        return;
    };
};

export const switchPage = pageName => {
    return (dispatch) => {
        dispatch(switchPageSuccess(pageName));
        return;
    };
};