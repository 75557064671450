import { signIn, signOut, signUp, getCurrentUser } from "../../api/cognitoApi/cognitoApi";
import { getUserInfoComposite, createSimpleUser, editUser } from "../../api/vermillionApi/userManagementApi";
import {sendContactEmail}  from "../../api/vermillionApi/historyManagementApi"
import * as types from "../constants/action-types";
import CreateSimpleUserRequest from '../../models/requests/CreateSimpleUserRequest';

export const userSigninSuccess = (signedInUserResponse, userInfoComposite) => {
    return { type: types.USER_SIGN_IN_SUCCESS, signedInUserResponse, userInfoComposite };
};

export const userSignupSuccess = (signedInUserResponse, userInfoComposite) => {
    return { type: types.USER_SIGN_UP_SUCCESS, signedInUserResponse, userInfoComposite };
};

export const userSignOutSuccess = () => {
    return { type: types.USER_SIGN_OUT_SUCCESS };
};

export const getUserGroupNamesSuccess = (userGroupNames) => {
    return { type: types.GET_USER_GROUP_NAMES_SUCCESS, userGroupNames };
};

export const getUsersByEmailSuccess = (users) => {
    return { type: types.GET_USERS_BY_EMAIL_SUCCESS, users };
};

export const editUserSuccess = (users) => {
    return { type: types.EDIT_USER_SUCCESS, users };
};

export const removeUsersSuccess = (userIds) => {
    return { type: types.REMOVE_USERS_SUCCESS, userIds };
};

export const getUsersByOrganizationIdsSuccess = (users) => {
    return { type: types.GET_USERS_BY_ORGANIZATION_IDS_SUCCESS, users };
};

export const sendContactEmailSuccess = () => {
    return { type: types.SEND_CONTACT_EMAIL_SUCCESS };
};

export const userSignin = (email, password) => {
    return async (dispatch) => {
        const signedInUserResponse = await signIn(email, password);
        const userInfoComposite = await getUserInfoComposite(signedInUserResponse.username);
        dispatch(userSigninSuccess(signedInUserResponse, userInfoComposite));
        return;
    };
}

export const userSignOut = () => {
    return async (dispatch) => {
        await signOut();
        dispatch(userSignOutSuccess());
        return;
    };
};

export const getSignedInUser = () => {
    return async (dispatch) => {
        const signedInUserResponse = await getCurrentUser();
        const userInfoComposite = await getUserInfoComposite(signedInUserResponse.username);
        dispatch(userSigninSuccess(signedInUserResponse, userInfoComposite));
        return;
    };
}

export const createSimpleUserAction = (userObj) => {
    return async (dispatch) => {
        const { email, password } = userObj;
        const signedUpUserResponse = await signUp(email, password);
        const signedInUserResponse = await signIn(email, password);
        const newUserObj = {
            id: signedUpUserResponse.userSub,
            ...userObj,
        };
        const createdUserInfoComposite = await createSimpleUser(new CreateSimpleUserRequest(newUserObj))
        dispatch(userSignupSuccess(signedInUserResponse, createdUserInfoComposite));
        return;
    };
}

export const editUserAction = (userId, firstName, lastName, organizationId) => {
    var userObject = {
        userId,
        firstName, 
        lastName, 
        organizationId
    }
    return async (dispatch) => {
        const editUserResponse = await editUser(userObject);
        dispatch(editUserSuccess(editUserResponse));
        return;
    };
}

export const sendContactEmailAction = (userId, bugType, bugTitle, bugDetails) => {
    return async (dispatch) => {
        await sendContactEmail(userId, bugType, bugTitle, bugDetails, dispatch);
        dispatch(sendContactEmailSuccess());
        return;
    };
}