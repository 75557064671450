import AssetStateBuilder from "./AssetStateBuilder";
class AssetState {
    constructor(properties) {
        this.assets = properties.assets;
        this.startIndex = properties.startIndex;
        this.endIndex = properties.endIndex;
        this.searchText = properties.searchText;
        this.pageNumber = properties.pageNumber;
        this.selectedAsset = properties.selectedAsset;
        this.downloadLink = properties.downloadLink;
        this.autoTags = properties.autoTags;
        this.numberOfHits = properties.numberOfHits;
        this.totalNumberImagesJNTO = properties.totalNumberImagesJNTO;
        this.totalNumberImagesJOMC = properties.totalNumberImagesJOMC;
        this.totalNumberVideosJNTO = properties.totalNumberVideosJNTO;
        this.totalNumberVideosJOMC = properties.totalNumberVideosJOMC;
        this.imageCount = properties.imageCount;
        this.videoCount = properties.videoCount;
        this.downloadBytes = properties.downloadBytes;
    }

    setStartIndex(newStartIndex) {
        if (newStartIndex >= this.assets.length) { return; }
        if (newStartIndex < 0) {
            this.startIndex = 0;
            return;
        }

        this.startIndex = newStartIndex;
    }

    setNumberOfHits(numberOfHits) {
        this.numberOfHits = numberOfHits;
    }

    setTotalImagesJNTO(numberOfHits) {
        this.totalNumberImagesJNTO = numberOfHits;
    }

    setTotalImagesJOMC(numberOfHits) {
        this.totalNumberImagesJOMC = numberOfHits;
    }

    setTotalVideosJNTO(numberOfHits) {
        this.totalNumberVideosJNTO = numberOfHits;
    }

    setTotalVideosJOMC(numberOfHits) {
        this.totalNumberVideosJOMC = numberOfHits;
    }

    setNumberImages(numberOfHits) {
        this.imageCount = numberOfHits;
    }

    setNumberVideos(numberOfHits) {
        this.videoCount = numberOfHits;
    }

    setAssets(assets) {
        this.assets = assets;
        this.startIndex = 0;
        this.pageNumber = 0;
    }

    selectAsset(asset) {
        this.selectedAsset = asset;
    }

    addAssets(newAssets) {
        this.assets.push(...newAssets);
    }

    setAutoTags(autoTags) {
        this.autoTags = autoTags;
    }

    setPageNumber(pageNumber) {
        this.pageNumber = pageNumber;
    }

    setDownloadLink(downloadLink, downloadBytes) {
        this.downloadBytes = downloadBytes;
        this.downloadLink = downloadLink;
    }

    static get builder() { return AssetStateBuilder; }
}

export default AssetState;