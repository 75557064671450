import React from "react";
import PropTypes from "prop-types";

export default class FloatingGenericModal extends React.Component {

  render() {
    if (!this.props.show) {
      return null;
    }
    return (
      <div>
        <div className="jnto-modal-wrapper fixed" id="jnto-modal">
          <div className='jnto-modal forward'>
            <div className="content">{this.props.children}</div>
          </div>
        </div>
      </div>
    );
  }
}
FloatingGenericModal.propTypes = {
  show: PropTypes.bool.isRequired
};
