import { combineReducers } from 'redux';
import userReducer from './userReducer';
import assetReducer from './assetReducer';
import navigationReducer from './navigationReducer';
import projectReducer from './projectReducer';
import folderReducer from './folderReducer';
import organizationReducer from './organizationReducer';
import myRequestsReducer from './myRequestsReducer';
import myPermissionRequestsReducer from './myPermissionRequestsReducer';
import fileUploadGridReducer from './fileUploadGridReducer';
import adminReducer from './adminReducer';
import errorHandlerReducer from './errorHandlerReducer';
import genericMessageReducer from './genericMessageReducer';

const rootReducer = combineReducers({
    user: userReducer,
    asset: assetReducer,
    navigation: navigationReducer,
    project: projectReducer,
    folder: folderReducer,
    organization: organizationReducer,
    myRequests: myRequestsReducer,
    myPermissionRequests: myPermissionRequestsReducer,
    fileUploadGrid: fileUploadGridReducer,
    admin: adminReducer,
    errorHandler: errorHandlerReducer,
    genericMessage: genericMessageReducer,
});

export default rootReducer;