import React from "react";
import { Loading, Toast } from "@shopify/polaris";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { withTranslation} from 'react-i18next';
import i18n from 'i18next';
import * as fileUploadGridActions from '../../../../redux/actions/fileUploadGridActions'
import FloatingModal from "../../../FloatingModal/FloatingModal";
import FloatingLoader from "../../../FloatingLoader/FloatingLoader";
import { withRouter} from 'react-router-dom';


const LoadingIndicator = ({ fileUploadInProgress, fileUploadGridActions, t, history }) => {

    const langJp = (i18n && i18n.language && (i18n.language === 'jp' || i18n.language === 'ja-JP'));

    return <div className="loading-indicator">
        {fileUploadInProgress === "in_progress" && <FloatingLoader show={fileUploadInProgress === "in_progress"} />
}
        {fileUploadInProgress === "complete" && 
            <FloatingModal closeText= {t('workflows.fileupload.successModal.button')} onClose={()=>{fileUploadGridActions.clearUploadGridStatus(); history.push('')}} show={fileUploadInProgress === "complete"}>
                <div>
                <h2>{t('workflows.fileupload.successModal.title')}</h2>
                {!langJp &&
                    <p>Upload has completed. If you want to view your uploaded material, click <a style={{'text-decoration': 'underline', cursor: 'pointer'}} onClick={()=>{fileUploadGridActions.clearUploadGridStatus(); history.push('/search')}}>here.</a> If you wish to continue uploading material, please click <a style={{'text-decoration': 'underline', cursor: 'pointer'}}  onClick={()=>{fileUploadGridActions.clearUploadGridStatus(); history.push('/file-upload')}}>here</a> to restart upload.</p>
                }
                {langJp &&
                     <p>アップロードが完了しました。保存された素材を確認する場合はこちら<a style={{'text-decoration': 'underline', cursor: 'pointer'}} onClick={()=>{fileUploadGridActions.clearUploadGridStatus(); history.push('/search')}}>こちら</a>から。<br/>続けて素材をアップロードされる場合はこちら<a style={{'text-decoration': 'underline', cursor: 'pointer'}}  onClick={()=>{fileUploadGridActions.clearUploadGridStatus(); history.push('/file-upload')}}>こちら</a>から登録してください。</p>
                }       
                <br/>
                <p>{t('workflows.fileupload.successModal.bodyText2')} </p>
                </div>
            </FloatingModal>
            }
            
        {fileUploadInProgress === "error" && 
            <FloatingModal closeText= {t('workflows.fileupload.successModal.button')} onClose={()=>{fileUploadGridActions.clearUploadGridStatus(); history.push('')}} show={fileUploadInProgress === "error"}>
                <div>
                <h2>{t('form.errors.errorTitle')}</h2>  
                <br/>
                <p>{t('form.errors.errorPopupMessage')} </p>
                </div>
            </FloatingModal>
            }
    </div>
};

const mapDispatchToProps = dispatch => ({
    fileUploadGridActions: bindActionCreators(fileUploadGridActions, dispatch),
});

const mapStateToProps = (state) => ({
    fileUploadInProgress: state.fileUploadGrid.fileUploadInProgress,
});

export default withTranslation()(withRouter(connect(mapStateToProps, mapDispatchToProps)(LoadingIndicator)));
