import React from "react";
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';


class SideBarDefaultView extends React.Component {
  constructor() {
    super();
  }

  render() {
    const {t, signedInUser, userInfoComposite, organization} = this.props;
    const userGroups = signedInUser && signedInUser.signInUserSession.idToken.payload["cognito:groups"];
    var userGroupToDisplay;
    if(userGroups.length > 1){
      for(var group of userGroups){
        if(group !== 'individual-jomc'){
          userGroupToDisplay = group;
        }
      }
    }
    else if (userGroups.length === 1){
      userGroupToDisplay = userGroups[0];
    }
    return (
      <>
      <div className='sidebar-main-title sidebar-wrapper'>{t('sideBar.userInformation')}</div>
      <div className='default-view sidebar-wrapper'>
        <section>
          <div className='sidebar-sub-title'>{t('sideBar.user')}</div>
          <div className='sidebar-text'>{userInfoComposite && userInfoComposite.user.firstName} {userInfoComposite && userInfoComposite.user.lastName}</div>
        </section>

        <section>
          <div className='sidebar-sub-title'>{t('sideBar.email')}</div>
    <div className='sidebar-text'>{userInfoComposite && userInfoComposite.user.email}</div>
        </section>

        <section>
          <div className='sidebar-sub-title'>{t('sideBar.organization')}</div>
          <div className='sidebar-text'>{organization && organization.organizationName}</div>
        </section>
        <section>
          <div className='sidebar-sub-title'>{t('sideBar.userGroup')}</div>
          <div className='sidebar-text'>{userGroupToDisplay && userGroupToDisplay}</div>
        </section>
      </div>
      </>
    )
  }
}

const mapStateToProps = (state) => {
  const userState = state.user;
  const organization = state.organization;

  return {
      signedInUser: userState.signedInUser,
      userInfoComposite: userState.userInfoComposite,
      organization: organization.organization,
  };
};


export default withTranslation()(connect(mapStateToProps)(SideBarDefaultView));
