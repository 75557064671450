import React from 'react';
import { FormLayout, Form, TextField, DisplayText, Button } from '@shopify/polaris';
import { useTranslation } from 'react-i18next';

const UserProfileForm = ({ userInfoComposite, signedInUser, organization}) => {
    const { t } = useTranslation();
    const userGroups = signedInUser && signedInUser.signInUserSession.idToken.payload["cognito:groups"];
    const userCreationDate = userInfoComposite &&  userInfoComposite.user && userInfoComposite.user.createdAt.split('T')[0];
    var organizationName = '';
    for(var org of organization.allOrganizations){
      if(org.id === userInfoComposite.user.organizationId){
        organizationName = org.organizationName
      }
    }
    var userGroupToDisplay;
    if(userGroups.length > 1){
      for(var group of userGroups){
        if(group !== 'individual-jomc'){
          userGroupToDisplay = group;
        }
      }
    }
    else if (userGroups.length === 1){
      userGroupToDisplay = userGroups[0];
    }

    return (
      <div className='user-profile-form-container'>
        <Form>
            <div className='d-flex user-profile-form-content basic-split-form'>
                <div className='left-col'>
                  <div className='field-item-content'>
                    <TextField disabled type='text' label={t('form.label.firstName')} value={userInfoComposite.user.firstName} />
                  </div>
                  <div className='field-item-content'>
                    <TextField disabled type='email' label={t('form.label.email')} value={userInfoComposite.user.email} />
                  </div>
                </div>
                <div className='right-col'>
                  <div className='field-item-content'>
                    <TextField disabled type='text' label={t('form.label.lastName')} value={userInfoComposite.user.lastName} />
                  </div>
                  <div className='field-item-content'>
                    <TextField disabled type='text' label={t('workflows.admin.userGroup')} value={organizationName} />
                  </div>
                </div>
            </div>
        </Form>
          <div>
              <hr className='simple-divider' />
          </div>
          <div className='d-flex user-metadata'>
              <div>
                <span>{t('workflows.userProfile.email')}:</span> {userInfoComposite.user.email}
              </div>
              <div>
                <span>{t('workflows.userProfile.creationDate')}</span> {userCreationDate}
              </div>
              <div>
                <span>{t('workflows.userProfile.signInAs')}</span> {userGroupToDisplay}
              </div>
          </div>
      </div>);
};

export default UserProfileForm;
