import React from 'react';
import { bindActionCreators } from 'redux';
// import Signup from '../../components/UserAuthentication/Signup/Signup';
import Signin from '../../components/UserAuthentication/Signin/Signin';
import * as navigationActions from '../../redux/actions/navigationActions';
import * as userActions from '../../redux/actions/userActions';
import { connect } from 'react-redux';
import { Page } from '@shopify/polaris';
import PageLoader from '../../components/PageLoader/PageLoader';
import { setStateToPromise } from '../../utilities/componentUtils';
import { withTranslation } from 'react-i18next';

class UserAuthentication extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoading: false,
        }
        this.handleAuthPageChange = this.handleAuthPageChange.bind(this);
        this.updateIsLoading = this.updateIsLoading.bind(this);
        this.onUserSignin = this.onUserSignin.bind(this);
        this.onUserSignup = this.onUserSignup.bind(this);
    }

    shouldComponentUpdate(nextProps, nextState) {
        const userChanged = nextProps.signedInUser !== this.props.signedInUser;
        const isLoadingStatusChanged = this.state.isLoading !== nextState.isLoading;
        return userChanged || isLoadingStatusChanged;
    }

    async onUserSignin(email, password) {
        await setStateToPromise(this, 'isLoading', true);
        try {
            await this.props.userActions.userSignin(email, password)
        } catch (ex) { }
        finally {
            await setStateToPromise(this, 'isLoading', false);
            this.props.history.push('/search');
        }
    }

    async onUserSignup(user) {
        await setStateToPromise(this, 'isLoading', true);

        try {
            await this.props.userActions.createSimpleUserAction(user)
        } catch (ex) { }
        finally {
            await setStateToPromise(this, 'isLoading', false);
            this.props.history.push('/search');
        }
    }

    handleAuthPageChange(newPageName) {
        this.props.navigationActions.switchAuthPage(newPageName);
    }

    updateIsLoading(isLoading) {
        this.setState({ isLoading, });
    }

    render() {
        const { isLoading } = this.state;
        const { t, signedInUser, currentAuthPage } = this.props;
        return (
            <>
                <PageLoader show={isLoading} displayText={t('common.processing')} />
                {(!signedInUser || !signedInUser.username) && < Page >
                    <div className='user-authentication-container'>
                        {/* {this.props.currentAuthPage === 'signup' && <Signup onUserSignup={this.onUserSignup} updateIsLoading={this.updateIsLoading} handleAuthPageChange={this.handleAuthPageChange} />} */}
                        {currentAuthPage === 'signin'
                            && <Signin
                                onUserSignin={this.onUserSignin}
                                updateIsLoading={this.updateIsLoading}
                                handleAuthPageChange={this.handleAuthPageChange} />}
                    </div>
                </Page>};
        </>);
    }
}

const mapDispatchToProps = dispatch => ({
    navigationActions: bindActionCreators(navigationActions, dispatch),
    userActions: bindActionCreators(userActions, dispatch)
});

const mapStateToProps = (state) => ({
    currentAuthPage: state.navigation.currentAuthPage,
    currentPage: state.navigation.currentPage,
    signedInUser: state.user.signedInUser,
});

export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(UserAuthentication));