
import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Switch, withRouter, Route, matchPath } from 'react-router-dom';
import { Page, Banner, Button } from '@shopify/polaris';
import { withTranslation } from 'react-i18next';
import queryString from 'query-string';
import * as assetActions from '../../redux/actions/assetActions';
import * as permissionActions from '../../redux/actions/permissionActions';
import * as folderActions from '../../redux/actions/folderActions';
import * as assetManagementApi from '../../api/vermillionApi/assetManagementApi';
import FolderGallery from '../../components/FolderGallery/FolderGallery';
import AssetsPanel from '../../components/shared/panels/AssetsPanel/AssetsPanel';
import PanelWithData from '../../components/PanelWithData/PanelWithData';
import PageLoader from "../../components/PageLoader/PageLoader";
import WithConfirmationModal from '../../components/shared/modals/WithConfirmationModal/WithConfirmationModal';
import DownloadRequestModal from '../../components/DownloadRequestModal/DownloadRequestModal';
import TermsModal from '../../components/TermsModal/TermsModal';
import { createPermissionStatusDictionary } from '../../utilities/permissionUtils';
import SimpleModal from '../../components/shared/modals/SimpleModal/SimpleModal';
import Sidebar from '../../components/Navigation/SideBar/SideBar'
import FloatingGenericModal from "../../components/FloatingGenericModal/FloatingGenericModal";
import FloatingDownloadRequestModal from "../../components/FloatingDownloadRequestModal/FloatingDownloadRequestModal";
import FloatingTermsModal from "../../components/FloatingTermsModal/FloatingTermsModal";
import { getContentForTypeID } from '../../components/DownloadRequestModal/PermissionRequestProjectTypes';
import { stringToBooleanDefaultFalse } from '../../utilities/textUtils';


class FoldersPage extends React.Component {
    constructor() {
        super();
        this.state = {
            downloadOverFourBytes: false,
            isGettingDownloadLink: false,
            isLoading: false,
            imageDownloadLinkUrl: undefined,
            videoDownloadLinkUrl: undefined,
            showDeleteConfirmationModal: false,
            showRequestApprovalModal: false,
            showDownloadConfirmModal:false,
            showDeletePermissionsConfirm: false,
        };
        this.getNeedsPermissionForAssets = this.getNeedsPermissionForAssets.bind(this)
        this.onSelectFolder = this.onSelectFolder.bind(this);
        this.onDownloadFolder = this.onDownloadFolder.bind(this);
        this.onDeleteFolderApprove = this.onDeleteFolderApprove.bind(this);
        this.onRenameFolderApprove = this.onRenameFolderApprove.bind(this);
        this.getDataLoader = this.getDataLoader.bind(this);
        this.downloadLinkifUnderFourGB = this.downloadLinkifUnderFourGB.bind(this);
        this.downloadLink = this.downloadLink.bind(this);
        this.onDeletePermissionRequests= this.onDeletePermissionRequests.bind(this);
        // this.loadData = this.loadData.bind(this)
    }

    componentDidMount() {
        const { assetActions, folderActions, userInfoComposite } = this.props;
        assetActions.updateSearchText();
        folderActions.getFoldersByUserId(userInfoComposite.user.id);
    }

//     componentDidUpdate(prevProps, prevState) {
//         if (prevProps.assets && prevProps.assets.length >0 && prevProps.assets != this.props.assets) {
//             this.loadData();
//     }
// }

    onSelectFolder(folderImageComposite) {
        const { history, match } = this.props;
        if (folderImageComposite && folderImageComposite.id) {
            history.push(`${match.url}/${folderImageComposite.id}`);
        } else {
            history.push(`${match.url}`)
        }
    }

    getNeedsPermissionForAssets(){
        const {outgoingPermissionRequests} = this.props
        var assetsNeedPermission = false
        for(var asset of this.props.assets){
            const approvalRequired = asset.context && stringToBooleanDefaultFalse(asset.context.requestApproval);
            const approvalGranted = () => {
                for(var req of outgoingPermissionRequests){
                    if(req.imageId && asset.publicId && req.imageId === asset.publicId && req.status === 'approved'){
                        return true
                    }
                }
                return false
            }
            const canDownload = !approvalRequired || approvalGranted() ;
            if(!canDownload){
                assetsNeedPermission = true;
            }
        }
        return assetsNeedPermission
    }


    async onDeletePermissionRequests(){
        const {permissionActions, userInfoComposite, folderActions, selectedFolder} = this.props;
        this.setState({ isLoading: true });
        await permissionActions.deletePermissionRequests(userInfoComposite.user.id, selectedFolder.id)
        this.setState({ showDeletePermissionsConfirm: false })
        await folderActions.getFoldersByUserId(userInfoComposite.user.id);
        this.setState({ isLoading: false });

    }

    async onDownloadFolder(agreedToTerms) {
        this.setState({ showDownloadConfirmModal: false })
        const userId = this.props.userInfoComposite.user.id;

        const imageIds = this.props.assets
            .filter(asset => asset.format.indexOf('mp4') < 0)
            .map(asset => asset.publicId);
        const imageSize = this.props.assets
            .filter(asset => asset.format.indexOf('mp4') < 0)
            .reduce((accumulator, currentValue) => accumulator + currentValue.fileSizeInMB, 0);
        let imageDownloadLink;
        if (imageIds && imageIds.length > 0) {
            imageDownloadLink = await assetManagementApi.queryDownloadLink(imageIds, userId, agreedToTerms, this.props.selectedFolder.id, false);
            if (imageDownloadLink) {
                this.setState({ imageDownloadLinkUrl: imageDownloadLink.url })
            }
        }

        const videoIds = this.props.assets
            .filter(asset => asset.format.indexOf('mp4') > -1)
            .map(asset => asset.publicId);
            
        const videoSizes = this.props.assets
            .filter(asset => asset.format.indexOf('mp4') > -1)
            .reduce((accumulator, currentValue) => accumulator + currentValue.fileSizeInMB, 0);
        let videoDownloadLink;
        if (videoIds && videoIds.length > 0) {
            videoDownloadLink = await assetManagementApi.queryDownloadLink(videoIds, userId, agreedToTerms, this.props.selectedFolder.id, true);
            if (videoDownloadLink) {
                this.setState({ videoDownloadLinkUrl: videoDownloadLink.url });
            }
        }
        if (videoDownloadLink || imageDownloadLink) {
            if(videoDownloadLink){
                videoDownloadLink.bytes = videoSizes;
            }
            if(imageDownloadLink){
                imageDownloadLink.bytes = imageSize
            }
            this.downloadLinkifUnderFourGB(videoDownloadLink, imageDownloadLink);
        }
    }

    downloadLinkifUnderFourGB(videoDownloadLink, imageDownloadLink) {
        const videoSize = videoDownloadLink ? videoDownloadLink.bytes : 0;
        const imageSize = imageDownloadLink ? imageDownloadLink.bytes : 0;

        if (((videoSize + imageSize) / Math.pow(1024, 3)) > 4) {
            this.setState({ downloadOverFourBytes: true });
        }
        else {
            if (imageDownloadLink && imageDownloadLink.url !== '') {
                var updatedImageLink = imageDownloadLink.url;
                if (imageDownloadLink.url.startsWith('http') && !imageDownloadLink.url.startsWith('https')) {
                    updatedImageLink = imageDownloadLink.url.replace('http', 'https');
                }
                window.location = (updatedImageLink);
            }
            if (videoDownloadLink && videoDownloadLink.url !== '') {
                // window.open(videoDownloadLink.url)
                var updatedVideoLink = videoDownloadLink.url;
                if (videoDownloadLink.url.startsWith('http') && !videoDownloadLink.url.startsWith('https')) {
                    updatedVideoLink = videoDownloadLink.url.replace('http', 'https');
                }
                setTimeout(function () { window.location = updatedVideoLink; }, 4000);

            }
        }
    }

    downloadLink() {
        this.setState({ showDownloadConfirmModal: false })
        this.setState({ downloadOverFourBytes: false });
        const { imageDownloadLinkUrl, videoDownloadLinkUrl } = this.state;
        if (imageDownloadLinkUrl && imageDownloadLinkUrl !== '') {
            window.open(imageDownloadLinkUrl)
            // window.location = imageDownloadLinkUrl;
        }
        if (videoDownloadLinkUrl && videoDownloadLinkUrl !== '') {
            window.open(videoDownloadLinkUrl)
            // window.location = videoDownloadLinkUrl;
        }
    }

    onDeleteFolderApprove(folderImageComposite) {
        this.setState({ isLoading: true });

        // invoke the action to delete the folder
        const folderAction = this.props.folderActions.deleteFolder({
            userId: this.props.userInfoComposite.user.id,
            folderId: folderImageComposite.id
        });

        folderAction.then(() => {
            // remove the modal
            this.setState({
                isLoading: false,
                showDeleteConfirmationModal: false
            });
            // if delete action selected from banner in selected folder view
            this.onSelectFolder(undefined)
        });
    }

    onRenameFolderApprove(folderId, folderName) {
        this.setState({ isLoading: true });
        const folderAction = this.props.folderActions.updateFolderName({
            userId: this.props.userInfoComposite.user.id,
            folderId,
            folderName
        });

        folderAction.then(() => {
            // remove the modal
            this.setState({
                isLoading: false,
            });
        });
    }

    // loadData() {
    //     const { pageNumber } = this.state;
    //     const { history, match, folderImageComposites, userInfoComposite, assetActions, signedInUser } = this.props;
    //     const selectedFolderId = match.params.folderId;
    //     const selectedFolderData = folderImageComposites.find(folder => folder.id === parseInt(selectedFolderId, 10));
    //     const imageIds = selectedFolderData.imageIds;
    //     const inputText = queryString.parse(history.location.search).inputText;
    //     permissionActions.getMySimpleOutgoingPermissionRequests(userInfoComposite.user.id, selectedFolderId);
    //     const userGroups = signedInUser.signInUserSession.idToken.payload["cognito:groups"];
    //     assetActions.getAssetsByPublicIdsPaginated(inputText, imageIds, pageNumber, userGroups);
    // }

    getDataLoader() {
        const { folderImageComposites, assetActions, userInfoComposite, permissionActions, signedInUser } = this.props;
        if (folderImageComposites.length === 0) { return null; }
        return (match, history, pageNumber) => {
            const selectedFolderId = match.params.folderId;
            const { imageIds } = folderImageComposites.find(folder => folder.id === parseInt(selectedFolderId, 10));
            const inputText = queryString.parse(history.location.search).inputText;
            permissionActions.getMySimpleOutgoingPermissionRequests(userInfoComposite.user.id, selectedFolderId);
            const userGroups = signedInUser.signInUserSession.idToken.payload["cognito:groups"];
            assetActions.getAssetsByPublicIdsPaginated(inputText, imageIds, pageNumber, userGroups);
        }
    }

    render() {
        const { t, selectedFolder, match, outgoingPermissionRequests, permissionRequestFailedNoUsers, signedInUser } = this.props;
        const { downloadOverFourBytes } = this.state;
        const hasSelectedFolder = selectedFolder && selectedFolder.id;
        const failedRequestsWithLineBreaks = permissionRequestFailedNoUsers && permissionRequestFailedNoUsers.join(" />")
        const userGroups = signedInUser.signInUserSession.idToken.payload["cognito:groups"];
        const requestTitle = selectedFolder ? getContentForTypeID(selectedFolder.title) || selectedFolder.title : null;
        return (

            <Sidebar>
                {(onUpdateSideBarView) => {
                    onUpdateSideBarView('default');
                    return <React.Fragment>
                        <div className="app">

                            <div className='projects-page-container'>
                                <PageLoader
                                    show={this.state.isLoading}
                                    displayText={t('common.processing')} />
                                {hasSelectedFolder &&
                                    <>
                                        <div className='main-folder-banner'>
                                            <div className='top-content'>
                                                <div className='top-content-text'>
                                                    <p className='bold'>{t('common.resultsFilteredTo', { name: selectedFolder.folderName })}</p>
                                                    {selectedFolder.title && <p className='bold' style={{ 'padding-bottom': '.5rem', 'paddingTop': '.5rem' }}> {t('workflows.myPermissionRequests.downloadUse', { reason: requestTitle})} </p>}
                                                    {selectedFolder.requestReason && <p className='bold' style={{ 'padding-bottom': '1rem' }}> {t('workflows.myPermissionRequests.downloadDetails', { reason: selectedFolder.requestReason })} </p>}
                                                </div>
                                                <div>
                                                    <a onClick={() => this.onSelectFolder()} style={{ cursor: 'pointer' }}>{t('workflows.folders.back')}</a>
                                                </div>
                                            </div>
                                            <section className='actions-section'>
                                                <div>
                                                    <button className='dui-btn dui-btn-secondary-alt s red--text skinny' onClick={() => this.getNeedsPermissionForAssets() ? this.setState({showDownloadConfirmModal: true}) : this.onDownloadFolder(false)}>
                                                         {t('form.label.download')}
                                                     </button>
                                                     <FloatingGenericModal show={this.state.showDownloadConfirmModal}>
                                                        <div className='generic-modal'>
                                                        <div>{t('workflows.folders.downloadWarning')}</div>
                                                        <div className="actions">
                                                            <button className="text-center dui-btn dui-btn-primary" onClick={()=>this.onDownloadFolder(false)}>
                                                            {t('Download')}
                                                            </button>
                                                
                                                        </div>
                                                        </div>
                                                    </FloatingGenericModal>

                                                    {downloadOverFourBytes &&
                                                        <SimpleModal
                                                            title={t('common.attention')}
                                                            message={t('workflows.downloadHistory.downloadSizeWarning')}
                                                            onConfirm={() => { this.downloadLink() }}
                                                            onCancel={() => { this.setState({ downloadOverFourBytes: false }); }}
                                                        />}
                                                </div>
                                                <div>
                                                    <button className='dui-btn dui-btn-secondary-alt red--text skinny' onClick={() => this.setState({ showDeleteConfirmationModal: true })}>
                                                        {t('workflows.folders.deleteFolder')}
                                                    </button>
                                                    {/*<WithConfirmationModal>*/}
                                                    {/*        {(show) => (*/}
                                                    {/*            <button className='dui-btn dui-btn-secondary-alt red--text skinny' onClick={() => show(() => this.onDeleteFolderApprove(selectedFolder))}>*/}
                                                    {/*                {t('workflows.folders.deleteFolder')}*/}
                                                    {/*            </button>)}*/}
                                                    {/*</WithConfirmationModal>*/}
                                                </div>
                                                <div>
                                                    <button className='dui-btn dui-btn-secondary-alt red--text skinny' onClick={() => this.setState({ showRequestApprovalModal: true })}>
                                                        {t('form.label.requestApproval')}
                                                    </button>
                                                    {/*<DownloadRequestModal*/}
                                                    {/*        title = {t('workflows.myPermissionRequests.usageReasonEnterTitle')}*/}
                                                    {/*        folder = { selectedFolder }*/}
                                                    {/*        onToggleIsLoading ={(isLoading) => this.setState({ isLoading })}*/}
                                                    {/*        message = {t('workflows.myPermissionRequests.usageReasonEnter')}>*/}
                                                    {/*            {(show) => (*/}
                                                    {/*                <button className='dui-btn dui-btn-secondary-alt red--text skinny' onClick={() => show(() => "")}>*/}
                                                    {/*                    {t('form.label.requestApproval')}*/}
                                                    {/*                </button>)}*/}
                                                    {/*    </DownloadRequestModal>*/}
                                                    {permissionRequestFailedNoUsers && (permissionRequestFailedNoUsers.length > 0) &&
                                                        <SimpleModal
                                                            title={t('common.attention')}
                                                            message={t('workflows.myPermissionRequests.couldNotDownloadNoUsers') + "\n \n" + failedRequestsWithLineBreaks}
                                                            onCancel={() => this.props.permissionActions.clearCouldntDownloadWarning()}
                                                            onConfirm={() => this.props.permissionActions.clearCouldntDownloadWarning()} />}
                                                </div>
                                                {requestTitle && <div>
                                                    <button className='dui-btn dui-btn-secondary-alt s red--text skinny' onClick={(e) => this.setState({ showDeletePermissionsConfirm: true })}>
                                                         {t('form.label.deletePermissions')}
                                                     </button>
                                                     <FloatingGenericModal show={this.state.showDeletePermissionsConfirm}>
                                                        <div className='generic-modal'>
                                                        <h2 >{t('workflows.folders.areYouSure')}</h2>
                                                        <div>{t('workflows.myPermissionRequests.deletePermissionsConfirm')}</div>
                                                        <div className="actions">
                                                        <button className="text-center dui-btn dui-btn-primary" onClick={(e) => this.setState({ showDeletePermissionsConfirm: false })}>
                                                            {t('form.label.cancel')}
                                                            </button>
                                                            <button className="text-center dui-btn dui-btn-primary" onClick={(e)=>this.onDeletePermissionRequests()}>
                                                            {t('form.label.ok')}
                                                            </button>
                                                        </div>
                                                        </div>
                                                    </FloatingGenericModal>
                                                </div>}
                                            </section>
                                        </div>
                                    </>
                                }
                                <Switch>
                                    <Route exact path={match.url} render={() =>
                                    (<Page>
                                        <FolderGallery
                                            onSelectFolder={this.onSelectFolder}
                                            userGroups={userGroups}
                                            onDeleteFolderApprove={this.onDeleteFolderApprove}
                                            onRenameFolderApprove={this.onRenameFolderApprove}
                                            folderImageComposites={this.props.folderImageComposites} />
                                    </Page>)} />
                                    <Route path={`${match.url}/:folderId`} render={({ location, history, match }) =>
                                        <Page fullWidth>
                                            <PanelWithData {...{ location, history, match }} dataLoader={this.getDataLoader()}>
                                                {(onLoadNextPage, assets) =>
                                                    <AssetsPanel
                                                        onLoadNextPage={onLoadNextPage}
                                                        assets={[...assets]}
                                                        onToggleIsLoading={(isLoading) => this.setState({ isLoading })}
                                                        folderPage={selectedFolder}
                                                        outgoingPermissionRequests={outgoingPermissionRequests ? createPermissionStatusDictionary(outgoingPermissionRequests) : {}} />}
                                            </PanelWithData>
                                        </Page>} />
                                </Switch>
                            </div>
                        </div>

                        {/*  Modals Section Start */}
                        <FloatingGenericModal show={this.state.showDeleteConfirmationModal}>
                            <div className='generic-modal'>
                                <h2>{t('workflows.folders.areYouSure')}</h2>
                                <div>
                                    {t('common.confirmChanges')}
                                </div>
                                <div className="actions">
                                    <button className="text-center dui-btn dui-btn-secondary-alt" onClick={(e) => this.setState({ showDeleteConfirmationModal: false })}>
                                        {t('form.label.cancel')}
                                    </button>
                                    <button className="text-center dui-btn dui-btn-primary" onClick={(e) => this.onDeleteFolderApprove(selectedFolder)}>
                                        {t('form.label.ok')}
                                    </button>
                                </div>
                            </div>
                        </FloatingGenericModal>
                        <FloatingDownloadRequestModal
                            show={this.state.showRequestApprovalModal}
                            cancel={() => this.setState({ showRequestApprovalModal: false })}
                            confirm={() => this.setState({ showRequestApprovalModal: false })}
                            title={t('workflows.myPermissionRequests.usageReasonEnterTitle')}
                            message={t('workflows.myPermissionRequests.usageReasonEnter')}
                            folder={selectedFolder}
                            onToggleIsLoading={(isLoading) => this.setState({ isLoading })}
                        />
                        {/*  Modals Section End */}

                    </React.Fragment>
                }}
            </Sidebar>
        );
    }
}

const mapDispatchToProps = dispatch => ({
    assetActions: bindActionCreators(assetActions, dispatch),
    permissionActions: bindActionCreators(permissionActions, dispatch),
    folderActions: bindActionCreators(folderActions, dispatch),
})

const mapStateToProps = ({ user, asset, folder, myPermissionRequests }, { location }) => {
    const pathObj = matchPath(location.pathname, {
        path: '/folders/:folderId',
        exact: false,
        strict: false
    });
    const folderId = (pathObj && pathObj.params && pathObj.params.folderId) || null;
    return {
        userInfoComposite: user.userInfoComposite,
        signedInUser: user.signedInUser,
        assets: asset.assets,
        pageNumber: asset.pageNumber,
        folderImageComposites: folder.folders,
        outgoingPermissionRequests: myPermissionRequests.outgoingPermissionRequests,
        permissionRequestFailedNoUsers: myPermissionRequests.requestsFailNouser,
        selectedFolder: folderId !== null ? folder.folders.find(folder => folder.id === parseInt(folderId, 10)) : null,
    };
}

export default withTranslation()(withRouter(connect(mapStateToProps, mapDispatchToProps)(FoldersPage)));
