import React from "react";
import PropTypes from "prop-types";
import {bindActionCreators} from "redux";
import * as folderActions from "../../redux/actions/folderActions";
import * as permissionActions from "../../redux/actions/permissionActions";
import {withTranslation} from "react-i18next";
import {withRouter} from "react-router-dom";
import {connect} from "react-redux";
import {ActionList, Button, Heading, Popover, Stack, TextContainer, TextField} from "@shopify/polaris";
import {getContentForTypeID, typeUse} from "../DownloadRequestModal/PermissionRequestProjectTypes";

class FloatingDownloadRequestModal extends React.Component {

  constructor() {
    super();
    this.state = {
      isDropdownActive: false,
      selectedType: undefined,
      typeDetailDisplayText: undefined,
    };
    this.togglePopover = this.togglePopover.bind(this);
    this.selectType = this.selectType.bind(this);
    this.onTypeDetailsChanged = this.onTypeDetailsChanged.bind(this);
    this.onSave = this.onSave.bind(this);
    this.onModalClose = this.onModalClose.bind(this);
    this.onCancelBtnPressed = this.onCancelBtnPressed.bind(this);
  }

  togglePopover() {
    this.setState(({ isDropdownActive }) => ({ isDropdownActive: !isDropdownActive }));
  }

  selectType(type) {
    this.setState({
      selectedType: type,
    });
  }

  onTypeDetailsChanged(newVal) {
    this.setState({
      typeDetailDisplayText: newVal,
    });
  }

  onCancelBtnPressed () {
    this.onModalClose()
    this.props.cancel()
  }
  onModalClose(){
    this.setState({
      isDropdownActive: false,
      selectedType: undefined,
      typeDetailDisplayText: undefined,
    });
  }

  onSave(saveCallback) {
    if(this.props.folder){
      const { onToggleIsLoading, userInfoComposite, folder, folderActions, permissionActions} = this.props;
      onToggleIsLoading(true);

      const permissionAction = permissionActions.requestFolderPermission({
        userId: this.props.userInfoComposite.user.id,
        folderId: this.props.folder.id,
        useDescription: folder.requestReason || this.state.typeDetailDisplayText,
        use: folder.title || this.state.selectedType,
      });

      permissionAction.then(async () => {
        folderActions.getFoldersByUserId(userInfoComposite.user.id);
        permissionActions.getMySimpleOutgoingPermissionRequests(userInfoComposite.user.id, folder.id);
        onToggleIsLoading(false);
        saveCallback();
        this.onModalClose()
      });
    }
  }


  render() {
    const { isDropdownActive, selectedType, typeDetailDisplayText} = this.state;
    const { title, message, t, children, folder} = this.props;
    if (!this.props.show) {
      return null;
    }

    let folderDownloadTitle;
    if(folder.title){
      folderDownloadTitle = getContentForTypeID(folder.title) || folder.title;
    }

    const folderUsageExisting = (folderDownloadTitle && folder.requestReason) || false;


    const dropdownItems = typeUse().filter((a)=> {
      return (a.id!=='COMMERCIAL' && a.id !== 'NON-COMMERCIAL')
    }).map((type) => {
      return  <option value={type.id}>{type.content}</option>
    }
      )

    const isDisabled = (!folderUsageExisting && (!selectedType || (!typeDetailDisplayText || !typeDetailDisplayText.trim())))

    const defaultSelected = (!selectedType) ? { value: "default" } : { value: selectedType }

    return (
      <div>
        <div className="jnto-modal-wrapper" id="jnto-modal">
          <div className='jnto-modal'>
            <div className="content">
              <h2>{this.props.title}</h2>
              <div>
                {(!folderDownloadTitle && !folder.requestReason) && <div style = {{'paddingBottom':'1rem'}}>{message}</div>}
                <div>
                  {!folderDownloadTitle &&
                  <div style={{paddingTop: '5px'}}className='field-group'>
                      <div className='jnto-selector-container'>
                        <select name='select-input'  {...defaultSelected} onChange={(e) => { this.selectType(e.target.value) }}>
                            <option value='default' disabled >{t('workflows.myPermissionRequests.useType')} </option>
                            {dropdownItems}
                        </select>
                      </div>
                    </div>
                  }
                  {folderDownloadTitle && <Stack vertical={true}>
                    <Heading>{t('workflows.myPermissionRequests.modalTitleExisting')} </Heading>
                    <TextContainer>{folderDownloadTitle}</TextContainer>
                  </Stack> }
                  {!folder.requestReason && <div style = {{'paddingTop':'1rem'}}>
                    <div className='generic-form-container set-bg'>
                      <div className='field-item-content'>
                        <TextField
                          label={t('workflows.myPermissionRequests.usageDescription')}
                          onChange={this.onTypeDetailsChanged}
                          value={this.state.typeDetailDisplayText}
                        />
                      </div>
                    </div>
                  </div>}
                  {folder.requestReason && <div style = {{'paddingTop':'1rem'}}>
                    <Stack vertical={true}>
                      <Heading>{t('workflows.myPermissionRequests.modalReasonExisting')} </Heading>
                      <TextContainer>{folder.requestReason}</TextContainer>
                    </Stack>
                  </div>}
                </div>
              </div>
              <div className="actions">
                <button className="text-center dui-btn dui-btn-secondary-alt" onClick={this.onCancelBtnPressed}>
                  {t('form.label.cancel')}
                </button>
                <button disabled={isDisabled} className="text-center dui-btn dui-btn-primary" onClick={() => this.onSave(this.props.confirm)}>
                  {t('form.label.ok')}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
FloatingDownloadRequestModal.propTypes = {
  title: PropTypes.string.isRequired,
  show: PropTypes.bool.isRequired,
  cancel: PropTypes.func.isRequired,
  confirm: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  userInfoComposite: state.user.userInfoComposite,
});

const mapDispatchToProps = (dispatch) => ({
  folderActions: bindActionCreators(folderActions, dispatch),
  permissionActions: bindActionCreators(permissionActions, dispatch),
});

export default withTranslation()(withRouter(connect(mapStateToProps, mapDispatchToProps)(FloatingDownloadRequestModal)));
