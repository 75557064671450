import * as types from "../constants/action-types";
import initialState from './initialState';

export default function adminReducer(state = initialState.admin, action) {
    let newState;
    const allOrganizationsCopy = state.allOrganizations.slice();
    switch (action.type) {
        case types.GET_USER_GROUP_NAMES_SUCCESS:
            newState = {
                ...state,
                userGroupNames: action.userGroupNames,
            };
            break;
        case types.GET_USERS_BY_ORGANIZATION_IDS_SUCCESS:
            newState = {
                ...state,
                usersForAdmin: action.users,
            };
            break;
        case types.REMOVE_USERS_SUCCESS:
            const usersForAdminOriginal = [...state.usersForAdmin];
            const updatedUsersForAdmin = usersForAdminOriginal.filter(user => action.userIds.indexOf(user.id) < 0);
            newState = {
                ...state,
                usersForAdmin: updatedUsersForAdmin,
            };
            break;
        case types.GET_ADMIN_PROJECTS_SUCCESS:
            newState = {
                ...state,
                adminProjects: action.adminProjects,
            };
            break;
        case types.GET_ORGANIZATIONS_SUCCESS:
            newState = {
                ...state,
                allOrganizations: action.organizationComposites,
            };
            break;
        case types.GET_ALL_USERS_SUCCESS:
            newState = {
                ...state,
                allUsers: action.userComposites,
            }
            break;
        case types.GET_ASSET_UPLOAD_USER_SUCCESS:
            newState = {
                ...state,
                assetUploadedByUser: action.uploadUserInformation,
            };
            break;
        case types.UPDATE_ORGANIZATION_PROJECTS_SUCCESS:
            // find index of updated organization, and replace it in the array with splice
            const indexToReplace = allOrganizationsCopy.findIndex(org => org.id === action.updatedOrganization.id);
            allOrganizationsCopy.splice(indexToReplace, 1, action.updatedOrganization);
            newState = {
                ...state,
                allOrganizations: allOrganizationsCopy,
            };
            break;
        case types.REMOVE_ORGANIZATIONS_SUCCESS:
            // find index of updated organization, and replace it in the array with splice
            const remainingOrganizations = allOrganizationsCopy
                .filter(organization => !action.organizationIdsRemoved.includes(organization.id));
            newState = {
                ...state,
                allOrganizations: remainingOrganizations,
            };
            break;
        case types.GET_ORGANIZATION_USER_IDS_SUCCESS:
            const organizationUserIds = action.organizations.reduce((map, organization) => {
                const usersInOrganization = action.users.filter(user => user.organizationId === organization.id);
                map[organization.id] = usersInOrganization.map(user => user.id);
                return map;
            }, {});

            newState = {
                ...state,
                allOrganizations: action.organizations,
                organizationUserIds,
            };
            break;
        default:
            break;
    }

    return newState || state;
}