import React from 'react';
import { useTranslation } from 'react-i18next';
import ModuleRow from '../shared/rows/ModuleRow/ModuleRow';
import { booleanToYesNo, formatYYYMMDD, approvalForExternalUsageUI } from '../../utilities/textUtils';
const AssetContextSectionUserData = ({ assetContext, publicId, orientation, fileSizeInMB, editMode = false, onEdit }) => {
    const { t } = useTranslation();

    // skip out if no context and dont display this component
    if (!assetContext) { return null; }

    const { location, prefecture,
         trimming, color, text, validDate, uploadUser, uploadUserOrg, approvalForExternalUsage} = assetContext;
    return (
        <>
          <ModuleRow label={t('workflows.downloadHistory.uploader')} value={(uploadUser)} fieldType={'text'}/>
          <ModuleRow label={t('workflows.downloadHistory.uploadOrg')} value={(uploadUserOrg)}  fieldType={'text'}/>
        </>);
};

export default AssetContextSectionUserData;
