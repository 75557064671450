class Project {
    constructor(properties) {
        this.id = properties.id;
        this.projectName = properties.projectName;
    }

    static fromDataObjects(projectDAOs) {
        return projectDAOs ? projectDAOs.map(dao => new Project(dao)) : [];
    }
}

export default Project;

