import React, { Component } from 'react';
import { withAuthenticator } from 'aws-amplify-react'
import Loadable from 'react-loadable';
import { Route, BrowserRouter as Router } from 'react-router-dom';
import { Frame } from "@shopify/polaris";
import '../App.css';
import UserAuthentication from '../workflows/UserAuthentication/UserAuthentication';
import ProjectsPage from '../workflows/ProjectsPage/ProjectsPage';
import UserProfilePage from '../workflows/UserProfilePage/UserProfilePage';
import FoldersPage from '../workflows/FoldersPage/FoldersPage';
import MyCustomSignin from '../components/UserAuthentication/Signin/Signin';
import MyCustomSignup from '../components/UserAuthentication/Signup/Signup';
import MyCustomForgotPassword from '../components/UserAuthentication/ForgotPassword/ForgotPassword';
import AutomaticRedirect from '../components/shared/navBars/AutomaticRedirect/AutomaticRedirect';
import LoadingIndicator from '../components/shared/indicators/LoadingIndicator/LoadingIndicator';
import ErrorIndicator from '../components/shared/indicators/ErrorIndicator/ErrorIndicator';
import LoadingSpinner from '../components/shared/indicators/LoadingSpinner/LoadingSpinner';
import GenericMessagePopup from '../components/shared/indicators/GenericMessagePopup/GenericMessagePopup';
import Navigator from '../workflows/Navigator/Navigator';
import FAQPage from '../workflows/FAQPage/FAQPage';

// temporarily adding to show mocked file upload flow
import DashboardPage from "../workflows/DashboardPage/DashboardPage";
import ManualPage from "../workflows/ManualPage/ManualPage";
/* implement route-level code splitting for modules that do not need to be loaded upfront */

const FileUploadPage = Loadable({
    loader: () => import('../workflows/FileUploadPage/FileUploadPage'),
    loading: () => <LoadingSpinner />
});

// const MyRequestsPage = Loadable({
//     loader: () => import('../workflows/MyRequestsPage/MyRequestsPage'),
//     loading: () => <LoadingSpinner />
// });

const DownloadHistoryPage = Loadable({
    loader: () => import('../workflows/DownloadHistoryPage/DownloadHistoryPage'),
    loading: () => <LoadingSpinner />
});


const PermissionRequestsPage = Loadable({
    loader: () => import('../workflows/PermissionRequestsPage/PermissionRequestsPage'),
    loading: () => <LoadingSpinner />
});

// const ModerationPage = Loadable({
//     loader: () => import('../workflows/ModerationPage/ModerationPage'),
//     loading: () => <LoadingSpinner />
// });

const UserAdminPage = Loadable({
    loader: () => import('../workflows/UserAdminPage/UserAdminPage'),
    loading: () => <LoadingSpinner />
});

const OrganizationAdminPage = Loadable({
    loader: () => import('../workflows/OrganizationAdminPage/OrganizationAdminPage'),
    loading: () => <LoadingSpinner />
});

const ProjectAdminPage = Loadable({
    loader: () => import('../workflows/ProjectAdminPage/ProjectAdminPage'),
    loading: () => <LoadingSpinner />
});
class App extends Component {
    render() {
        return (
            <Router>
                <Frame>
                    <GenericMessagePopup />
                    <ErrorIndicator />
                    <Navigator />
                    <div className='app-container'>
                        <LoadingIndicator />
                        <AutomaticRedirect />
                        <Route path="/user-authentication" component={UserAuthentication} />
                        <Route path="/search" component={ProjectsPage} />
                        <Route path="/folders" component={FoldersPage} />
                        <Route path="/user-profile" component={UserProfilePage} />
                        <Route path="/permission-requests" component={PermissionRequestsPage} />
                        {/* <Route path="/my-requests" component={MyRequestsPage} /> */}
                        <Route path="/download-history" component={DownloadHistoryPage} />
                        <Route path="/file-upload" component={FileUploadPage} />
                        {/* <Route path="/moderation" component={ModerationPage} /> */}
                        <Route path="/user-admin" component={UserAdminPage} />
                        <Route path="/organization-admin" component={OrganizationAdminPage} />
                        {/* <Route path="/project-admin" component={ProjectAdminPage} /> */}
                        <Route path="/faq" component={FAQPage} />
                        <Route path="/user-guide" component={ManualPage} />
                        {/* Sample Page Mocks   */}
                        <Route path="/dashboard" component={DashboardPage} />
                    </div>
                </Frame>
            </Router>
        );
    }
}

export default withAuthenticator(App, false, [<MyCustomSignin />, <MyCustomSignup />, <MyCustomForgotPassword />]);
