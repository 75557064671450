import React from "react";
import PropTypes from "prop-types";
import loaderAsset from '../../assets/loader/loader.gif';

export default class FloatingLoader extends React.Component {

  render() {
    if (!this.props.show) {
      return null;
    }
    return (
      <div>
        <div className="jnto-modal-wrapper" id="jnto-loader-modal">
          <div className='jnto-loader'>
            <img className='icon' src={loaderAsset} />
            <div className="content">{this.props.children}</div>
          </div>
        </div>
      </div>
    );
  }
}
FloatingLoader.propTypes = {
  show: PropTypes.bool.isRequired
};
