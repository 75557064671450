import React from 'react';
import { useTranslation } from 'react-i18next';
import ModuleRow from '../shared/rows/ModuleRow/ModuleRow';
import { booleanToYesNo, formatYYYMMDD, approvalForExternalUsageUI } from '../../utilities/textUtils';
const AssetContextSectionBrief = ({ assetContext, publicId, orientation, fileSizeInMB, editMode = false, onEdit, duration }) => {
    const { t } = useTranslation();

    // skip out if no context and dont display this component
    if (!assetContext) { return null; }

    const { location, prefecture,
         trimming, color, text, validDate, requestApproval, prePostApproval, approvalForExternalUsage} = assetContext;
    return (
        <>
          <ModuleRow label={t('common.approvalNeededText')} value={booleanToYesNo(requestApproval)} editMode={editMode} fieldType={'boolean'} onEdit={(value)=>{onEdit('requestApproval', value)}}/>
          <ModuleRow label={t('common.prePostApproval')} value={booleanToYesNo(prePostApproval)}  editMode={editMode} fieldType={'boolean'} onEdit={(value)=>{onEdit('prePostApproval', value)}}/>
          <ModuleRow label={t('common.trimming')} value={booleanToYesNo(trimming)} editMode={editMode} fieldType={'boolean'} onEdit={(value)=>{onEdit('trimming', value)}}/>
          <ModuleRow label={t('common.color')} value={booleanToYesNo(color)}  editMode={editMode} fieldType={'boolean'} onEdit={(value)=>{onEdit('color', value)}}/>
          <ModuleRow label={t('common.text')} value={booleanToYesNo(text)}  editMode={editMode} fieldType={'boolean'} onEdit={(value)=>{onEdit('text', value)}}/>
          <ModuleRow label={t('common.expirationDate')} value={(validDate && validDate != -1)? formatYYYMMDD(validDate.toString()) : ''}  editMode={editMode} fieldType={'date'} onEdit={(value)=>{onEdit('validDate', value)}} />
          <ModuleRow label={t('common.location')} value={location} showPopover showCopyOption  editMode={editMode} fieldType={'text'} onEdit={(value)=>{onEdit('location', value)}}/>
          <ModuleRow label={t('common.prefecture')} value={prefecture}  editMode={editMode} fieldType={'select'} fieldValues={'prefecture'} onEdit={(value)=>{onEdit('prefecture', value)}}/>
          <ModuleRow label={t('common.mediaSize')} value={`${fileSizeInMB}MB`}  />
          {duration && <ModuleRow label={t('common.duration')} value={`${duration}${t('common.durationSeconds')}`}  />}
          <ModuleRow label={t('common.approvalForExternalUsage')} value={approvalForExternalUsageUI.hasOwnProperty(approvalForExternalUsage) ? approvalForExternalUsageUI[approvalForExternalUsage] : ''}   editMode={editMode} fieldType={'select'} fieldValues={'externalUse'} onEdit={(value)=>{onEdit('approvalForExternalUsage', value)}}/>
        </>);
};

export default AssetContextSectionBrief;
