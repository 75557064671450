import React from "react";
import arrowIcon from '../../../assets/icons/icon_add-tag.svg';
// We can remove when implementation is finished

class ManualFloatingBar extends React.Component {
  constructor() {
    super();
  }

  componentDidMount() {
  }

  _generateButton(button) {
    if(typeof button.callback === 'function') {
      return (
        <a key={button.text} onClick={ () => button.callback()}>
          <div className={`floating-bar-btn-container ${button.position}`}>
            <div className={`floating-bar-btn-container-img-item`}>
              <img src={'https://asset.japan.travel/image/upload/v1611201314/dam-admin/manual/icon_arrow_left_red.svg'} />
            </div>
            <div className='floating-bar-btn-container-content-item'>
              {button.text}
            </div>
          </div>
        </a>
      )
    } else {
      return (
        <a key={button.text} href={button.link}>
          <div className={`floating-bar-btn-container ${button.position}`}>
            <div className={`floating-bar-btn-container-img-item`}>
                <img src={'https://asset.japan.travel/image/upload/v1611201314/dam-admin/manual/icon_arrow_left_red.svg'} />
            </div>
            <div className='floating-bar-btn-container-content-item'>
              {button.text}
            </div>
          </div>
        </a>
      )
    }
  }

  generateLeftButton() {
    const buttonOptions = this.props.buttons 

    const buttonItem = buttonOptions.find((button)=> {
      return button.position === 'left'
    })

    if(!buttonItem){
      return(
       <div className={`floating-bar-btn-container left`}>
        </div>
      )
    }

    return this._generateButton(buttonItem)

  }

  generateRightButton() {
    const buttonOptions = this.props.buttons 
    const buttonItem = buttonOptions.find((button)=> {
      return button.position === 'right'
    })

    if(!buttonItem){
      return;
    }

    return this._generateButton(buttonItem)
  }

  render() {
    return (
      <div className='floating-nav-bar'>
        <div className='manual-floating-nav-bar-content'>
          {this.generateLeftButton()}
          {this.generateRightButton()}
        </div>
      </div>
    )
  }
}

export default ManualFloatingBar;
