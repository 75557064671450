import * as types from "../constants/action-types";
import initialState from './initialState';

export default function navigationReducer(state = initialState.navigation, action) {
    let newState;
    switch (action.type) {
        case types.SWITCH_AUTH_PAGE:
            newState = {
                ...state,
                currentAuthPage: action.pageName,
            }
            break;
        case types.SWITCH_PAGE_SUCCESS:
            newState = {
                ...state,
                currentPage: action.pageName,
            }
            break;
        default:
            break;
    }

    return newState || state;
}