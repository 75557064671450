import React from "react";
import { Switch, withRouter, Route, Redirect } from 'react-router-dom';
import {Page} from "@shopify/polaris";

class ToggleContainer extends React.Component {
  constructor() {
    super();
    this.state = {
      selectedTabIndex: 0
    };
    // bind handlers to this
    this.handleChange = this.handleChange.bind(this);
  }

  handleChange(event) {
    const { history, match, tabs } = this.props;
    let selectedTabId = null;
    let index = null
    if(event.target.checked) {
      index = 1;
    } else {
      index = 0;
    }
    selectedTabId = tabs[index].id;
    this.setState({ index }, () => {
      history.push(`${match.path}/${selectedTabId}`);
    });
  }

  render() {
    const { match, tabs, defaultTabIndex } = this.props;
    const routes = tabs.map(({ id, component, data }) => {
      const DynamicComponent = component
      return (
      <Route key={id} path={`${match.path}/${id}`}
             render={(props) => {
               return <DynamicComponent {...props} {...data} />
             }}
      />)
    });
    routes.push(<Redirect key='default-tab' from={`${match.path}`} to={`${match.path}/${tabs[defaultTabIndex].id}`} />);
    return (
      <>
        <div className='toggle-container'>
          <div className="custom-toggle-switch">
            <input id="status"
                   type="checkbox"
                   name="status"
                   onChange={this.handleChange}
            />
              <label htmlFor="status">
                <div className="status-switch"
                     data-unchecked={tabs[0].name}
                     data-checked={tabs[1].name}>
                </div>
              </label>
          </div>
        </div>
        <Page fullWidth>
          <Switch>{routes}</Switch>
        </Page>
      </>
    )
  }
}

export default withRouter(ToggleContainer);

