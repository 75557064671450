import React from 'react';

class WithConfirmation extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            open: false,
            callback: null,
        };

        this.show = this.show.bind(this);
        this.cancel = this.cancel.bind(this);
        this.confirm = this.confirm.bind(this);
    }

    show(callback) {
        this.setState({
            open: true,
            callback,
        });
    }

    cancel() {
        this.setState({
            open: false,
            callback: null,
        });
    }

    confirm() {
        this.state.callback();
        this.cancel();
    }

    render() {
        const { open } = this.state;
        const { children } = this.props;

        return (
            <>
                {children(this.show, this.confirm, this.cancel, open)}
            </>);
    }
}

export default WithConfirmation;
