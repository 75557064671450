import { createAdvancedUser, getUserGroupNames, getUsersByOrganizationIds, removeUsers, searchAllUsers } from "../../api/vermillionApi/userManagementApi";
import { userByUploadId } from "../../api/vermillionApi/historyManagementApi";
import { createProject, getAdminProjects } from '../../api/vermillionApi/projectManagementApi';
import * as types from "../constants/action-types";
import CreateAdvancedUserRequest from '../../models/requests/CreateAdvancedUserRequest';
import { searchAllOrganizations, createOrganization, removeOrganizations } from '../../api/vermillionApi/organizationManagementApi';
import CreateOrganizationRequest from '../../models/requests/CreateOrganizationRequest';

export const userSigninSuccess = (signedInUserResponse, userInfoComposite) => {
    return { type: types.USER_SIGN_IN_SUCCESS, signedInUserResponse, userInfoComposite };
};

export const userSignupSuccess = (signedInUserResponse, userInfoComposite) => {
    return { type: types.USER_SIGN_UP_SUCCESS, signedInUserResponse, userInfoComposite };
};

export const userSignOutSuccess = () => {
    return { type: types.USER_SIGN_OUT_SUCCESS };
};

export const getUploadUserSuccess = (uploadUserInformation) => {
    return { type: types.GET_ASSET_UPLOAD_USER_SUCCESS, uploadUserInformation };
};

export const createAdvancedUserSuccess = () => {
    return { type: types.CREATE_ADVANCED_USER_SUCCESS };
};

export const getUserGroupNamesSuccess = (userGroupNames) => {
    return { type: types.GET_USER_GROUP_NAMES_SUCCESS, userGroupNames };
};

export const getUsersByEmailSuccess = (users) => {
    return { type: types.GET_USERS_BY_EMAIL_SUCCESS, users };
};

export const removeUsersSuccess = (userIds) => {
    return { type: types.REMOVE_USERS_SUCCESS, userIds };
};

export const getUsersByOrganizationIdsSuccess = (users) => {
    return { type: types.GET_USERS_BY_ORGANIZATION_IDS_SUCCESS, users };
};

export const getOrganizationUserIdsSuccess = (organizations, users) => {
    return { type: types.GET_ORGANIZATION_USER_IDS_SUCCESS, organizations, users }
};

export const searchOrganizationsSuccess = (organizationComposites) => {
    return { type: types.GET_ORGANIZATIONS_SUCCESS, organizationComposites };
}

export const searchUsersSuccess = (userComposites) => {
    return { type: types.GET_ALL_USERS_SUCCESS, userComposites };
}

export const createOrganizationSuccess = () => {
    return { type: types.CREATE_ORGANIZATION_SUCCESS };
}

export const getAdminProjectsSuccess = (adminProjects) => {
    return { type: types.GET_ADMIN_PROJECTS_SUCCESS, adminProjects };
};

export const createProjectSuccess = () => {
    return { type: types.CREATE_PROJECT_SUCCESS };
};

export const removeOrganizationsSuccess = organizationIdsRemoved => {
    return {
        type: types.REMOVE_ORGANIZATIONS_SUCCESS,
        organizationIdsRemoved,
    };
};

export const getUserGroupNamessAction = () => {
    return async (dispatch) => {
        const userGroupNames = await getUserGroupNames(dispatch)
        dispatch(getUserGroupNamesSuccess(userGroupNames));
        return;
    };
}

export const getUsersByOrganizationIdsAction = (organizationIds) => {
    return async (dispatch) => {
        const users = await getUsersByOrganizationIds(organizationIds, dispatch);
        dispatch(getUsersByOrganizationIdsSuccess(users));
        return;
    };
}

export const createdAdvancedUserAction = (userObj) => {
    return async (dispatch) => {
        const createdUserInfoComposite = await createAdvancedUser(new CreateAdvancedUserRequest(userObj), dispatch)
        dispatch(createAdvancedUserSuccess(createdUserInfoComposite));
        return;
    };
}

export const removeUsersAction = (usernames) => {
    return async (dispatch) => {
        const removedUserIds = await removeUsers(usernames, dispatch)
        dispatch(removeUsersSuccess(removedUserIds));
        return;
    };
}

export const getAllOrganizations = () => {
    return async (dispatch) => {
        const organizationComposites = await searchAllOrganizations(dispatch);
        dispatch(searchOrganizationsSuccess(organizationComposites));
        return;
    };
}

export const getAllUsers = () => {
    return async (dispatch) => {
        const userInfoComposites = await searchAllUsers(dispatch);
        dispatch(searchUsersSuccess(userInfoComposites));
    }
}

export const getUploadUserForAssetId = (assetId) => {
    return async (dispatch) => {
        const uploadUserInformation = await userByUploadId(assetId, dispatch);
        return uploadUserInformation;
    };
}

export const getOrganizationUserIds = () => {
    return async (dispatch) => {
        const organizationComposites = await searchAllOrganizations(dispatch);
        const organizationIds = organizationComposites.map(organization => organization.id);
        const usersForOrganizations = await getUsersByOrganizationIds(organizationIds, dispatch);
        dispatch(getOrganizationUserIdsSuccess(organizationComposites, usersForOrganizations));
    };
}

export const createOrganizationAction = (organizationObj) => {
    return async (dispatch) => {
        const organizationCreated = await createOrganization(new CreateOrganizationRequest(organizationObj), dispatch);
        dispatch(createOrganizationSuccess(organizationCreated));
        return;
    };
}

export const getAdminProjectsAction = () => {
    return async (dispatch) => {
        const projects = await getAdminProjects(dispatch);
        projects.sort((projA, projB) => (projA.projectName.toUpperCase() > projB.projectName.toUpperCase())
            - (projB.projectName.toUpperCase() > projA.projectName.toUpperCase()));
        dispatch(getAdminProjectsSuccess(projects));
        return;
    };
}

export const createProjectAction = (projectName) => {
    return async (dispatch) => {
        await createProject(projectName, dispatch);
        dispatch(createProjectSuccess());
        return;
    };
}

export const removeOrganizationsAction = organizationIds => {
    return async (dispatch) => {
        const organizationIdsRemoved = await removeOrganizations(organizationIds, dispatch);
        dispatch(removeOrganizationsSuccess(organizationIdsRemoved));
        return;
    }
}