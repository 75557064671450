import * as types from "../constants/action-types";
import initialState from './initialState';
import AssetState from './AssetState';

export default function assetReducer(state = initialState.asset, action) {
    const INCREMENT = 10;
    let newState, builder = new AssetState.builder().init({ ...state }, INCREMENT);
    switch (action.type) {
        case types.DELETE_ASSET_SUCCESS:
            break;
        case types.SEARCH_TEXT_UPDATED:
            newState = builder.forSearchText(action.searchText).build();
            break;
        case types.GET_BLOG_POSTS_SUCCESS:
            newState = builder.forSearchResults(action.assets, action.searchText).build();
            break;
        case types.GET_NEXT_PAGE_SUCCESS:
            newState = builder.forNextPageSearchResults(action.assets, action.numberOfHits, action.searchText, action.newPageNumber, action.imageCount, action.videoCount).build();
            break;
        case types.SELECT_ASSET_SUCCESS:
            newState = builder.forSelectedAsset(action.asset).build();
            break;
        case types.GET_DOWNLOAD_LINK_SUCCESS:
            newState = builder.forDownloadLink(action.downloadLink).build();
            break;
        case types.GET_AUTO_TAGS_SUCCESS:
            newState = builder.forAutoTags(action.autoTags).build();
            break;
        case types.CLEAR_AUTO_TAGS_SUCCESS:
            newState = builder.forAutoTags([]).build();
            break;
        case types.CLEAR_ASSETS_SUCCESS:
            newState = builder.forClearAssets().build();
            break;
        case types.GET_TOTAL_NUMBER_IMAGES_JOMC_SUCCESS:
            newState = builder.forTotalNumberImagesJOMC(action.numberOfHits).build()
            break;
        case types.GET_TOTAL_NUMBER_IMAGES_JNTO_SUCCESS:
            newState = builder.forTotalNumberImagesJNTO(action.numberOfHits).build()
            break;
        case types.GET_TOTAL_NUMBER_VIDEOS_JNTO_SUCCESS:
            newState = builder.forTotalNumberVideosJNTO(action.numberOfHits).build()
            break;
        case types.GET_TOTAL_NUMBER_VIDEOS_JOMC_SUCCESS:
            newState = builder.forTotalNumberVideosJOMC(action.numberOfHits).build()
            break;
        default:
            break;
    }

    return newState || state;
}