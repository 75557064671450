import * as types from "../constants/action-types";
import initialState from './initialState';

export default function fileUploadGridReducer(state = initialState.fileUploadGrid, action) {
    let newState;
    switch (action.type) {
        case types.ADD_FILE_UPLOAD_GRID_ERROR_MESSAGE_SUCCESS:
            newState = {
                ...state,
                errorMessages: [...state.errorMessages, action.newErrorMessage]
            };
            break;
        case types.REMOVE_FILE_UPLOAD_GRID_ERROR_MESSAGE_SUCCESS:
            const clonedErrorMsgs = state.errorMessages.slice();
            const indexOfErrMsg = clonedErrorMsgs.indexOf(action.newErrorMessage);
            if (indexOfErrMsg > -1) {
                clonedErrorMsgs.splice(indexOfErrMsg, 1);
                newState = {
                    ...state,
                    errorMessages: clonedErrorMsgs
                };
            }
            break;
        case types.ADD_STATIC_FILE_UPLOAD_GRID_ERROR_MESSAGE_SUCCESS:
            const clonedErr = state.errorMessages.slice();
            const indexOfErr = clonedErr.indexOf(action.newErrorMessage);
            const newErrorMessage = (indexOfErr <= 0) ? [...state.errorMessages, action.newErrorMessage] : state.errorMessages;
            newState = {
                ...state,
                errorMessages: newErrorMessage
            };
            break;
        case types.CLEAR_UPLOAD_GRID_ERROR_MESSAGES_SUCCESS:
            newState = {
                ...state,
                errorMessages: [],
            };
            break;
        case types.BEGIN_FILE_UPLOAD_GRID_AJAX_CALL:
            newState = {
                ...state,
                fileUploadInProgress: 'in_progress'
            };
            break;
        case types.FILE_UPLOAD_GRID_AJAX_CALL_SUCCESS:
            newState = {
                ...state,
                fileUploadInProgress: 'complete'
            };
            break;
        case types.FILE_UPLOAD_GRID_AJAX_CALL_ERROR:
            newState = {
                ...state,
                fileUploadInProgress: 'error'
            };
            break;
        case types.CLEAR_UPLOAD_GRID_STATUS_SUCCESS:
            newState = {
                ...state,
                fileUploadInProgress: initialState.fileUploadGrid.fileUploadInProgress
            };
            break;
        default:
            break;
    }

    return newState || state;
}