import AlgoliaAssetItem from './AlgoliaAssetItem';

class AlgoliaSearchResponse {
    constructor(items, numberOfHits, imageCount, videoCount) {
        this.items = items;
        this.numberOfHits = numberOfHits;
        this.imageCount = imageCount;
        this.videoCount = videoCount;
    }

    static fromResponse(rawResponse) {
        var videoCount = 0;
        var imageCount = 0;
        if(rawResponse.facets && rawResponse.facets.format){
            for(var fac in rawResponse.facets.format){
                if(fac === 'mp4'){
                    videoCount += rawResponse.facets.format[fac];
                }
                else{
                    imageCount += rawResponse.facets.format[fac];
                }
            }
        }
        const responseEmpty = (!rawResponse || rawResponse.length === 0);
        const items = responseEmpty ? [] : rawResponse.hits.map(rawAssetItem => new AlgoliaAssetItem(rawAssetItem));
        const numberOfHits = responseEmpty ? 0 : rawResponse.nbHits;
        return new AlgoliaSearchResponse(items, numberOfHits, imageCount, videoCount);
    }
}

export default AlgoliaSearchResponse;
