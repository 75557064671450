import * as types from "../constants/action-types";
import initialState from './initialState';

export default function organizationReducer(state = initialState.organization, action) {
    let newState;
    switch (action.type) {
        case types.GET_ORGANIZATION_BY_ID_SUCCESS:
            newState = {
                ...state,
                organization: action.organizationComposite,
            };
            break;
        case types.GET_ORGANIZATIONS_SUCCESS:
            newState = {
                ...state,
                allOrganizations: action.organizationComposites,
            };
            break;
        default:
            break;
    }

    return newState || state;
};