import React from "react";
import {TextField} from "@shopify/polaris";
import ToggleBtn from "../../../FormElements/ToggleBtn";
import {approvalForExternalUsageToBackend}from "../../../../utilities/textUtils";
import {PREFECTURE_FILTERS, DEPARTMENT_IN_CHARGE, SEASONS, EXTERNAL_USAGE}from "../../../../utilities/constants";


const ModuleRowInput = ({fieldType = '', value, fieldValues, t, handleChange}) => {


  const uniqueId =  Math.random().toString(16).slice(2)

  const handleTextChange = (value) => {
    handleChange(value)
  }

  const handleBooleanChange = (event) => {
    handleChange((!(event.target.checked)).toString())
      }

  const handleFilterChange = (value, approvalForExternalUsage) => {
    if(approvalForExternalUsage){
      value= approvalForExternalUsageToBackend(value)
    }
    handleChange(value)

}

  const handleYearChange = (year, date) => {
    var dateString;
    var dateUpdated = date
    if(dateUpdated.length !== 3){
      dateUpdated=[year, "", ""]
    }
    else{
      dateUpdated[0] = year;
    }
    dateString = dateUpdated.join("-")
    handleChange(dateString)

  }

  const handleMonthChange = (month, date) => {
    if(month.length === 1){
      month = "0" + month;
    }
    var dateString;
    var dateUpdated = date
    if(dateUpdated.length !== 3){
      dateUpdated=["", month, ""]
    }
    else{
      dateUpdated[1] = month;
    }
    dateString = dateUpdated.join("-")
    handleChange(dateString)
  }

  const handleDayChange = (day, date) => {
    var dateString
    var dateUpdated = date
    if(dateUpdated.length !== 3){
      dateUpdated=["", "", day]
    }
    else{
      dateUpdated[2] = day;
    }
    dateString = dateUpdated.join("-")
    handleChange(dateString)
  }



  
  const options = []
  var externalUseField= false;

  if(fieldValues == 'externalUse'){
    externalUseField= true;
    for(var pref of EXTERNAL_USAGE){
      options.push( <option value={pref}>{t(pref)}</option>)
  }
}

  if(fieldValues == 'departmentInCharge'){

    for(var pref of DEPARTMENT_IN_CHARGE){
      options.push( <option value={pref}>{t(pref)}</option>)
    
  }
}


  if(fieldValues == 'season'){

    for(var pref of SEASONS){
      options.push( <option value={pref}>{t(pref)}</option>)
    
  }
}

  if(fieldValues == 'prefecture'){

    for(var pref of PREFECTURE_FILTERS){
      options.push( <option value={pref}>{t(pref)}</option>)
    
  }
}

var yearValue;
var monthValue;
var dayValue;
const yearsOptions = []
const days = []
const months = []
var dateValues;

if(fieldType === 'date'){
  dateValues = value.split("-");
  if(dateValues.length=== 3){
    yearValue = parseInt(dateValues[0])
    monthValue = parseInt(dateValues[1])
    dayValue = parseInt(dateValues[2])
  }

months.push(<option value={""}></option>)
for(var month = 1; month < 13; month ++){
  months.push(<option value={month}>{month}</option>)
}

const years = [2023, 2024, 2025]
yearsOptions.push(<option value={""}></option>)

if(yearValue && years.indexOf(yearValue) < 0){
  yearsOptions.push(<option value={yearValue}>{yearValue}</option>)

}
for(var year of years){
  yearsOptions.push(<option value={year}>{year}</option>)
}

days.push(<option value={""}></option>)
for(var day = 1; day < 32; day ++){
  days.push(<option value={day}>{day}</option>)
}
}



const isChecked = function(value) {
  const checked = (value === 'No' || value === false);

  return (
      {checked: checked}
  );
}


  return (
    <div className={'jnto-generic-field'}>
      { fieldType === 'text' &&
        <div className={'field-item-block'}>
          <div className={'field-items'}>
                      <span className={'field text-field'}>
                        <TextField
                          type="text"
                          value={value}
                          onChange={handleTextChange}
                        />
                      </span>
          </div>
        </div>
      }
      { fieldType === 'select' &&
        <div className={'field-item-block'}>
          <div className={'field-items'}>
            <div className='field-group'>
              <div className='jnto-selector-container'>
                <select value={value} name='select-input' onChange={e => handleFilterChange(e.target.value, externalUseField)}>
                  {options}
                </select>
              </div>
            </div>
          </div>
        </div>
      }
      { fieldType === 'boolean' &&
        <div>
          <div className='toggle-container'>
            <div className="custom-toggle-switch slim">
              <input className={'status'} id={`status_${uniqueId}`}
                    field ={value}
                     type="checkbox"
                     name="status"
                     onChange={handleBooleanChange}
                     {...isChecked(value)}
              />
              <label htmlFor={`status_${uniqueId}`}>
                <div className="status-switch"
                     data-unchecked={'Yes'}
                     data-checked={'No'}>
                </div>
              </label>
            </div>
          </div>
        </div>
      }
      { fieldType === 'date' &&
        <div className={'field-item-block'}>
          <div className={'field-items'}>
            <div className='field-group date-type'>
              <div className='jnto-selector-container'>
                <select value={yearValue} name='select-input' onChange={(e)=> {handleYearChange(e.target.value, dateValues)}}>
                    {yearsOptions}
                </select>
              </div>
              <div className='jnto-selector-container'>
                <select value={monthValue} name='select-input' onChange={(e)=> {handleMonthChange(e.target.value, dateValues)}}>
                  {months}
                </select>
              </div>
              <div className='jnto-selector-container'>
                <select value={dayValue} name='select-input' onChange={(e)=> {handleDayChange(e.target.value, dateValues)}}>
                  {days}
                </select>
              </div>
            </div>
          </div>
        </div>
      }
    </div>
  )
}

export default ModuleRowInput;
