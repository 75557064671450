import React from 'react';
import { withTranslation } from 'react-i18next';
import GenericGallery from '../shared/galleries/GenericGallery/GenericGallery';
import ProjectCard from '../ProjectCard/ProjectCard';

const ProjectGallery = ({ onSelectProject, projects, t ,　userGroups}) => {
    const projectCards = projects ? projects.map(project =>
        <div key={project.id}><ProjectCard project={project} onSelectProject={onSelectProject} userGroups = {userGroups}　 /></div>) : [];

    return (<GenericGallery items={projectCards} displayText={t('workflows.projects.selectProjectToViewAssets')} />);
};

export default withTranslation()(ProjectGallery);
