import React from "react";
import { createQuerySuggestionsPlugin } from '@algolia/autocomplete-plugin-query-suggestions';
import { autocomplete } from '@algolia/autocomplete-js';
import algoliaSearch from 'algoliasearch';
import PropTypes from "prop-types";
import queryString from 'query-string';
import { withTranslation } from 'react-i18next';
import { withRouter } from 'react-router-dom';
import CollapsibleFilterBar from "./CollapsibleFilterBar";
import ToggleBtn from "../../FormElements/ToggleBtn";
import '@algolia/autocomplete-theme-classic/dist/theme.css';
import config from '../../../config';
import i18n from 'i18next';
import {Tooltip} from '@shopify/polaris';
import toolTipIcon from '../../../assets/icons/icon hint.svg';
import {PREFECTURE_FILTERS}from "../../../utilities/constants";


const initialState={
  reset: true,
  checked: false,
  checkedImage: false,
  checkedVideo: false,
  checkedMediaType: [],
  checkedLandscape: false,
  checkedPortrait: false,
  checkedSquare: false,
  checkedOrientation: [],
  checkedPrefecture: [],
  checkedAllSeason: null,
  checkedSpring: false,
  checkedSummer: false,
  checkedAutumn: false,
  checkedWinter: false,
  checkedSeason: [],
  downloadPermissionYesChecked: false,
  downloadPermissionNoChecked: false,
  prePostApprovalYesChecked: false,
  prePostApprovalNoChecked: false,
  checkedValidDate: undefined,
  checkedexpireDateNo: false,
  checkedexpireDateYes: false,
  checkedExpireDate: [],
  tag1: false,
  tag2: false,
  tag3: false,
  tag4: false,
  tag5: false,
  tag6: false,
  tag7: false,
  tag8: false,
  tag9: false,
  tag10: false,
  tag11: false,
  tag12: false,
  tag13: false,
  tag14: false,
  tag15: false,
  tag16: false,
  tag17: false,
  tag18: false,
  tag19: false,
  tag20: false,
  checkedTags: [],
  filterTranslations:[],
  // textField: i18n.t('filters.enterAssetId'),
  textField: "",
  dateTextField: '',
  selectedDates: undefined
}
class FilterBar extends React.Component {

  constructor() {
    super();
    this.onUpdateSearchText = this.onUpdateSearchText.bind(this);
    this.handleTextChange = this.handleTextChange.bind(this);
    this.onSubmitSearch = this.onSubmitSearch.bind(this);
    this.handlFilterChange = this.handlFilterChange.bind(this);
    this.handleEnterOnSearch = this.handleEnterOnSearch.bind(this);
    this.state = {
      reset: true,
      searchText: '',
      initialFilters: true,
      checked: false,
      checkedImage: false,
      checkedVideo: false,
      checkedMediaType: [],
      checkedLandscape: false,
      checkedPortrait: false,
      checkedSquare: false,
      checkedOrientation: [],
      checkedPrefecture: [],
      checkedAllSeason: null,
      checkedSpring: false,
      checkedSummer: false,
      checkedAutumn: false,
      checkedWinter: false,
      checkedSeason: [],
      downloadPermissionYesChecked: false,
      downloadPermissionNoChecked: false,
      checkedPermission: [],
      prePostApprovalYesChecked: false,
      prePostApprovalNoChecked: false,
      checkedExternalUseApproved: false,
      checkedExternalUseDenied: false,
      checkedExternalUseContact: false,
      checkedExternalUse: [],
      checkedexpireDateNo: false,
      checkedexpireDateYes: false,
      checkedExpireDate: [],
      checkedApproval:[],
      filterTranslations: [],
      tag1: false,
      tag2: false,
      tag3: false,
      tag4: false,
      tag5: false,
      tag6: false,
      tag7: false,
      tag8: false,
      tag9: false,
      tag10: false,
      tag11: false,
      tag12: false,
      tag13: false,
      tag14: false,
      tag15: false,
      tag16: false,
      tag17: false,
      tag18: false,
      tag19: false,
      tag20: false,
      checkedTags: [],
    }
    this.submitBtnEl = React.createRef()
  }

  handleTextChange(value) {
    const { onSelectSortBy } = this.props;
    this.setState({ textField: value })
    onSelectSortBy(value, 'dam_id')
  }

  componentDidMount() {
    this.setState({
      searchText: this.props.searchText,
    });


   var onUpdateSearchText = (e) =>{
      this.setState({
        searchText: e,
      });
    }

    const searchClient = algoliaSearch(config.algolia.APP_ID, config.algolia.API_KEY);
    const querySuggestionsPlugin = createQuerySuggestionsPlugin({
      searchClient,
      indexName: config.algolia.INDEX_NAME_SUGGESTIONS,
      getSearchParams({ state }) {
        return { hitsPerPage: 10 };
      },
    });
    const inputText = queryString.parse(this.props.history.location.search).inputText;
    autocomplete({
            container: '#autocomplete',
            placeholder: i18n.t('common.navigationSearch'),
            plugins: [querySuggestionsPlugin],
            openOnFocus: true,
            initialState:{
              query:inputText
            },
            onStateChange({state}){
              onUpdateSearchText(state.query)
            }
          })
  }

  componentDidUpdate() {
    // fixme: this is risky as the autocomplete library could change the element id '"autocomplete-0-input', should do a look up to find the input field
    // const alAutoCompleteEl = document.getElementById("autocomplete-0-input");
    // alAutoCompleteEl.addEventListener("keydown", this.handleEnterOnSearch);
  }

  componentDidUpdate() {
    // fixme: this is risky as the autocomplete library could change the element id '"autocomplete-0-input', should do a look up to find the input field
    const alAutoCompleteEl = document.getElementById("autocomplete-0-input");
    if(alAutoCompleteEl && !alAutoCompleteEl.getAttribute('listener')){
      alAutoCompleteEl.setAttribute('listener', 'true');
      alAutoCompleteEl.addEventListener("keydown", this.handleEnterOnSearch);
    }
  }

  handleEnterOnSearch(e) {
    if (e.code === "Enter") {
      this.onSubmitSearch(e)
    }
  }

  onUpdateSearchText(e) {
    this.setState({
      searchText: e.target.value,
    });
  }

  resetFilters(){
    this.setState(
      initialState
    );
  }

  handlFilterChange(filt, trans, value) {
    const { onSelectSortBy } = this.props;
    this.setState({reset:false})
    //Handle translation array
    var prefectureTranslations = [];
    if(trans.indexOf('prefecturefilters') >= 0){
      prefectureTranslations = this.state.filterTranslations.filter(item => !item.includes('prefecturefilters'))
      if(trans === "prefecturefilters."){
        this.setState({'filterTranslations': prefectureTranslations})
      }
      else{
        this.setState({ filterTranslations: [...prefectureTranslations, trans ]})
      }
    }
     else if(trans === 'filters.expireDate'){
       if(!(this.state.filterTranslations.indexOf(trans) >= 0) ){
        this.setState({ filterTranslations: [...this.state.filterTranslations, trans] })
       }
     }
     else if(this.state.filterTranslations.indexOf(trans) >= 0 ){
      var filterTranslations = this.state.filterTranslations.filter(item => item !== trans)
      this.setState({'filterTranslations': filterTranslations})
    }
    else{
      this.setState({ filterTranslations: [...this.state.filterTranslations, trans] })

    }
    //updated chekcboxes and algolia request filters
    if (filt === 'video') {
      if (this.state.checkedVideo) {
        var types = this.state.checkedMediaType.filter(item => item != 'mp4')
        this.setState({ checkedMediaType: types })
        onSelectSortBy(types, 'mediatype')
      }
      else {
        this.setState({ checkedMediaType: [...this.state.checkedMediaType, 'mp4'] })
        onSelectSortBy([...this.state.checkedMediaType, 'mp4'], 'mediatype')
      }
      this.setState({ checkedVideo: !this.state.checkedVideo })
    }
    if (filt === 'image') {
      if (this.state.checkedImage) {
        var types = this.state.checkedMediaType.filter(item => item != 'jpg')
        this.setState({ checkedMediaType: types })
        onSelectSortBy(types, 'mediatype')
      }
      else {
        this.setState({ checkedMediaType: [...this.state.checkedMediaType, 'jpg'] })
        onSelectSortBy([...this.state.checkedMediaType, 'jpg'], 'mediatype')
      }
      this.setState({ checkedImage: !this.state.checkedImage })
    }
    if (filt === 'landscape') {
      if (this.state.checkedLandscape) {
        var types = this.state.checkedOrientation.filter(item => item != 'landscape')
        this.setState({ checkedOrientation: types })
        onSelectSortBy(types, 'aspect')
      }
      else {
        this.setState({ checkedOrientation: [...this.state.checkedOrientation, 'landscape'] })
        onSelectSortBy([...this.state.checkedOrientation, 'landscape'], 'aspect')
      }
      this.setState({ checkedLandscape: !this.state.checkedLandscape })
    }
    if (filt === 'portrait') {
      if (this.state.checkedPortrait) {
        var types = this.state.checkedOrientation.filter(item => item != 'portrait')
        this.setState({ checkedOrientation: types })
        onSelectSortBy(types, 'aspect')
      }
      else {
        this.setState({ checkedOrientation: [...this.state.checkedOrientation, 'portrait'] })
        onSelectSortBy([...this.state.checkedOrientation, 'portrait'], 'aspect')
      }
      this.setState({ checkedPortrait: !this.state.checkedPortrait })
    }
    if (filt === 'square') {
      if (this.state.checkedSquare) {
        var types = this.state.checkedOrientation.filter(item => item != 'square')
        this.setState({ checkedOrientation: types })
        onSelectSortBy(types, 'aspect')
      }
      else {
        this.setState({ checkedOrientation: [...this.state.checkedOrientation, 'square'] })
        onSelectSortBy([...this.state.checkedOrientation, 'square'], 'aspect')
      }
      this.setState({ checkedSquare: !this.state.checkedSquare })
    }
    if (PREFECTURE_FILTERS.indexOf(filt) >= 0) {
      if(this.state.checkedPrefecture.indexOf(filt) >= 0){
        var prefs = this.state.checkedPrefecture.filter(item => item != filt)
        this.setState({ checkedPrefecture: prefs })
        onSelectSortBy(prefs, 'prefecture')
      }
      else{
        this.setState({ checkedPrefecture: [...this.state.checkedPrefecture, filt] })
        onSelectSortBy([...this.state.checkedPrefecture, filt], 'prefecture')
      }
    }
    if (filt === 'spring') {
      if (this.state.checkedSpring) {
        var types = this.state.checkedSeason.filter(item => item != 'spring')
        this.setState({ checkedSeason: types })
        onSelectSortBy(types, 'season')
      }
      else {
        this.setState({ checkedSeason: [...this.state.checkedSeason, 'spring'] })
        onSelectSortBy([...this.state.checkedSeason, 'spring'], 'season')
      }
      this.setState({ checkedSpring: !this.state.checkedSpring })
    }
    if (filt === 'summer') {
      if (this.state.checkedSummer) {
        var types = this.state.checkedSeason.filter(item => item != 'summer')
        this.setState({ checkedSeason: types })
        onSelectSortBy(types, 'season')
      }
      else {
        this.setState({ checkedSeason: [...this.state.checkedSeason, 'summer'] })
        onSelectSortBy([...this.state.checkedSeason, 'summer'], 'season')
      }
      this.setState({ checkedSummer: !this.state.checkedSummer })
    }
    if (filt === 'autumn') {
      if (this.state.checkedAutumn) {
        var types = this.state.checkedSeason.filter(item => item != 'autumn')
        this.setState({ checkedSeason: types })
        onSelectSortBy(types, 'season')
      }
      else {
        this.setState({ checkedSeason: [...this.state.checkedSeason, 'autumn'] })
        onSelectSortBy([...this.state.checkedSeason, 'autumn'], 'season')
      }
      this.setState({ checkedAutumn: !this.state.checkedAutumn })
    }
    if (filt === 'winter') {
      if (this.state.checkedWinter) {
        var types = this.state.checkedSeason.filter(item => item != 'winter')
        this.setState({ checkedSeason: types })
        onSelectSortBy(types, 'season')
      }
      else {
        this.setState({ checkedSeason: [...this.state.checkedSeason, 'winter'] })
        onSelectSortBy([...this.state.checkedSeason, 'winter'], 'season')
      }
      this.setState({ checkedWinter: !this.state.checkedWinter })
    }
    if (filt === 'allSeasons') {
      if (this.state.checkedAllSeason) {
        var types = this.state.checkedSeason.filter(item => item != 'All Season')
        this.setState({ checkedSeason: types })
        onSelectSortBy(types, 'season')
      }
      else {
        this.setState({ checkedSeason: [...this.state.checkedSeason, 'All Season'] })
        onSelectSortBy([...this.state.checkedSeason, 'All Season'], 'season')
      }
      this.setState({ checkedAllSeason: !this.state.checkedAllSeason })
    }
    if (filt === 'downloadPermissionYes') {
      if (this.state.downloadPermissionYesChecked) {
        var types = this.state.checkedPermission.filter(item => item != 'true')
        this.setState({ checkedPermission: types })
        onSelectSortBy(types, 'downloadPermission')
      }
      else {
        this.setState({ checkedPermission: [...this.state.checkedPermission, 'true'] })
        onSelectSortBy([...this.state.checkedPermission, 'true'], 'downloadPermission')
      }
      this.setState({ downloadPermissionYesChecked: !this.state.downloadPermissionYesChecked })
    }
    if (filt === 'downloadPermissionNo') {
      if (this.state.downloadPermissionNoChecked) {
        var types = this.state.checkedPermission.filter(item => item != 'false')
        this.setState({ checkedPermission: types })
        onSelectSortBy(types, 'downloadPermission')
      }
      else {
        this.setState({ checkedPermission: [...this.state.checkedPermission, 'false'] })
        onSelectSortBy([...this.state.checkedPermission, 'false'], 'downloadPermission')
      }
      this.setState({ downloadPermissionNoChecked: !this.state.downloadPermissionNoChecked })
    }
    if (filt === 'prePostApprovalYes') {
      if (this.state.prePostApprovalYesChecked) {
        var types = this.state.checkedApproval.filter(item => item != 'true')
        this.setState({ checkedApproval: types })
        onSelectSortBy(types, 'prePostApproval')
      }
      else {
        this.setState({ checkedApproval: [...this.state.checkedApproval, 'true'] })
        onSelectSortBy([...this.state.checkedApproval, 'true'], 'prePostApproval')
      }
      this.setState({ prePostApprovalYesChecked: !this.state.prePostApprovalYesChecked })
    }
    if (filt === 'prePostApprovalNo') {
      if (this.state.prePostApprovalNoChecked) {
        var types = this.state.checkedApproval.filter(item => item != 'false')
        this.setState({ checkedApproval: types })
        onSelectSortBy(types, 'prePostApproval')
      }
      else {
        this.setState({ checkedApproval: [...this.state.checkedApproval, 'false'] })
        onSelectSortBy([...this.state.checkedApproval, 'false'], 'prePostApproval')
      }
      this.setState({ prePostApprovalNoChecked: !this.state.prePostApprovalNoChecked })
    }
    if (filt === 'expireDateYes') {
      if (this.state.checkedexpireDateYes) {
        var types = this.state.checkedExpireDate.filter(item => item != 'true')
        this.setState({ checkedExpireDate: types })
        onSelectSortBy(types, 'expireDate')
      }
      else {
        this.setState({ checkedExpireDate: [...this.state.checkedExpireDate, 'true'] })
        onSelectSortBy([...this.state.checkedExpireDate, 'true'], 'expireDate')
      }
      this.setState({ checkedexpireDateYes: !this.state.checkedexpireDateYes })
    }
    if (filt === 'expireDatelNo') {
      if (this.state.checkedexpireDateNo) {
        var types = this.state.checkedExpireDate.filter(item => item != 'false')
        this.setState({ checkedExpireDate: types })
        onSelectSortBy(types, 'expireDate')
      }
      else {
        this.setState({ checkedExpireDate: [...this.state.checkedExpireDate, 'false'] })
        onSelectSortBy([...this.state.checkedExpireDate, 'false'], 'expireDate')
      }
      this.setState({ checkedexpireDateNo: !this.state.checkedexpireDateNo })
    }
    if (filt === 'externalUseApproved') {
      if (this.state.checkedExternalUseApproved) {
        var types = this.state.checkedExternalUse.filter(item => item != 'Approved External')
        this.setState({ checkedExternalUse: types })
        onSelectSortBy(types, 'approvedExternal')
      }
      else {
        this.setState({ checkedExternalUse: [...this.state.checkedExternalUse, 'Approved External'] })
        onSelectSortBy([...this.state.checkedExternalUse, 'Approved External'], 'approvedExternal')
      }
      this.setState({ checkedExternalUseApproved: !this.state.checkedExternalUseApproved })
    }
    if (filt === 'externalUseDenied') {
      if (this.state.checkedExternalUseDenied) {
        var types = this.state.checkedExternalUse.filter(item => item != 'Forbidden External')
        this.setState({ checkedExternalUse: types })
        onSelectSortBy(types, 'approvedExternal')
      }
      else {
        this.setState({ checkedExternalUse: [...this.state.checkedExternalUse, 'Forbidden External'] })
        onSelectSortBy([...this.state.checkedExternalUse, 'Forbidden External'], 'approvedExternal')
      }
      this.setState({ checkedExternalUseDenied: !this.state.checkedExternalUseDenied })
    }
    if (filt === 'externalUseContact') {
      if (this.state.checkedExternalUseContact) {
        var types = this.state.checkedExternalUse.filter(item => item != 'Contact')
        this.setState({ checkedExternalUse: types })
        onSelectSortBy(types, 'approvedExternal')
      }
      else {
        this.setState({ checkedExternalUse: [...this.state.checkedExternalUse, 'Contact'] })
        onSelectSortBy([...this.state.checkedExternalUse, 'Contact'], 'approvedExternal')
      }
      this.setState({ checkedExternalUseContact: !this.state.checkedExternalUseContact })
    }
    if (filt === 'date') {
      const month = value.getMonth() + 1;
      const date = value.getDate();
      const dateFormattedString= [value.getFullYear(),
        (month>9 ? '' : '0') + month,
        (date>9 ? '' : '0') + date
       ].join('')
       const dateFormatted = parseInt(dateFormattedString);

      if(dateFormatted === this.state.checkedValidDate){
        this.setState({ checkedValidDate: undefined})
        onSelectSortBy(undefined, 'date')
        var filterTranslations = this.state.filterTranslations.filter(item => item !== trans)
        this.setState({'filterTranslations': filterTranslations})
      }
      else{
        this.setState({ checkedValidDate: dateFormatted})
        onSelectSortBy(dateFormatted, 'date')
      }
    }
    if (filt.substring(0, 3) === 'tag') {
      this.setState({ [filt]: !this.state[filt] })
      if (this.state.checkedTags.indexOf(trans) >= 0) {
        var tags = this.state.checkedTags.filter(item => item != trans)
        onSelectSortBy(tags, 'tag')
        this.setState({ checkedTags: tags })
      }
      else {
        onSelectSortBy([...this.state.checkedTags, trans], 'tag')
        this.setState({ checkedTags: [...this.state.checkedTags, trans] })
      }
    }
    else {

    }
  }

  onSubmitSearch(e) {
    e.preventDefault();
    const { history } = this.props;
    var searchText = this.state.searchText;
    const queryStr = searchText ? `?inputText=${searchText}` : '';
    history && history.push(`/search${queryStr}`);
  }

  render() {
    const { onSelectOrderBy, onSelectSortBy, onReset, t } = this.props;
    const { searchText, reset } = this.state;
    return (
      <div>
        <div className="jnto-filter-bar-wrapper">

          <div className='search-container-block'>
          <div className='search-input-container'>
            <div className='search-input-container' id='autocomplete'>
            </div>
            <button ref={this.submitBtnEl} className='dui-btn xs-skinny dui-btn-primary' onClick={this.onSubmitSearch}>{t('common.search')}</button>
          </div>
          </div>

          <div className='filter-options-block'>
            <div className='field-group'>
              <div className='jnto-selector-container'>
                <select name='select-input' onChange={onSelectOrderBy}>
                  <option filter='date'>{t('common.date')}</option>
                  <option filter= 'Size'>{t('common.fileSize')}</option>
                  <option filter='Filename'>{t('common.fileName')}</option>
                  <option filter='Download Count'>{t('filters.downloadCount')}</option>
                 </select>
              </div>
            </div>
            <label className="radio-group">JNTO
              <input type="radio" name="radio2" value="first" onInput={() => onSelectSortBy('JNTO')} defaultChecked />
              <span className="checkmark"></span>
            </label>
            <label className="radio-group">JOMC
              <input type="radio" name="radio2" value="first" onInput={() => onSelectSortBy('JOMC')} />
              <span className="checkmark"></span>
            </label>
          </div>
          <div className='filter-options-block'>
            <label onClick={() => {onReset(); this.resetFilters()}} className="reset-button">{t('filters.reset')}</label>
          </div>
        </div>
        <div className={'secondary-option-block'} style={{"display":'flex'}}>
          <ToggleBtn onSelect={onSelectSortBy} fieldLabel={t('filters.fuzzySearch')} checked={false} />
          <div className="tooltip-filter-bar">
            <Tooltip classname="tooltip-inner" content={t('filters.tooptipFuzzyDetail')}>
              <div style={{"display":'flex'}}>
              <img className='icon tooltip-icon' src={toolTipIcon} />
              <div className="tooltip-mouseover"> {t('filters.tooptipFuzzy')}</div>
              </div>
            </Tooltip>
          </div>
          <div className="tooltip-filter-bar">
            <Tooltip borderRadius='100'preferredPosition='below' classname="tooltip-inner" content={t('filters.tooptipKeywordDetail')}>
              <div style={{"display":'flex'}}>
              <img className='icon tooltip-icon' src={toolTipIcon} />
              <div className="tooltip-mouseover"> {t('filters.tooptipKeyword')}</div>
              </div>
            </Tooltip>
          </div>


        </div>
        <CollapsibleFilterBar filters={this.state} handlFilterChange={this.handlFilterChange} handleTextChange={this.handleTextChange} onSelectSortBy={onSelectSortBy} isReset={reset}/>
      </div>
    );
  }
}
FilterBar.propTypes = {};

export default withTranslation()(withRouter(FilterBar));

