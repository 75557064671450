import React from "react";
import { withTranslation } from 'react-i18next';
import { withRouter } from 'react-router-dom';
import Sidebar from '../../components/Navigation/SideBar/SideBar'


class DashboardPage extends React.Component {
  constructor() {
    super();
    this.state = {
      isModalVisible: false,
    }
    this.onTileClick = this.onTileClick.bind(this)

  }

  showBugForm = e => {
    this.setState({
      isModalVisible: !this.state.isModalVisible
    });
  };

  onTileClick = (link) => {
    this.props.history && this.props.history.push(link);
  }

  render() {
    const { t } = this.props;
    return (
      <Sidebar>
        {(onUpdateSideBarView) => {
          onUpdateSideBarView('default');
          return <React.Fragment>
            <div className="app">

              <div className='jnto-generic-page-wrapper'>
                <div className='dashboard-page'>
                  <div className='dashboard-item-container'>


                  <a onClick={() => this.onTileClick('search')}>
                      < div className='dashboard-item' >
                        <img className='icon' src={"https://asset.japan.travel/image/upload/v1611213597/dam-admin/icon_asset-gallery.svg"} />
                        <h3>{t('dashboard.imageGallery')}</h3>
                      </div>
                    </a>


                    <a onClick={() => this.onTileClick('folders')}>
                      <div className='dashboard-item'>
                        <img className='icon' src={"https://asset.japan.travel/image/upload/v1611022236/dam-admin/icon_my-bag_red.svg"} />
                        <h3>{t('dashboard.myBag')}</h3>
                      </div>
                    </a>

                    <a onClick={() => this.onTileClick('file-upload')}>
                      <div className='dashboard-item'>
                        <img className='icon' src={"https://asset.japan.travel/image/upload/v1611022145/dam-admin/icon_upload.svg"} />
                        <h3>{t('dashboard.uploadAssets')}</h3>
                      </div>
                    </a>

                    <a onClick={() => this.onTileClick('permission-requests')}>
                      <div className='dashboard-item'>
                        <img className='icon' src={"https://asset.japan.travel/image/upload/v1611022176/dam-admin/icon_download.svg"} />
                        <h3>{t('dashboard.downloadRequest')}</h3>
                      </div>
                    </a>

                    <a onClick={() => this.onTileClick('download-history')}>
                      <div className='dashboard-item'>
                        <img className='icon' src={"https://asset.japan.travel/image/upload/v1611202669/dam-admin/icon_download-history.svg"} />
                        <h3>{t('dashboard.downloadHistory')}</h3>
                      </div>
                    </a>

                    <a onClick={() => this.onTileClick('user-guide')}>
                      <div className='dashboard-item'>
                        <img className='icon' src={"https://asset.japan.travel/image/upload/v1611022214/dam-admin/icon_user-guide.svg"} />
                        <h3>{t('dashboard.userGuideDB')}</h3>
                      </div>
                    </a>

                    <a onClick={() => this.onTileClick('faq')}>
                      <div className='dashboard-item'>
                        <img className='icon' src={"https://asset.japan.travel/image/upload/v1611202669/dam-admin/icon_faq.svg"} />
                        <h3>{t('dashboard.faq')}</h3>
                      </div>
                    </a>

                  </div>
                </div>
              </div>
            </div>
          </React.Fragment>
        }
        }
      </Sidebar >
    )
  }
}

export default withTranslation()(withRouter(DashboardPage));