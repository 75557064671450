import React from "react";
import PropTypes from 'prop-types';
import { Spinner } from "@shopify/polaris";
import { useTranslation } from 'react-i18next';
import loaderAsset from '../../assets/loader/loader.gif';

const PageLoader = ({ show = false, displayText = '', includeSpinner = false, offset }) => {
    const { t } = useTranslation();
    if (displayText === '') {
        displayText = t('common.loading')
    }
    return (
        <div className={`page-loader ${show && 'loader-isvisible'}`} style={{ ...offset }}>
            <div className='page-loader-content'>
                {includeSpinner &&
                    <div className='spinner-content'>
                        <Spinner size="small" color="white" />
                    </div>
                }
                <div className='spinner-context-text'>
                    <img className='icon' src={loaderAsset} />

                    {/* {displayText} */}
                    {/* <span className="jnto-dam-loader-dot">.</span>
                    <span className="jnto-dam-loader-dot">.</span>
                    <span className="jnto-dam-loader-dot">.</span> */}
                </div>
            </div>
        </div>
    );
}
PageLoader.propTypes = {
    show: PropTypes.bool.isRequired,
    displayText: PropTypes.string,
    styles: PropTypes.object
};
export default PageLoader;
