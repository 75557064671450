import {
     CaretDownMinor,
    LogOutMinor, ProfileMinor, DuplicateMinor, FolderDownMajorMonotone, ProductsMajorTwotone, ListMajorMonotone, CircleChevronUpMinor
} from '@shopify/polaris-icons';
import NavMenuItem from '../shared/navBars/NavMenuItem/NavMenuItem';

class UserMenuGenerator {
    constructor(onNavigation, onLogout, i18n) {
        this.baseActionItems = [
            new NavMenuItem(i18n.t('common.myProfile'), ProfileMinor, 'user-profile', onNavigation),
            new NavMenuItem(i18n.t('common.myProjects'), ProductsMajorTwotone, 'search', onNavigation)];

        this.uploadUserActionItems = [
            new NavMenuItem(i18n.t('common.uploadMenu'), null, 'uploads', null),
            new NavMenuItem(i18n.t('common.uploadAssets'), CircleChevronUpMinor, 'file-upload', onNavigation)];
            // new NavMenuItem(i18n.t('common.myRequests'), FolderUpMajorMonotone, 'my-requests', onNavigation)];

        this.permissionUserActionItems = [
            new NavMenuItem(i18n.t('common.permissionMenu'), null, 'permission', null),
            new NavMenuItem(i18n.t('common.myFolders'), DuplicateMinor, 'folders', onNavigation),
            new NavMenuItem(i18n.t('common.myPermissionRequests'), FolderDownMajorMonotone, 'permission-requests', onNavigation),
            new NavMenuItem(i18n.t('workflows.downloadHistory.downloadHistories'), ListMajorMonotone, 'download-history', onNavigation)];

        this.permissionUserActionItemsVendor = [
                new NavMenuItem(i18n.t('common.permissionMenu'), null, 'permission', null),
                new NavMenuItem(i18n.t('common.myFolders'), DuplicateMinor, 'folders', onNavigation),
                new NavMenuItem(i18n.t('common.myPermissionRequests'), FolderDownMajorMonotone, 'permission-requests', onNavigation)];
                // new NavMenuItem(i18n.t('workflows.downloadHistory.downloadHistories'), ListMajorMonotone, 'download-history', onNavigation)

        this.adminUserActionItems = [
            new NavMenuItem(i18n.t('common.adminMenu'), null, 'admin', null),
            new NavMenuItem(i18n.t('common.user'), CaretDownMinor, 'user-admin', onNavigation),
            new NavMenuItem(i18n.t('common.organization'), CaretDownMinor, 'organization-admin', onNavigation),
            new NavMenuItem(i18n.t('common.project'), CaretDownMinor, 'project-admin', onNavigation)];
            // new NavMenuItem(i18n.t('common.moderation'), DisputeMinor, 'moderation', onNavigation)];
        this.logoutActionItem = new NavMenuItem(i18n.t('common.logout'), LogOutMinor, undefined, onLogout);
        this.actionItemsMap = {
            'individual-jomc': this.getSimpleUserItems(),
            'vendor': this.getVendorUserItems(),
            'jnto-local-office': this.getAdvancedUserItems(),
            'jnto-hq-regular': this.getAdvancedUserItems(),
            'jnto-hq-admin': this.getAdminUserItems(),
            'admin': this.getAdminUserItems(),
            'municipality': this.getAdvancedUserItems(),
            'government': this.getAdvancedUserItems(),
        }
    }

    getFromUserGroup(userGroups) {
        const arrayOfLists = userGroups.map(userGroup => this.actionItemsMap[userGroup]);
        arrayOfLists.sort((list1, list2) => list2.length - list1.length || 0);

        return arrayOfLists[0];
    }

    getSimpleUserItems() {
        return this.baseActionItems
            .concat([this.logoutActionItem]);
    }

    getAdvancedUserItems() {
        return this.baseActionItems
        .concat(this.uploadUserActionItems)
        .concat(this.permissionUserActionItems)
        .concat([this.logoutActionItem]);
    }

    getVendorUserItems() {
        return this.baseActionItems
        .concat(this.uploadUserActionItems)
        .concat(this.permissionUserActionItemsVendor)
        .concat([this.logoutActionItem]);
    }

    getAdminUserItems() {
        return this.baseActionItems
            .concat(this.uploadUserActionItems)
            .concat(this.permissionUserActionItems)
            .concat(this.adminUserActionItems)
            .concat([this.logoutActionItem]);
    }
}

export default UserMenuGenerator;