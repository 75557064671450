import React from 'react';
import { Modal, TextContainer } from '@shopify/polaris';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

const SimpleModal = ({ children, onCancel, onConfirm, title, message, disabled = () => {return false}}) => {
    const { t } = useTranslation();
    const messageDisplay = message || <p>{t('common.confirmChanges')}</p>;

    return (
        <Modal
            open
            onClose={onCancel}
            title={title || t('workflows.folders.areYouSure')}
            primaryAction={{
                content: t('form.label.ok'),
                onAction: onConfirm,
                disabled: disabled(),
            }}
            secondaryActions={[
                {
                    content: t('form.label.cancel'),
                    onAction: onCancel
                },
            ]}>
            <Modal.Section>
                {children || <TextContainer>
                    {messageDisplay}
                </TextContainer>}
            </Modal.Section>
        </Modal>
    );
};

SimpleModal.propTypes = {
    children: PropTypes.object,
    onCancel: PropTypes.func.isRequired,
    onConfirm: PropTypes.func.isRequired,
    title: PropTypes.string,
    message: PropTypes.string,
};

export default SimpleModal;
