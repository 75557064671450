export const languageOptions = [
    {
        labelKey: "locale.english",
        locale: "en",
        localeVariations : ['en', 'en-US', 'en-GB']
    },
    {
        labelKey: "locale.japanese",
        locale: "jp",
        localeVariations : ['jp', 'ja-JP']
    },
];
