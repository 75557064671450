import * as types from "../constants/action-types";
import { uploadAssetsDirect, cloudinarySignature } from '../../api/vermillionApi/assetManagementApi';
const retry = require('retry');


export const addGridValidationErrorMessageSuccess = (newErrorMessage) => {
    return { type: types.ADD_FILE_UPLOAD_GRID_ERROR_MESSAGE_SUCCESS, newErrorMessage };
};

export const removeGridValidationErrorMessageSuccess = (newErrorMessage) => {
    return { type: types.REMOVE_FILE_UPLOAD_GRID_ERROR_MESSAGE_SUCCESS, newErrorMessage };
};

export const uploadGridAssetsBegin = () => {
    return { type: types.BEGIN_FILE_UPLOAD_GRID_AJAX_CALL };
};

export const uploadGridAssetsSuccess = () => {
    return { type: types.FILE_UPLOAD_GRID_AJAX_CALL_SUCCESS };
};

export const uploadGridAssetsError = () => {
    return { type: types.FILE_UPLOAD_GRID_AJAX_CALL_ERROR };
};

export const clearUploadGridErrorMessagesSuccess = () => {
    return { type: types.CLEAR_UPLOAD_GRID_ERROR_MESSAGES_SUCCESS };
};

export const clearUploadGridStatusSuccess = () => {
    return { type: types.CLEAR_UPLOAD_GRID_STATUS_SUCCESS };
};

export const addGridStaticValidationErrorMessageSuccess = (newErrorMessage) => {
    return { type: types.ADD_STATIC_FILE_UPLOAD_GRID_ERROR_MESSAGE_SUCCESS, newErrorMessage };
};

export const addGridValidationErrorMessage = (newErrorMessage) => {
    return (dispatch) => {
        dispatch(addGridValidationErrorMessageSuccess(newErrorMessage));
        return;
    };
};

export const removeGridValidationErrorMessage = (newErrorMessage) => {
    return (dispatch) => {
        dispatch(removeGridValidationErrorMessageSuccess(newErrorMessage));
        return;
    };
};

export const clearUploadGridErrorMessages = () => {
    return (dispatch) => {
        dispatch(clearUploadGridErrorMessagesSuccess());
        return;
    }
};

export const clearUploadGridStatus = () => {
    return (dispatch) => {
        dispatch(clearUploadGridStatusSuccess());
    }
};

export const uploadGridAssets = (assetUploadRequests, userid) => {
    return async (dispatch) => {
        dispatch(uploadGridAssetsBegin());

        try {

            var signedRequests = assetUploadRequests.map((req, i) => {
                const operation = retry.operation({
                    retries: 10,
                    minTimeout: 2 * 1000,
                    // maxTimeout: 20 * 1000,
                    // randomize: true,
                });
                return new Promise((resolve, reject) =>{

                setTimeout(() => {

       
                     operation.attempt(async (currentAttempt) => {
                        console.log('sending request: ', currentAttempt, ' attempt');
                         var response;

                         try {
                             response = await cloudinarySignature(req);
                            req.options.timestamp = response.timestamp;
                            if(!response || !response.signature || response.signature === ''){
                                throw new Error('Error on Image Upload Signature')
                            }
            
                        } catch (e) {
                             if (operation.retry(e)) { return; }
                         }
     
                         if(!response || !response.signature || response.signature === ''){
                             reject('Error on Image Upload Signature')
                         }
                         else{
                             resolve({
                                file:req.file,
                                signature: response.signature,
                                options:req.options,
                                timestamp: response.timestamp
        
                            })
                         }
     
                     })
                    }, 200 * i)

                  })

             })

            await Promise.all(signedRequests)
                .then(async (response)=>{
                await uploadAssetsDirect(response, dispatch);
                // await adminApproveUploads(userid, uploads.map(asset => asset.data.data.id), dispatch);
                dispatch(uploadGridAssetsSuccess());
            })
            .catch(err => {
                dispatch(uploadGridAssetsError());
        });
        } catch (err) {
            dispatch(uploadGridAssetsError());
            throw (err);
        }

        return;
    };
};

export const addGridStaticValidationErrorMessage = (newErrorMessage) => {
    return (dispatch) => {
        dispatch(addGridStaticValidationErrorMessageSuccess(newErrorMessage));
        return;
    };
};
