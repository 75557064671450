import React from 'react';
import GenericGallery from '../shared/galleries/GenericGallery/GenericGallery';
import FolderCard from '../FolderCard/FolderCard';

const FolderGallery = ({ onSelectFolder, onDeleteFolderApprove, onRenameFolderApprove, folderImageComposites, userGroups }) => {
    const foldersToDisplay = [...folderImageComposites];
    const folderCards = foldersToDisplay ? foldersToDisplay.map((folder, index) =>
        <FolderCard
            key={index}
            userGroups = {userGroups}
            folderImageComposite={folder}
            onSelectFolder={onSelectFolder}
            onDeleteFolderApprove={onDeleteFolderApprove}
            onRenameFolderApprove={onRenameFolderApprove} />) : [];

    return (<GenericGallery items={folderCards} />);
};

export default FolderGallery;
