import React from 'react';
import { withTranslation } from 'react-i18next';
import Sidebar from '../../components/Navigation/SideBar/SideBar';

class FAQPage extends React.Component {
    render() {
        const { t } = this.props;

        return (
            <Sidebar>
                {(onUpdateSideBarView) => {
                    onUpdateSideBarView('default');
                    return <React.Fragment>
                        <div className="app">
                            <div className='jnto-generic-page-wrapper'>
                                <section className='generic-block'>
                                    <h2>{t('faq.faqcategory1')}</h2>
                                    <hr className='simple-divider' />
                                    <p class = 'faq-question'>{t('faq.faqQuestion1')}</p>
                                    <p>{t('faq.faqAnswer1')}</p>
                                    {/* <br></br>
                                    <p class = 'faq-question'>{t('faq.faqQuestion2')}</p>
                                    <p>{t('faq.faqAnswer2')}</p> */}
                                    <br></br>
                                    <p class = 'faq-question'>{t('faq.faqQuestion3')}</p>
                                    <p>{t('faq.faqAnswer3')}</p>
                                    <br></br>
                                    <p class = 'faq-question'>{t('faq.faqQuestion4')}</p>
                                    <p>{t('faq.faqAnswer4')}</p>
                                    <br></br>
                                    <p class = 'faq-question'>{t('faq.faqQuestion6')}</p>
                                    <p>{t('faq.faqAnswer6')}</p>
                                    <br></br>
                                </section>
                                <section className='generic-block'>
                                    <h2>{t('faq.faqcategory2')}</h2>
                                    <hr className='simple-divider' />
                                    <p class = 'faq-question'>{t('faq.faqQuestion7')}</p>
                                    <p>{t('faq.faqAnswer7')}</p>
                                    <br></br>
                                    <p class = 'faq-question'>{t('faq.faqQuestion8')}</p>
                                    <p>{t('faq.faqAnswer8')}</p>
                                    <br></br>
                                    <p class = 'faq-question'>{t('faq.faqQuestion9')}</p>
                                    <p>{t('faq.faqAnswer9')}</p>
                                    <br></br>
                                    <p class = 'faq-question'>{t('faq.faqQuestion10')}</p>
                                    <p>{t('faq.faqAnswer10')}</p>
                                    <br></br>
                                    <p class = 'faq-question'>{t('faq.faqQuestion11')}</p>
                                    <p>{t('faq.faqAnswer11')}</p>
                                    <br></br>
                                    <p class = 'faq-question'>{t('faq.faqQuestion12')}</p>
                                    <p>{t('faq.faqAnswer12')}</p>
                                    <br></br>
                                </section>
                                <section className='generic-block'>
                                    <h2>{t('faq.faqcategory3')}</h2>
                                    <hr className='simple-divider' />
                                    <p class = 'faq-question'>{t('faq.faqQuestion14')}</p>
                                    <p>{t('faq.faqAnswer14')}</p>
                                    <br></br>
                                    <p class = 'faq-question'>{t('faq.faqQuestion15')}</p>
                                    <p>{t('faq.faqAnswer15A')}</p>
                                    <p>{t('faq.faqAnswer15B')}</p>
                                    <p>{t('faq.faqAnswer15C')}</p>
                                    <br></br>
                                    <p class = 'faq-question'>{t('faq.faqQuestion16')}</p>
                                    <p>{t('faq.faqAnswer16')}</p>
                                    <br></br>
                                </section>
                            </div>
                        </div>
                    </React.Fragment>
                }}
            </Sidebar>
        )
    }
}

export default withTranslation()(FAQPage);
