import React from 'react';
import { searchAssetsByTextAndProjectIdsPaginated } from '../../api/algoliaSearchApi/algoliaSearchApi';
class ProjectCard extends React.Component {
    constructor() {
        super();
        this.state = {
            thumbnailUrl: ''
        };
    }

    componentDidMount() {
        searchAssetsByTextAndProjectIdsPaginated('', 0, [this.props.project.id], this.props.userGroups).then(response => {
            if (response.items.length > 0) {
                this.setState({
                    thumbnailUrl: response.items[0].thumbnailUrl,
                });
            }
        });
    }

    render() {
        const { project, onSelectProject } = this.props;
        return (<div className='project-card-container'>
            <div className='card-overlay'>
                <div className='card-overlay-container'>
                <span onClick={() => onSelectProject(project)}>{(project.projectName)}</span>
                </div>
            </div>
            {this.state.thumbnailUrl && <img alt={'Loading...'} className='generic-card-image' src={this.state.thumbnailUrl} />}
        </div>);
    }
};

export default ProjectCard;
