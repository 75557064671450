import React from "react";
import ManualFloatingBar from "../../components/Navigation/ManualFloatingBar/ManualFloatingBar";
import { Frame, Page } from "@shopify/polaris";
import { withTranslation } from 'react-i18next';
import { withRouter } from "react-router-dom";

class UserGuideUpload01 extends React.Component {
    constructor() {
        super();
        this.state = {}
    }
    render() {
        const { t, history, match } = this.props;
        return (
            <>
                <Frame>
                    <Page fullWidth>
                        <div className='jnto-generic-page-wrapper'>
                            <section className='generic-block'>
                                <h3>{t('manual.assets.assetsGallery.subTitle')}</h3>
                                <h2>{t('manual.assets.assetsGallery.title')}</h2>
                                <hr className='simple-divider' />
                            </section>

                            <section>
                                <b>{t('manual.assets.assetsGallery.label1')}</b>
                                <p>{t('manual.assets.assetsGallery.detail01')}</p>
                                <br/>
                                <p>{t('manual.assets.assetsGallery.detail02')}</p>
                                <br/>
                                <img src='https://asset.japan.travel/image/upload/w_300,f_auto,fl_lossy,e_improve,e_sharpen/v1711715509/dam-admin/manual/updated/p16-1.png' />
                                <br/><br/>
                                <p>{t('manual.assets.assetsGallery.detail03')}</p>
                                <br/>
                                <img src='https://asset.japan.travel/image/upload/w_300,f_auto,fl_lossy,e_improve,e_sharpen/v1711715506/dam-admin/manual/updated/p16-2.png' />
                                <br/><br/>
                                <p>{t('manual.assets.assetsGallery.detail05')}</p>
                                <br/>
                                <p>{t('manual.assets.assetsGallery.detail06')}</p>
                                <br/>
                                <img src='https://asset.japan.travel/image/upload/w_300,f_auto,fl_lossy,e_improve,e_sharpen/v1711715505/dam-admin/manual/updated/p16-3.png' />
                                <br/><br/>
                                <p>{t('manual.assets.assetsGallery.detail07')}</p>
                                <br/><br/>
                                <img src='https://asset.japan.travel/image/upload/w_300,f_auto,fl_lossy,e_improve,e_sharpen/v1711715503/dam-admin/manual/updated/p16-4.png' />
                                <br/><br/>      
                                
                                <b>{t('manual.assets.assetsGallery.label2')}</b>                          
                                <p>{t('manual.assets.assetsGallery.detail08')}</p>
                                <br/><br/>
                                <p>{t('manual.assets.assetsGallery.detail09')}</p>
                                <br/><br/>
                                <p>{t('manual.assets.assetsGallery.detail10')}</p>
                                <section className='bullet-list'>
                                    <ul>
                                        <li>{t('manual.assets.assetsGallery.detail11')}</li>
                                        <li>{t('manual.assets.assetsGallery.detail12')}</li>
                                        <li>{t('manual.assets.assetsGallery.detail13')}</li>
                                        <li>{t('manual.assets.assetsGallery.detail14')}</li>
                                        <li>{t('manual.assets.assetsGallery.detail15')}</li>
                                    </ul>
                                </section>
                                <img src='https://asset.japan.travel/image/upload/w_100,f_auto,fl_lossy,e_improve,e_sharpen/v1711716296/dam-admin/manual/updated/p17-1.png' />
                                <br/><br/>                          
                                <img src='https://asset.japan.travel/image/upload/w_300,f_auto,fl_lossy,e_improve,e_sharpen/v1711716299/dam-admin/manual/updated/p17-2.png' />
                            
                                <br/><br/>   
                                <b>{t('manual.assets.assetsGallery.label3')}</b>
                                <p>{t('manual.assets.assetsGallery.detail16')}</p>
                                <p>{t('manual.assets.assetsGallery.detail17')}</p>
                                <br/>
                                <b>{t('manual.assets.assetsGallery.label4')}</b>
                                <p>{t('manual.assets.assetsGallery.detail18')}</p>
                                <p>{t('manual.assets.assetsGallery.detail19')}</p>
                                <br/>
                                <p>{t('manual.assets.assetsGallery.detail20')}</p>
                                <p>{t('manual.assets.assetsGallery.detail21')}</p>
                                <br/><br/>

                                <img src='https://asset.japan.travel/image/upload/w_600,f_auto,fl_lossy,e_improve,e_sharpen/v1711716693/dam-admin/manual/updated/p18-1.png' />
                                <br/><br/>                          
                                <img src='https://asset.japan.travel/image/upload/w_300,f_auto,fl_lossy,e_improve,e_sharpen/v1711716690/dam-admin/manual/updated/p18-2.png' />
                                <br/><br/>   
                                <b>{t('manual.assets.assetsGallery.label5')}</b>
                                <p>{t('manual.assets.assetsGallery.detail22')}</p>
                                <br/>
                                <img src='https://asset.japan.travel/image/upload/w_300,f_auto,fl_lossy,e_improve,e_sharpen/v1711718210/dam-admin/manual/updated/P19.png' />
                                <br/><br/>
                                <b>{t('manual.assets.assetsGallery.label6')}</b>
                                <p>{t('manual.assets.assetsGallery.detail23')}</p>
                                <br/>
                                <section className='bullet-list'>
                                    <ul>
                                        <li>{t('manual.assets.assetsGallery.detail24')}</li>
                                        <li>{t('manual.assets.assetsGallery.detail25')}</li>
                                    </ul>
                                </section>
                                <p>{t('manual.assets.assetsGallery.detail26')}</p>
                                <br/>
                                <b>{t('manual.assets.assetsGallery.label7')}</b>
                                <p>{t('manual.assets.assetsGallery.detail27')}</p>
                                <br/><br/>
                                <img src='https://asset.japan.travel/image/upload/w_300,f_auto,fl_lossy,e_improve,e_sharpen/v1711718207/dam-admin/manual/updated/P20-1.png' />
                                <br/><br/>
                                <b>{t('manual.assets.assetsGallery.label8')}</b>
                                <p>{t('manual.assets.assetsGallery.detail28')}</p>
                                <br/><br/>
                                <img src='https://asset.japan.travel/image/upload/w_300,f_auto,fl_lossy,e_improve,e_sharpen/v1711718205/dam-admin/manual/updated/P20-2.png' />
                                <br/><br/>
                                <b>{t('manual.assets.assetsGallery.label9')}</b>
                                <p>{t('manual.assets.assetsGallery.detail29')}</p>
                            </section>

                            <ManualFloatingBar buttons={[
                                {
                                    position: 'left',
                                    text: t('manual.assets.assetsDetails.title'),
                                    callback: () => history.push(`assets-details`),
                                },
                                {
                                    position: 'right',
                                    text: t('manual.assets.assetsEdit.title'),
                                    callback: () => history.push(`assets-edit`),
                                }
                            ]} />
                        </div>
                    </Page>
                </Frame>
            </>)
    }
}

export default withTranslation()(withRouter((UserGuideUpload01)));