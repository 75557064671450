// libraries
import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import queryString from 'query-string';
import { withRouter, Route, matchPath } from 'react-router-dom';
import { Page, Banner, Select } from '@shopify/polaris';
import { withTranslation } from 'react-i18next';
// redux actions
import * as projectActions from '../../redux/actions/projectActions';
import * as assetActions from '../../redux/actions/assetActions';
import * as folderActions from '../../redux/actions/folderActions';
// custom components
import ProjectGallery from '../../components/ProjectGallery/ProjectGallery';
import AddAssetsToFolderBanner from '../../components/AddAssetsToFolderBanner/AddAssetsToFolderBanner';
import PageLoader from "../../components/PageLoader/PageLoader";
import PanelWithData from '../../components/PanelWithData/PanelWithData';
import AssetsPanel from '../../components/shared/panels/AssetsPanel/AssetsPanel';
import Sidebar from '../../components/Navigation/SideBar/SideBar'

class ProjectsPage extends React.Component {
    constructor() {
        super();
        this.state = {
            isLoading: false,
            sortBy: 'JNTO',
            isFuzzy: false
        };

        // Method Binding
        this.onSelectProject = this.onSelectProject.bind(this);
        this.onAddAssetToFolder = this.onAddAssetToFolder.bind(this);
        this.onRemoveAssetFromFolder = this.onRemoveAssetFromFolder.bind(this);
        this.onCloseFolderModal = this.onCloseFolderModal.bind(this);
        this.getDataLoader = this.getDataLoader.bind(this);
        this.getAssetsPanel = this.getAssetsPanel.bind(this);
        this.onSelectOrderBy = this.onSelectOrderBy.bind(this);
        this.onSelectSortBy = this.onSelectSortBy.bind(this);
        this.onReset = this.onReset.bind(this);

    }

    componentDidMount() {
        const { projectActions, userInfoComposite, assetActions } = this.props;
        projectActions.getProjectsByOrganizationId(userInfoComposite.user.organizationId);
        assetActions.getTotalNumberImagesJOMC();
        assetActions.getTotalNumberVideosJOMC();
        assetActions.getTotalNumberImagesJNTO();
        assetActions.getTotalNumberVideosJNTO();

    }

    componentDidUpdate(prevProps, prevState){
        //scroll inner window to top on page change.  Risky to get generic polaris class name
        if(prevProps.pageNumber !== this.props.pageNumber){
            if(document.getElementsByClassName('Polaris-Scrollable')[0]){
                var scrollWindow = document.getElementsByClassName('Polaris-Scrollable')[0]
                scrollWindow.scrollTo(0,0);
            }
        }
    }

    componentWillUnmount() {
        this.onCloseFolderModal();
    }

    onSelectProject(project) {
        const { match, history } = this.props;
        if (project) {
            history.push(`${match.url}/${project.id}`);
        } else {
            history.push(match.url);
        }
    }

    getAssetsPanel(onLoadNextPage, assets, onLoadPreviousPage, pageNumber) {
        const { assetsForFolder, history, numberOfHits, totalNumberImagesJOMC, totalNumberImagesJNTO, totalNumberVideosJOMC, totalNumberVideosJNTO, imageCount, videoCount } = this.props;
        const searchText = queryString.parse(history.location.search).inputText;

        return (<>
            <AssetsPanel
                onLoadPreviousPage={onLoadPreviousPage}
                pageNumber={pageNumber}
                onLoadNextPage={onLoadNextPage}
                isJOMC={this.state.sortBy && this.state.sortBy === 'JOMC'}
                onAddAssetToFolder={this.onAddAssetToFolder}
                onRemoveAssetFromFolder={this.onRemoveAssetFromFolder}
                assetsForFolder={assetsForFolder}
                assets={[...assets]}
                onSelectOrderBy={this.onSelectOrderBy}
                onSelectSortBy={this.onSelectSortBy}
                searchText={searchText}
                onReset={this.onReset}
                numberOfHits={numberOfHits}
                totalNumberImagesJNTO ={totalNumberImagesJNTO}
                totalNumberImagesJOMC ={totalNumberImagesJOMC}
                videoCount={videoCount}
                imageCount={imageCount}
                totalNumberVideosJNTO ={totalNumberVideosJNTO}
                totalNumberVideosJOMC ={totalNumberVideosJOMC}
                onToggleIsLoading={(isLoading) => this.setState({ isLoading })}
            />
        </>);
    }

    getDataLoader() {
        const { projects, assetActions, signedInUser } = this.props;
        const { filter } = this.state;
        return (match, history, pageNumber) => {
            const projectIds = [];
            const inputText = queryString.parse(history.location.search).inputText;
            const userGroups = signedInUser.signInUserSession.idToken.payload["cognito:groups"];
            const orderBy = this.state.orderBy;
            const sortBy = this.state.sortBy;
            const filterApprovedExternal = this.state.filterApprovedExternal;
            const filterAspect = this.state.filterAspect;
            const filterValidDate = this.state.filterValidDate;
            const filterMediaType = this.state.filterMediaType;
            const filterTags = this.state.filterTags;
            const filterPrefecture = this.state.filterPrefecture;
            const filterSeason = this.state.filterSeason;
            const filterDamId = this.state.filterDamId;
            const filterDownloadPermission = this.state.filterDownloadPermission;
            const filterPrePostApproval = this.state.filterPrePostApproval;
            const isFuzzy = this.state.isFuzzy;

            assetActions.getAssetsByTextAndProjectIdsPaginated(inputText || '', pageNumber, projectIds, userGroups, orderBy, sortBy, filterAspect, filterMediaType, filterPrefecture, filterSeason, isFuzzy, filterTags, filterDamId, filterPrePostApproval, filterDownloadPermission, filterValidDate, filterApprovedExternal);
        }
    }

    /* adding to folder */
    onCloseFolderModal() {
        this.props.folderActions.selectFolderToAddAssets(undefined);
        this.props.folderActions.clearAssetsFromFolder();
    }

    onAddAssetToFolder(asset) {
        this.props.folderActions.addAssetToFolder(asset);
    }

    onRemoveAssetFromFolder(asset) {
        this.props.folderActions.removeAssetFromFolder(asset);
    }

    onSelectOrderBy(event) {
        this.setState({
            isLoading: false,
            orderBy: event.target.selectedOptions[0].attributes.filter.value,
        });
    }

    onReset() {
        const {assetActions} = this.props;
            this.setState({
                isLoading: false,
                // isFuzzy: true,
                filterPrePostApproval: [],
                filterDownloadPermission: [],
                filterDamId: '',
                filterSeason: [],
                filterPrefecture: '',
                filterTags: [],
                filterMediaType: [],
                filterAspect: [], 
                filterApprovedExternal: [],
                filterValidDate: undefined
            });

    }

    onSelectSortBy(value, type = null) {
        var stateType;
        if (type === 'aspect') {
            stateType = 'filterAspect'

        }
        else if (type === 'fuzzy') {
            stateType = 'isFuzzy';
            value = !this.state.isFuzzy;
        }
        else if (type === 'mediatype') {
            stateType = 'filterMediaType'
        }
        else if (type === 'prefecture') {
            stateType = 'filterPrefecture'
        }
        else if (type === 'season') {
            stateType = 'filterSeason'
        }
        else if (type === 'downloadPermission') {
            stateType = 'filterDownloadPermission'
        }
        else if (type === 'prePostApproval') {
            stateType = 'filterPrePostApproval'
        }
        else if (type === 'tag') {
            stateType = 'filterTags'
        }
        else if (type == 'dam_id') {
            stateType = 'filterDamId'
        }
        else if (type == 'expireDate') {
            stateType = 'filterValidDate'
        }
        else if (type == 'approvedExternal') {
            stateType = 'filterApprovedExternal'
        }
        else {
            stateType = 'sortBy'
        }

        this.setState({
            isLoading: false,
            [stateType]: value,
        });
    }

    render() {
        const { t, selectedProject, assetsForFolder,
            projects, match, history, location, signedInUser } = this.props;
        const hasSelectedProject = selectedProject && selectedProject.id;
        const { orderBy, sortBy, filterMediaType, filterAspect, filterApprovedExternal, filterPrefecture, filterSeason, filterTags, filterDamId, filterValidDate, filterPrePostApproval, filterDownloadPermission } = this.state;
        const searchText = queryString.parse(history.location.search).inputText;
        const shouldDisplayProjects = false;
        const shouldDisplayAssetGallery = true;
        const haveAssetsToAdd = assetsForFolder && assetsForFolder.length > 0;
        const userGroups = signedInUser.signInUserSession.idToken.payload["cognito:groups"];
        const selectOptions = [
            { label: 'Date', value: 'date' },
            { label: 'Size', value: 'size' },
            { label: 'Filename', value: 'filename' },
        ];
        /* Route-based Components */
        const projectGallery = (<ProjectGallery onSelectProject={this.onSelectProject} projects={projects} userGroups={userGroups} />);

        return (
            <Sidebar>
                {(onUpdateSideBarView) => {
                    onUpdateSideBarView('default');
                    return <React.Fragment>
                        <div className="app">
                            <div className='projects-page-container'>
                                <PageLoader
                                    show={this.state.isLoading}
                                    displayText={t('common.processing')} />

                                {haveAssetsToAdd &&
                                    <AddAssetsToFolderBanner
                                        onModalClose={this.onCloseFolderModal}
                                        assets={assetsForFolder}
                                        onToggleIsLoading={(isLoading) => this.setState({ isLoading })} />}

                                <Page fullWidth={!shouldDisplayProjects}>
                                    {shouldDisplayAssetGallery ? (
                                        <PanelWithData {...{ history, match, location }} orderBy={orderBy} sortBy={sortBy} filterMediaType={filterMediaType} filterAspect={filterAspect} filterApprovedExternal= {filterApprovedExternal} filterPrefecture={filterPrefecture} filterSeason={filterSeason} filterTags={filterTags} filterDamId={filterDamId} filterValidDate={filterValidDate} filterPrePostApproval={filterPrePostApproval} filterDownloadPermission={filterDownloadPermission}
                                            dataLoader={this.getDataLoader()}>
                                            {this.getAssetsPanel}
                                        </PanelWithData>) :
                                        (<>
                                            <Route exact path={match.url} render={() => projectGallery} />
                                            <Route path={`${match.url}/:projectId`} render={(props) => (
                                                <PanelWithData {...props} orderBy={orderBy} sortBy={sortBy} filterMediaType={filterMediaType} filterTags={filterTags} filterAspect={filterAspect} filterApprovedExternal={filterApprovedExternal} filterPrefecture={filterPrefecture} filterSeason={filterSeason} filterDamId={filterDamId} filterValidDate={filterValidDate} filterPrePostApproval={filterPrePostApproval} filterDownloadPermission={filterDownloadPermission} dataLoader={this.getDataLoader()}>
                                                    {this.getAssetsPanel}
                                                </PanelWithData>
                                            )} />
                                        </>)}
                                </Page>
                            </div >
                        </div>
                    </React.Fragment>
                }}
            </Sidebar>
        );
    }
}

const mapDispatchToProps = dispatch => ({
    projectActions: bindActionCreators(projectActions, dispatch),
    assetActions: bindActionCreators(assetActions, dispatch),
    folderActions: bindActionCreators(folderActions, dispatch),
});

const mapStateToProps = ({ user, project, asset, folder }, { location }) => {
    const pathObj = matchPath(location.pathname, {
        path: '/search/:projectId',
        exact: false,
        strict: false
    });
    const projectId = (pathObj && pathObj.params && pathObj.params.projectId) || null;
    return {
        userInfoComposite: user.userInfoComposite,
        selectedProject: projectId ? project.projects.find(project => project.id === parseInt(projectId, 10)) : null,
        projects: project.projects,
        assetsForFolder: folder.toAdd.assets,
        pageNumber: asset.pageNumber,
        numberOfHits: asset.numberOfHits,
        totalNumberImagesJNTO: asset.totalNumberImagesJNTO,
        totalNumberImagesJOMC: asset.totalNumberImagesJOMC,
        totalNumberVideosJNTO: asset.totalNumberVideosJNTO,
        totalNumberVideosJOMC: asset.totalNumberVideosJOMC,
        imageCount: asset.imageCount, 
        videoCount: asset.videoCount,
        signedInUser: user.signedInUser,
    }
};

export default withTranslation()(withRouter(connect(mapStateToProps, mapDispatchToProps)(ProjectsPage)));
