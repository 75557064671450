import baseVermillionApi from './baseVermillionApi';

const apiRoot = '/history-management';

export const searchMyUploadRequests = (userId, isComplete) => {
    const path = `${apiRoot}/advanced/uploads/users/${userId}?isComplete=${isComplete}`;
    return baseVermillionApi.get(path);
};

export const searchMyDownloadRequests = (userId, isAdmin) => {
    const path = `${apiRoot}/simple/downloads/users/${userId}/?isAdmin=${isAdmin}`;
    return baseVermillionApi.get(path);
};

export const saveMyUploadRequests = (uploadrequests) => {
    const promises = uploadrequests.map(req => updateMyUploadRequest(req.id, req.context));
    return Promise.all(promises);
};

export const updateMyUploadRequest = (uploadHistoryId, context) => {
    const path = `${apiRoot}/advanced/uploads/${uploadHistoryId}`;
    return baseVermillionApi.put(path, {
        context,
    });
};

export const downloadHistoryExcel = (userId, isAdmin, startDate, endDate) => {
    var startDateFormatted;
    var endDateFormatted;
    if(startDate){
        var offset = startDate.getTimezoneOffset()
        var dateDownloaded = new Date(startDate.getTime() - (offset*60*1000))
        startDateFormatted = dateDownloaded.toISOString();
    }
    if(endDate){
        var offset = endDate.getTimezoneOffset()
        var dateDownloaded = new Date(endDate.getTime() - (offset*60*1000))
        endDateFormatted = dateDownloaded.toISOString();
    }
    const path = `${apiRoot}/advanced/downloads/export/excel/?userId=${userId}&isAdmin=${isAdmin}&startDate=${startDateFormatted}&endDate=${endDateFormatted}`;
    return baseVermillionApi.get(path, {
    });
};


export const adminSearchUserUploadRequests = (adminUserId, isComplete) => {
    const path = `${apiRoot}/advanced/uploads/supervisor/${adminUserId}?isComplete=${isComplete}`;
    return baseVermillionApi.get(path);
};

export const adminApproveUploads = (adminUserId, uploadHistoryIds, dispatch) => {
    const path = `${apiRoot}/advanced/uploads/supervisor/${adminUserId}/approve`;
    return baseVermillionApi.postWithErrorHandling(path, {
        uploadHistoryIds,
    }, dispatch);
};

export const sendContactEmail = (userId, bugType, bugTitle, bugDetails, dispatch) => {
    const path = `${apiRoot}/bug-report/submit?userId=${userId}&bugType=${bugType}&bugTitle=${bugTitle}&bugDetails=${bugDetails}`;
    return baseVermillionApi.postWithErrorHandling(path, {
        userId,
    }, dispatch);
}

export const imageUploadByUser = (userId, dispatch) => {
    const path = `${apiRoot}/simple/uploads/users/users-upload?userId=${userId}`;
    return baseVermillionApi.get(path);
};

export const imageUploadByOrg = (orgId, dispatch) => {
    const path = `${apiRoot}/simple/uploads/organizations/org-upload?orgId=${orgId}`;
    return baseVermillionApi.get(path);
};

export const userByUploadId = (assetId, dispatch) => {
    const path = `${apiRoot}/simple/asset?assetId=${assetId}`;
    return baseVermillionApi.get(path);
};

export const adminRejectUploads = (adminUserId, rejections, dispatch) => {
    const path = `${apiRoot}/advanced/uploads/supervisor/${adminUserId}/reject`;
    const contextList = rejections.map(rej => ({
        uploadHistoryId: rej.id,
        notes: rej.notes,
    }));
    return baseVermillionApi.postWithErrorHandling(path, {
        contextList,
    }, dispatch);
};