import 'babel-polyfill';
import 'react-app-polyfill/ie11';
import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { persistor, store } from 'redux/configureStore';
import config from './config';
import Amplify from 'aws-amplify';
import { Auth } from 'aws-amplify';
import { PersistGate } from 'redux-persist/lib/integration/react';
import './App/App';
import '@shopify/polaris/styles.css';
import { AppProvider } from '@shopify/polaris';
import App from './App/App';
import ja from "@shopify/polaris/locales/ja.json";

import './i18n';
import i18n from 'i18next';

const theme = {
    colors: {
        topBar: {
            background: 'white',
            backgroundDarker: '#white',
            backgroundLighter: '#fafafa',
            color: 'gray',
        },
    },
    logo: {
        width: 40,
        topBarSource:
            'https://asset.japan.travel/image/upload/v1515551210/static/svg/common/logo_black.svg',
        url: '/search',
        accessibilityLabel: 'Japan National Tourism Organization',
    },
};

i18n.on('languageChanged', (lng) => {document.documentElement.setAttribute('lang', lng);})

Amplify.configure({
    Auth: {
        mandatorySignIn: false,
        region: config.cognito.REGION,
        userPoolId: config.cognito.USER_POOL_ID,
        userPoolWebClientId: config.cognito.APP_CLIENT_ID
    },
    API: {
        endpoints: [
            {
                name: 'vermillion',
                endpoint: config.apiGateway.URL,
                region: config.apiGateway.REGION,
                custom_header: async () => {
                    return { Authorization: (await Auth.currentSession()).idToken.jwtToken }
                }
            }
        ]
    }
});

ReactDOM.render(
    <Provider store={store}>
        <PersistGate persistor={persistor}>
            <AppProvider i18n={ja} theme={theme}>
                <App />
            </AppProvider>
        </PersistGate>
    </Provider>,
    document.getElementById('root')
);
