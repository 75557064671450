import React from 'react';
import { Player } from 'video-react';
import 'video-react/dist/video-react.css';
import MediaDisplayFooter from '../../footers/MediaDisplayFooter/MediaDisplayFooter';

const VideoDisplay = ({ height, width, thumbnailUrl, secureUrl }) => (
    <>
        <Player playsInline poster={thumbnailUrl} src={secureUrl} />
    </>);

export default VideoDisplay;
