import  React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Switch, withRouter, Route, matchPath } from 'react-router-dom';
import { TopBar, Navigation } from '@shopify/polaris';
import {　CircleAlertMajorMonotone　} from '@shopify/polaris-icons';
import { withTranslation } from 'react-i18next';
import i18n from 'i18next';
import * as _ from 'lodash';
import queryString from 'query-string';
import SearchNavBar from '../../components/shared/navBars/SearchNavBar/SearchNavBar';
import UserMenuGenerator from '../../components/AssetSearchBar/UserMenuGenerator';
import * as userActions from '../../redux/actions/userActions';
import * as organizationActions from '../../redux/actions/organizationActions';
import * as navigationActions from '../../redux/actions/navigationActions';
import * as permissionActions from '../../redux/actions/permissionActions';
import TopNavigation from '../../components/Navigation/TopNavigation/TopNavigation'


class Navigator extends React.Component {
    constructor() {
        super();
        this.state = {
            userMenuOpen: false,
            searchText: '',
        };

        /* binding methods to "this" instance */
        this.onSearch = _.debounce(this.onSearch.bind(this), 300); // throttle queries to 0.3 seconds
        this.getProjectPlaceholderText = this.getProjectPlaceholderText.bind(this);
        this.toggleUserMenu = this.toggleUserMenu.bind(this);
        this.onNavigateToPage = this.onNavigateToPage.bind(this);
        this.onUserLogout = this.onUserLogout.bind(this);
        this.onUpdateSearchText = this.onUpdateSearchText.bind(this);
        this.getFolderPlaceholderText = this.getFolderPlaceholderText.bind(this);
    }

    /* component lifecycle methods */
    componentDidMount() {
        const { userInfoComposite, organizationActions, location } = this.props;
        this.props.userActions.getSignedInUser();
        const searchText = queryString.parse(location.search).inputText;
        this.onUpdateSearchText(searchText);
        this.props.permissionActions.getMyIncomingPermissionRequests(this.props.userInfoComposite.user.id, false)
        this.props.permissionActions.getMyOutgoingPermissionRequests(this.props.userInfoComposite.user.id, false)
        if (userInfoComposite && userInfoComposite.user) {
            organizationActions.getOrganizationById(userInfoComposite.user.organizationId);
        }

        // if user is not admin and page mounts on admin page, redirect to project
        if(location.pathname.indexOf('admin') >= 0){
            const userList = ['admin', 'jnto-hq-admin'];
            const userGroups = this.props.signedInUser.signInUserSession.idToken.payload["cognito:groups"];
            const isAdmin = userGroups.some(r=> userList.indexOf(r) >= 0);
            if(!isAdmin){
                this.onNavigateToPage('search');
            };
        }
    }

    componentDidUpdate(prevProps) {
        const { location } = this.props;
        if (location !== prevProps.location) {
            const updatedSearchText = queryString.parse(location.search).inputText || '';
            this.onUpdateSearchText(updatedSearchText);
        }
    }

    /* custom methods */

    onUserLogout() {
        const { navigationActions, userActions } = this.props;
        navigationActions.switchPage('user-authentication');
        this.props.history.push('');
        userActions.userSignOut();
    }

    onNavigateToPage(pageName) {
        const { history } = this.props;
        history.push(`/${pageName}`);
    }

    onUpdateSearchText(inputText) {
        this.setState({
            searchText: inputText,
        });
    }

    onSearch(inputText) {
        const { history } = this.props;
        const queryStr = inputText ? `?inputText=${inputText}` : '';
        history.push(`${history.location.pathname}${queryStr}`);
    }

    toggleUserMenu = () => {
        this.setState({ userMenuOpen: !this.state.userMenuOpen });
    };

    getProjectPlaceholderText() {
        const { t, location, projects } = this.props;
        const pathObj = matchPath(location.pathname, {
            path: '/search/:projectId',
            exact: true,
            strict: false
        });
        const projectId = (projects.length > 0 && pathObj && pathObj.params && pathObj.params.projectId) || null;
        if (projectId) {
            const selectedProject = projects.find(project => project.id === parseInt(projectId, 10));
            return t('workflows.projects.searchWithinProject', { projectName: selectedProject.projectName });
        }

        return t('workflows.projects.searchAcrossProjects');
    }

    getFolderPlaceholderText() {
        const { location, t, folders } = this.props;
        const pathObj = matchPath(location.pathname, {
            path: '/folders/:folderId',
            exact: true,
            strict: false
        });
        const folderId = (pathObj && pathObj.params && pathObj.params.folderId) || null;
        if (folderId && folders.length > 0) {
            const selectedFolder = folders.find(folder => folder.id === parseInt(folderId, 10));
            if(selectedFolder){
                return t('workflows.folders.searchWithinFolder', { folderName: selectedFolder.folderName });
            }
        }
    }

    render() {
        const { searchText } = this.state;
        const { organization, signedInUser, userInfoComposite, incomingPermissionRequests, t } = this.props;
        const organizationName = organization.organizationName;
        const fullName = userInfoComposite.user && `${userInfoComposite.user.firstName} ${userInfoComposite.user.lastName}`;
        const userNameAbbreviations = userInfoComposite.user && (userInfoComposite.user.firstName[0] + userInfoComposite.user.lastName[0]);
        const userGroups = signedInUser.signInUserSession.idToken.payload["cognito:groups"];
        const userMenuItems = new UserMenuGenerator(this.onNavigateToPage, this.onUserLogout, i18n).getFromUserGroup(userGroups);

        return (
            <>
                {signedInUser &&
                    <div>
                        {/* <TopNavigation permissionRequests= {(Array.isArray(incomingPermissionRequests) && incomingPermissionRequests.length)} /> */}
                        {/*<TopBar*/}
                        {/*    showNavigationToggle={true}*/}
                        {/*    searchResultsVisible={false}*/}
                        {/*    searchField={searchFieldMarkup}*/}
                        {/*    userMenu={userMenuMarkup}*/}
                        {/*/>*/}
                    </div>
                }
            </>);
    }
};

const mapStateToProps = ({ organization, project, user, asset, folder, myPermissionRequests }) => ({
    organization: organization.organization,
    userId: user.userInfoComposite && user.userInfoComposite.user ? user.userInfoComposite.user.id : undefined,
    signedInUser: user.signedInUser,
    userInfoComposite: user.userInfoComposite,
    numberOfHits: asset.numberOfHits,
    projects: project.projects,
    folders: folder.folders,
    incomingPermissionRequests: myPermissionRequests.incomingPermissionRequests,
});

const mapDispatchToProps = dispatch => ({
    userActions: bindActionCreators(userActions, dispatch),
    organizationActions: bindActionCreators(organizationActions, dispatch),
    navigationActions: bindActionCreators(navigationActions, dispatch),
    permissionActions: bindActionCreators(permissionActions, dispatch),
});

export default withTranslation()(withRouter(connect(mapStateToProps, mapDispatchToProps)(Navigator)));
