import React, { } from "react";
import { withTranslation } from 'react-i18next';
import { Collapsible, Button, Popover, OptionList } from '@shopify/polaris';
import i18n from 'i18next';
import {PREFECTURE_FILTERS}from "../../../utilities/constants";

import {
  Checkbox,
  TextField,
  DatePicker,
  Icon
} from "@shopify/polaris";
import { CalendarMinor } from '@shopify/polaris-icons';
import { filter } from "jszip";


const today = new Date()
const initialState = {
  textField: i18n.t('filters.enterAssetId'),
  dateTextField: '',
  viewMoreFilters: true,
  datePickerVisible: false,
  month: today.getMonth(),
  year: today.getFullYear(),
  selectedDates: undefined,
  typePopoverActive: false,
  type: [],
  ratioPopoverActive: false,
  ratio: [],
  seasonPopoverActive: false,
  season: [],
  approvalRequiredPopoverActive: false,
  approvalRequired: [],
  prePostApprovalPopoverActive: false,
  prePostApproval: [],
  tagsPopoverActive: false,
  tags: [],
  prefecturePopoverActive: false,
  prefecture: [],
  externalUsePopoverActive: false,
  externalUse: [],
};

class CollapsibleFilterBar extends React.Component {
  constructor() {
    super();
    const today = new Date()
    this.state = {
      textField: i18n.t('filters.enterAssetId'),
      dateTextField: '',
      viewMoreFilters: true,
      datePickerVisible: false,
      month: today.getMonth(),
      year: today.getFullYear(),
      selectedDates: undefined,
      typePopoverActive: false,
      type: [],
      ratioPopoverActive: false,
      ratio: [],
      seasonPopoverActive: false,
      season: [],
      approvalRequiredPopoverActive: false,
      approvalRequired: [],
      prePostApprovalPopoverActive: false,
      prePostApproval: [],
      tagsPopoverActive: false,
      tags: [],
      prefecturePopoverActive: false,
      prefecture: [],
      externalUsePopoverActive: false,
      externalUse: [],
    };

    this.showDatePicker = this.showDatePicker.bind(this);
    // this.setSelectedDates = this.setSelectedDates.bind(this);
    this.handleMonthChange = this.handleMonthChange.bind(this);
    this.getFilterList = this.getFilterList.bind(this);
    this.setSelected = this.setSelected.bind(this);

    // page event handlers
    this.wrapperRef = React.createRef();
    this.handleClickOutside = this.handleClickOutside.bind(this);
  }

  componentDidMount() {
    document.addEventListener("mousedown", this.handleClickOutside);
  }

  componentDidUpdate(prevProps, prevState) {
    if(!prevProps.isReset && this.props.isReset){
      this.setState(
        initialState
      );    }
  }

  componentWillUnmount() {
    document.removeEventListener("mousedown", this.handleClickOutside);
  }

  handleChange() {
    this.setState({ checked: !this.state.checked })
  }

  showDatePicker() {
    this.setState({ datePickerVisible: true })
  }

  // setSelectedDates(selection) {
  //   const currentYear = this.state.selectedDates && this.state.selectedDates.start.getFullYear()
  //   const currentMonth = this.state.selectedDates && this.state.selectedDates.start.getMonth()
  //   const currentDate = this.state.selectedDates && this.state.selectedDates.start.getDate()
  //   const newYear = selection.start.getFullYear()
  //   const newMonth = selection.start.getMonth()
  //   const newDate = selection.start.getDate()
  //   if (currentDate === newDate && currentMonth === newMonth && currentYear === newYear){
  //     this.setState({
  //       datePickerVisible: false,
  //       selectedDates: undefined,
  //       dateTextField: undefined
  //     })    }
  //     else{
  //   this.setState({
  //     datePickerVisible: false,
  //     selectedDates: {
  //       ...selection
  //     },
  //     dateTextField: new Date(selection.start).toDateString()
  //   })
  // }
  // }

  handleMonthChange(month, year) {
    this.setState({
      month: month,
      year: year,
    })
  }


  handleClickOutside(event) {
    if (this.wrapperRef && this.wrapperRef.current && !this.wrapperRef.current.contains(event.target)) {
      this.setState({ datePickerVisible: false })
    }
  }

  setSelected(selected, filterType, filterTextOveride){
    var {t} = this.props;
    var previousSelection = this.state[filterType];
    var newSelection;
    if(previousSelection.length > selected.length){
      for(var s of previousSelection){
        if(selected.indexOf(s) <0){
          newSelection=s;
        }
      } 
    }
    else{
      newSelection= selected[0]
    }

    var filterText = 'filters.' + newSelection
    if(filterType==='approvalRequired' || filterType==='prePostApproval' ){
        filterText = filterText + 'Checked'
    }
    if(filterType==='prefecture' ){
      filterText = 'filters.' + newSelection.toLowerCase()
    }
    if(filterType==='tags'){
      filterText = t(filterText)
    }
    
    this.props.handlFilterChange(newSelection, filterText)
    if(filterType==='type'){
      this.setState({type:selected})
    }
    if(filterType==='ratio'){
      this.setState({ratio:selected})
    }
    if(filterType==='season'){
      this.setState({season:selected})
    }
    if(filterType==='approvalRequired'){
      this.setState({approvalRequired:selected})
    }
    if(filterType==='prePostApproval'){
      this.setState({prePostApproval:selected})
    }
    if(filterType==='tags'){
      this.setState({tags:selected})
    }
    if(filterType==='prefecture'){
      this.setState({prefecture:selected})
    }
    if(filterType==='externalUse'){
      this.setState({externalUse:selected})
    }
  }

  getFilterList(){
    const {t, filters} = this.props;
    const filterList = filters.filterTranslations.map((x) => {
      if(x.indexOf('prefecturefilters') >= 0){
        return t(x.split('prefecture')[1])
      }
      else{
        return t(x)
      }
    })
    return filterList.join(', ')

  }
  

  render() {
    const { t, handleTextChange, handlFilterChange, filters, isReset } = this.props;

    const toggleTypePopoverActive = ()=> {
      let active = this.state.typePopoverActive
      this.setState({typePopoverActive: !active})
    }

    const toggleRatioPopoverActive = ()=> {
      let active = this.state.ratioPopoverActive
      this.setState({ratioPopoverActive: !active})
    }

    const toggleSeasonPopoverActive = ()=> {
      let active = this.state.seasonPopoverActive
      this.setState({seasonPopoverActive: !active})
    }

    const toggleApprovalRequiredPopoverActive = ()=> {
      let active = this.state.approvalRequiredPopoverActive
      this.setState({approvalRequiredPopoverActive: !active})
    }

    const togglePrePostApprovalPopoverActive = ()=> {
      let active = this.state.prePostApprovalPopoverActive
      this.setState({prePostApprovalPopoverActive: !active})
    }

    const toggleTagsPopoverActive = ()=> {
      let active = this.state.tagsPopoverActive
      this.setState({tagsPopoverActive: !active})
    }

    const togglePrefecturePopoverActive = ()=> {
      let active = this.state.prefecturePopoverActive
      this.setState({prefecturePopoverActive: !active})
    }
    const toggleExternalUsePopoverActive = ()=> {
      let active = this.state.externalUsePopoverActive
      this.setState({externalUsePopoverActive: !active})
    }

    const activatorType = (
      <button className="jnto-selector-container jnto-selector-container-filter" onClick={toggleTypePopoverActive}>
        {t('filters.assetType')}
      </button>
    );

    const activatorRatio = (
      <button className="jnto-selector-container jnto-selector-container-filter" onClick={toggleRatioPopoverActive}>
        {t('filters.assetRatio')}
      </button>
    );

    const activatorSeason = (
      <button className="jnto-selector-container jnto-selector-container-filter" onClick={toggleSeasonPopoverActive}>
        {t('filters.seasons')}
      </button>
    );

    const activatorApprovalRequired = (
      <button className="jnto-selector-container jnto-selector-container-filter" onClick={toggleApprovalRequiredPopoverActive}>
        {t('filters.downloadPermission')}
      </button>
    );

    const activatorPrePostApproval = (
      <button className="jnto-selector-container jnto-selector-container-filter" onClick={togglePrePostApprovalPopoverActive}>
        {t('filters.prePostApproval')}
      </button>
    );

    const activatorTags = (
      <button className="jnto-selector-container jnto-selector-container-filter" onClick={toggleTagsPopoverActive}>
        {t('filters.tags')}
      </button>
    );

    const activatorExternalUse = (
      <button className="jnto-selector-container jnto-selector-container-filter" onClick={toggleExternalUsePopoverActive}>
        {t('filters.externalUse')}
      </button>
    );

    const activatorPrefecture = (
      <button className="jnto-selector-container jnto-selector-container-filter" onClick={togglePrefecturePopoverActive}>
        {t('filters.prefecture')}
      </button>
    );

    const prefectureRows = [];
    for (var pref of PREFECTURE_FILTERS) {
      prefectureRows.push(
         {
          value:pref, 
          label: t('filters.' + pref.toLocaleLowerCase())
        }
      )
    }
    return (
      <div>
        <div className="jnto-filter-bar-collapsible-wrapper">
          <div className={'jnto-filter-bar-collapsible-top-row'}>
            <div>{t('filters.filter')}: {this.getFilterList()}</div>
            <div>
              <button onClick={(() => {
                this.setState({ viewMoreFilters: !this.state.viewMoreFilters })
              })} className={`chev-down-btn red ${this.state.viewMoreFilters ? 'chev-up' : ''}`}>
                <span>{this.state.viewMoreFilters ? 'Close' : 'Open'}</span></button>
            </div>
          </div>
          <div>
            <Collapsible
              open={this.state.viewMoreFilters}
              id="basic-collapsible"
              transition={{ duration: "500ms", timingFunction: "ease-in-out" }}
              expandOnPrint
            >
              <>
                <div className={'jnto-filter-bar-collapsible-row'}>
                  <div className={'jnto-filter-bar-collapsible-popover'}>
                    <Popover
                      active={this.state.typePopoverActive}
                      activator={activatorType}
                      onClose={toggleTypePopoverActive}>
                        <OptionList
                          onChange={(a)=>{this.setSelected(a, "type")}}
                          allowMultiple
                          options={[
                            {
                              value: 'image',
                              label: t('filters.image')
                            },
                            {
                              value: 'video', 
                              label: t('filters.video')},
                          ]}
                          selected={this.state.type}
                        />
                    </Popover>
                  </div>
                <div className={'jnto-filter-bar-collapsible-popover'}>
                <Popover
                  active={this.state.ratioPopoverActive}
                  activator={activatorRatio}
                  onClose={toggleRatioPopoverActive}>
                    <OptionList
                      onChange={(a)=>{this.setSelected(a, "ratio")}}
                      allowMultiple
                      options={[
                        {
                          value: 'portrait',
                          label: t('filters.portrait')
                        },
                        {
                          value: 'landscape', 
                          label: t('filters.landscape')
                        },
                        {
                          value: 'square', 
                          label: t('filters.square')
                        },
                      ]}
                      selected={this.state.ratio}
                    />
                </Popover>
                </div>

                <div className={'jnto-filter-bar-collapsible-popover'}>
                  <Popover
                    active={this.state.prefecturePopoverActive}
                    activator={activatorPrefecture}
                    onClose={togglePrefecturePopoverActive}>
                      <OptionList
                        onChange={(a)=>{this.setSelected(a, "prefecture")}}
                        allowMultiple
                        options={prefectureRows}
                        selected={this.state.prefecture}
                      />
                  </Popover>
                </div>
                <div className={'jnto-filter-bar-collapsible-popover'}>
                  <Popover
                    active={this.state.seasonPopoverActive}
                    activator={activatorSeason}
                    onClose={toggleSeasonPopoverActive}>
                      <OptionList
                        onChange={(a)=>{this.setSelected(a, "season")}}
                        allowMultiple
                        options={[
                          {
                            value: 'spring',
                            label: t('filters.spring')
                          },
                          {
                            value: 'summer', 
                            label: t('filters.summer')
                          },
                          {
                            value: 'autumn', 
                            label: t('filters.autumn')
                          },
                          {
                            value: 'winter', 
                            label: t('filters.winter')
                          },
                          {
                            value: 'allSeasons', 
                            label: t('filters.allSeasons')
                          },
                        ]}
                        selected={this.state.season}
                      />
                  </Popover>
                  </div>
                </div>
                <div className={'jnto-filter-bar-collapsible-row'}>
                <div className={'jnto-filter-bar-collapsible-popover'}>
                  <Popover
                    active={this.state.approvalRequiredPopoverActive}
                    activator={activatorApprovalRequired}
                    onClose={toggleApprovalRequiredPopoverActive}>
                      <OptionList
                        onChange={(a)=>{this.setSelected(a, "approvalRequired")}}
                        allowMultiple
                        options={[
                          {
                            value: 'downloadPermissionYes',
                            label: t('filters.downloadPermissionYes')
                          },
                          {
                            value: 'downloadPermissionNo', 
                            label: t('filters.downloadPermissionNo')
                          },
                        ]}
                        selected={this.state.approvalRequired}
                      />
                  </Popover>
                </div>
                <div className={'jnto-filter-bar-collapsible-popover'}>
                  <Popover
                    active={this.state.prePostApprovalPopoverActive}
                    activator={activatorPrePostApproval}
                    onClose={togglePrePostApprovalPopoverActive}>
                      <OptionList
                        onChange={(a)=>{this.setSelected(a, "prePostApproval")}}
                        allowMultiple
                        options={[
                          {
                            value: 'prePostApprovalYes',
                            label: t('filters.prePostApprovalYes')
                          },
                          {
                            value: 'prePostApprovalNo', 
                            label: t('filters.prePostApprovalNo')
                          },
                        ]}
                        selected={this.state.prePostApproval}
                      />
                  </Popover>
                </div>
                <div className={'jnto-filter-bar-collapsible-popover'}>
                <Popover
                  active={this.state.tagsPopoverActive}
                  activator={activatorTags}
                  onClose={toggleTagsPopoverActive}>
                    <OptionList
                      onChange={(a)=>{this.setSelected(a, "tags")}}
                      allowMultiple
                      options={[
                        {
                          value: 'tag1',
                          label: t('filters.foodDrink')
                        },
                        {
                          value: 'tag2', 
                          label: t('filters.shopping')
                        },
                        {
                          value: 'tag3', 
                          label: t('filters.historicSites')
                        },
                        {
                          value: 'tag4', 
                          label: t('filters.architecture')
                        },
                        {
                          value: 'tag5', 
                          label: t('filters.art')
                        },
                        {
                          value: 'tag6', 
                          label: t('filters.themeParks')
                        },
                        {
                          value: 'tag7', 
                          label: t('filters.cities')
                        },
                        {
                          value: 'tag8', 
                          label: t('filters.entertainment')
                        },
                        {
                          value: 'tag9', 
                          label: t('filters.subculture')
                        },
                        {
                          value: 'tag10', 
                          label: t('filters.fashion')
                        },
                        {
                          value: 'tag11', 
                          label: t('filters.tradition')
                        },
                        {
                          value: 'tag12', 
                          label: t('filters.skillsKnowledge')
                        },
                        {
                          value: 'tag13', 
                          label: t('filters.localLifestyle')
                        },
                        {
                          value: 'tag14', 
                          label: t('filters.festival')
                        },
                        {
                          value: 'tag15', 
                          label: t('filters.sports')
                        },
                        {
                          value: 'tag16', 
                          label: t('filters.outdoors')
                        },
                        {
                          value: 'tag17', 
                          label: t('filters.resorts')
                        },
                        {
                          value: 'tag18', 
                          label: t('filters.nature')
                        },
                        {
                          value: 'tag19', 
                          label: t('filters.nightlife')
                        },
                        {
                          value: 'tag20', 
                          label: t('filters.hotSprings')
                        },
                        
                      ]}
                      selected={this.state.tags}
                    />
                </Popover>
                </div>
                <div className={'jnto-filter-bar-collapsible-popover'}>
                  <Popover
                    active={this.state.externalUsePopoverActive}
                    activator={activatorExternalUse}
                    onClose={toggleExternalUsePopoverActive}>
                      <OptionList
                        onChange={(a)=>{this.setSelected(a, "externalUse")}}
                        allowMultiple
                        options={[
                          {
                            value: 'externalUseApproved',
                            label: t('filters.externalUseApproved')
                          },
                          {
                            value: 'externalUseDenied', 
                            label: t('filters.externalUseDenied')
                          },
                          {
                            value: 'externalUseContact', 
                            label: t('filters.externalUseContact')
                          },
                        ]}
                        selected={this.state.externalUse}
                      />
                  </Popover>
                </div>


                  {/* <div className={'filter-item'}>
                    <div className={'filter-item-label'}>{t('filters.assetRatio')}</div>
                    <div className={'filter-item-fields'}>
                      <span className={'filter-item-field filter-item-field-checkbox'}>
                        <Checkbox
                          label={t('filters.portrait')}
                          checked={filters.checkedPortrait}
                          onChange={() => handlFilterChange('portrait', 'filters.portrait')}
                        />
                      </span>
                      <span className={'filter-item-field filter-item-field-checkbox'}>
                        <Checkbox
                          label={t('filters.landscape')}
                          checked={filters.checkedLandscape}
                          onChange={() => handlFilterChange('landscape', 'filters.landscape')}
                        />
                      </span>
                      <span className={'filter-item-field filter-item-field-checkbox'}>
                        <Checkbox
                          label={t('filters.square')}
                          checked={filters.checkedSquare}
                          onChange={() => handlFilterChange('square', 'filters.square')}
                        />
                      </span>
                    </div>
                  </div> */}
                  {/* <div className={'filter-item'}>
                    <div className={'filter-item-label'}>{t('filters.prefecture')}</div>
                    <div className={'filter-item-fields'}>
                      <div className='field-group'>
                        <div className='jnto-selector-container'>
                          <select value={filters.checkedPrefecture} name='select-input' onChange={e => handlFilterChange('prefecture', "prefecturefilters." + e.target.value.toLowerCase(), e.target.value)}>
                            <option></option>
                            {prefectureRows}
                          </select>
                        </div>
                      </div>
                    </div>
                  </div> */}

                </div>
                <div className={'jnto-filter-bar-collapsible-row'} style={{"padding-bottom":"0px"}}>
                  {/* <div className={'filter-item'}>
                    <div className={'filter-item-label'}>{t('filters.seasons')}</div>
                    <div className={'filter-item-fields'}>
                      <span className={'filter-item-field filter-item-field-checkbox'}>
                        <Checkbox
                          label={t('filters.spring')}
                          checked={filters.checkedSpring}
                          onChange={() => handlFilterChange('spring','filters.spring')}
                        />
                      </span>
                      <span className={'filter-item-field filter-item-field-checkbox'}>
                        <Checkbox
                          label={t('filters.summer')}
                          checked={filters.checkedSummer}
                          onChange={() => handlFilterChange('summer', 'filters.summer')}
                        />
                      </span>
                      <span className={'filter-item-field filter-item-field-checkbox'}>
                        <Checkbox
                          label={t('filters.autumn')}
                          checked={filters.checkedAutumn}
                          onChange={() => handlFilterChange('autumn', 'filters.autumn')}
                        />
                      </span>
                      <span className={'filter-item-field filter-item-field-checkbox'}>
                        <Checkbox
                          label={t('filters.winter')}
                          checked={filters.checkedWinter}
                          onChange={() => handlFilterChange('winter', 'filters.winter')}
                        />
                      </span>
                      <span className={'filter-item-field filter-item-field-checkbox'}>
                        <Checkbox
                          label={t('filters.allSeasons')}
                          checked={filters.checkedAllSeason}
                          onChange={() => handlFilterChange('allSeasons', 'filters.allSeasons')}
                        />
                      </span>
                    </div>
                  </div> */}
                  {/* <div className={'filter-item'}>
                    <div className={'filter-item-label'}>{t('filters.downloadPermission')}</div>
                    <div className={'filter-item-fields'}>
                      <span className={'filter-item-field filter-item-field-checkbox'}>
                        <Checkbox
                          label={t('filters.downloadPermissionYes')}
                          checked={filters.downloadPermissionYesChecked}
                          onChange={() => handlFilterChange('downloadPermissionYes', 'filters.downloadPermissionYesChecked')}
                        />
                      </span>
                      <span className={'filter-item-field filter-item-field-checkbox'}>
                        <Checkbox
                          label={t('filters.downloadPermissionNo')}
                          checked={filters.downloadPermissionNoChecked}
                          onChange={() => handlFilterChange('downloadPermissionNo', 'filters.downloadPermissionNoChecked')}
                        />
                      </span>
                    </div>
                  </div> */}
                  {/* <div className={'filter-item'}>
                    <div className={'filter-item-label'}>{t('filters.prePostApproval')}</div>
                    <div className={'filter-item-fields'}>
                      <span className={'filter-item-field filter-item-field-checkbox'}>
                        <Checkbox
                          label={t('filters.prePostApprovalYes')}
                          checked={filters.prePostApprovalYesChecked}
                          onChange={() => handlFilterChange('prePostApprovalYes', 'filters.prePostApprovalYesChecked')}
                        />
                      </span>
                      <span className={'filter-item-field filter-item-field-checkbox'}>
                        <Checkbox
                          label={t('filters.prePostApprovalNo')}
                          checked={filters.prePostApprovalNoChecked}
                          onChange={() => handlFilterChange('prePostApprovalNo', 'filters.prePostApprovalNoChecked')}
                        />
                      </span>
                    </div>
                  </div> */}
                  <div className={'filter-item'}>
                    <div className={'filter-item-label'}>{t('filters.expireDate')}</div>
                    <div className={'filter-item-fields'}>
                      {/* <span className={'filter-item-field filter-item-field-checkbox'}>
                        <Checkbox
                          label={t('filters.yes')}
                          checked={filters.checkedexpireDateYes}
                          onChange={() => handlFilterChange('expireDateYes', 'filters.checkedExpireDateYes')}
                        />
                      </span> */}
                      <span className={'filter-item-field filter-item-field-checkbox'}>
                        <Checkbox
                          label={t('filters.expireDateNo')}
                          checked={filters.checkedexpireDateNo}
                          onChange={() => handlFilterChange('expireDatelNo', 'filters.checkedExpireDateNo')}
                        />
                      </span>
                    </div>
                  </div>
                  <div className={'filter-item'}>
                    <div className={'filter-item-label'}>{t('filters.assetIdTopBar')}</div>
                    <div className={'filter-item-fields'}>
                      <span style={{height:'70px'}}className={'filter-item-field filter-item-field-text'}>
                        <TextField
                          type="text"
                          value={filters.textField}
                          onChange={handleTextChange}
                        />
                      </span>
                    </div>
                  </div>
                  {/* <div className={'filter-item'}>
                    <div className={'filter-item-label'}>{t('filters.expireDate')}</div>
                    <div className={'filter-item-fields'}>
                      <span className={'filter-item-field filter-item-field-text'}>
                        <TextField
                          type="text"
                          value={this.state.dateTextField}
                          readOnly
                          onChange={() => { }}
                          suffix={<Icon source={CalendarMinor} />}
                          onFocus={this.showDatePicker}
                        />
                        {this.state.datePickerVisible &&
                          <div className={'filter-datepicker-container'} ref={this.wrapperRef}>

                            <DatePicker
                              month={this.state.month}
                              year={this.state.year}
                              onChange={(date)=> {this.setSelectedDates(date); handlFilterChange('date', 'filters.expireDate', date.end)}}
                              onMonthChange={this.handleMonthChange}
                               selected= {this.state.selectedDates && this.state.selectedDates}
                            />
                          </div>
                        }
                      </span>
                    </div>
                  </div> */}
                </div>
                {/* <div className={'jnto-filter-bar-collapsible-row'}>
                  <div className={'filter-item'}>
                    <div className={'filter-item-label'}>{t('filters.tags')}</div>
                    <div className={'filter-item-fields'}>
                      <span className={'filter-item-field filter-item-field-checkbox'}>
                        <Checkbox
                          label={t('filters.foodDrink')}
                          checked={filters.tag1}
                          onChange={() => handlFilterChange('tag1', t('filters.foodDrink'))}
                        />
                      </span>
                      <span className={'filter-item-field filter-item-field-checkbox'}>
                        <Checkbox
                          label={t('filters.shopping')}
                          checked={filters.tag2}
                          onChange={() => handlFilterChange('tag2', t('filters.shopping'))}
                        />
                      </span>
                      <span className={'filter-item-field filter-item-field-checkbox'}>
                        <Checkbox
                          label={t('filters.historicSites')}
                          checked={filters.tag3}
                          onChange={() => handlFilterChange('tag3', t('filters.historicSites'))}
                        />
                      </span>
                      <span className={'filter-item-field filter-item-field-checkbox'}>
                        <Checkbox
                          label={t('filters.architecture')}
                          checked={filters.tag4}
                          onChange={() => handlFilterChange('tag4', t('filters.architecture'))}
                        />
                      </span>
                      <span className={'filter-item-field filter-item-field-checkbox'}>
                        <Checkbox
                          label={t('filters.art')}
                          checked={filters.tag5}
                          onChange={() => handlFilterChange('tag5', t('filters.art'))}
                        />
                      </span>
                      <span className={'filter-item-field filter-item-field-checkbox'}>
                        <Checkbox
                          label={t('filters.themeParks')}
                          checked={filters.tag6}
                          onChange={() => handlFilterChange('tag6', t('filters.themeParks'))}
                        />
                      </span>
                      <span className={'filter-item-field filter-item-field-checkbox'}>
                        <Checkbox
                          label={t('filters.cities')}
                          checked={filters.tag7}
                          onChange={() => handlFilterChange('tag7', t('filters.cities'))}
                        />
                      </span>
                      <span className={'filter-item-field filter-item-field-checkbox'}>
                        <Checkbox
                          label={t('filters.entertainment')}
                          checked={filters.tag8}
                          onChange={() => handlFilterChange('tag8', t('filters.entertainment'))}
                        />
                      </span>
                      <span className={'filter-item-field filter-item-field-checkbox'}>
                        <Checkbox
                          label={t('filters.subculture')}
                          checked={filters.tag9}
                          onChange={() => handlFilterChange('tag9', t('filters.subculture'))}
                        />
                      </span>
                      <span className={'filter-item-field filter-item-field-checkbox'}>
                        <Checkbox
                          label={t('filters.fashion')}
                          checked={filters.tag10}
                          onChange={() => handlFilterChange('tag10', t('filters.fashion'))}
                        />
                      </span>
                      <span className={'filter-item-field filter-item-field-checkbox'}>
                        <Checkbox
                          label={t('filters.tradition')}
                          checked={filters.tag11}
                          onChange={() => handlFilterChange('tag11', t('filters.tradition'))}
                        />
                      </span>
                      <span className={'filter-item-field filter-item-field-checkbox'}>
                        <Checkbox
                          label={t('filters.skillsKnowledge')}
                          checked={filters.tag12}
                          onChange={() => handlFilterChange('tag12', t('filters.skillsKnowledge'))}
                        />
                      </span>
                      <span className={'filter-item-field filter-item-field-checkbox'}>
                        <Checkbox
                          label={t('filters.localLifestyle')}
                          checked={filters.tag13}
                          onChange={() => handlFilterChange('tag13', t('filters.localLifestyle'))}
                        />
                      </span>
                      <span className={'filter-item-field filter-item-field-checkbox'}>
                        <Checkbox
                          label={t('filters.festival')}
                          checked={filters.tag14}
                          onChange={() => handlFilterChange('tag14', t('filters.festival'))}
                        />
                      </span>
                      <span className={'filter-item-field filter-item-field-checkbox'}>
                        <Checkbox
                          label={t('filters.sports')}
                          checked={filters.tag15}
                          onChange={() => handlFilterChange('tag15', t('filters.sports'))}
                        />
                      </span>
                      <span className={'filter-item-field filter-item-field-checkbox'}>
                        <Checkbox
                          label={t('filters.outdoors')}
                          checked={filters.tag16}
                          onChange={() => handlFilterChange('tag16', t('filters.outdoors'))}
                        />
                      </span>
                      <span className={'filter-item-field filter-item-field-checkbox'}>
                        <Checkbox
                          label={t('filters.resorts')}
                          checked={filters.tag17}
                          onChange={() => handlFilterChange('tag17', t('filters.resorts'))}
                        />
                      </span>
                      <span className={'filter-item-field filter-item-field-checkbox'}>
                        <Checkbox
                          label={t('filters.nature')}
                          checked={filters.tag18}
                          onChange={() => handlFilterChange('tag18', t('filters.nature'))}
                        />
                      </span>
                      <span className={'filter-item-field filter-item-field-checkbox'}>
                        <Checkbox
                          label={t('filters.nightlife')}
                          checked={filters.tag19}
                          onChange={() => handlFilterChange('tag19', t('filters.nightlife'))}
                        />
                      </span>
                      <span className={'filter-item-field filter-item-field-checkbox'}>
                        <Checkbox
                          label={t('filters.hotSprings')}
                          checked={filters.tag20}
                          onChange={() => handlFilterChange('tag20', t('filters.hotSprings'))}
                        />
                      </span>
                    </div> */}
                  {/* </div> */}
                {/* </div> */}
                {/* <div className={'jnto-filter-bar-collapsible-row'}></div> */}
              </>
            </Collapsible>
          </div>
        </div>
      </div>
    )
  }
}

CollapsibleFilterBar.propTypes = {};

export default withTranslation()(CollapsibleFilterBar);
