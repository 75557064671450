import React from 'react';
import { useTranslation } from 'react-i18next';
import ModuleRow from '../shared/rows/ModuleRow/ModuleRow';
import { booleanToYesNo } from '../../utilities/textUtils';
const AssetContextSection = ({ assetContext, publicId, orientation, fileSizeInMB, editMode = false, onEdit }) => {
  const { t } = useTranslation();

  // skip out if no context and dont display this component
  if (!assetContext) { return null; }

  const { caption, description, credit, photographer, jomcAsset, projectName, season, from, remark, departmentInCharge, referenceUrl } = assetContext;
  return (
    <>
      <ModuleRow label={t('common.departmentInCharge')} value={departmentInCharge} editMode={editMode} fieldType={'select'} fieldValues={'departmentInCharge'} onEdit={(value)=>{onEdit('departmentInCharge', value)}} />
      <ModuleRow label={t('common.projectName')} value={projectName} editMode={editMode} fieldType={'text'} onEdit={(value)=>{onEdit('projectName', value)}}/>
      <ModuleRow label={t('common.fileName')} value={caption || publicId} />
      <ModuleRow label={t('common.season')} value={season} editMode={editMode} fieldType={'select'} fieldValues={'season'} onEdit={(value)=>{onEdit('season', value)}}/>
      <ModuleRow label={t('common.orientation')} value={orientation} />
      <ModuleRow label={t('common.referenceUrl')} value={referenceUrl} editMode={editMode} fieldType={'text'} onEdit={(value)=>{onEdit('referenceUrl', value)}}/>
      <ModuleRow label={t('common.from')} value={from} editMode={editMode} fieldType={'text'} onEdit={(value)=>{onEdit('from', value)}}/>
      <ModuleRow label={t('common.credit')} value={credit} editMode={editMode} fieldType={'text'} onEdit={(value)=>{onEdit('credit', value)}} />
      <ModuleRow label={t('common.photographer')} value={photographer} editMode={editMode} fieldType={'text'} onEdit={(value)=>{onEdit('photographer', value)}}/>
      <ModuleRow label={t('common.availableJOMC')} value={booleanToYesNo(jomcAsset)} fieldType={'boolean'}/>
      <ModuleRow label={t('common.description')} value={description} editMode={editMode} fieldType={'text'} onEdit={(value)=>{onEdit('description', value)}}/>
      <ModuleRow label={t('common.remark')} value={remark} editMode={editMode} fieldType={'text'} onEdit={(value)=>{onEdit('remark', value)}}/>
    </>);
};

export default AssetContextSection;