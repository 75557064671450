/** Libraries */
import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Banner, Popover, Button, ActionList, Stack } from '@shopify/polaris';
import { withTranslation } from 'react-i18next';
/** Redux */
import * as folderActions from '../../redux/actions/folderActions';
/** Custom Components */
import NewFolderModal from '../NewFolderModal/NewFolderModal';
import MainGalleryBanner from "../MainGalleryBanner/MainGalleryBanner";
import { withRouter} from 'react-router-dom';
import FloatingGenericModal from "../../components/FloatingGenericModal/FloatingGenericModal";

class AddAssetsToFolderBanner extends React.Component {
    constructor() {
        super();
        this.state = {
            isDropdownActive: false,
            selectedFolder: undefined,
            newFolderName: undefined,
            optionSelected: false,
        };

        // method bindings
        this.togglePopover = this.togglePopover.bind(this);
        this.selectFolder = this.selectFolder.bind(this);
        this.onSetNewFolderName = this.onSetNewFolderName.bind(this);
        this.onSave = this.onSave.bind(this);
    }

    componentDidMount() {
        const { folderActions, userInfoComposite } = this.props;
        folderActions.getFoldersByUserId(userInfoComposite.user.id);
    }

    togglePopover() {
        this.setState(({ isDropdownActive }) => ({ isDropdownActive: !isDropdownActive }));
    }

    onSetNewFolderName() {
        const {assets, userInfoComposite, history, folderActions, onToggleIsLoading, onModalClose} = this.props;
        const {newFolderName} = this.state;
        const userId = userInfoComposite.user.id;
        onToggleIsLoading(true);
        const folderAction = folderActions.addFolderWithAssets(newFolderName, assets, userId);
        folderAction.then((response) => {
            onToggleIsLoading(false);
            onModalClose();
            const folderToNavigate =  response.id;
            history.push(`/folders/` + folderToNavigate);
        })
    }



    selectFolder(e) {
        const {folders} = this.props;

        var selectedFolder = e && e.target && e.target.value;
        for(var folder of folders){
            if ( e && e.target && e.target.value && folder.folderName === e.target.value){
                selectedFolder = folder;
            }
        }
        this.setState({
            selectedFolder: selectedFolder,
            optionSelected: true,
        });
    }

    onSave() {
        const { onToggleIsLoading, onModalClose, folderActions, assets, userInfoComposite, history } = this.props;
        const { selectedFolder } = this.state;
        onToggleIsLoading(true);
        const userId = userInfoComposite.user.id;
        const folderAction = selectedFolder.id ? folderActions.saveFolderWithAssets(selectedFolder, assets, userId)
            : folderActions.addFolderWithAssets(selectedFolder.folderName, assets, userId);
        folderAction.then((response) => {
            onToggleIsLoading(false);
            onModalClose();
            const folderToNavigate = selectedFolder.id || response.id;
            history.push(`/folders/` + folderToNavigate);

        });
    }


    render() {
        const { isDropdownActive, selectedFolder,optionSelected } = this.state;
        const { folders, onModalClose, assets, t, history } = this.props;
        const activator = (
            <Button onClick={this.togglePopover}>
                {selectedFolder && selectedFolder.folderName ? selectedFolder.folderName : t('common.folders')}
            </Button>
        );

        const addNewFolderDropdownItem = {
            content: t('workflows.projects.addNewFolder'),
            onAction: () => {
                this.selectFolder({ id: undefined });
                this.togglePopover();
            }
        };

        const dropdownItems = folders.map(folder => ({
            content: folder.folderName,
            onAction: () => {
                this.selectFolder(folder);
                this.togglePopover();
            }
        }));

        const shoulDisplayNewFolderModal = selectedFolder && !selectedFolder.id && !selectedFolder.folderName;

        return (
            <>
            <MainGalleryBanner assetCount = {assets && assets.length} onSave= {this.onSave} optionDisabled={optionSelected} submitDisabled={!selectedFolder} selectFolder={this.selectFolder} items={[addNewFolderDropdownItem, ...dropdownItems]} />
            <FloatingGenericModal show={shoulDisplayNewFolderModal}>
            <div className='generic-modal'>
            <div className='generic-form'>
            <h2>{t('workflows.projects.addNewFolder')}</h2>
                <div className='field-group'>
                <label>{t('workflows.folders.newFolderName')}</label>
                <input type='text' name='field1' onChange={(e) => this.setState({newFolderName : e.target.value})}/>
                </div>
    
            </div>
            <div className="actions">
                <button className="text-center dui-btn dui-btn-secondary-alt" onClick={(e)=> {this.selectFolder(undefined);this.setState({newFolderName : undefined}) }}>
                {t('form.label.cancel')}
                </button>
    
                <button className="text-center dui-btn dui-btn-primary" onClick={this.onSetNewFolderName} disabled={!this.state}>
                {t('form.label.save')}
                </button>
    
            </div>
            </div>
            </FloatingGenericModal>
            </>);
    }
};

const mapStateToProps = (state) => ({
    folders: state.folder.folders,
    userInfoComposite: state.user.userInfoComposite,
});

const mapDispatchToProps = (dispatch) => ({
    folderActions: bindActionCreators(folderActions, dispatch),
});

export default withRouter((withTranslation()(connect(mapStateToProps, mapDispatchToProps)(AddAssetsToFolderBanner))));
