import React from "react";
import Sidebar from '../../components/Navigation/SideBar/SideBar';
import { Route, Switch } from 'react-router-dom';
import { withRouter } from 'react-router-dom';
import UserGuideUpload01 from '../../components/UserGuideSections/UserGuideUpload01';
import UserGuideUpload02 from '../../components/UserGuideSections/UserGuideUpload02';
import UserGuideUpload03 from '../../components/UserGuideSections/UserGuideUpload03';
import UserGuideUpload04 from '../../components/UserGuideSections/UserGuideUpload04';
import UserGuideUpload05 from '../../components/UserGuideSections/UserGuideUpload05';
import UserGuideWelcomePage from '../../components/UserGuideSections/UserGuideWelcomePage';
import UserGuideLogin from "../../components/UserGuideSections/UserGuideLogin";
import UserGuideLogout from "../../components/UserGuideSections/UserGuideLogout";
import UserGuideResetPassword from "../../components/UserGuideSections/UserGuideResetPassword";
import UserGuideUserAuth from "../../components/UserGuideSections/UserGuideUserAuth";
import UserGuideNavigation from "../../components/UserGuideSections/UserGuideNavigation";
import UserGuideAssetsDetails from "../../components/UserGuideSections/UserGuideAssetsDetails";
import UserGuideAssetsGallery from "../../components/UserGuideSections/UserGuideAssetsGallery";
import UserGuideUpload from "../../components/UserGuideSections/UserGuideUpload";
import UserGuideAssetsAbout from "../../components/UserGuideSections/UserGuideAssetsAbout";
import UserGuideAssetsEdit from "../../components/UserGuideSections/UserGuideAssetsEdit";
import UserGuideMyBag from "../../components/UserGuideSections/UserGuideMyBag";
import UserGuideMyBagCreate from "../../components/UserGuideSections/UserGuideMyBagCreate";
import UserGuideMyBagEdit from "../../components/UserGuideSections/UserGuideMyBagEdit";
import UserGuideMyBagDelete from "../../components/UserGuideSections/UserGuideMyBagDelete";
import UserGuideDownload from "../../components/UserGuideSections/UserGuideDownload";
import UserGuideDownload01 from "../../components/UserGuideSections/UserGuideDownload01";
import UserGuideDownload02 from "../../components/UserGuideSections/UserGuideDownload02";
import UserGuideAssetsDelete from "../../components/UserGuideSections/UserGuideAssetsDelete";

class ManualPage extends React.Component {
  constructor() {
    super();
    this.state = {}
  }
  render() {
    const { match } = this.props;
    return (
      <>
        <Sidebar>
          {(onUpdateSideBarView) => {
            onUpdateSideBarView('manual');
            return <React.Fragment>
              <div className="app">
                <Switch>
                  <Route
                    path={`${match.url}/auth`}
                    component={UserGuideUserAuth}
                  />
                  <Route
                    path={`${match.url}/login`}
                    component={UserGuideLogin}
                  />
                  <Route
                    path={`${match.url}/logout`}
                    component={UserGuideLogout}
                  />
                  <Route
                    path={`${match.url}/reset-password`}
                    component={UserGuideResetPassword}
                  />
                  <Route
                    path={`${match.url}/navigation`}
                    component={UserGuideNavigation}
                  />
                  <Route
                    path={`${match.url}/upload`}
                    component={UserGuideUpload}
                  />
                  <Route
                    path={`${match.url}/upload-step1`}
                    component={UserGuideUpload01}
                  />
                  <Route
                    path={`${match.url}/upload-step2`}
                    component={UserGuideUpload02}
                  />
                  <Route
                    path={`${match.url}/upload-step3`}
                    component={UserGuideUpload03}
                  />
                  <Route
                    path={`${match.url}/upload-step4`}
                    component={UserGuideUpload04}
                  />
                  <Route
                    path={`${match.url}/upload-step5`}
                    component={UserGuideUpload05}
                  />
                  <Route
                    path={`${match.url}/assets-about`}
                    component={UserGuideAssetsAbout}
                  />
                  <Route
                    path={`${match.url}/assets-details`}
                    component={UserGuideAssetsDetails}
                  />
                  <Route
                    path={`${match.url}/assets-gallery`}
                    component={UserGuideAssetsGallery}
                  />
                  <Route
                    path={`${match.url}/assets-edit`}
                    component={UserGuideAssetsEdit}
                  />
                  <Route
                    path={`${match.url}/assets-delete`}
                    component={UserGuideAssetsDelete}
                  />
                  <Route
                    path={`${match.url}/my-bag`}
                    component={UserGuideMyBag}
                  />
                  <Route
                    path={`${match.url}/my-bag-create`}
                    component={UserGuideMyBagCreate}
                  />
                  <Route
                    path={`${match.url}/my-bag-edit`}
                    component={UserGuideMyBagEdit}
                  />
                  <Route
                    path={`${match.url}/my-bag-delete`}
                    component={UserGuideMyBagDelete}
                  />
                  <Route
                    path={`${match.url}/download`}
                    component={UserGuideDownload}
                  />
                  <Route
                    path={`${match.url}/download-step1`}
                    component={UserGuideDownload01}
                  />
                  <Route
                    path={`${match.url}/download-step2`}
                    component={UserGuideDownload02}
                  />
                  <Route
                    component={UserGuideWelcomePage}
                  />
                </Switch>
              </div>
            </React.Fragment>
          }}
        </Sidebar>
      </>)
  }
}

export default withRouter(ManualPage);
