import i18n from 'i18next';
export const tabs = function(){
    return [
        {
            id: 'incoming-permissions',
            content: i18n.t('workflows.myPermissionRequests.tabLabels.incoming'),
            panelID: 'incoming-content',
        },
        {
            id: 'outgoing-permissions',
            content: i18n.t('workflows.myPermissionRequests.tabLabels.outgoing'),
            panelID: 'outgoing-content',
        },
    ];
}

export const TAB_ENUMS = {
    INCOMING_REQUESTS: 0,
    OUTGOING_REQUESTS: 1,
}