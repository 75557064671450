import * as types from "../constants/action-types";
import initialState from './initialState';

export default function projectReducer(state = initialState.project, action) {
    let newState;
    switch (action.type) {
        case types.GET_PROJECTS_SUCCESS:
            newState = {
                ...state,
                projects: action.projects,
            };
            break;
        case types.SELECT_PROJECT_SUCCESS:
            newState = {
                ...state,
                selectedProject: action.selectedProject,
            };
            break;
        case types.GET_ADMIN_PROJECTS_SUCCESS:
            newState = {
                ...state,
                adminProjects: action.adminProjects,
            };
            break;
        default:
            break;
    }

    return newState || state;
};